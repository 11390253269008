import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { BASE_URL } from "./../global";
import { useEffect } from "react";
import EditTypeA from "./typeA/editTypeA";
import EditTypeB from "./typeB/editTypeB";
import EditBudget from "./budget/editBudget";
const axios = require("axios");

export default function ViewAll() {
  const {
    instrumentId,
    lc,
    range,
    datasheetId,
    tableId,
    // nominalVal,
    // datasheetReadingId,
  } = useParams();

  const [datasheetStaticReadings, setDatasheetStaticReadings] = useState([]);

  function fetchStaticReadingRows() {
    // for static datasheet
    axios
      .get(
        BASE_URL +
          `datasheetStaticReadings?_where=(datasheetId,eq,${datasheetId})`
      )
      .then((res) => {
        setDatasheetStaticReadings(res.data);
      })
      .catch((err) => {
        console.log("datasheetStaticReadings fetching error: ", err);
      });
  }

  useEffect(() => {
    fetchStaticReadingRows();
  }, []);

  return (
    <>
      {datasheetStaticReadings &&
        datasheetStaticReadings.map((reading) => {
          if(reading.c1.includes("_unit_") || reading.c1.includes("_rh_"))
            return null;
          // else
          return (
            <>
              <EditTypeA
                datasheetReadingId={reading.id}
                tableId={reading.tableId}
                datasheetId={reading.datasheetId}
                viewAll={true}
              />
              <EditTypeB
                datasheetReadingId={reading.id}
                datasheetId={reading.datasheetId}
                instrumentId={instrumentId}
                nominalVal={reading.c1}
                lc={lc}
                viewAll={true}
              />
              <EditBudget
                datasheetReadingId={reading.id}
                datasheetId={reading.datasheetId}
                instrumentId={instrumentId}
                nominalVal={reading.c1}
                lc={lc}
                range={range}
                viewAll={true}
                
              />
            </>
          );
        })}
    </>
  );
}
