import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Button,
  Toolbar,
} from "@mui/material";

const axios = require("axios");
export default function EditProduct(props) {
  const [rate, setRate] = React.useState("");
  const [finalProductName, setFinalProductName] = React.useState("");

  const params = useParams();
  const getProductIdList = () => {
    let url = BASE_URL;
    axios
      .get(url + `customProducts/${params.id}`)
      .then((res) => {
        setFinalProductName(res.data[0].productName);
        setRate(res.data[0].rate);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  useEffect(() => {
    getProductIdList();
  }, []);

  const onSubmit = (id) => {
    var jsonData = {
      productName: finalProductName,
      rate: rate,
    };
    let url = BASE_URL;

    var refresh = () => {
      window.location.reload(false);
    };
    axios
      .patch(url + `customProducts/${params.id}`, jsonData)
      .then((res) => {
        props.setLoader(false);
        toast("updated Products!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        props.setLoader(false);
        toast.error("Something Went Wrong!");
      });
  };

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Edit Product
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Product Name *"
            size="small"
            value={finalProductName}
            fullWidth
            variant="outlined"
            onChange={(e) => setFinalProductName(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="Rate *"
              size="small"
              value={rate}
              fullWidth
              variant="outlined"
              onChange={(e) => setRate(e.target.value)}
            />
          </Grid>
        </Grid>
      </Grid>

      <Toolbar style={{ padding: "0px" }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            props.setLoader(true);
            onSubmit();
          }}
        >
          Edit Product
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
