import React, { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";

import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Collapse from "@mui/material/Collapse";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Settings from "@mui/icons-material/Settings";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemButton from "@mui/material/ListItemButton";
import StarBorder from "@mui/icons-material/StarBorder";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import BuildIcon from "@mui/icons-material/Build";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import CategoryIcon from "@mui/icons-material/Category";
import GroupIcon from "@mui/icons-material/Group";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import TableViewIcon from "@mui/icons-material/TableView";
import ArticleIcon from "@mui/icons-material/Article";
import TableChartIcon from "@mui/icons-material/TableChart";
import useGlobalStorage from "use-global-storage";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import clientcertificateList from "../clientCertificate/clientCertificateList";
import { useNavigate } from "react-router-dom";

const drawerWidth = 220;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function ClientNavbar(props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [masterNested, setMasterNested] = React.useState(false);
  const [srfNested, setSrfNested] = React.useState(false);
  const userName = localStorage.getItem("userName");
  const userType = localStorage.getItem("type");

  const useStorage = useGlobalStorage({
    storageOptions: { name: "test-db" },
  });
  const [title, setTitle] = useStorage("title");
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logout = () => {
    props.setIsLoggedIn(false);
    window.localStorage.clear();
    navigate("/");
  };

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar variant="dense">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
            align="left"
          >
            {title}
          </Typography>
          <Button color="inherit" onClick={logout}>
            <AccountCircleIcon />
            &nbsp;
            {userName} &nbsp;
            <span style={{ textTransform: "lowercase" }}>
              ({["admin", "user", "client"][userType - 1]})
            </span>
            &nbsp;&nbsp; | &nbsp;&nbsp;Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <Typography variant="h6" gutterBottom component="div">
            Calibration
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <List
          sx={{
            width: "100%",
            maxWidth: 360,
            bgcolor: "background.paper",
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <ListItemButton
            component={Link}
            to="/clientDashboard"
            onClick={() => setTitle("DASHBOARD")}
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
          {/* <ListItemButton
                        onClick={(e) => setMasterNested(!masterNested)}
                    >
                        <ListItemIcon>
                            <FormatListBulletedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Master" />
                        {masterNested ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton> */}
          {/* <Collapse in={masterNested} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemButton
                                sx={{ pl: 4 }}
                                component={Link}
                                to="/master/standard"
                                onClick={() => setTitle("STANDARD")}
                            >
                                <ListItemIcon>
                                    <BuildIcon />
                                </ListItemIcon>
                                <ListItemText primary="Standard" />
                            </ListItemButton>
                            <ListItemButton
                                sx={{ pl: 4 }}
                                component={Link}
                                to="/master/instrument"
                                onClick={() => setTitle("INSTRUMENT")}
                            >
                                <ListItemIcon>
                                    <PrecisionManufacturingIcon />
                                </ListItemIcon>
                                <ListItemText primary="Instrument" />
                            </ListItemButton>
                            <ListItemButton
                                sx={{ pl: 4 }}
                                component={Link}
                                to="/master/discipline"
                                onClick={() => setTitle("DISCIPLINE")}
                            >
                                <ListItemIcon>
                                    <ArchitectureIcon />
                                </ListItemIcon>
                                <ListItemText primary="Discipline" />
                            </ListItemButton>
                            <ListItemButton
                                sx={{ pl: 4 }}
                                component={Link}
                                to="/master/product"
                                onClick={() => setTitle("Product")}
                            >
                                <ListItemIcon>
                                    <ArchitectureIcon />
                                </ListItemIcon>
                                <ListItemText primary="Product" />
                            </ListItemButton>
                            <ListItemButton
                                sx={{ pl: 4 }}
                                component={Link}
                                to="/master/client"
                                onClick={() => setTitle("CLIENT")}
                            >
                                <ListItemIcon>
                                    <GroupIcon />
                                </ListItemIcon>
                                <ListItemText primary="Client" />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4 }}
                            component={Link}
                            to="/master/users/addUser"
                            onClick={() => setTitle("Users")}
                            >
                                <ListItemIcon>
                                    <ManageAccountsIcon />
                                </ListItemIcon>
                                <ListItemText primary="User" />
                            </ListItemButton>{" "}
                            <ListItemButton
                                sx={{ pl: 4 }}
                                component={Link}
                                to="/master/table"
                                onClick={() => setTitle("Dynamic Table")}
                            >
                                <ListItemIcon>
                                    <TableChartIcon />
                                </ListItemIcon>
                                <ListItemText primary="Table" />
                            </ListItemButton>
                        </List>
                    </Collapse> */}
          {/* <ListItemButton onClick={(e) => setSrfNested(!srfNested)}>
                        <ListItemIcon>
                            <FormatListBulletedIcon />
                        </ListItemIcon>
                        <ListItemText primary="SRF" />
                        {srfNested ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={srfNested} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemButton
                                sx={{ pl: 4 }}
                                component={Link}
                                to="/srf/createSRF"
                                onClick={() => setTitle("SRF")}
                            >
                                <ListItemIcon>
                                    <BuildIcon />
                                </ListItemIcon>
                                <ListItemText primary="Create New" />
                            </ListItemButton>
                            <ListItemButton
                                sx={{ pl: 4 }}
                                component={Link}
                                to="/srf"
                                onClick={() => setTitle("SRF")}
                            >
                                <ListItemIcon>
                                    <PrecisionManufacturingIcon />
                                </ListItemIcon>
                                <ListItemText primary="View List" />
                            </ListItemButton>
                        </List>
                    </Collapse> */}
          {/* <ListItemButton
                        component={Link}
                        to="/datasheet"
                        onClick={() => setTitle("Datasheet")}
                    >
                        <ListItemIcon>
                            <TableViewIcon />
                        </ListItemIcon>
                        <ListItemText primary="Datasheets" />
                    </ListItemButton> */}
          <ListItemButton
            component={Link}
            to="/certificate"
            onClick={() => setTitle("CERTIFICATE")}
          >
            <ListItemIcon>
              <ArticleIcon />
            </ListItemIcon>
            <ListItemText primary="Certificates" />
          </ListItemButton>
          <ListItemButton
            component={Link}
            to="/clientHistoryCard"
            onClick={() => setTitle("history card")}
          >
            <ListItemIcon>
              <ArticleIcon />
            </ListItemIcon>
            <ListItemText primary="history card" />
          </ListItemButton>
          <ListItemButton
            component={Link}
            to="/clientRenewal"
            onClick={() => setTitle("renewals")}
          >
            <ListItemIcon>
              <ArticleIcon />
            </ListItemIcon>
            <ListItemText primary="renewals" />
          </ListItemButton>
          <ListItemButton
            component={Link}
            to="/pickup"
            onClick={() => setTitle("pickup")}
          >
            <ListItemIcon>
              <ArticleIcon />
            </ListItemIcon>
            <ListItemText primary="pickup" />
          </ListItemButton>
          <ListItemButton
            component={Link}
            to="/quotation"
            onClick={() => setTitle("Quotation")}
          >
            <ListItemIcon>
              <ArticleIcon />
            </ListItemIcon>
            <ListItemText primary="Quotation" />
          </ListItemButton>
          <ListItemButton
            component={Link}
            to="/invoice"
            onClick={() => setTitle("Invoice")}
          >
            <ListItemIcon>
              <ArticleIcon />
            </ListItemIcon>
            <ListItemText primary="Invoice" />
          </ListItemButton>
          <ListItemButton
            component={Link}
            to="/master/scope/scopes"
            onClick={() => setTitle("Nable scope")}
          >
            <ListItemIcon>
              <ArticleIcon />
            </ListItemIcon>
            <ListItemText primary="Nable scope" />
          </ListItemButton>
          <ListItemButton
            component={Link}
            to="/master/feedback/feedbackList"
            onClick={() => setTitle("Feedback")}
          >
            <ListItemIcon>
              <ArticleIcon />
            </ListItemIcon>
            <ListItemText primary="Feedback" />
          </ListItemButton>
        </List>
      </Drawer>
    </>
  );
}
