import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../global";
import PrintIcon from "@mui/icons-material/Print";

import Tooltip from "@mui/material/Tooltip";

const _clientId = localStorage.getItem("clientId");
const userType = localStorage.getItem("type");
// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const axios = require("axios");

const columns = [
  { id: "id", label: "Sr. No.", minWidth: 40 },
  { id: "clientName", label: "Company Name", minWidth: 40 },
  {
    id: "poNumber",
    label: "PO Number",
    minWidth: 40,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "poDate",
    label: "PO Date",
    minWidth: 40,
    align: "left",
    format: (value) => value.toFixed(2),
  },
];

function createData(id, clientName, clientId, poNumber, poDate) {
  return {
    id,
    clientName,
    clientId,
    poNumber,
    poDate,
  };
}

export default function CustomInvoiceList() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [invoiceList, setInvoiceList] = React.useState([]);
  const [invoiceData, setInvoiceData] = React.useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const getInvoiceList = (event) => {
    let url = BASE_URL;
    if (userType == "1" && userType) {
      axios
        .get(
          url +
            `xjoin?_join=invoice.customInvoice,_j,client.clients&_on1=(invoice.clientId,eq,client.id)&_fields=client.companyName,invoice.clientId,invoice.poNumber,invoice.poDate,invoice.id`
        )
        .then((res) => {
          setInvoiceList(res.data);
          initializeDataTable();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios
        .get(
          url +
            `xjoin?_join=invoice.customInvoice,_j,client.clients&_on1=(invoice.clientId,eq,client.id)&_fields=client.companyName,invoice.clientId,invoice.poNumber,invoice.poDate,invoice.id&_where=(invoice.clientId,eq,${_clientId})`
        )
        .then((res) => {
          setInvoiceList(res.data);
          initializeDataTable();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const initializeDataTable = () => {
    $("#datatable-keytable").DataTable({
      destroy: true,
      keys: true,
      dom:
        "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
        "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
        "<'row'<'col-sm-12' tr>>" +
        "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
      bPaginate: false,
      bLengthChange: true,
      bFilter: true,
      bInfo: false,
      bAutoWidth: true,
      language: {
        zeroRecords: " ",
      },

      buttons: ["copy", "csv", "excel", "print"],
    });
  };

  const initiatInvoiceList = () => {
    var rowData = [];

    for (let i = 0; i < invoiceList.length; i++) {
      rowData.push(
        createData(
          invoiceList[i].invoice_id,
          invoiceList[i].client_companyName,
          invoiceList[i].invoice_clientId,
          invoiceList[i].invoice_poNumber,
          invoiceList[i].invoice_poDate
            ? moment(invoiceList[i].invoice_poDate).format("DD-MM-YYYY")
            : ""
        )
      );
    }
    setInvoiceData(
      rowData.filter(
        (v, i, a) =>
          a.findIndex((v2) =>
            ["clientName", "poNumber"].every((k) => v2[k] === v[k])
          ) === i
      )
    );
  };

  useEffect(() => {
    initiatInvoiceList();
  }, [invoiceList]);

  useEffect(() => {
    getInvoiceList();
  }, []);

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Toolbar>
        <Button
          variant="contained"
          size="small"
          component={Link}
          to="/customInvoice/createInvoice"
        >
          ADD NEW CUSTOME INVOICE
        </Button>
      </Toolbar>
      <hr />

      <Table
        stickyHeader
        aria-label="sticky table"
        size="small"
        id="datatable-keytable"
      >
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                <b>{column.label}</b>
              </TableCell>
            ))}
            <TableCell>
              <b>Actions</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoiceData
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    let value = row[column.id];
                    if (column.id == "id") {
                      value = index + 1;
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}

                  <TableCell>
                    <ButtonGroup size="small" aria-label="small button group">
                      <Tooltip title="Edit Invoice" placement="top-start">
                        <Button
                          style={{
                            borderColor: "#dc3545",
                          }}
                          component={Link}
                          to={`/customInvoice/editInvoice/${row.clientId}/${row.poNumber}/${row.id}`}
                        >
                          <PreviewIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Delete Invoice" placement="top-start">
                        <Button
                          onClick={(e) => {
                            if (
                              window.confirm("Really want to delete standard?")
                            ) {
                              // submitSiteDelete(row.id);
                            }
                          }}
                          style={{
                            borderColor: "#dc3545",
                          }}
                        >
                          <DeleteIcon style={{ color: "#dc3545" }} />
                        </Button>
                      </Tooltip>
                      <Tooltip title="View Invoice" placement="top-start">
                        <Button
                          component={Link}
                          to={`/customInvoice/viewCustomInvoice/${row.clientId}/${row.poNumber}/${row.id}`}
                        >
                          <PrintIcon />
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={invoiceData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ToastContainer />
    </TableContainer>
  );
}
