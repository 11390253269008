import * as React from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Checkbox,
  Toolbar,
  Button
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useEffect } from "react";
const axios = require("axios");
export default function AddUserMaster() {
  const [userName, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [modules, setModules] = React.useState(null);
  const [selectedModules, setSelectedModules] = React.useState(null);
  const [clientId, setClientId] = React.useState(null);
  const [executing, setExecuting] = React.useState(false);  

  const onSubmit = () => {
    setExecuting(true);
    var jsonData = {
      userName: userName,
      password: password,
    };
    if(clientId){
      jsonData["clientId"] = clientId
      jsonData["type"] = 3
    }
    if(selectedModules){
      jsonData["modules"] = selectedModules
      jsonData["type"] = 2
    }

    axios
      .post(BASE_URL + "users", jsonData)
      .then((res) => {
        toast("new user added!");
        setTimeout(refresh, 1000);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };


  const initModules = ()=>{
    setModules([
      {
        "id": 1,
        "name": "dashboard",
      },
      {
        "id": 2,
        "name": "master",
      },
      {
        "id": 3,
        "name": "SRF",
      },
      {
        "id": 4,
        "name": "Datasheet",
      },
      {
        "id": 5,
        "name": "Certificates",
      },

    ])
  }
  useEffect(()=>{
    initModules()
  },[])


  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Create new user
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Username *"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => setUserName(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Password *"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => setPassword(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Client ID (optional)"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => setClientId(e.target.value)}
          />
        </Grid>
        <Grid item xs={9}>
            <Autocomplete
              multiple
              fullWidth
              id="checkboxes-tags-demo"
              size="small"
              options={
                modules != null
                  ? modules.map((item) => {
                      return {
                        id: item.id,
                        name: item.name
                      };
                    })
                  : []
              }
              disableCloseOnSelect
              getOptionLabel={(option) => option.name}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  
                  {option.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select modules (optional)"
                  placeholder="select multiple modules"
                />
              )}
              onChange={(event, value) => {
                if (value.length) {
                  console.log(value)
                  var arrToStr = Array.prototype.map
                    .call(value, function (item) {
                      return item.id;
                    })
                    .join(",");
                  setSelectedModules(
                    arrToStr);
                } else {
                  setSelectedModules(null);
                }
              }}
            />
          </Grid>
      </Grid>
      <br />


      <Toolbar style={{ padding: "0px" }} sx={{ mt: 5 }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          disabled={executing}
          onClick={() => {
            // enqueueSnackbar("Standard added successfully !", {
            //     variant: "success",
            // });
            onSubmit();
          }}
        >
          Save user
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
