import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextareaAutosize,
  Stack,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useSnackbar } from "notistack";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import Checkbox from "@mui/material/Checkbox";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { DataGrid } from "@mui/x-data-grid";
import {
  randomCreatedDate,
  randomTraderName,
  randomUpdatedDate,
} from "@mui/x-data-grid-generator";
import { ClassicTable } from "../../utils/components/Styles";
import { API_URL } from "../../../src/global";
import { insert, view } from "../../components/common/api";
import { integerPropType } from "@mui/utils";
import { parse, set } from "date-fns";

const axios = require("axios");

export default function EditChallan() {
  const [clientId, setClientId] = React.useState("");
  const [challanNo, setChallanNo] = React.useState("");
  const [challanDate, setChallanDate] = React.useState("");
  const [dcNumber, setDcNumber] = React.useState("");
  const [dcDate, setDcDate] = React.useState("");
  const params = useParams();
  const userType = localStorage.getItem("type");
  const [quantity, setQuantity] = React.useState(0);
  const [amount, setAmount] = React.useState(0);
  const [challanReadingsData, setChallanReadingsData] = React.useState([]);
  const [deletedIds, setDeletedIds] = React.useState([]);
  const [readingRows, setReadingRows] = React.useState([]);
  const [totalQt, setTotalQt] = React.useState(0);

  const readingColumns = [
    [
      {
        field: "id",
        headerName: "Sr. No.",
        editable: false,
      },
      {
        field: "discription",
        headerName: "Discription",
        editable: true,
      },
      {
        field: "size",
        headerName: "Size",
        editable: true,
      },
      {
        field: "slNo",
        headerName: "Sl No.",
        editable: true,
      },
      {
        field: "idNo",
        headerName: "Id No.",
        editable: true,
      },
      {
        field: "quantity",
        headerName: "Quantity",
        editable: true,
      },
      {
        field: "remarks",
        headerName: "Remarks",
        editable: true,
      },
      {
        field: "Action",
      },
    ],
  ];

  const getChallanData = () => {
    let url = BASE_URL;
    axios
      .get(
        url +
          `xjoin?_join=challan.challanMaster,_j,client.clients,_j&_on1=(client.id,eq,challan.clientId)&_fields=challan.clientId,client.companyName,challan.challanNo,challan.challanDate,challan.dcNumber,challan.dcDate,challan.id&_where=(challan.id,eq,${params.id})`
      )
      .then((res) => {
        console.log(res.data);
        setClientId(res.data[0].client_companyName);
        setChallanNo(res.data[0].challan_challanNo);
        setChallanDate(res.data[0].challan_challanDate);
        setDcNumber(res.data[0].challan_dcNumber);
        setDcDate(res.data[0].challan_dcDate);
      })
      .catch((error) => {
        toast.error("Something Went Wrong with standards!");
      });
  };

  const counterAmt = () => {
    var count = 0;
    for (let i = 0; i < readingRows.length; i++) {
      if (readingRows[i][5] != "") {
        count += parseInt(readingRows[i][5]);
      }
    }
    setTotalQt(count);
  };

  useEffect(() => {
    counterAmt();
  }, [readingRows]);


  const clearPrevRanges = (ids) => {
    console.log(ids);
    ids = [...ids, ...deletedIds];
    if (ids.length < 1) return;
    axios
      .delete(BASE_URL + `challanReadings/bulk?_ids=${ids.join(",")}`)
      .then((res) => {})
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const updateCellValue = (rowIndex, colIndex, value) => {
    const newRows = [...readingRows];
    newRows[rowIndex][colIndex] = value;
    setReadingRows([...newRows]);
  };

  const addRangeRow = () => {
    let newReadingRows = [...readingRows];
    newReadingRows.push([-1, "", "", "", "", "", ""]);
    setReadingRows([...newReadingRows]);
  };

  const deleteReadingRow = (rowIndex) => {
    let newReadingRows = [...readingRows];
    if (newReadingRows[rowIndex] != undefined) {
      setDeletedIds([...deletedIds, newReadingRows[rowIndex][0]]);
      newReadingRows.splice(rowIndex, 1);
      setReadingRows([...newReadingRows]);
    }
  };

  //   const deleteReadingRow = (tableIndex, rowIndex) => {
  //     let newReadingRows = [...readingRows];
  //     newReadingRows.splice(rowIndex, 0);
  //     setReadingRows([...newReadingRows]);
  //   };

  const getChallanReadingsArray = () => {
    axios
      .get(BASE_URL + `challanReadings?_where=(challanId,eq,${params.id})`)
      .then((res) => {
        setChallanReadingsData(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong while fetching standardRanges!");
      });
  };

  const handleSubmit = async (event) => {
    let rangeIds = [];
    let rows = [];
    for (let i = 0; i < readingRows.length; i++) {
      rows.push({
        challanId: parseInt(params.id),
        discription: readingRows[i][1],
        size: readingRows[i][2],
        slNo: readingRows[i][3],
        idNo: readingRows[i][4],
        quantity: readingRows[i][5],
        remarks: readingRows[i][6],
        totalQuantity:0
      });
      Number(readingRows[i][0]) && rangeIds.push(readingRows[i][0]);
    }

    let row = {
      challanNo: challanNo,
      challanDate: challanDate
        ? moment(challanDate).format("YYYY-MM-DD")
        : null,
      dcNumber: dcNumber,
      dcDate: dcDate ? moment(dcDate).format("YYYY-MM-DD") : null,
    };
    let url = BASE_URL;

    await clearPrevRanges(rangeIds);

    axios
      .patch(url + `challanMaster/${params.id}`, row)
      .then((res) => {
        toast("Challan updated successfully !");
        setTimeout(refresh, 1000);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });

    if (rows.length > 0) {
      axios
        .post(url + "challanReadings/bulk", rows)
        .then((res) => {
          console.log("saved");
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    }
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const initiateChData = () => {
    try {
      let rows = [];
      for (let i = 0; i < challanReadingsData.length; i++) {
        rows.push([
          challanReadingsData[i].id,
          challanReadingsData[i].discription,
          challanReadingsData[i].size,
          challanReadingsData[i].slNo,
          challanReadingsData[i].idNo,
          challanReadingsData[i].quantity,
          challanReadingsData[i].remarks,
        ]);
      }
      setReadingRows(rows);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getChallanData();
    getChallanReadingsArray();
  }, []);

  useEffect(() => {
    initiateChData();
  }, [challanReadingsData]);

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Edit Challan
      </Typography>
      <Grid container spacing={2} style={{ marginBottom: "10px" }}>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Company Name"
            size="small"
            InputLabelProps={{ shrink: true }}
            value={clientId}
            fullWidth
            disabled
            variant="outlined"
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Challan Number"
            size="small"
            InputLabelProps={{ shrink: true }}
            disabled
            value={challanNo}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setChallanNo(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Challan Date"
              value={challanDate ? challanDate : new Date()}
              inputFormat="dd/MM/yyyy"
              onChange={(newValue) => {
                setChallanDate(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="DC Number"
            size="small"
            value={dcNumber}
            fullWidth
            InputLabelProps={{ shrink: true }}
            disabled
            variant="outlined"
          />
        </Grid>
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Dc Date"
              value={dcDate ? dcDate : new Date()}
              inputFormat="dd/MM/yyyy"
              onChange={(newValue) => {
                setDcDate(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <hr />
      <h4 style={{ "margin-bottom": "0px" }}>Challan Details </h4>
      <Button
        style={{ float: "right" }}
        onClick={(e) => {
          addRangeRow(0);
        }}
      >
        <AddIcon />
      </Button>
      <div style={{ width: "100%", overflow: "auto" }}>
        <ClassicTable>
          <Table sx={{ minWidth: 660 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {readingColumns[0].map((column) => (
                  <TableCell key={column.field}>
                    {" "}
                    <Typography noWrap>{column.headerName}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {readingRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  {row.map((cell, cellIndex) => {
                    if (cellIndex > 0) {
                      return (
                        <TableCell>
                          <TextField
                            size="small"
                            value={cell}
                            onChange={(e) => {
                              updateCellValue(index, cellIndex, e.target.value);
                            }}
                          ></TextField>
                        </TableCell>
                      );
                    }
                  })}
                  <TableCell>
                    <DeleteIcon
                      style={{ color: "#dc3545" }}
                      onClick={(e) => {
                        deleteReadingRow(0, index);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ClassicTable>
        <br />
      <Grid
        container
        spacing={2}
        style={{ marginBottom: "10px" }}
        justifyContent="flex-end"
      >
        <Grid item xs={3} justify="flex-end">
          <TextField
            id="outlined-basic"
            label="Total Quantity"
            size="small"
            fullWidth
            // value={gstType==1?totalAmount - (18 * totalAmount/100):totalAmount-(9 * totalAmount/100)}
            value={totalQt}
            InputLabelProps={{ shrink: true }}
            disabled
            variant="outlined"
            //  onChange={(e) => {
            //   setTotalDiscount(e.target.value * totalAmount/100);
            // }}
          />
        </Grid>
      </Grid>
        {/* <br /> */}
        {/* <Grid
          container
          spacing={2}
          style={{ marginBottom: "10px", marginTop: "10px" }}
          justifyContent="flex-end"
        >
          <Grid item xs={3} justify="flex-end">
            <TextField
              id="outlined-basic"
              label="Total Quantity"
              size="small"
              fullWidth
              value={quantity}
              InputLabelProps={{ shrink: true }}
              disabled
              variant="outlined"
              //  onChange={(e) => {
              //   setTotalDiscount(e.target.value * totalAmount/100);
              // }}
            />
          </Grid>
        </Grid> */}

        <br />
      </div>

      <br />
      <br />
      <Toolbar style={{ padding: "0px", overflow: "auto" }}>
        {userType !== "3" ? (
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            onClick={() => {
              handleSubmit();
            }}
          >
            Save
          </Button>
        ) : (
          console.log("")
        )}
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0, ml: 2 }}
          onClick={() => {
            // handleSubmit();
          }}
        >
          Print
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
