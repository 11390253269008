import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { ClassicTable } from "../../../utils/components/Styles";
const axios = require("axios");

export default function CreateStandard(props) {
  const [standardName, setStandardName] = React.useState("");
  const [make, setMake] = React.useState("");
  const [traceability, setTraceability] = React.useState("");
  const [validUpto, setValidUpto] = React.useState("");
  const [certificateNumber, setCertificateNumber] = React.useState("");
  const [disciplines, setDisciplineArray] = React.useState([]);
  const [srfObject, createSrfObject] = React.useState({});
  const [standardRanges, setStandardRanges] = React.useState([]);
  const [curruntsetDecipline, setDeciplines] = React.useState("");
  const [calDate, setCalDate] = React.useState("");
  const [stId, setStId] = React.useState("");
  const [readingRows, setReadingRows] = React.useState([]);
  const [readingRangeValue, setReadingRangeValue] = React.useState([]);
  const [disciplineList, setDisciplineList] = React.useState([]);
  const [deletedIds, setDeletedIds] = React.useState([]);
  const [updatedArray, setUpdatedArray] = React.useState([]);
  const [unitFilter, setUnitFilter] = React.useState([]);
  const [unitArray, setUnitArray] = React.useState([]);
  const [units, setUnits] = React.useState([]);
  const [percent, setPercent] = React.useState(false);
  const [plus, setPlus] = React.useState(false);
  const [acc, setAccuracy] = React.useState(false);
  const [ids, setId] = React.useState("");

  const params = useParams();
  var readingColumns = [
    [
      {
        field: "id",
        headerName: "Sr. No.",
        editable: false,
      },
      {
        field: "range",
        headerName: "Range",
        editable: true,
      },
      {
        field: "uncertainty",
        headerName: "Uncertainty",
        editable: true,
      },
      {
        field: "accuracy",
        headerName: "Accuracy",
        editable: true,
      },
      {
        field: "percentOfOutput",
        headerName: "% Of Output",
        editable: true,
      },
      {
        field: "plusValue",
        headerName: "+ Value",
        editable: true,
      },
      {
        field: "stability",
        headerName: "Stability",
        editable: true,
      },
      {
        field: "uniformity",
        headerName: "Uniformity",
        editable: true,
      },

      {
        field: "reaction",
        headerName: "reaction",
        editable: true,
      },
      {
        field: "gravity",
        headerName: "Gravity",
        editable: true,
      },
      {
        field: "drift",
        headerName: "Drift",
        editable: true,
      },
    ],
  ];
  const getDiscipline = () => {
    let url = BASE_URL;
    axios
      .get(url + "discipline?_where=(status,eq,1)")
      .then((res) => {
        setDisciplineArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong while fetching disciplines!");
      });
  };

  const getStandard = () => {
    let url = BASE_URL;
    axios
      .get(
        url +
          `xjoin?_join=ds.standards,_j,disciplines.discipline,&_on1=(ds.disciplineId,eq,disciplines.id)&_fields=ds.standardName,ds.make,ds.id,ds.traceability,ds.validUpto,ds.id,ds.certificateNumber,disciplines.name,ds.stId,ds.calDate,disciplines.id&_where=(ds.id,eq,${params.id})~and(ds.status,eq,1)`
      )
      .then((res) => {
        setStandardName(res.data[0]?.ds_standardName);
        setMake(res.data[0]?.ds_make);
        setTraceability(res.data[0]?.ds_traceability);
        setValidUpto(res.data[0]?.ds_validUpto);
        setCertificateNumber(res.data[0]?.ds_certificateNumber);
        setId(res.data[0]?.ds_stId);

        setDeciplines(
          res.data[0]?.disciplines_id + ", " + res.data[0]?.disciplines_name
        );
        setStId(res.data[0]?.ds_stId);
        if (res.data[0]?.disciplines_id == 2) {
          setPercent(false);
          setPlus(false);
          setAccuracy(false);
        } else {
          setPercent(true);
          setPlus(true);
          setAccuracy(false);
        }

        const unitArr = [];
        for (let i = 0; i < unitArray?.length; i++) {
          if (
            parseInt(res.data[0]?.disciplines_id) == unitArray[i]?.disciplineId
          ) {
            unitArr.push(unitArray[i]);
          }
        }
        setUnits(unitArr);
        setCalDate(res.data[0]?.ds_calDate);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something Went Wrong with standards!");
      });
  };

  const getRanges = () => {
    axios
      .get(BASE_URL + `standardRanges?_where=(standardId,eq,${params.id})`)
      .then((res) => {
        setStandardRanges(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong while fetching standardRanges!");
      });
  };
  const initiateRanges = () => {
    let rows = [];
    let rowsSpace = [];
    for (let i = 0; i < standardRanges.length; i++) {
      rowsSpace.push([
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      ]);
      rows.push([
        standardRanges[i].id,
        standardRanges[i].rangeName,
        standardRanges[i].uncertainty,
        standardRanges[i].accuracy,
        standardRanges[i].percentOfOutput,
        standardRanges[i].plusValue,
        standardRanges[i].stability,
        standardRanges[i].uniformity,
        standardRanges[i].reactionTime,
        standardRanges[i].gravity,
        standardRanges[i].drift,
      ]);
    }
    setReadingRows(rows);
    setReadingRangeValue(rowsSpace);
  };

  const getUnitMasterArray = () => {
    let url = BASE_URL;
    axios
      .get(url + "unit?_where=(status,eq,1)")
      .then((res) => {
        setUnitArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const UnitList = () => {
    var uArr = [];
    for (let i = 0; i < units.length; i++) {
      uArr.push({
        label: units[i].id + ", " + units[i].symbol,
      });
    }
    setUnitFilter(uArr);
  };

  useEffect(() => {
    getDiscipline();
    getRanges();
    getUnitMasterArray();
  }, []);

  useEffect(() => {
    UnitList();
  }, [units]);

  useEffect(() => {
    initiateDisciplineList();
  }, [disciplines]);
  useEffect(() => {
    initiateRanges();
  }, [standardRanges]);
  useEffect(() => {
    getStandard();
  }, [unitArray]);

  const initiateDisciplineList = () => {
    let disciplineList = [];
    for (let i = 0; i < disciplines.length; i++) {
      disciplineList.push({
        label: disciplines[i].id + ", " + disciplines[i].name,
      });
    }
    setDisciplineList(disciplineList);
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const handleSubmit = (event) => {
    let row = {
      standardName: standardName,
      stId: ids,
      make: make,
      certificateNumber: certificateNumber,
      traceability: traceability,
      calDate: moment(calDate).format("YYYY-MM-DD"),
      validUpto: validUpto ? moment(validUpto).format("YYYY-MM-DD") : null,
    };
    let url = BASE_URL;
    axios
      .patch(url + `standards/${params.id}`, row)
      .then((res) => {
        props.setLoader(true);
        submitRanges();
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
        props.setLoader(false);
      });
  };

  function sendRequest(data) {
    return new Promise((resolve, reject) => {
      if (data.id) {
        axios
          .patch(BASE_URL + `standardRanges/${data.id}`, data)
          .then((res) => {
            resolve();
          })
          .catch((error) => {
            props.setLoader(false);
            toast.error("Something Went Wrong!");
          });
      } else {
        delete data.id;
        axios
          .post(BASE_URL + `standardRanges`, data)
          .then((res) => {
            resolve();
          })
          .catch((error) => {
            props.setLoader(false);
            toast.error("Something Went Wrong!");
          });
      }
    });
  }

  const submitRanges = async () => {
    let rows = [];
    let rangeIds = [];
    for (let i = 0; i < readingRows.length; i++) {
      rows.push({
        standardId: params.id,
        rangeName: readingRows[i][1],
        uncertainty: readingRows[i][2],
        accuracy: readingRows[i][3],
        percentOfOutput: readingRows[i][4],
        plusValue: readingRows[i][5],
        stability: readingRows[i][6],
        uniformity: readingRows[i][7],
        reactionTime: readingRows[i][8],
        gravity: readingRows[i][9],
        drift: readingRows[i][10],
        id: readingRows[i][0],
      });
      Number(readingRows[i][0]) && rangeIds.push(readingRows[i][0]);
    }

    const finalArr = [];
    for (let i = 0; i < rows.length; i++) {
      // for (let j = 0; j < readingRangeValue.length; j++) {
      finalArr.push({
        id: rows[i].id,
        standardId: params.id,
        rangeName:
          readingRangeValue[i][1] != null
            ? rows[i].rangeName.split(",")[0] + "," + readingRangeValue[i][1]
            : rows[i].rangeName,
        uncertainty:
          readingRangeValue[i][2] != null
            ? rows[i].uncertainty.split(",")[0] + "," + readingRangeValue[i][2]
            : rows[i].uncertainty,
        accuracy:
          readingRangeValue[i][3] != null
            ? rows[i].accuracy.split(",")[0] + "," + readingRangeValue[i][3]
            : rows[i].accuracy,
        percentOfOutput:
          readingRangeValue[i][4] != null
            ? rows[i].percentOfOutput.split(",")[0] +
              "," +
              readingRangeValue[i][4]
            : rows[i].percentOfOutput,
        plusValue:
          readingRangeValue[i][5] != null
            ? rows[i].plusValue.split(",")[0] + "," + readingRangeValue[i][5]
            : rows[i].plusValue,
        stability:
          readingRangeValue[i][6] != null
            ? rows[i].stability.split(",")[0] + "," + readingRangeValue[i][6]
            : rows[i].stability,
        uniformity:
          readingRangeValue[i][7] != null
            ? rows[i].uniformity.split(",")[0] + "," + readingRangeValue[i][7]
            : rows[i].uniformity,
        reactionTime:
          readingRangeValue[i][8] != null
            ? rows[i].reactionTime.split(",")[0] + "," + readingRangeValue[i][8]
            : rows[i].reactionTime,
        gravity:
          readingRangeValue[i][9] != null
            ? rows[i].gravity.split(",")[0] + "," + readingRangeValue[i][9]
            : rows[i].gravity,
        drift:
          readingRangeValue[i][10] != null
            ? rows[i].drift.split(",")[0] + "," + readingRangeValue[i][10]
            : rows[i].drift,
      });
    }
    // insert/update records
    await Promise.all(finalArr.map(sendRequest));
    props.setLoader(false);
    toast.success("Standards updated successfully!");
  };

  // data operations
  const updateSrfObject = (key, value) => {
    let newSrfObject = {
      ...srfObject,
    };
    newSrfObject[key] = value;
    createSrfObject(newSrfObject);
  };
  const updateCellValue = (rowIndex, colIndex, value) => {
    const newRows = [...readingRows];
    newRows[rowIndex][colIndex] = value;
    setReadingRows([...newRows]);
  };

  const updateRangeCellValue = (rowIndex, colIndex, value) => {
    console.log(rowIndex, colIndex, value);
    const newRowss = [...readingRangeValue];
    newRowss[rowIndex][colIndex] = value;
    setReadingRangeValue([...newRowss]);
  };

  const addRangeRow = (tableIndex) => {
    let newReadingRows = [...readingRows];
    newReadingRows.push(["", "", "", "", "", "", "", "", "", "", ""]);
    setReadingRows([...newReadingRows]);

    let newReadingRowss = [...readingRangeValue];
    newReadingRowss.push([
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ]);
    setReadingRangeValue([...newReadingRowss]);
  };

  const deleteReadingRow = (rowIndex, id) => {
    let newReadingRows = [...readingRows];
    if (newReadingRows[rowIndex] != undefined) {
      newReadingRows.splice(rowIndex, 1);
      let url = BASE_URL;
      axios
        .delete(url + `standardRanges/${id}`)
        .then((res) => {
          // toast("standards Deleted Successfully !");
          // setTimeout(refresh, 500);
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
      setReadingRows([...newReadingRows]);
    }
  };

  readingColumns = updatedArray.length ? updatedArray : readingColumns;

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Edit standard
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            value={curruntsetDecipline}
            options={disciplineList}
            renderInput={(params) => (
              <TextField {...params} label="Discipline *" />
            )}
            onInputChange={(event, newInputValue) => {
              const unitArr = [];
              for (let i = 0; i < unitArray.length; i++) {
                if (
                  parseInt(newInputValue.split(",")[0]) ==
                  unitArray[i].disciplineId
                ) {
                  unitArr.push(unitArray[i]);
                }
              }
              setUnits(unitArr);

              updateSrfObject(
                "disciplineId",
                parseInt(newInputValue.split(",")[0])
              );

              var disciplineKeys = false;
              disciplines.forEach((el) => {
                if (el.id == newInputValue.split(",")[0]) {
                  if (el.disciplineKey == "0") {
                    disciplineKeys = true;
                  }
                }
              });

              if (disciplineKeys == true) {
                setPercent(false);
                setPlus(false);
                setAccuracy(false);
                setUpdatedArray(readingColumns);
              }

              if (disciplineKeys !== true) {
                setPercent(true);
                setPlus(true);
                setAccuracy(false);
              }
            }}
          />
        </Grid>

        <Grid item xs={2}>
          <TextField
            id="outlined-basic"
            label="Standard name *"
            size="small"
            value={standardName}
            fullWidth
            variant="outlined"
            onChange={(e) => setStandardName(e.target.value)}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="outlined-basic"
            label="id/Sr No. *"
            size="small"
            value={ids}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setId(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="outlined-basic"
            label="Make/Model *"
            size="small"
            value={make}
            fullWidth
            variant="outlined"
            onChange={(e) => setMake(e.target.value)}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="outlined-basic"
            label="Certificate Number *"
            size="small"
            fullWidth
            value={certificateNumber}
            variant="outlined"
            onChange={(e) => setCertificateNumber(e.target.value)}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="outlined-basic"
            label="Cal. Agency."
            size="small"
            fullWidth
            value={traceability}
            variant="outlined"
            onChange={(e) => setTraceability(e.target.value)}
          />
        </Grid>
        <Grid item xs={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Cal Date *"
              inputFormat="dd/MM/yyyy"
              value={calDate ? calDate : new Date()}
              onChange={(newValue) => setCalDate(newValue)}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Due Date  *"
              inputFormat="dd/MM/yyyy"
              value={validUpto ? validUpto : new Date()}
              // onChange={(newValue) => {
              //   updateSrfObject("validUpto", newValue);
              // }}
              onChange={(newValue) => setValidUpto(newValue)}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <br />
      <hr />
      <br />
      <h4 style={{ "margin-bottom": "0px" }}>Standard Ranges</h4>
      <Button
        style={{ float: "right" }}
        onClick={(e) => {
          addRangeRow();
        }}
      >
        <AddIcon />
      </Button>
      <div style={{ width: "100%", overflow: "auto" }}>
        <ClassicTable>
          <Table sx={{ minWidth: 660 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {readingColumns[0].map((column) => (
                  <TableCell key={column.field}>
                    <Typography noWrap>{column.headerName}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {readingRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  {row.map((cell, cellIndex) => {
                    if (
                      cellIndex != 3 &&
                      cellIndex != 4 &&
                      cellIndex != 5 &&
                      cellIndex != 0
                    ) {
                      return (
                        <TableCell>
                          <TextField
                            size="small"
                            value={cell?.split(",")[0]}
                            onChange={(e) => {
                              updateCellValue(index, cellIndex, e.target.value);
                            }}
                          ></TextField>
                          <select
                            disabled={acc}
                            styles={{ width: "100%" }}
                            onChange={(e) => {
                              updateRangeCellValue(
                                index,
                                cellIndex,
                                e.target.value
                              );
                            }}
                          >
                            {units.length
                              ? units.map((option) => {
                                  if (option.symbol === cell?.split(",")[1]) {
                                    return (
                                      <option value={option.symbol} selected>
                                        {cell.split(",")[1]}
                                      </option>
                                    );
                                  } else {
                                    return (
                                      <option value={option.symbol}>
                                        {option.symbol}
                                      </option>
                                    );
                                  }
                                })
                              : []}
                          </select>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 3) {
                      return (
                        <TableCell>
                          <TextField
                            size="small"
                            disabled={acc}
                            value={acc ? null : cell?.split(",")[0]}
                            onChange={(e) => {
                              updateCellValue(index, cellIndex, e.target.value);
                            }}
                          ></TextField>

                          <select
                            disabled={acc}
                            styles={{ width: "100%" }}
                            onChange={(e) => {
                              updateRangeCellValue(
                                index,
                                cellIndex,
                                e.target.value
                              );
                            }}
                          >
                            {units.length
                              ? units.map((option) => {
                                  if (option.symbol === cell?.split(",")[1]) {
                                    return (
                                      <option value={option.symbol} selected>
                                        {cell.split(",")[1]}
                                      </option>
                                    );
                                  } else {
                                    return (
                                      <option value={option.symbol}>
                                        {option.symbol}
                                      </option>
                                    );
                                  }
                                })
                              : []}
                          </select>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 4) {
                      return (
                        <TableCell>
                          <TextField
                            size="small"
                            value={cell?.split(",")[0]}
                            disabled={percent}
                            onChange={(e) => {
                              updateCellValue(index, cellIndex, e.target.value);
                            }}
                          ></TextField>

                          <select
                            styles={{ width: "100%" }}
                            disabled={percent}
                            onChange={(e) => {
                              updateRangeCellValue(
                                index,
                                cellIndex,
                                e.target.value
                              );
                            }}
                          >
                            {units.length
                              ? units.map((option) => (
                                  <option value={option.symbol}>
                                    {option.symbol}
                                  </option>
                                ))
                              : []}
                          </select>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 5) {
                      return (
                        <TableCell>
                          <TextField
                            size="small"
                            disabled={plus}
                            value={cell?.split(",")[0]}
                            onChange={(e) => {
                              updateCellValue(index, cellIndex, e.target.value);
                            }}
                          ></TextField>
                          <select
                            styles={{ width: "100%" }}
                            disabled={plus}
                            onChange={(e) => {
                              updateRangeCellValue(
                                index,
                                cellIndex,
                                e.target.value
                              );
                            }}
                          >
                            {units.length
                              ? units.map((option) => (
                                  <option value={option.symbol}>
                                    {option.symbol}
                                  </option>
                                ))
                              : []}
                          </select>
                        </TableCell>
                      );
                    }
                  })}
                  <TableCell>
                    <DeleteIcon
                      style={{ color: "#dc3545" }}
                      onClick={(e) => {
                        deleteReadingRow(0, row[0]);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ClassicTable>
      </div>

      <br />
      <Toolbar style={{ padding: "0px", overflow: "auto" }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            // props.setLoader(true);
            handleSubmit();
          }}
        >
          Update Standard
        </Button>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0, ml: 2 }}
          onClick={() => {
            handleSubmit();
          }}
        >
          Print
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
