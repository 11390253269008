import React, {  useEffect } from "react";
import {
    Grid,
    Typography,
    Card,
    CardContent,
    CardActions,
    Button,
    Box,
    CardMedia,
    TableContainer,
    Paper,
    Chip,
    Divider,
} from "@mui/material";

import GroupIcon from "@mui/icons-material/Group";
import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined";
import PrecisionManufacturingOutlinedIcon from "@mui/icons-material/PrecisionManufacturingOutlined";
import RotateLeftOutlinedIcon from "@mui/icons-material/RotateLeftOutlined";
import ArticleIcon from "@mui/icons-material/Article";

import Analytics from "./Analytics";
import { BASE_URL } from "./../../global";

const axios = require("axios");


export default function ClientDashboard() {
    const [clientCount, setClientCount] = React.useState("");
    const [certificateCount, setCertificateCount] = React.useState("");
    const [instrumentCount, setInstrumentCount] = React.useState("");
    const [todayClientCount, setTodayClientCount] = React.useState("");
    const [srfCount, setSrfCount] = React.useState("");
    const [todaysCertificateCount, setTodaysCertificateCount] = React.useState("");
    const [totalQuot, setTotalQuot] = React.useState("");
    const [totalInvoice, setTotalInvoice] = React.useState("");
    const _clientId = localStorage.getItem("clientId")
    const userType = localStorage.getItem("type")

    const getClientCount = (event) => {
        let url = BASE_URL;
        axios
          .get(url + "clients/count?_where=(status,eq,1)")
          .then((res) => {
            console.log(res.data[0].no_of_rows)
            setClientCount(res.data[0].no_of_rows);
          })
          .catch((error) => {
            console.log(error);
          });
      };

      const getCertificateCount = (event) => {
        let url = BASE_URL;
        axios
          .get(url + `certificates/count?_where=(status,eq,1)~and(clientId,eq, 5)`)
          .then((res) => {
            console.log('cert c',res.data[0].no_of_rows)
            setCertificateCount(res.data[0].no_of_rows);
          })
          .catch((error) => {
            console.log(error);
          });
      };
      const getQuotationCount = (event) => {
        let url = BASE_URL;
        axios
          .get(url + `quotation/count?_where=(clientId,eq, ${_clientId})`)
          .then((res) => {
            setTotalQuot(res.data[0].no_of_rows);
          })
          .catch((error) => {
            console.log(error);
          });
      };

      const getInvoiceCount = (event) => {
        let url = BASE_URL;
        axios
          .get(url + `invoices/count?_where=(status,eq,1)~and(clientId,eq, ${_clientId})`)
          .then((res) => {
            setTotalInvoice(res.data[0].no_of_rows);
          })
          .catch((error) => {
            console.log(error);
          });
      };

      const getInstrumentCount = (event) => {
        let url = BASE_URL;
        axios
          // .get(url + "instruments/count?_where=(status,eq,1)~and(clientId,eq,9)")
          .get(url + `dispatch/count?_where=(status,eq,1)~and(clientId,eq,${_clientId})`)
          .then((res) => {
            setInstrumentCount(res.data[0].no_of_rows);
          })
          .catch((error) => {
            console.log(error);
          });
      };

      
      const getTodayClientCount = (event) => {
        let url = BASE_URL;
        axios
          .get(url + "clients/count?_where=(status,eq,1)")
          .then((res) => {
            console.log(res.data[0].no_of_rows)
            setTodayClientCount(res.data[0].no_of_rows);
          })
          .catch((error) => {
            console.log(error);
          });
      };

      const getSrfCount = (event) => {
        let url = BASE_URL;
        axios
          .get(url + `srfs/count?_where=(status,eq,1)${_clientId ? "~and(clientId,eq," + _clientId + ")" : ""}`)
          .then((res) => {
            console.log(res.data[0].no_of_rows)
            setSrfCount(res.data[0].no_of_rows);
          })
          .catch((error) => {
            console.log(error);
          });
      };

      const getTodayCertificateCount = (event) => {
        let url = BASE_URL;
        axios
          .get(url + `certificates/count?_where=(status,eq,1)~and(clientId,eq, ${_clientId})`)
          .then((res) => {
            console.log(res.data[0].no_of_rows)
            setTodaysCertificateCount(res.data[0].no_of_rows);
          })
          .catch((error) => {
            console.log(error);
          });
      };
    useEffect(() => {
        getClientCount();
        getCertificateCount();
        getInstrumentCount();
        getTodayClientCount();
        getSrfCount();
        getTodayCertificateCount();
        getQuotationCount();
        getInvoiceCount()
      }, []);
    

    return (
        <div>
            <div component={Paper} sx={{ mt: 2 }}>
                <Divider sx={{ mt: 5 }}>
                    <Chip
                        label="OVERALL"
                        variant="outlined"
                        sx={{ fontSize: "16px" }}
                        style={{fontWeight:'bold'}}
                    />
                </Divider>
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <Card
                            className="cardContent"
                            sx={{
                                m: 2,
                                p: 1,
                                display: "flex",
                                border: 1,
                                borderColor: "#283593",
                            }}
                            style={{ height:'150px' }}
                        >
                            <GroupIcon
                                sx={{ color: "#283593" }}
                                style={{ fontSize: "100px" }}
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <CardContent sx={{ flex: "1 0 auto" }} >
                                    <Typography>Total Certificates</Typography>
                                    <Typography
                                        component="div"
                                        variant="h4"
                                        sx={{
                                            fontWeight: "bold",
                                            color: "#283593",
                                        }}
                                    >
                                        {certificateCount}
                                        
                                    </Typography>
                                </CardContent>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        pl: 1,
                                        pb: 1,
                                    }}
                                >
                                    {/* <Button size="small" variant="outlined">
                                        view more
                                    </Button> */}
                                </Box>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={3}>
                        <Card
                        className="cardContent"
                            sx={{
                                m: 2,
                                p: 1,
                                display: "flex",
                                border: 1,
                                borderColor: "#283593",
                            }}
                            style={{ height:'150px' }}
                        >
                            <CardGiftcardOutlinedIcon
                                sx={{ color: "#283593" }}
                                style={{ fontSize: "100px" }}
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <CardContent sx={{ flex: "1 0 auto" }}>
                                    <Typography>Instrument Dues</Typography>
                                    <Typography
                                        component="div"
                                        variant="h4"
                                        sx={{
                                            fontWeight: "bold",
                                            color: "#283593",
                                        }}
                                    >
                                        {certificateCount}
                                    </Typography>
                                </CardContent>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        pl: 1,
                                        pb: 1,
                                    }}
                                >
                                    {/* <Button size="small" variant="outlined">
                                        view more
                                    </Button> */}
                                </Box>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={3}>
                        <Card
                        className="cardContent"
                            sx={{
                                m: 2,
                                p: 1,
                                display: "flex",
                                border: 1,
                                borderColor: "#283593",
                            }}
                            style={{ height:'150px' }}
                        >
                            <PrecisionManufacturingOutlinedIcon
                                sx={{ color: "#283593" }}
                                style={{ fontSize: "100px" }}
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <CardContent sx={{ flex: "1 0 auto" }}>
                                    <Typography>Received Instrument</Typography>
                                    <Typography
                                        component="div"
                                        variant="h4"
                                        sx={{
                                            fontWeight: "bold",
                                            color: "#283593",
                                        }}
                                    >
                                        {instrumentCount}
                                    </Typography>
                                </CardContent>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        pl: 1,
                                        pb: 1,
                                    }}
                                >
                                    {/* <Button size="small" variant="outlined">
                                        view more
                                    </Button> */}
                                </Box>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={3}>
                        <Card
                        className="cardContent"
                            sx={{
                                m: 2,
                                p: 1,
                                display: "flex",
                                border: 1,
                                borderColor: "#283593",
                            }}
                            style={{ height:'150px' }}
                        >
                            <RotateLeftOutlinedIcon
                                sx={{ color: "#283593" }}
                                style={{ fontSize: "100px" }}
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <CardContent sx={{ flex: "1 0 auto" }}>
                                    <Typography>Total <br/>Quotations</Typography>
                                    <Typography
                                        component="div"
                                        variant="h4"
                                        sx={{
                                            fontWeight: "bold",
                                            color: "#283593",
                                        }}
                                    >
                                       {totalQuot}
                                    </Typography>
                                </CardContent>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        pl: 1,
                                        pb: 1,
                                    }}
                                >
                                    {/* <Button size="small" variant="outlined">
                                        view more
                                    </Button> */}
                                </Box>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </div>
            <div component={Paper} sx={{ mt: 5 }}>
                {/* <Divider sx={{ mt: 5 }}>
                    <Chip
                        label="TODAY'S"
                        variant="outlined"
                        sx={{ fontSize: "16px" }}
                        style={{fontWeight:'bold'}}
                    />
                </Divider> */}
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <Card
                        className="cardContent"
                            sx={{
                                m: 2,
                                p: 1,
                                display: "flex",
                                border: 1,
                                borderColor: "#ff5722",
                            }}
                            style={{ height:'150px' }}
                        >
                            <GroupIcon
                                sx={{ color: "#ff5722" }}
                                style={{ fontSize: "100px" }}
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <CardContent sx={{ flex: "1 0 auto" }}>
                                    <Typography>invoice</Typography>
                                    <Typography
                                        component="div"
                                        variant="h4"
                                        sx={{
                                            fontWeight: "bold",
                                            color: "#ff5722",
                                        }}
                                    >
                                        {totalInvoice}
                                    </Typography>
                                </CardContent>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        pl: 1,
                                        pb: 1,
                                    }}
                                >
                                    {/* <Button size="small" variant="outlined" className="but">
                                        view more
                                    </Button> */}
                                </Box>
                            </Box>
                        </Card>
                    </Grid>{" "}
                    
                </Grid>
            </div>
            {/* <div component={Paper} sx={{ mt: 5 }}>
                <Divider sx={{ mt: 5 }}>
                    <Chip
                        label="ANALYTICS"
                        variant="outlined"
                        sx={{ fontSize: "16px" }}
                        style={{fontWeight:'bold'}}
                    />
                </Divider>
                <Analytics />
            </div> */}
        </div>
    );
}
