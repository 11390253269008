import React, { useEffect } from "react";
import { BASE_URL,DOMAIN } from "./../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import Modal from "@mui/material/Modal";
import Global from "../image/global.png";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { useReactToPrint } from "react-to-print";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";
import useGlobalStorage from "use-global-storage";
import { API_URL } from "./../../global";
import { TextField } from "@mui/material";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import { useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import QRCode from "react-qr-code";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const axios = require("axios");

export default function MultiQr() {
  const printComponentRef = React.useRef();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [qrCodeUrl, setQrCodeUrl] = React.useState(null);
  const [qrModalOpen, setQrModalOpen] = React.useState(false);
  const [instrumentName, setInstrumentName] = React.useState("");
  const [idNumber, setIdNumber] = React.useState("");
  const [calDate, setCalDate] = React.useState("");
  const [dueDate, setDueDate] = React.useState("");

  const [allData, setAllData] = React.useState([]);
  const params = useParams();

  const useStorage = useGlobalStorage({
    storageOptions: { name: "test-db" },
  });
  const [title, setTitle] = useStorage("title");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const qrModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 350,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 2,
  };

  // console.log('dd',enableDisable)
  const handleOpen = () => setQrModalOpen(true);
  const handleClose = () => setQrModalOpen(false);

  var refresh = () => {
    window.location.reload(false);
    // <Link to="/master/master/standard">Home</Link>
  };

  const getExpenseList = (event) => {
    let url = BASE_URL;
    axios
      .get(url + `xjoin?_join=certificate.certificates,_j,inst.instruments&_on1=(certificate.instrumentId,eq,inst.id)&_fields=certificate.DUCID,certificate.calibrationDate,certificate.nextDueDate,inst.instrumentName,certificate.id`)
      .then((res) => {
        var splitIdArray = [];
        params.certificateId
          ? params.certificateId.split(',').map((ids) => {
              for (let i = 0; i < res.data.length; i++) {
                if (parseInt(ids) == res.data[i].certificate_id) {
                  splitIdArray.push(res.data[i]);
                }
              }
            })
          : console.log("no data");
        setAllData(splitIdArray);
        //   res.data.map(function(data, index){
        //     setQrCodeUrl(
        //       `http://localhost:3000/#/certificate/edit/${data.id}`
        //     );
        //     handleOpen();
        //     setInstrumentName(data.instrumentId);
        //     setIdNumber(data.identificationNo);
        //     setCalDate(data.calibrationDate);
        //     setDueDate(data.nextDueDate);
        // })
        initializeDataTable();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const initializeDataTable = () => {
    $("#datatable-keytable").DataTable({
      destroy: true,
      keys: true,
      dom:
        "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
        "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
        "<'row'<'col-sm-12' tr>>" +
        "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
      bPaginate: false,
      bLengthChange: true,
      bFilter: true,
      bInfo: false,
      bAutoWidth: true,
      language: {
        zeroRecords: " ",
      },

      buttons: ["copy", "csv", "excel", "print"],
    });
  };
  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
  });


  const renderQrModal = () => {
    return allData.map((data, index) => {
      return (
        <div  item xs={6} style={{lineHeight:'14px',textAlign: "left", border: '3px solid', margin: '5px', padding: '4px', width:'231.6px',height:'auto' }}>
          <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              align="center"
            >
              {/* <img className="mt-1" src={Krishna} width="20" alt="IMG" /> */}
              <b style={{fontSize:'12px'}}>Global CALIBRATION</b>
            </Typography>
            <span style={{fontSize:'12px'}}>
              <b>Inst Name:</b> {data.inst_instrumentName}
            </span>
            <br />
            <span style={{fontSize:'12px'}}>
              <b>Id Number:</b> {data.certificate_DUCID}
            </span>
            <br />
            <span style={{fontSize:'12px'}}>
              <b>Cal Date:</b> {data.certificate_calibrationDate ? moment(data.certificate_calibrationDate).format("DD-MM-YYYY") : ""}
            </span>
            <br />
            <span className="mb-1" style={{fontSize:'12px'}}>
              <b>Due Date:</b> {data.certificate_nextDueDate ? moment(data.certificate_nextDueDate).format("DD-MM-YYYY") : ""}
            </span><br/>
            <span className="mb-2" style={{fontSize:'12px'}}>
              <b>calibrated by:</b>
            </span>
            <QRCode
              value={`${DOMAIN}/#/certificate/viewCertificate/${data.id}`}
              size={80}
              align="right"
              style={{float:'right',marginTop:'-2em'}}
            />
        </div>
      );
    });
  };

  // const renderQrModal = () => {
  //   return (
  //    allData.map(function(data, index){

  //   <div>
  //      <QRCode value={`http://localhost:3000/#/certificate/edit/${data.id}`} size={50} align="center" />
  //   </div>
  //   })
  //   )
  //   // return (
  //   //   <Modal
  //   //     open={qrModalOpen}
  //   //     onClose={handleClose}
  //   //     aria-labelledby="modal-modal-title"
  //   //     aria-describedby="modal-modal-description"
  //   //     size="md"
  //   //   >
  //   //     <Box sx={qrModalStyle} ref={printComponentRef}>
  //   //       <Typography
  //   //         id="transition-modal-title"
  //   //         variant="h6"
  //   //         component="h2"
  //   //         align="center"
  //   //       >
  //   //         <img src={Global} width="50" alt="IMG" />
  //   //         <b>GLOBAL CALIBRATION</b>
  //   //       </Typography>
  //   //       <hr />

  //   //       <div style={{ fontWeight: "bold" }}>
  //   //         <span>Inst Name : {instrumentName}</span>
  //   //         <br />
  //   //         <span>ID No : {idNumber}</span>
  //   //         <br />
  //   //         <span>Cal. Date : {moment(calDate).format("DD-MM-YYYY")}</span>
  //   //         <br />
  //   //         <span>
  //   //           Due Date : {moment(dueDate).format("DD-MM-YYYY")}
  //   //           <div align="right">
  //   //             <QRCode value={qrCodeUrl} size={50} align="center" />
  //   //           </div>
  //   //         </span>
  //   //       </div>

  //   //       <div align="center" className="textfield">
  //   //         {/* <QRCode value={qrCodeUrl} size={80} align="center" /> */}
  //   //         <hr />
  //   //         <Button size="small" align="center" onClick={handlePrint}>
  //   //           Print
  //   //         </Button>
  //   //       </div>
  //   //     </Box>
  //   //   </Modal>
  //   // );
  // };

  useEffect(() => {
    getExpenseList();
  }, []);

  return (
    <TableContainer
      component={Paper}
      sx={{ mt: 2, p: 2 }}
      className="hiddenscroll"
    >
      <Typography variant="h5" component="h5" style={{ float: "center" }}>
        <b>
          <u>Multiple QR Code</u>
        </b>
      </Typography>
      <hr />

      <div className="row border-dark p-2 qrSticker" ref={printComponentRef} style={{width:'100%',pageBreakAfter:'auto'}}>
        <Grid container spacing={2} sx={{ ml: 2, my:2 }}>
          {renderQrModal()}
        </Grid>
        <Button
          variant="contained"
          size="small"
          className="non"
          sx={{ m: 0, ml: 2, mt: 4 }}
          onClick={() => {
            handlePrint();
          }}
        >
          Print
        </Button>
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <ToastContainer />
    </TableContainer>
  );
}
