import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextareaAutosize,
  Stack,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useSnackbar } from "notistack";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import Checkbox from "@mui/material/Checkbox";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { DataGrid } from "@mui/x-data-grid";
import {
  randomCreatedDate,
  randomTraderName,
  randomUpdatedDate,
} from "@mui/x-data-grid-generator";
import { ClassicTable } from "../../utils/components/Styles";
import { API_URL } from "../../../src/global";
import { insert, view } from "../../components/common/api";
import { integerPropType } from "@mui/utils";
import { set } from "date-fns";
import { useReactToPrint } from "react-to-print";

const axios = require("axios");

export default function ViewCustomInvoice() {
  const printComponentRef = React.useRef();

  const [companyName, setCompanyName] = React.useState("");
  const [instrumentName, setInstrumentName] = React.useState("");
  const [clientId, setClientId] = React.useState("");
  const [invoiceData, setInvoiceData] = React.useState([]);
  const [deletedIds, setDeletedIds] = React.useState([]);
  const [instrumentArray, setInstrumentArray] = React.useState([]);
  const [totalAmount, setTotalAmount] = React.useState("");
  const [discount, setTotalDiscount] = React.useState("");
  const [totalD, setTotalD] = React.useState("");
  const [gstType, setGstType] = React.useState(null);
  const [address, setAddress] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [invoiceDate, setInvoiceDate] = React.useState("");
  const [conveyanceCharges, setConveyanceCharges] = React.useState("");
  const [instrumentIds, setInstrumentIds] = React.useState([]);
  const [invoiceNumber, setInvoiceNumber] = React.useState(
    (Math.random() + 1).toString(36).substring(7)
  );
  const [contactNumber, setContactNumber] = React.useState("");
  const [poNumber, setPoNumber] = React.useState("");
  const [sGst, setSGST] = React.useState(0);
  const [cGst, setCGST] = React.useState(0);
  const [poDate, setPoDate] = React.useState("");
  const [instrumentDuplicateCount, setInstrumentDuplicateCount] =
    React.useState("");
  const [products, setProducts] = React.useState([]);
  const params = useParams();
  const userType = localStorage.getItem("type");
  const [enableDisable, setEnableData] = React.useState("");
  const [customInvoiceReadingsData, setCustomInvoiceReadingsData] =
    React.useState([]);
  const [quantity, setQuantity] = React.useState(0);
  const [amount, setAmount] = React.useState(0);
  const [productsArray, setProductsArray] = React.useState([]);
  const [productArray, setProductArray] = React.useState([]);
  var [clientArray, setClientArray] = React.useState([]);


  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
  });

  const readingColumns = [
    [
      {
        field: "id",
        headerName: "Sr. No.",
        editable: false,
      },
      {
        field: "productName",
        headerName: "Product Name",
        editable: true,
      },
      {
        field: "discription",
        headerName: "Discription",
        editable: true,
      },
      {
        field: "quantity",
        headerName: "Quantity",
        editable: true,
      },
      {
        field: "rate",
        headerName: "Rate",
        editable: true,
      },
      {
        field: "amount",
        headerName: "Amount",
        editable: true,
      },
    ],
  ];
  const [readingRows, setReadingRows] = React.useState([]);

  const getInvoiceData = () => {
    let url = BASE_URL;
    axios
      .get(
        url +
        `xjoin?_join=invoice.customInvoice,_j,client.clients&_on1=(invoice.clientId,eq,client.id)&_fields=client.companyName,invoice.clientId,invoice.subject,client.address,client.contact,invoice.poNumber,invoice.poDate,invoice.gstType&_where=(invoice.clientId,eq,${params.id})~and(invoice.poNumber,eq,${params.poNumber})~and(invoice.status,eq,1)`
      )
      .then((res) => {
        setCompanyName(res.data[0].client_companyName);
        setClientId(res.data[0].invoice_clientId);
        setSubject(res.data[0].invoice_subject);
        setInstrumentArray(res.data);
        setAddress(res.data[0].client_address);
        setContactNumber(res.data[0].client_contact);
        setPoNumber(res.data[0].invoice_poNumber);
        setPoDate(res.data[0].invoice_poDate);
        setInstrumentDuplicateCount(res.data[0].instrumentDuplicateCount);
        setGstType(res.data[0].gstType);
      })
      .catch((error) => {
        toast.error("Something Went Wrong with standards!");
      });
  };

  const initiateInstrumentList = () => {
    var pushIArray = [];
    for (let i = 0; i < productArray.length; i++) {
      pushIArray.push({
        label: productArray[i].id + ", " + productArray[i].productName,
      });
    }
    setProductsArray(pushIArray);
  };

  const getCustomProductList = () => {
    let url = BASE_URL;
    axios
      .get(url + "customProducts")
      .then((res) => {
        setProductArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var pushCArray = [];
  var clientsArrays = [];
  for (let i = 0; i < clientArray.length; i++) {
    if (clientArray[i].companyName) {
      pushCArray.push({
        label: clientArray[i].id + ", " + clientArray[i].companyName,
      });
    }
    clientsArrays.push({
      id: clientArray[i].id,
      address: clientArray[i].address,
    });
  }

  const updateEnableDisable = (value, id) => {
    const newColumnName = [...enableDisable];
    newColumnName[id] = id + "," + value;
    setEnableData(newColumnName);
  };

  const getProductArray = () => {
    let url = BASE_URL;
    axios
      .get(url + `products`)
      //
      .then((res) => {
        setProducts(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong with standards!");
      });
  };

  const updateCellValue = (rowIndex, colIndex, value) => {
    const newRows = [...readingRows];
    newRows[rowIndex][colIndex] = value;
    setReadingRows([...newRows]);
  };

  const addRangeRow = () => {
    let newReadingRows = [...readingRows];
    newReadingRows.push([-1, "", "", "", "", ""]);
    setReadingRows([...newReadingRows]);
  };

  const deleteReadingRow = (rowIndex) => {
    let newReadingRows = [...readingRows];
    if (newReadingRows[rowIndex] != undefined) {
      setDeletedIds([...deletedIds, newReadingRows[rowIndex][0]]);
      newReadingRows.splice(rowIndex, 1);
      setReadingRows([...newReadingRows]);
    }
  };

  //   const deleteReadingRow = (tableIndex, rowIndex) => {
  //     let newReadingRows = [...readingRows];
  //     newReadingRows.splice(rowIndex, 0);
  //     setReadingRows([...newReadingRows]);
  //   };

  const getCustomReadingsArray = () => {
    axios
      .get(
        BASE_URL +
        `xjoin?_join=mainInvoice.customInvoice,_j,prR.customProductRanges,_j,custP.customProducts&_on1=(mainInvoice.id,eq,prR.customProductId)&_on2=(prR.productId,eq,custP.id)&_fields=prR.id,custP.productName,prR.id,prR.discription,prR.quantity,prR.rate,prR.amount,prR.totalAmount,prR.sgst,prR.cgst,custP.id&_where=(prR.customProductId,eq,${params.mainId})`
        )
      .then((res) => {
        setCustomInvoiceReadingsData(res.data);
        console.log(res.data)

      })
      .catch((error) => {
        toast.error("Something Went Wrong while fetching standardRanges!");
      });
  };

  const initiateCustomReadingsData = () => {
    try {
      let rows = [];
      for (let i = 0; i < customInvoiceReadingsData.length; i++) {
        rows.push([
          customInvoiceReadingsData[i].prR_id,
          customInvoiceReadingsData[i].custP_id +
          ", " +
          customInvoiceReadingsData[i].custP_productName,
          customInvoiceReadingsData[i].prR_discription,
          customInvoiceReadingsData[i].prR_quantity,
          customInvoiceReadingsData[i].prR_rate,
          customInvoiceReadingsData[i].prR_amount,
        ]);
      }
      setReadingRows(rows);
      console.log("rows",rows)
    } catch (error) {
      console.log(error);
    }
  };

  const getInvoiceDataArray = () => {
    axios
      .get(
        BASE_URL +
        `customInvoice?_where=(clientId,eq,${params.id})~and(poNumber,eq,${params.poNumber})~and(status,eq,1)`
      )
      .then((res) => {
        setInvoiceData(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong while fetching standardRanges!");
      });
  };

  const handleSubmit = (event) => {
    let rows = readingRows.map((row) => ({
      id: parseInt(row[0]),
      customProductId: parseInt(row[1]),
      discription: row[2],
      quantity: parseInt(row[3]),
      rate: parseInt(row[4]),
      amount: parseInt(row[5]),
    }));

    axios
      .patch(BASE_URL + `customInvoice/${params.mainId}`, {
        clientId: clientId,
        poDate: poDate,
        invoiceNumber: invoiceNumber,
        subject: subject,
        invoiceDate: invoiceDate
          ? moment(invoiceDate).format("YYYY-MM-DD")
          : null,
        poNumber: poNumber,
        gstType: gstType,
      })
      .then((res) => {
        toast("Custom Invoice Updated successfully !");
        setTimeout(refresh, 1000);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });

    rows.map((row) => {
      axios
        .patch(BASE_URL + `customProductRanges/${row.id}`, {
          instrumentDuplicateCount: row.instrumentDuplicateCount,
          productId: row.customProductId,
          discription: row.discription,
          quantity: row.quantity,
          rate: row.rate,
          amount: row.amount,
        })
        .then((res) => { })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    });

    let row = {
      clientId: clientId,
      address: address,
      totalAmount: totalAmount ? totalAmount : 0,
      discount: totalD,
      gstType: gstType ? gstType : 0,
      sgst: sGst ? sGst : 0,
      cgst: cGst ? cGst : 0,
      address: address,
      subject: subject,
      conveyanceCharges: conveyanceCharges,
      invoiceNumber: invoiceNumber,
      contactNumber: contactNumber,
      poNumber: poNumber,
      instrumentId: instrumentIds.toString(),
      invoiceDate: invoiceDate
        ? moment(invoiceDate).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),
    };
    return;
    const invoiceHistory = {
      invoiceId: 2,
      instrumentId: instrumentIds.toString(),
      amount: 250,
      invoiceDate: invoiceDate
        ? moment(invoiceDate).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),
      quantity: 1,
      pendingInvoices: 1,
    };
    // console.log('row',row)
    // return
    let url = BASE_URL;
    axios
      .post(url + "paymentMaster", row)
      .then((res) => {
        toast("Payment created successfully!");
        setTimeout(refresh, 1000);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
    // <Link to="/master/standard">Home</Link>
  };

  const allData = [
    {
      instrumentName: "RTD With Dig. Temp. Data Logger",
      invoiceDate: "2022-09-19",
      amount: 250,
      pendingInvoices: 1,
    },
  ];

  const counterAmt = () => {
    var count = 0;
    for (let i = 0; i < readingRows.length; i++) {
      if (readingRows[i][2] != "" && readingRows[i][3] != "") {
        count += parseInt(readingRows[i][3] * readingRows[i][4]);
      }
    }
    setTotalAmount(count);
  };

  useEffect(() => {
    counterAmt();
  }, [readingRows]);

  useEffect(() => {
    getProductArray();
    getInvoiceData();
    getInvoiceDataArray();
    getCustomReadingsArray();
    getCustomProductList();
  }, []);

  useEffect(() => {
    initiateInstrumentList();
  }, [productArray]);

  useEffect(() => {
    initiateCustomReadingsData();
  }, [customInvoiceReadingsData]);


  return (
    <>
      <Paper sx={{ mx: 5, mt: 2, p: 2 }} style={{ border: '1px solid' }}>
        <div className="invoice" ref={printComponentRef}>
          <div style={{ padding: '10px', margin: '10px' }}>
            <h1>Performance Invoice</h1>
            <hr />
            <div style={{ marginBottom: '1em' }}>
              {/* <img src={KrishnaInvoice} alt="logo" /> */}
            </div>
            <div className="invoice-box" >
              <table width="90%" style={{ marginLeft: 'auto', marginRight: 'auto', lineHeight: '20px !important' }}>
                <thead>
                  <tr>
                    <th colSpan="4" rowspan="4" className="text-start">M/s {companyName} <br />
                      GSTIN/Unique ID - 09AAAcL1745Q1Z2<br />
                      Place Code -</th>
                    <th>P.I. No.</th>
                    <th>062</th>
                  </tr>
                  <tr>
                    <th>Dated</th>
                    <th>{invoiceDate}</th>
                  </tr>
                  <tr>
                    <th>SRC No.</th>
                    <th>4531</th>
                  </tr>
                </thead>
                <thead>
                  <tr>
                    <th colspan="4">Place of Service - Uttar Pradesh</th>
                    <th>Src Dated: </th>
                    <th>13.02.20</th>
                  </tr>
                  <tr>
                    <th colspan="4"></th>
                    <th colspan="2">Taxes Pyable on reverse Charges </th>
                  </tr>
                </thead>
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>ITEMS PARTICULARS</th>
                    <th>Description</th>
                    <th>Qty</th>
                    <th>Rate</th>
                    <th>Unit Price</th>
                  </tr>
                </thead>
                {readingRows.map((row, index) => (
                  <tbody>
                    <tr>
                      <td>{index + 1}</td>
                      <td>{row[1].split(",")[1].trim()}</td>
                      <td>{row[2]}</td>
                      <td>{row[3]}</td>
                      <td>{row[4]}</td>
                      <td>{row[5]}</td>
                    </tr>
                  </tbody>
                ))}
                <tbody>
                  <tr>
                    <td colspan="5">Total</td>
                    <td colspan="1">{totalAmount}</td>
                  </tr>
                  {gstType == 2 ?
                    <tr>
                      <td colspan="5">IGST@18%</td>
                      <td colspan="1">{18 * totalAmount / 100}</td>
                    </tr>
                    : ''}
                  {gstType == 1 ?
                    <tr>
                      <td colspan="5">CGST@9%</td>
                      <td colspan="1">{9 * totalAmount / 100}</td>
                    </tr>
                    : ''}
                  {gstType == 1 ?
                    <tr>
                      <td colspan="5">SGST@9%</td>
                      <td colspan="1">{9 * totalAmount / 100}</td>
                    </tr>
                    : ''}
                  <tr>
                    <td colspan="5"></td>
                    <td colspan="1"></td>
                  </tr>
                  <tr>
                    <td colspan="3" className="text-start">Rupees in words. :  Only</td>
                    <td colspan="2" className="text-end">Grand Total</td>
                    <td>{totalAmount}</td>
                  </tr>
                  <tr>
                    <td colspan="6" rowspan="5">
                        {/* <span style={{ float: 'left', paddingLeft: '5px' }}>Bank Details</span><br />
                        <span style={{ float: 'left', paddingLeft: '5px' }}>Account Name : S V Precision Instruments.</span><br />
                        <span style={{ float: 'left', paddingLeft: '5px' }}>Account No : 50200069882396.</span><br />
                        <span style={{ float: 'left', paddingLeft: '5px' }}>BRANCH/IFSC Code: HDFC Bank / HDFC0006712 </span> */}
                    </td>
                  </tr>

                </tbody>
                <tbody>
                  <tr>
                    <td colspan="6" rowspan="4">
                      <span style={{ float: 'left', paddingLeft: '5px' }}>* All disputes are subject to Ghaziabad Jurisdiction.</span><br />
                      <span style={{ float: 'left' }}>* Intrest @2% will be charged if payment is not made with in 15 days or time frame maturally agreed</span><br />
                      <span style={{ float: 'left', paddingLeft: '5px' }}>* E. & O.E.</span><br />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Paper>

      <Toolbar style={{ padding: "0px", width: "100%" }}>
        <Button
          variant="contained"
          size="small"
          sx={{ ml: 3 }}
          onClick={handlePrint}
        >
          Print
        </Button>
      </Toolbar>
    </>
  );
}
