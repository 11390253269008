import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
} from "@mui/material";
const axios = require("axios");

export default function UpdateUncertinity(props) {
  const [name, setName] = React.useState("");
  const [distribution, setDistribution] = React.useState("");
  const [standardRanges, setStandardRanges] = React.useState("");
  const [defaultValue, setDefaultValue] = React.useState("");
  const [standardRangesDataArr, setStandardRangesDataArr] = React.useState([]);
  const [sensitives, setSensitives] = React.useState([]);
  const params = useParams();

  const getUncertinityList = () => {
    let url = BASE_URL;
    axios
      .get(url + `uncertainty/${params.id}`)
      .then((res) => {
        setName(res.data[0].name);
        setDistribution(res.data[0].distribution);
        setSensitives(res.data[0].sensitives);
        setStandardRanges(res.data[0].linkedRanges);
        setDefaultValue(res.data[0].defaultValue);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const getStandardRangesData = (id) => {
    let url = BASE_URL;
    axios
      .get(url + "standardRanges")
      .then((res) => {
        var keys = [];
        Object.assign(res.data[0], { leastCount: "00" });
        for (var k in res.data[0]) {
          if (
            k != "id" &&
            k !== "standardId" &&
            k !== "rangeName" &&
            k !== "status" &&
            k !== "lastModified"
          )
            keys.push({ name: k });
        }
        setStandardRangesDataArr(keys);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getUncertinityList();
    getStandardRangesData();
  }, []);

  var refresh = () => {
    window.location.reload(false);
  };

  const onSubmit = () => {
    var jsonData = {
      name: name,
      distribution: distribution,
      sensitives: sensitives,
      linkedRanges: standardRanges,
      defaultValue: defaultValue
    };

    let url = BASE_URL;

    axios
      .patch(url + `uncertainty/${params.id}`, jsonData)
      .then((res) => {
        props.setLoader(false);
        toast("Updated Uncertainty!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        props.setLoader(false);
        toast.error("Something Went Wrong!");
      });
  };

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Edit Uncertainty
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Name *"
            size="small"
            fullWidth
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />{" "}
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Distribution *"
            size="small"
            fullWidth
            variant="outlined"
            value={distribution}
            onChange={(e) => setDistribution(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Sensitive Coefficient *"
            size="small"
            fullWidth
            variant="outlined"
            value={sensitives}
            onChange={(e) => setSensitives(e.target.value)}
          />{" "}
        </Grid>{" "}
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={standardRangesDataArr.map(({ id, name }) => ({
              label: name,
            }))}
            renderInput={(params) => (
              <TextField {...params} label="Standard Ranges *" />
            )}
            onInputChange={(event, newInputValue) => {
              setStandardRanges(newInputValue);
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Default Value *"
            size="small"
            fullWidth
            variant="outlined"
            value={defaultValue}
            onChange={(e) =>  setDefaultValue(e.target.value)}
          />{" "}
        </Grid>{" "}
      </Grid>
      <br />
      <Toolbar style={{ padding: "0px" }} sx={{ mt: 5 }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            props.setLoader(true);
            onSubmit();
          }}
        >
          Edit Uncertainty
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
