import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Chip,
  Divider,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { useSnackbar } from "notistack";
import CButton from "./../../../utils/components/Button";
const axios = require("axios");
export default function CreateTable() {
  const { enqueueSnackbar } = useSnackbar();
  const [datasheetColumnName, setDatasheetColumnName] = React.useState([""]);
  const [certificateColumnName, setCertificateColumnName] = React.useState([
    "",
  ]);
  var [instrumentArray, setInstrumentArray] = React.useState([]);
  const [unitType, setUnitType] = React.useState("");
  const [instrument, setInstruments] = React.useState("");
  const [newTableName, setNewTableName] = React.useState("");
  const [parameterName, setParameterName] = React.useState("");
  const [rangeSize, setRangesize] = React.useState("");
  const [standardValue, setStandardValue] = React.useState(false);
  const [testPoint, setTestPoint] = React.useState(false);
  const [setPoint, setSetPoint] = React.useState(false);
  const [ducValue, setDUCValue] = React.useState(false);
  const [nominlValue, setNominalValue] = React.useState(false);
  const [scaleReading, setScaleReading] = React.useState(false);
  const [nominalMassValue, setNominalMassValue] = React.useState(false);
  const [conventionalMassValueObserved, setConventionalMassValueObserved] = React.useState(false);
  const [observedValue, setObservedValue] = React.useState(false);
  const [uucValue, setUucValue] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [mean, setMean] = React.useState(false);
  const [errorPercent, setErrorPercent] = React.useState(false);
  const [expandedUncertainty, setExpandedUncertainty] = React.useState(false);
  const [expandedUncertaintyRdg, setExpandedUncertaintyRdg] =
    React.useState(false);
  const [expandedUncertaintyFsd, setExpandedUncertaintyFsd] =
    React.useState(false);
  const [standardDeviation, setStandardDeviation] = React.useState(false);
  const [repeatability, setRepeatability] = React.useState(false);
  const [averageReading, setAverageReading] = React.useState(false);
  const [hysteresis, setHysteresis] = React.useState(false);
  const [coverageFactor, setCoverageFactor] = React.useState(false);
  const [up1, setUp1] = React.useState(false);
  const [down1, setDown1] = React.useState(false);
  const [up2, setUp2] = React.useState(false);
  const [down2, setDown2] = React.useState(false);
  const [m1, setM1] = React.useState(false);
  const [m2, setM2] = React.useState(false);
  const [m3, setM3] = React.useState(false);
  const [m4, setM4] = React.useState(false);
  const [m5, setM5] = React.useState(false);
  const [m6, setM6] = React.useState(false);
  const [m7, setM7] = React.useState(false);
  const [m8, setM8] = React.useState(false);
  const [m9, setM9] = React.useState(false);
  const [m10, setM10] = React.useState(false);
  const [channel1, setChannel1] = React.useState(false);
  const [channel2, setChannel2] = React.useState(false);
  const [channel3, setChannel3] = React.useState(false);
  const [channel4, setChannel4] = React.useState(false);
  const [channel5, setChannel5] = React.useState(false);
  const [channel6, setChannel6] = React.useState(false);
  const [channel7, setChannel7] = React.useState(false);
  const [channel8, setChannel8] = React.useState(false);
  const [channel9, setChannel9] = React.useState(false);
  const [channel10, setChannel10] = React.useState(false);
  const [expectedoutputinmA, setExpectedoutputinmA] = React.useState(false);
  const [AverageobsopinmA, setAverageobsopinmA] = React.useState(false);
  const [deviationinmA, setDeviationinmA] = React.useState(false);
  const [deviation, setDeviation] = React.useState(false);
  const [remark, setRemark] = React.useState(false);
  const [certificateKeys, setCertificateKeys] = React.useState([]);
  const [certificateMergeKeys, setCertificateMergeKeys] = React.useState([]);
  const [executing, setExecuting] = React.useState(false);

  const updateCertificateKeys = (value, keyName) => {
    const newColumnName = [...certificateMergeKeys];
    newColumnName[keyName] = value.toString();
    console.log(newColumnName);
    setCertificateMergeKeys(newColumnName);
  };

  const onSubmit = () => {
    setExecuting(true);
    if (datasheetColumnName.length) {
      var c1, c2, c3, c4, c5, c6, c7, c8, c9, c10;
      c1 = datasheetColumnName[0];
      c2 = datasheetColumnName[1];
      c3 = datasheetColumnName[2];
      c4 = datasheetColumnName[3];
      c5 = datasheetColumnName[4];
      c6 = datasheetColumnName[5];
      c7 = datasheetColumnName[6];
      c8 = datasheetColumnName[7];
      c9 = datasheetColumnName[8];
      c10 = datasheetColumnName[9];
    }

    var jsonData = {
      unitType: unitType,
      instrumentId: instrument,
      tableName: newTableName,
      rangeSize: rangeSize,
      parameterName: parameterName,
      c1: c1,
      c2: c2,
      c3: c3,
      c4: c4,
      c5: c5,
      c6: c6,
      c7: c7,
      c8: c8,
      c9: c9,
      c10: c10,
    };

    Object.keys(jsonData).forEach((key) => {
      if (jsonData[key] === undefined || !jsonData[key]) {
        delete jsonData[key];
      }
    });

    let url = BASE_URL;
    console.log('datasheetTables',jsonData);
    axios
      .post(url + "datasheetTables", jsonData)
      .then((res) => {

        certData();
        // setTimeout(refresh, 1000);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something Went Wrong3!");
      });

    var certData = (id) => {
      if (certificateColumnName.length) {
        var c1, c2, c3, c4, c5, c6, c7, c8, c9, c10;
        c1 = certificateColumnName[0];
        c2 = certificateColumnName[1];
        c3 = certificateColumnName[2];
        c4 = certificateColumnName[3];
        c5 = certificateColumnName[4];
        c6 = certificateColumnName[5];
        c7 = certificateColumnName[6];
        c8 = certificateColumnName[7];
        c9 = certificateColumnName[8];
        c10 = certificateColumnName[9];
      }
      var jsonTableData2 = {
        tableName: newTableName,
        unitType: unitType,
        instrumentId: instrument,
        rangeSize: rangeSize,
        parameterName: parameterName,
        up1: up1,
        down1: down1,
        up2: up2,
        down2: down2,
        m1: m1,
        m2: m2,
        m3: m3,
        m4: m4,
        m5: m5,
        m6: m6,
        m7: m7,
        m8: m8,
        m9: m9,
        m10: m10,
        ch1: channel1,
        ch2: channel2,
        ch3: channel3,
        ch4: channel4,
        ch5: channel5,
        ch6: channel6,
        ch7: channel7,
        ch8: channel8,
        ch9: channel9,
        ch10: channel10,
        c1: c1,
        c2: c2,
        c3: c3,
        c4: c4,
        c5: c5,
        c6: c6,
        c7: c7,
        c8: c8,
        c9: c9,
        c10: c10,

        standardValue: standardValue,
        testPoint: testPoint,
        setPoint: setPoint,
        ducValue: ducValue,
        uucValue: uucValue,
        nominalValue: nominlValue,
        scaleReading: scaleReading,
        nominalMassValue: nominalMassValue,
        conventionalMassValueObserved: conventionalMassValueObserved,
        observedValue: observedValue,
        uucValue: uucValue,
        error: error,
        mean: mean,
        errorPercent: errorPercent,
        expandedUncertaintyRdg: expandedUncertaintyRdg,
        expandedUncertaintyFsd: expandedUncertaintyFsd,
        stdDev: standardDeviation,
        repeatability: repeatability,
        avgReading: averageReading,
        hysteresis: hysteresis,
        coverageFactor: coverageFactor,
        expectedOutputInMa: expectedoutputinmA,
        avgObsinMa: AverageobsopinmA,
        deviationinMa: deviationinmA,
      };

      Object.keys(jsonTableData2).forEach((key) => {
        if (jsonTableData2[key] === undefined && !jsonTableData2[key]) {
          delete jsonTableData2[key];
        }
      });
      axios
        .post(url + "certificateTables", jsonTableData2)
        .then((res) => {
          toast("new table added!");
          // setTimeout(refresh, 1000);
        })
        .catch((error) => {
          toast.error("Something Went Wrong in certificate!");
        });
    };
  };

  const getCertificateTableKeysFromCertificateTables = (event) => {
    var splitArr = [];
    let url = BASE_URL;
    axios
      .get(url + "certificateTables")
      .then((res) => {
        if (res.data.length) {
          var sortedData = res.data.reverse()[0];
          var keys = [];
          for (var k in sortedData) keys.push(k);
          keys.splice(keys.length - 12, 12);
          keys.slice(5).forEach(function (value) {
            splitArr.push(value);
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setCertificateKeys(splitArr);
  };

  const renderCertificateTableKeysData = () => {
    return certificateKeys.map((certKeyData, index) => {
      return (
        <Grid item xs={4}>
          <FormControlLabel
            sx={{
              display: "flex",
              gap: 2,
            }}
            control={
              <Checkbox
                onChange={(e) => {
                  updateCertificateKeys(e.target.checked, certKeyData);
                  // setMatrixId({isChecked:e.target.checked, id:matrixData.id});
                }}
              />
            }
            label={certKeyData}
          />
        </Grid>
      );
    });
  };


  const addDatasheetColumn = () => {
    setDatasheetColumnName([...datasheetColumnName, ""]);
  };

  const deleteDatasheetColumn = (index) => {
    let newDatasheetColumnName = [...datasheetColumnName];
    newDatasheetColumnName.splice(index, 1);
    setDatasheetColumnName([...newDatasheetColumnName]);
  };
  const updateDatasheetColumnName = (value, index) => {
    const newColumnName = [...datasheetColumnName];
    newColumnName[index] = value;
    setDatasheetColumnName(newColumnName);
  };

  const addCertificateColumn = () => {
    setCertificateColumnName([...certificateColumnName, ""]);
  };
  const deleteCertificateColumn = (index) => {
    let newCertificateColumnName = [...certificateColumnName];
    newCertificateColumnName.splice(index, 1);
    setCertificateColumnName([...newCertificateColumnName]);
  };
  const updateCertificateColumnName = (value, index) => {
    const newColumnName = [...certificateColumnName];
    newColumnName[index] = value;
    setCertificateColumnName(newColumnName);
  };

  const getInstrumentsList = () => {
    let url = BASE_URL;
    axios
      .get(url + "instruments?_where=(status,eq,1)")
      .then((res) => {
        setInstrumentArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong2!");
      });
  };

  useEffect(() => {
    getCertificateTableKeysFromCertificateTables();
    getInstrumentsList();
  }, []);

  var pushIArray = [];
  for (let i = 0; i < instrumentArray.length; i++) {
    if (instrumentArray[i].instrumentName) {
      pushIArray.push({
        label: instrumentArray[i].id + ", " + instrumentArray[i].instrumentName,
      });
    }
  }

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6">
        create new table
      </Typography>
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={4}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={pushIArray}
            onChange={(event, value) => {
              setInstruments(parseInt(value.label.split(",")[0]));
            }}
            renderInput={(params) => (
              <TextField {...params} label="Instrument" />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            label="New table name"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => setNewTableName(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            label="Unit"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => setUnitType(e.target.value)}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            label="parameter name"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => setParameterName(e.target.value)}
          />
        </Grid>
      </Grid>

      <Divider sx={{ mt: 5 }}>
        <Chip
          label="Datasheet table configuration"
          variant="outlined"
          sx={{ fontSize: "16px" }}
        />
      </Divider>
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            label="No. of Readings"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => setRangesize(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
            sx={{
              display: "inline-block",
              gap: 2,
            }}
            control={
              <Checkbox
                onChange={(e) => {
                  // setStandardValue(e.target.checked);
                }}
              />
            }
            label="Test Point "
          />
          <FormControlLabel
            sx={{
              display: "inline-block",
              gap: 2,
            }}
            control={
              <Checkbox
                onChange={(e) => {
                  // setStandardValue(e.target.checked);
                }}
              />
            }
            label="Set Point "
          />
        </Grid>
      </Grid>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        add custom columns
        <CButton
          variant="contained"
          size="small"
          label={<AddIcon />}
          sx={{ ml: 3 }}
          onClick={() => {
            addDatasheetColumn();
          }}
        ></CButton>
      </Typography>
      <Grid container spacing={1} sx={{ mb: 3 }}>
        {datasheetColumnName.map((column, index) => (
          <Grid item xs={4}>
            <OutlinedInput
              id="outlined-adornment-password"
              label={"column " + (index + 1)}
              value={column}
              placeholder={"column " + (index + 1)}
              onChange={(e) => {
                updateDatasheetColumnName(e.target.value, index);
              }}
              size="small"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      deleteDatasheetColumn(index);
                    }}
                    edge="end"
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
        ))}
      </Grid>

      <Divider sx={{ mt: 5 }}>
        <Chip
          label="Certificate table configuration"
          variant="outlined"
          sx={{ fontSize: "16px" }}
        />
      </Divider>
      <br />
      <Grid container spacing={2} sx={{ mb: 3 }} justify="flex-start">
        {/* {renderCertificateTableKeysData()} */}
        
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setTestPoint(e.target.checked);
                                }}
                            />
                        }
                        label="Test Point"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setSetPoint(e.target.checked);
                                }}
                            />
                        }
                        label="Set Point"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setStandardValue(e.target.checked);
                                }}
                            />
                        }
                        label="Standard value"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setNominalValue(e.target.checked);
                                }}
                            />
                        }
                        label="Nominal Value"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setScaleReading(e.target.checked);
                                }}
                            />
                        }
                        label="Scale Reading"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setNominalMassValue(e.target.checked);
                                }}
                            />
                        }
                        label="Nominal Mass Value"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setConventionalMassValueObserved(e.target.checked);
                                }}
                            />
                        }
                        label="Conventional Mass Value Observed"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setObservedValue(e.target.checked);
                                }}
                            />
                        }
                        label="Observed Value"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setDUCValue(e.target.checked);
                                }}
                            />
                        }
                        label="DUC Value"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setUucValue(e.target.checked);
                                }}
                            />
                        }
                        label="UUC Value"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setError(e.target.checked);
                                }}
                            />
                        }
                        label="Error"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setMean(e.target.checked);
                                }}
                            />
                        }
                        label="Mean"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setErrorPercent(e.target.checked);
                                }}
                            />
                        }
                        label="Error %"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setExpandedUncertainty(e.target.checked);
                                }}
                            />
                        }
                        label="Expanded Uncertainty"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setExpandedUncertaintyRdg(e.target.checked);
                                }}
                            />
                        }
                        label="Expanded Uncertainty (% Rdg)"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setExpandedUncertaintyFsd(e.target.checked);
                                }}
                            />
                        }
                        label="Expanded Uncertainty (% FSD)"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setStandardDeviation(e.target.checked);
                                }}
                            />
                        }
                        label="Standard Deviation"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setRepeatability(e.target.checked);
                                }}
                            />
                        }
                        label="Repeatability"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setAverageReading(e.target.checked);
                                }}
                            />
                        }
                        label="Average Reading"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setDeviation(e.target.checked);
                                }}
                            />
                        }
                        label="deviation"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setHysteresis(e.target.checked);
                                }}
                            />
                        }
                        label="Hysteresis"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setCoverageFactor(e.target.checked);
                                }}
                            />
                        }
                        label="Coverage factor"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setUp1(e.target.checked);
                                }}
                            />
                        }
                        label="up"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setDown1(e.target.checked);
                                }}
                            />
                        }
                        label="down"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setUp2(e.target.checked);
                                }}
                            />
                        }
                        label="up"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setDown2(e.target.checked);
                                }}
                            />
                        }
                        label="down"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setM1(e.target.checked);
                                }}
                            />
                        }
                        label="m1"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setM2(e.target.checked);
                                }}
                            />
                        }
                        label="m2"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setM3(e.target.checked);
                                }}
                            />
                        }
                        label="m3"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setM4(e.target.checked);
                                }}
                            />
                        }
                        label="m4"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setM5(e.target.checked);
                                }}
                            />
                        }
                        label="m5"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setM6(e.target.checked);
                                }}
                            />
                        }
                        label="m6"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setM7(e.target.checked);
                                }}
                            />
                        }
                        label="m7"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setM8(e.target.checked);
                                }}
                            />
                        }
                        label="m8"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setM9(e.target.checked);
                                }}
                            />
                        }
                        label="m9"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setM10(e.target.checked);
                                }}
                            />
                        }
                        label="m10"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setChannel1(e.target.checked);
                                }}
                            />
                        }
                        label="channel 1"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setChannel2(e.target.checked);
                                }}
                            />
                        }
                        label="channel 2"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setChannel3(e.target.checked);
                                }}
                            />
                        }
                        label="channel 3"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setChannel4(e.target.checked);
                                }}
                            />
                        }
                        label="channel 4"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setChannel5(e.target.checked);
                                }}
                            />
                        }
                        label="channel 5"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                sx={{
                                    display: "flex",
                                    gap: 2,
                                }}
                                onChange={(e) => {
                                    setChannel6(e.target.checked);
                                }}
                            />
                        }
                        label="channel 6"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setChannel7(e.target.checked);
                                }}
                            />
                        }
                        label="channel 7"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setChannel8(e.target.checked);
                                }}
                            />
                        }
                        label="channel 8"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setChannel9(e.target.checked);
                                }}
                            />
                        }
                        label="channel 9"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setChannel10(e.target.checked);
                                }}
                            />
                        }
                        label="channel 10"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setExpectedoutputinmA(e.target.checked);
                                }}
                            />
                        }
                        label="Expected output in Ma"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setAverageobsopinmA(e.target.checked);
                                }}
                            />
                        }
                        label="Average obs. o/p in Ma"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setDeviationinmA(e.target.checked);
                                }}
                            />
                        }
                        label="Deviation in Ma"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setRemark(e.target.checked);
                                }}
                            />
                        }
                        label="Remark"
                    />
                </Grid>
      </Grid>

      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        add custom columns
        <CButton
          variant="contained"
          size="small"
          label={<AddIcon />}
          sx={{ ml: 3 }}
          onClick={() => {
            addCertificateColumn();
          }}
        ></CButton>
      </Typography>

      <Grid container spacing={1} sx={{ mb: 3 }}>
        {certificateColumnName.map((column, index) => (
          <Grid item xs={4}>
            <OutlinedInput
              id="outlined-adornment-password"
              label={"column " + (index + 1)}
              value={column}
              placeholder={"column " + (index + 1)}
              onChange={(e) => {
                updateCertificateColumnName(e.target.value, index);
              }}
              size="small"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      deleteCertificateColumn(index);
                    }}
                    edge="end"
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
        ))}
      </Grid>
      <br />
      <hr />
      <Toolbar style={{ padding: "0px" }}>
        <CButton
          variant="contained"
          label="SAVE"
          disabled={executing}
          // onClick={() => {
          //     enqueueSnackbar("Table saved successfully !", {
          //         variant: "success",
          //     });
          // }}
          onClick={() => {
            // enqueueSnackbar("Standard added successfully !", {
            //     variant: "success",
            // });
            onSubmit();
          }}
        >
          SAVE
        </CButton>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
