import React, { useEffect,useRef } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";
import useGlobalStorage from "use-global-storage";
import Tooltip from '@mui/material/Tooltip';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import {
  Grid,
  TextField,
  Autocomplete
} from "@mui/material";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  width: '50% !important',
    height: '40% !important'
};

const axios = require("axios");

const columns = [
  { id: "id", label: "Sr. No",minWidth: 40, },

  {
    id: "instrumentName",
    label: "Instrument Name",
    align: "left",
    minWidth: 40,
  },
  {
    id: "tableName",
    label: "Table Name",
    align: "left",
    minWidth: 40,
  },

  {
    id: "unitType",
    label: "Unit Type",
    align: "left",
    minWidth: 40,
  },
];

function createData(id, instrumentName, tableName, unitType) {
  return {
    id,
    instrumentName,
    tableName,
    unitType,
  };
}

export function TablesList() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [clientList, setClientsList] = React.useState("");
  const [instrumentArray, setInstrumentArray] = React.useState([]);
  const [instrumentId, setInstrumentId] = React.useState(null);
  const [staticDsTableArray, setDSStaticTableArray] = React.useState([]);
  const [certStaticTableArray, setCertStaticTableArray] = React.useState([]);
  const [tableName, setTableName] = React.useState([]);
  const ref = useRef(null);

  const [open, setOpen] = React.useState(false);
  const handleOpen = (row) => {
    setTableName(row.tableName)
      let url = BASE_URL;
      axios
        .get(url + `datasheetStaticTables?_where=(id,eq,${row.id})`)
        .then((res) => {
          setDSStaticTableArray(res.data);
        })
        .catch((error) => {
          toast.error("Something Went Wrong2!");
        });

      axios
        .get(url + `certificateStaticTables?_where=(id,eq,${row.id})`)
        .then((res) => {
          setCertStaticTableArray(res.data);
        })
        .catch((error) => {
          toast.error("Something Went Wrong2!");
        });

    setOpen(true);
  };
  
  const handleClose = () => setOpen(false);

  const useStorage = useGlobalStorage({
    storageOptions: { name: "test-db" },
  });
  const [title, setTitle] = useStorage("title");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getClientList = (event) => {
    let url = BASE_URL;
    axios
      .get(
        url +
          "xjoin?_join=pl.datasheetStaticTables,_j,pr.instruments&_on1=(pl.instrumentId,eq,pr.id)&_fields=pl.instrumentId,pr.instrumentName,pl.tableName,pl.unitType,pl.id&_where=(pl.status,eq,1)"
      )
      .then((res) => {
        setClientsList(res.data);
        initializeDataTable();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getInstrumentsList = () => {
    let url = BASE_URL;
    axios
      .get(url + "instruments?_where=(status,eq,1)")
      .then((res) => {
        setInstrumentArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong2!");
      });
  };


  const submitSiteDelete = (id) => {
    let url = BASE_URL;
    axios
      .delete(url + `datasheetStaticTables/${id}`)
      .then((res) => {
        toast("datasheet table deleted!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
      axios
      .delete(url + `certificateStaticTables/${id}`)
      .then((res) => {
        toast("certificate table table deleted!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };
  var refresh = () => {
    window.location.reload(false);
    // <Link to="/master/master/standard">Home</Link>
  };
  

  var rowDaata = [];
  for (let i = 0; i < clientList.length; i++) {
    rowDaata.push(
      createData(
        clientList[i].pl_id,
        clientList[i].pr_instrumentName,
        clientList[i].pl_tableName,
        clientList[i].pl_unitType
      )
    );
  }

  var pushIArray = [];
  for (let i = 0; i < instrumentArray.length; i++) {
    if (instrumentArray[i].instrumentName) {
      pushIArray.push({
        label: instrumentArray[i].id + ", " + instrumentArray[i].instrumentName,
      });
    }
  }

  const initializeDataTable = () => {
  
    $("#datatable-keytable").DataTable({
        destroy: true,
        keys: true,
        dom:
          "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
          "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
          "<'row'<'col-sm-12' tr>>" +
          "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
          "bPaginate": false,
          "bLengthChange": true,
          "bFilter": true,
          "bInfo": false,
          "bAutoWidth": true,
          language: {
              "zeroRecords": " "
          },
  
          buttons: ["copy", "csv", "excel", "print"],
    });
  }


  const handleSubmit = async (e) => {
    if(instrumentId!=null){

    var jsData = staticDsTableArray[0]
    var certData = certStaticTableArray[0]
    jsData.instrumentId = instrumentId
    certData.instrumentId = instrumentId
    delete jsData.id
    delete jsData.lastModified
    delete certData.id
    delete certData.lastModified
    let url = BASE_URL;
    axios
      .post(url + "datasheetStaticTables", jsData)
      .then((res) => {
        // setTimeout(refresh, 1000);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something Went Wrong3!");
      });
    axios
      .post(url + "certificateStaticTables", certData)
      .then((res) => {
        toast("Table copied successfully !");
        setTimeout(refresh, 500);
        // setTimeout(refresh, 1000);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something Went Wrong3!");
      });
            
    }else{
      toast.error("Please select atleast one instrument!");
    }

  }

  useEffect(() => {
    getClientList();
    getInstrumentsList();
  }, []);

  return (
  
    <TableContainer component={Paper} sx={{ mt: 2 }}>
        <div>
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <h5 className="text-center"><u>Table Name: {tableName}</u></h5>
        <br/>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={instrumentArray != null
              ? instrumentArray.map((item) => {
                    return {
                      id: item.id,
                      instrumentName: item.instrumentName,                    
                    };
                })
              : []}
            getOptionLabel={(option) =>
              option.id +', '+option.instrumentName
            }
            renderInput={(params) => (
              <TextField {...params} label="Select Instrument" />
            )}
            onInputChange={(event, newInputValue) => {
              setInstrumentId(newInputValue.split(',')[0])
            }}
          />
        </Grid>
        </Grid>
        <br />
      <Toolbar style={{ padding: "0px", overflow: "auto" }}>
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            onClick={() => {
              handleSubmit();
            }}
          >
            Save
          </Button>
      
      </Toolbar>
      </Box>
    </Modal>
  </div>
      <Toolbar>
        <Button
          variant="contained"
          size="small"
          component={Link}
          to="/master/createStaticTable"
          onClick={() => {
            setTitle("Create Table");
          }}
        >
          <b>ADD NEW STATIC TABLE</b>
        </Button>
      </Toolbar>
      <hr/>

      <Table stickyHeader aria-label="sticky table" size="small" id="datatable-keytable">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                <b>{column.label}</b>
              </TableCell>
            ))}
            <TableCell>
              <b>Actions</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowDaata
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    let value = row[column.id];
                    if (column.id == "id") {
                      value = index + 1;
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                  <TableCell>
                    <ButtonGroup size="small" aria-label="small button group">
                    <Tooltip title="Copy Table" placement="top-start">
                      <Button
                      id={row.id}
                      key={row.id}
                      ref={ref} 
                      onClick={()=> { handleOpen(row) }} 
                     >
                        <ContentCopyIcon />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Edit Table" placement="top-start">
                      <Button
                        component={Link}
                        to={`/master/editStaticTable/${row.id}`}
                      >
                        <PreviewIcon />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Delete Table" placement="top-start">
                      <Button
                        onClick={(e) => {
                          if (
                            window.confirm("Really want to delete datasheet table?")
                          ) {
                            submitSiteDelete(row.id);
                          }
                        }}
                      >
                        <DeleteIcon style={{ color: "#dc3545" }} />
                      </Button>
                    </Tooltip>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rowDaata.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ToastContainer />
    </TableContainer>
  );
}
