import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import moment from "moment";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
} from "@mui/material";

const axios = require("axios");

export default function UpdateExpense() {
  const [expenseDate, setExpenseDate] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [paymentMode, setPaymentMode] = React.useState("");
  const [nameOfThePerson, setNameOfThePerson] = React.useState("");
  const [reason, setReason] = React.useState("");
  const [clientId, setClientId] = React.useState("");
  var [clientArray, setClientArray] = React.useState([]);


  const params = useParams();

  const getExpenseList = () => {
    let url = BASE_URL;
    axios
      .get(url + `expenses/${params.id}`)
      .then((res) => {
        setExpenseDate(res.data[0].expenseDate);
        setAmount(res.data[0].amount);
        setPaymentMode(res.data[0].paymentMode);
        setNameOfThePerson(res.data[0].nameOfPerson);
        setReason(res.data[0].reason);
        setClientId(res.data[0].clientId);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  useEffect(() => {
    getExpenseList();
  }, []);

  var refresh = () => {
    window.location.reload(false);
  };

  const onSubmit = () => {
    var jsonData = {
      clientId: clientId,
      expenseDate: expenseDate ? moment(expenseDate).format("YYYY-MM-DD") : "",
      amount: amount,
      paymentMode: paymentMode,
      nameOfPerson: nameOfThePerson,
      reason: reason,
    };

    let url = BASE_URL;
    axios
      .patch(url + `expenses/${params.id}`, jsonData)
      .then((res) => {
        toast("updated expense!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const getClientIdList = () => {
    let url = BASE_URL;
    axios
      .get(url + "clients?_where=(status,eq,1)")
      .then((res) => {
        setClientArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };
  var pushCArray = [];
  for (let i = 0; i < clientArray.length; i++) {
    if (clientArray[i].companyName) {
      pushCArray.push({
        label: clientArray[i].id + ", " + clientArray[i].companyName,
      });
    }
  }

  useEffect(() => {
    getClientIdList();
  }, []);

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Edit Expense
      </Typography>

      <Grid container spacing={2}>
      <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={pushCArray}
            renderInput={(params) => (
              <TextField {...params} label="Company Name *" />
            )}
            onInputChange={(event, newInputValue) => {
              setClientId(newInputValue.split(",")[0])
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Payment Date *"
              value={expenseDate ? expenseDate : new Date()}
              InputLabelProps={{ shrink: true }}
              inputFormat="dd/MM/yyyy"
              onChange={(newValue) => {
                setExpenseDate(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Amount *"
            size="small"
            fullWidth
            variant="outlined"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </Grid>{" "}
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={[
              { id: 1, label: "cash" },
              { id: 2, label: "cheque" },
              { id: 3, label: "NEFT" },
              { id: 4, label: "upi" },
            ]}
            value={paymentMode}
            renderInput={(params) => (
              <TextField {...params} label="Payment Mode *" />
            )}
            onInputChange={(event, newInputValue) => {
              setPaymentMode(newInputValue);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Name Of Person *"
            size="small"
            fullWidth
            variant="outlined"
            value={nameOfThePerson}
            onChange={(e) => setNameOfThePerson(e.target.value)}
          />
        </Grid>{" "}
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Reason *"
            size="small"
            fullWidth
            variant="outlined"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </Grid>
      </Grid>
      <br />

      <Toolbar style={{ padding: "0px" }} sx={{ mt: 5 }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            onSubmit();
          }}
        >
          Save
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
