import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  FormControlLabel,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import Checkbox from "@mui/material/Checkbox";
import { ClassicTable } from "../../utils/components/Styles";

const axios = require("axios");

export default function EditInvoice() {
  const [companyName, setCompanyName] = React.useState("");
  const [instrumentName, setInstrumentName] = React.useState("");
  const [clientId, setClientId] = React.useState("");
  const [invoiceData, setInvoiceData] = React.useState([]);
  const [deletedIds, setDeletedIds] = React.useState([]);
  const [instrumentArray, setInstrumentArray] = React.useState([]);
  const [totalAmount, setTotalAmount] = React.useState("");
  const [discount, setTotalDiscount] = React.useState("");
  const [totalD, setTotalD] = React.useState("");
  const [gstType, setGstType] = React.useState(null);
  const [address, setAddress] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [invoiceDate, setInvoiceDate] = React.useState("");
  const [conveyanceCharges, setConveyanceCharges] = React.useState("");
  const [instrumentIds, setInstrumentIds] = React.useState([]);
  const [invoiceNumber, setInvoiceNumber] = React.useState(
    (Math.random() + 1).toString(36).substring(7)
  );
  const [contactNumber, setContactNumber] = React.useState("");
  const [poNumber, setPoNumber] = React.useState("");
  const [sGst, setSGST] = React.useState(0);
  const [cGst, setCGST] = React.useState(0);
  const [poDate, setPoDate] = React.useState("");
  const [instrumentDuplicateCount, setInstrumentDuplicateCount] =
    React.useState("");
  const [products, setProducts] = React.useState([]);
  const params = useParams();
  const userType = localStorage.getItem("type");
  const [enableDisable, setEnableData] = React.useState("");

  const readingColumns = [
    [
      {
        id: "enableDisableStatus",
        label: "status",
        align: "ceter",
        minWidth: 10,
      },
      {
        field: "id",
        headerName: "Sr. No.",
        editable: false,
      },
      {
        field: "Product Name",
        headerName: "Product Name",
        editable: true,
      },
      {
        field: "Quantity",
        headerName: "Quantity",
        editable: true,
      },
      {
        field: "Rate",
        headerName: "Rate",
        editable: true,
      },
      {
        field: "Amount",
        headerName: "Amount",
        editable: true,
      },
    ],
  ];
  const [readingRows, setReadingRows] = React.useState([["", "", "", "", ""]]);

  const getInvoiceData = () => {
    let url = BASE_URL;
    axios
      .get(
        url +
          `xjoin?_join=invoice.invoices,_j,ins.instruments,_j,client.clients&_on1=(invoice.instrumentId,eq,ins.id)&_on2=(invoice.clientId,eq,client.id)&_fields=ins.instrumentName,client.companyName,invoice.clientId,ins.id,client.address,client.contact,invoice.poNumber,invoice.poDate&_where=(invoice.clientId,eq,${params.id})~and(invoice.poNumber,eq,${params.poNumber})~and(invoice.status,eq,1)`
      )
      .then((res) => {
        setCompanyName(res.data[0].client_companyName);
        setInstrumentName(res.data[0].ins_instrumentName);
        setClientId(res.data[0].invoice_clientId);
        setInstrumentArray(res.data);
        setAddress(res.data[0].client_address);
        setContactNumber(res.data[0].client_contact);
        setPoNumber(res.data[0].invoice_poNumber);
        setPoDate(res.data[0].invoice_poDate);
        setInstrumentDuplicateCount(res.data[0].instrumentDuplicateCount);
      })
      .catch((error) => {
        toast.error("Something Went Wrong with standards!");
      });
  };

  const updateEnableDisable = (value, id) => {
    const newColumnName = [...enableDisable];
    newColumnName[id] = id + "," + value;
    setEnableData(newColumnName);
  };

  const getProductArray = () => {
    let url = BASE_URL;
    axios
      .get(url + `products`)
      .then((res) => {
        setProducts(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong with standards!");
      });
  };

  const updateCellValue = (rowIndex, colIndex, value) => {
    const newRows = [...readingRows];
    newRows[rowIndex][colIndex] = value;
    setReadingRows([...newRows]);
  };

  const getInvoiceDataArray = () => {
    axios
      .get(
        BASE_URL +
          `invoices?_where=(clientId,eq,${params.id})~and(poNumber,eq,${params.poNumber})~and(status,eq,1)`
      )
      .then((res) => {
        setInvoiceData(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong while fetching standardRanges!");
      });
  };

  const handleSubmit = (event) => {
    let rows = readingRows.map((row) => ({
      id: parseInt(row[0].split(",")[0]),
      instrumentDuplicateCount: parseInt(row[1]),
    }));
    rows.map((row) => {
      let url = BASE_URL;
      axios
        .patch(url + `invoices/${row.id}`, {
          instrumentDuplicateCount: row.instrumentDuplicateCount,
        })
        .then((res) => {
          // toast("SRF created successfully !");
          // setTimeout(refresh, 1000);
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    });

    let row = {
      clientId: clientId,
      address: address,
      totalAmount: totalAmount ? totalAmount : 0,
      discount: totalD,
      gstType: gstType ? gstType : 0,
      sgst: sGst ? sGst : 0,
      cgst: cGst ? cGst : 0,
      subject: subject,
      conveyanceCharges: conveyanceCharges,
      invoiceNumber: invoiceNumber,
      contactNumber: contactNumber,
      poNumber: poNumber,
      instrumentId: instrumentIds.toString(),
      invoiceDate: invoiceDate
        ? moment(invoiceDate).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),
    };

    const invoiceHistory = {
      invoiceId: 2,
      instrumentId: instrumentIds.toString(),
      amount: 250,
      invoiceDate: invoiceDate
        ? moment(invoiceDate).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),
      quantity: 1,
      pendingInvoices: 1,
    };
    // console.log('row',row)
    // return
    let url = BASE_URL;
    axios
      .post(url + "paymentMaster", row)
      .then((res) => {
        toast("Payment created successfully!");
        setTimeout(refresh, 1000);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const initiateInvoiceData = () => {
    try {
      let rows = [];
      const instrumentIds = [];
      for (let i = 0; i < invoiceData.length; i++) {
        rows.push([
          invoiceData[i].instrumentId,
          invoiceData[i].instrumentDuplicateCount,
          invoiceData[i].id,
        ]);
        instrumentIds.push(invoiceData[i].instrumentId);
      }

      for (let i = 0; i < rows.length; i++) {
        for (let j = 0; j < instrumentArray.length; j++) {
          if (rows[i][0] === instrumentArray[j].ins_id) {
            for (let k = 0; k < products.length; k++) {
              if (products[k].srfInstrumentId == instrumentArray[j].ins_id) {
                rows[i][0] =
                  rows[i][2] + "," + instrumentArray[j].ins_instrumentName;
                rows[i][1] = rows[i][1];
                rows[i][2] = products[k].rate;
                rows[i][3] = products[k].rate * rows[i][1];
              }
            }
          }
        }
      }

      let uniqueChars = [...new Set(instrumentIds)];
      setInstrumentIds(uniqueChars);
      setReadingRows(rows);
    } catch (error) {
      console.log(error);
    }
  };

  const initiateAmountData = () => {
    var totalCounter = 0;
    for (let i = 0; i < readingRows.length; i++) {
      totalCounter += readingRows[i][3];
    }
    setTotalAmount(totalCounter);
  };

  const allData = [
    {
      instrumentName: "RTD With Dig. Temp. Data Logger",
      invoiceDate: "2022-09-19",
      amount: 250,
      pendingInvoices: 1,
    },
  ];

  useEffect(() => {
    getProductArray();
    getInvoiceData();
    getInvoiceDataArray();
  }, []);

  useEffect(() => {
    initiateInvoiceData();
  }, [invoiceData, products]);

  useEffect(() => {
    initiateAmountData();
  }, [readingRows]);

  const invoicesData = () => {
    if (gstType === 1) {
      return (
        <Grid
          container
          spacing={2}
          style={{ marginBottom: "10px", marginTop: "10px" }}
          justifyContent="flex-end"
        >
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="SGST"
              InputLabelProps={{ shrink: true }}
              value={(9 * totalAmount) / 100}
              disabled
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="CGST"
              disabled
              value={(9 * totalAmount) / 100}
              InputLabelProps={{ shrink: true }}
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid
          container
          spacing={2}
          style={{ marginBottom: "10px", marginTop: "10px" }}
          justifyContent="flex-end"
        >
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="IGST"
              value={(18 * totalAmount) / 100}
              InputLabelProps={{ shrink: true }}
              disabled
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Edit Invoice
      </Typography>
      <Grid container spacing={2} style={{ marginBottom: "10px" }}>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Company Name"
            size="small"
            InputLabelProps={{ shrink: true }}
            value={companyName}
            fullWidth
            disabled
            variant="outlined"
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Contact Number"
            size="small"
            InputLabelProps={{ shrink: true }}
            disabled
            value={contactNumber}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setContactNumber(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            InputLabelProps={{ shrink: true }}
            label="Address"
            size="small"
            value={address}
            disabled
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Invoice Number"
            size="small"
            value={(Math.random() + 1).toString(36).substring(7)}
            fullWidth
            InputLabelProps={{ shrink: true }}
            disabled
            variant="outlined"
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Subject"
            size="small"
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setSubject(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Invoice Date"
              value={invoiceDate ? invoiceDate : new Date()}
              inputFormat="dd/MM/yyyy"
              onChange={(newValue) => {
                setInvoiceDate(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="PO Number"
            size="small"
            value={poNumber}
            InputLabelProps={{ shrink: true }}
            disabled
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setPoNumber(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={[
              { value: 1, label: "State GST" },
              { value: 2, label: "Central Gst" },
            ]}
            renderInput={(params) => <TextField {...params} label="GST Type" />}
            onInputChange={(event, newInputValue) => {
              setGstType(newInputValue == "State GST" ? 1 : 2);
              if (newInputValue == "State GST") {
                setCGST((9 * totalAmount) / 100);
                setSGST((9 * totalAmount) / 100);
              } else {
                setCGST((18 * totalAmount) / 100);
              }
            }}
          />
        </Grid>
      </Grid>
      <hr />
      <h4 style={{ "margin-bottom": "0px" }}>Product Details </h4>
      <div style={{ width: "100%", overflow: "auto" }}>
        <ClassicTable>
          <Table sx={{ minWidth: 660 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {readingColumns[0].map((column) => (
                  <TableCell key={column.field}>
                    {" "}
                    <Typography noWrap>{column.headerName}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {readingRows.map((row, index) => (
                <TableRow key={index}>
                  <tableCell>
                    <Grid item xs={2}>
                      <FormControlLabel
                        classes={{
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        sx={{
                          gap: 2,
                        }}
                        control={
                          <Checkbox
                            onChange={(e) => {
                              updateEnableDisable(
                                e.target.checked,
                                row.cert_id
                              );
                            }}
                          />
                        }
                        // label={matrixData.matrixName}
                      />
                    </Grid>
                  </tableCell>
                  <TableCell>{index + 1}</TableCell>
                  {row.map((cell, cellIndex) => {
                    if (cellIndex != 0 && cellIndex != 1 && cellIndex != 3) {
                      return (
                        <TableCell>
                          <TextField
                            size="small"
                            value={cell}
                            onChange={(e) => {
                              updateCellValue(index, cellIndex, e.target.value);
                            }}
                          ></TextField>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 0) {
                      return (
                        <TableCell style={{ width: "200px" }}>
                          <Grid item xs={3}>
                            <TextField
                              id="outlined-basic"
                              size="small"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              variant="outlined"
                              value={readingRows[index][cellIndex]}
                              disabled
                            />
                          </Grid>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 1) {
                      return (
                        <TableCell style={{ width: "200px" }}>
                          <Grid item xs={3}>
                            <TextField
                              id="outlined-basic"
                              size="small"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              value={readingRows[index][cellIndex]}
                              variant="outlined"
                              onChange={(e) => {
                                updateCellValue(
                                  index,
                                  cellIndex,
                                  parseInt(e.target.value)
                                );
                                updateCellValue(
                                  index,
                                  3,
                                  e.target.value !== ""
                                    ? parseInt(readingRows[index][2]) *
                                        parseInt(e.target.value)
                                    : 0
                                );
                              }}
                            />
                          </Grid>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 3) {
                      return (
                        <TableCell style={{ width: "200px" }}>
                          <Grid item xs={3}>
                            <TextField
                              id="outlined-basic"
                              size="small"
                              fullWidth
                              disabled
                              InputLabelProps={{ shrink: true }}
                              variant="outlined"
                              value={readingRows[index][cellIndex]}
                              onChange={(e) =>
                                updateCellValue(
                                  index,
                                  cellIndex,
                                  parseInt(e.target.value)
                                )
                              }
                            />
                          </Grid>
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ClassicTable>
        <br />

        <Grid
          container
          spacing={2}
          style={{ marginBottom: "10px" }}
          justifyContent="flex-end"
        >
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="Conveyance Charges"
              size="small"
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              onChange={(e) => {
                setConveyanceCharges(e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <br />
        <Grid
          container
          spacing={2}
          style={{ marginBottom: "10px" }}
          justifyContent="flex-end"
        >
          <Grid item xs={3} justify="flex-end">
            <TextField
              id="outlined-basic"
              label="Discount %"
              minvalue="100"
              size="small"
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              onChange={(e) => {
                setTotalDiscount(
                  totalAmount * (e.target.value / 100) -
                    totalAmount * (18 / 100)
                );
                setTotalD(e.target.value);
              }}
            />
          </Grid>
        </Grid>

        {invoicesData()}
        <Grid
          container
          spacing={2}
          style={{ marginBottom: "10px", marginTop: "10px" }}
          justifyContent="flex-end"
        >
          <Grid item xs={3} justify="flex-end">
            <TextField
              id="outlined-basic"
              label="Total Amount"
              size="small"
              fullWidth
              value={totalAmount - discount}
              InputLabelProps={{ shrink: true }}
              disabled
              variant="outlined"
            />
          </Grid>
        </Grid>
        <br />
      </div>
      <br />
      <div>
        <Typography variant="h6" component="div" sx={{ mb: 2 }}>
          Invoice History
        </Typography>
        <ClassicTable>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Sr. No.</TableCell>
                <TableCell>Instrument Name</TableCell>
                <TableCell>Invoice Date</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Pending invoices</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allData.map((row, index) => (
                <TableRow key={row.name}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{row.instrumentName}</TableCell>
                  <TableCell>
                    {row.invoiceDate
                      ? moment("2022-09-19").format("DD-MM-YYYY")
                      : ""}
                  </TableCell>
                  <TableCell>{row.amount}</TableCell>
                  <TableCell>{row.pendingInvoices}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ClassicTable>
      </div>
      <br />
      <br />
      <Toolbar style={{ padding: "0px", overflow: "auto" }}>
        {userType !== "3" ? (
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            onClick={() => {
              gstType != null
                ? handleSubmit()
                : toast.error("Please select GST Type!");
            }}
          >
            Save
          </Button>
        ) : (
          console.log("")
        )}
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0, ml: 2 }}
          onClick={() => {
            // handleSubmit();
          }}
        >
          Print
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
