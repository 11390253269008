import {
  Button,
  Chip,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useReactToPrint } from "react-to-print";
import { BASE_URL } from "./../../global";
import { ClassicTable } from "./../../utils/components/Styles";
import { useDispatch } from "react-redux";
import { addSD } from "./typeASlice";

const axios = require("axios");

export default function EditTypeA(props) {
  let { datasheetReadingId, datasheetId, tableId } = useParams();
  if (props.viewAll == true) {
    datasheetReadingId = props.datasheetReadingId;
    datasheetId = props.datasheetId;
    tableId = props.tableId;
  }

  const [typeARows, setTypeARows] = useState([]);
  const [typeA, setTypeA] = useState(null);
  const [datasheetReading, setDatasheetReading] = useState(null);
  const [xXbarSqr, setXXbarSqr] = useState(null);
  const [stdDevOfMean, setStdDevOfMean] = useState(null);
  const [meanOfStdDev, setMeanOfStdDev] = useState(null);
  const [degreesOfFreedom, setDegreesOfFreedom] = useState(null);
  const [colsToProcess, setColsToProcess] = useState(null);
  const printComponentRef = React.useRef();

  const dispatch = useDispatch();

  // api calls
  function fetchDatasheetStaticTables() {
    let url = BASE_URL;
    axios
      .get(url + `datasheetStaticTables?_where=(id,eq,${tableId})`)
      .then((res) => {
        if (res.data.length > 0) {
          let cols = Object.keys(res.data[0])
            .map((key) => {
              if (String(res.data[0][key]).match(/m+\d+/g)) {
                return key;
              }
            })
            .filter((val) => {
              if (val) return val;
            });
          console.log(cols);
          setColsToProcess(cols);
        }
      })
      .catch((err) => {
        console.log("type-a data fetching error: ", err);
      });
  }
  // api calls
  function fetchTypeA() {
    // TODO: make filter for datasheetStaticReadingId
    axios
      .get(
        BASE_URL + `typeA?_where=(datasheetReadingId,eq,${datasheetReadingId})`
      )
      .then((res) => {
        setTypeA(res.data[0]);
        if (res.data[0]) {
          setStdDevOfMean(res.data[0]?.stdDevOfMean);
          setMeanOfStdDev(res.data[0]?.meanOfStdDev);
        }
      })
      .catch((err) => {
        console.log("type-a data fetching error: ", err);
      });
  }
  function fetchDatasheetReading() {
    let url = BASE_URL;

    // TODO: make filter for datasheetStaticReadingId
    axios
      .get(url + `datasheetStaticReadings?_where=(id,eq,${datasheetReadingId})`)
      .then((res) => {
        setDatasheetReading(res.data[0]);
        console.log(res.data[0]);
      })
      .catch((err) => {
        console.log("type-a data fetching error: ", err);
      });
  }

  const updateDatasheetSD = (value) => {
    axios
      .patch(BASE_URL + `datasheetStaticReadings/${datasheetReadingId}`, {
        standardDeviation: value,
      })
      .then((res) => {
        console.log("uncertainty updated successfully!");
      })
      .catch((err) => {
        console.log("uncertainty update error: ", err);
      });
  };

  const onSubmit = (stdDevOfMean, meanOfStdDev, degreesOfFreedom) => {
    var jsonData = {
      datasheetReadingId: parseInt(datasheetReadingId),
      stdDevOfMean: stdDevOfMean,
      meanOfStdDev: meanOfStdDev,
      degreesOfFreedom: parseInt(degreesOfFreedom),
    };
    let url = BASE_URL;
    if (typeA?.id) {
      axios
        .patch(url + `typeA/${typeA?.id}`, jsonData)
        .then((res) => {
          // toast("Readings Updated!");
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    }
    else{
      axios
        .post(url + `typeA`, jsonData)
        .then((res) => {
          // toast("Readings Updated!");
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    }
  };

  const calculateData = () => {
    if(colsToProcess == null || datasheetReading == null)
      return;

    //calculate mean
    let data = datasheetReading || [];
    let readings = [];
    colsToProcess.forEach((value) => {
      readings.push(data[value]);
    });

    let sum = 0;
    for (let i = 0; i < readings.length; i++) sum += Number(readings[i]);
    let mean = sum / readings.length;
    let rows = [];

    let x_xbar_sqr = 0;
    for (let i = 0; i < readings.length; i++) {
      rows.push(
        createData(
          readings[i],
          Number(mean).toFixed(4),
          Number(readings[i] - mean).toFixed(4),
          Number(Math.pow(readings[i] - mean, 2)).toFixed(4)
        )
      );
      x_xbar_sqr += Math.pow(readings[i] - mean, 2);
    }

    setXXbarSqr(x_xbar_sqr.toFixed(4));
    let sd = Math.sqrt(x_xbar_sqr / (readings.length - 1)).toFixed(4);
    setStdDevOfMean(sd);
    let meanOfStdDev = (sd / Math.sqrt(rows.length)).toFixed(4);
    setMeanOfStdDev(meanOfStdDev);
    setDegreesOfFreedom(readings.length - 1);
    setTypeARows(rows);

    updateDatasheetSD((sd / Math.sqrt(readings.length)).toFixed(4));
    onSubmit(sd, meanOfStdDev, readings.length - 1);
    dispatch(
      addSD({
        datasheetReadingId: datasheetReadingId,
        stdDevOfMean: sd, 
        meanOfStdDev: meanOfStdDev
      })
    );
  };

  function createData(reading, mean, x_xbar, x_xbar_sqr) {
    return { reading, mean, x_xbar, x_xbar_sqr };
  }

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
  });

  var refresh = () => {
    window.location.reload(false);
    // <Link to="/master/standard">Home</Link>
  };

  useEffect(() => {
    fetchDatasheetStaticTables();
    fetchTypeA();
    fetchDatasheetReading();
  }, []);

  useEffect(() => {
    calculateData();
  }, [typeA, colsToProcess, datasheetReading]);

  return (
    <Paper sx={{ mx: 5, mt: 2, p: 2 }} ref={printComponentRef}>
      <Divider sx={{ mb: 1, mt: 2 }}>
        <Chip label="TYPE-A EVALUATION" style={{ fontWeight: "bold" }} />
      </Divider>
      <div>
        <ClassicTable>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Sr. No.</TableCell>
                <TableCell>READINGS x</TableCell>
                <TableCell>MEAN x̄</TableCell>
                <TableCell>x-x̄</TableCell>
                <TableCell>
                  (x-x̄)<sup>2</sup>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {typeARows.map((row, index) => (
                <TableRow key={"tar" + index + 1}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{row.reading}</TableCell>
                  <TableCell>{row.mean}</TableCell>
                  <TableCell>{row.x_xbar}</TableCell>
                  <TableCell>{row.x_xbar_sqr}</TableCell>
                </TableRow>
              ))}
              {/* calculations */}{" "}
              <TableRow>
                <TableCell colSpan={3}> </TableCell>

                <TableCell>
                  Σ(x-x̄)<sup>2</sup>
                </TableCell>
                <TableCell>{xXbarSqr}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4}>
                  STANDARD DEVIATION OF THE MEAN (σ)
                </TableCell>
                <TableCell>{stdDevOfMean}</TableCell>
              </TableRow>{" "}
              <TableRow>
                <TableCell colSpan={4}>MEAN OF STANDARD DEVIATION</TableCell>
                <TableCell>{meanOfStdDev}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4}>DEGREES OF FREEDOM</TableCell>
                <TableCell>{typeARows.length - 1}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </ClassicTable>
      </div>
      <Grid container spacing={2} sx={{ mt: 2 }} style={{ marginTop: "80px" }}>
        <Grid item xs={4}>
          <Typography align="center">Calibrated By</Typography>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <Typography align="center">Approved By</Typography>
        </Grid>
      </Grid>
      <Divider sx={{ mb: 1, mt: 2, displayPrint: "none" }}></Divider>
      {props.viewAll != true && (
        <Toolbar
          sx={{ displayPrint: "none" }}
          style={{ padding: "0px", width: "100%" }}
        >
          {/* <Button
            variant="contained"
            size="small"
            sx={{ m: 0, displayPrint: "none" }}
            onClick={() => {
              // enqueueSnackbar("Standard added successfully !", {
              //     variant: "success",
              // });
              onSubmit();
            }}
          >
            Save
          </Button> */}
          <Button
            variant="contained"
            size="small"
            sx={{ ml: 3, displayPrint: "none" }}
            onClick={handlePrint}
          >
            Print
          </Button>
        </Toolbar>
      )}
      <ToastContainer />
    </Paper>
  );
}
