import React, { useState } from 'react'
import PDFMerger from 'pdf-merger-js/browser'
import jsPDF from 'jspdf'
import { renderToString } from 'react-dom/server'
import {
  Button,
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { Row, Col } from 'react-bootstrap'
import Image from 'mui-image'

import letterHead from '../image/nagmanLetterHead.png'
import './generateObservationPdf.css'

const FirstPage = () => {
  const renderProperties = () => {
    let properties = [
      { key: 'Description' },
      { key: 'Make' },
      { key: 'Model' },
      { key: 'Serial number' },
      { key: 'Identification Number' },
      { key: 'Range' },
      { key: 'Resolution' },
      { key: 'Accuracy' },
      { key: 'Equipment received on' },
      { key: 'Condition of the equipment on receipt' },
      { key: 'Calibration procedure number' },
      { key: 'Method of Calibration' },
      { key: 'Date of calibration' },
      { key: 'Date of next calibration suggested' },
    ]

    return properties.map((property) => {
      return (
        <Row>
          <Col>{property.key}</Col>
          <Col>{'test value'}</Col>
        </Row>
      )
    })
  }
  const renderStandards = () => {
    let standards = [
      {
        Nomenclature: 'teat value',
        CertificateNo: 'teat value',
        Validity: 'teat value',
        Traceability: 'teat value',
      },
    ]

    return standards.map((standard) => {
      return (
        <TableRow>
          <TableCell>{standard.Nomenclature}</TableCell>
          <TableCell>{standard.CertificateNo}</TableCell>
          <TableCell>{standard.Validity}</TableCell>
          <TableCell>{standard.Traceability}</TableCell>
        </TableRow>
      )
    })
  }
  return (
    <div className="generateObservationPdf m-2" style={{ width: '100%' }}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <div>
                <Image
                  // src={"https://dummyimage.com/1480x160/000/fff"}
                  src={letterHead}
                  style={{ width: '100%' }}
                  alt="letter head"
                />
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Row>
                <Col>Date of Issue - {'1/1/1'}</Col>
              </Row>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Row>
                <Col>Customer Name and Address - {'Test Cust Name'}</Col>
              </Row>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Row>
                <Col>Customer Reference - {'Test Cust Ref'}</Col>
              </Row>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Row>
                <Col>
                  <b>Details of the Instrument</b>
                </Col>
              </Row>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{renderProperties()}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Row>
                <Col>Calibration environments</Col>
              </Row>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Row>
                <Col>{'Temperature'}</Col>
                <Col>{'°C'}</Col>
              </Row>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Row>
                <Col>{'Relative humidity'}</Col>
                <Col>{'% Rh'}</Col>
              </Row>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Row>
                <Col>{'Atmospheric pressure'}</Col>
                <Col>{'mmHg'}</Col>
              </Row>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table style={{ width: '100%', marginTop: "10px"}}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={'4'}>
              <b>
                Master Instrument Details : The Standards used are traceable to
                National / International Standards
              </b>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Nomenclature </TableCell>
            <TableCell>Certificate No. </TableCell>
            <TableCell>Validity</TableCell>
            <TableCell>Traceability</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{renderStandards()}</TableBody>
      </Table>
    </div>
  )
}

export default function GenerateObservationPdf() {
  const [file, setFile] = useState(null)
  const [mergedPdfUrl, setMergedPdfUrl] = useState(null)

  const mergePdf = async (pdf) => {
    const merger = new PDFMerger()
    await merger.add(pdf)
    await merger.add(file)

    const mergedPdf = await merger.saveAsBlob()
    const url = URL.createObjectURL(mergedPdf)
    setMergedPdfUrl(url)
  }
  const generatePdf = () => {
    const pdf = new jsPDF("p", "pt", "a4")
    const string = renderToString(<FirstPage />)
    console.log(string)
    pdf.html(string, {
      callback: () => {
        mergePdf(pdf.output('blob'))
      },
    })
  }

  return (
    <div>
      <FirstPage />
      <hr />
      <div style={{ display: 'flex' }}>
        <h5>Upload observations file:&nbsp;&nbsp; </h5>
        <Input
          type="file"
          onChange={(e) => {
            let [file] = e.target.files
            setFile(file)
          }}
        />
        <Button
          variant="outlined"
          size="small"
          sx={{ m: 0 }}
          onClick={(e) => {
            generatePdf()
          }}
        >
          process
        </Button>
      </div>

      <hr />
      {mergedPdfUrl && (
        <iframe
          height={1000}
          src={`${mergedPdfUrl}`}
          title="pdf-viewer"
          width="100%"
        ></iframe>
      )}
    </div>
  )
}
