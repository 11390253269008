import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
} from "@mui/material";
const axios = require("axios");
export default function CreateIntermediateProcedure() {
  var [standardData, setStandardData] = React.useState([]);
  var [standards, setStandards] = React.useState("");
  var [standardIds, setStandardIds] = React.useState("");
  var [procedures, setProcedures] = React.useState("");
  const [checkPoint, setCheckPoint] = React.useState("");
  const [acceptanceCriteria, setAcceptanceCriteria] = React.useState("");
  const [frequency, setFrequency] = React.useState("");
  const [executing, setExecuting] = React.useState(false);

  const onSubmit = () => {
    setExecuting(true);
    var jsonData = {
      standardIds: standardIds,
      procedures: procedures,
      checkPoint: checkPoint,
      acceptanceCriteria: acceptanceCriteria,
      frequency: frequency,
    };
    console.log(jsonData);
    let url = BASE_URL;
    axios
      .post(url + "intermediateProcedure", jsonData)
      .then((res) => {
        toast("new Intermediate Procedure added!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const getStandards = () => {
    let url = BASE_URL;
    axios
      .get(url + "standards?_where=(status,eq,1)")
      .then((res) => {
        setStandardData(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const initiateStandardData = () => {
    var pushCArray = [];
    for (let i = 0; i < standardData.length; i++) {
      if (standardData[i].standardName) {
        pushCArray.push({
          label: standardData[i].id + ", " + standardData[i].standardName,
        });
      }
    }
    setStandards(pushCArray);
  };
  useEffect(() => {
    initiateStandardData();
  }, [standardData]);

  useEffect(() => {
    getStandards();
  }, []);
  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Create new Intermediate Procedure
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={standards}
            multiple
            renderInput={(params) => (
              <TextField {...params} label="Standards" />
            )}
            onChange={(event, newInputValue) => {
              const ids = [];
              newInputValue.map((data, i) => {
                ids.push(newInputValue[i].label.split(",")[0]);
              });
              setStandardIds(ids.toString());
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            label="Acceptance Criteria"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => setAcceptanceCriteria(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            label="Frequency"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => setFrequency(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Procedure"
            size="small"
            rows={3}
            multiline
            fullWidth
            variant="outlined"
            onChange={(e) => setProcedures(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Check Point"
            rows={3}
            multiline
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => setCheckPoint(e.target.value)}
          />
        </Grid>
      </Grid>

      <Toolbar style={{ padding: "0px" }} sx={{ mt: 5 }}>
        <Button
          variant="contained"
          disabled={executing}
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            onSubmit();
          }}
        >
          Save Intermediate Procedure
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
