import React, { useState } from "react";
import "./components/dashboard/style.css";
import { HashRouter, Routes, Route } from "react-router-dom";
import { CircularProgress } from "@mui/material";

import Main from "./components/home/main";
import { useEffect } from "react";
// import LoginForm from "./../login/Login";

function App() {
  const [loader, setLoader] = useState(undefined);
  useEffect(()=>{
    if(loader == false){
      window.location.reload(true);
    }
  }, [loader]);


  return (
    <div className="App">
      {console.log("------------------ Akshay   | update data: 30-DEC-2022 -----------------------")}
      {console.log("------------------ Shubham  | update data: 21-DEC-2022 -----------------------")}
      {console.log("------------------ Abhishek | update data:             -----------------------")}
      {loader && (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            background: "rgba(255,255,255, 0.85)",
            zIndex: "2",
          }}
        >
          <CircularProgress style={{ width: "70px", height: "70px" }} />
        </div>
      )}
      <Main setLoader={setLoader} />
    </div>
  );
}
export default App;
