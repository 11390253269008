import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";
import useGlobalStorage from "use-global-storage";
import Tooltip from '@mui/material/Tooltip';

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const axios = require("axios");

const columns = [
  { id: "id", label: "Sr. No",minWidth: 40, },

  {
    id: "instrumentName",
    label: "Instrument Name",
    align: "left",
    minWidth: 40,
  },
  {
    id: "tableName",
    label: "Table Name",
    align: "left",
    minWidth: 40,
  },

  {
    id: "unitType",
    label: "Unit Type",
    align: "left",
    minWidth: 40,
  },
];

function createData(id, instrumentName, tableName, unitType) {
  return {
    id,
    instrumentName,
    tableName,
    unitType,
  };
}

export default function TablesList() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [clientList, setClientsList] = React.useState("");

  const useStorage = useGlobalStorage({
    storageOptions: { name: "test-db" },
  });
  const [title, setTitle] = useStorage("title");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getClientList = (event) => {
    let url = BASE_URL;
    axios
      .get(
        url +
          "xjoin?_join=pl.datasheetTables,_j,pr.instruments&_on1=(pl.instrumentId,eq,pr.id)&_fields=pl.instrumentId,pr.instrumentName,pl.tableName,pl.unitType,pl.id&_where=(pl.status,eq,1)"
      )
      .then((res) => {
        setClientsList(res.data);
        initializeDataTable();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const submitSiteDelete = (id) => {
    let url = BASE_URL;
    axios
      .delete(url + `datasheetTables/${id}`)
      .then((res) => {
        toast("datasheet table deleted!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
      axios
      .delete(url + `certificateTables/${id}`)
      .then((res) => {
        toast("certificate table table deleted!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };
  var refresh = () => {
    window.location.reload(false);
    // <Link to="/master/master/standard">Home</Link>
  };
  

  var rowDaata = [];
  for (let i = 0; i < clientList.length; i++) {
    rowDaata.push(
      createData(
        clientList[i].pl_id,
        clientList[i].pr_instrumentName,
        clientList[i].pl_tableName,
        clientList[i].pl_unitType
      )
    );
  }

  const initializeDataTable = () => {
  
    $("#datatable-keytable").DataTable({
        destroy: true,
        keys: true,
        dom:
          "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
          "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
          "<'row'<'col-sm-12' tr>>" +
          "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
          "bPaginate": false,
          "bLengthChange": true,
          "bFilter": true,
          "bInfo": false,
          "bAutoWidth": true,
          language: {
              "zeroRecords": " "
          },
  
          buttons: ["copy", "csv", "excel", "print"],
    });
  }

  useEffect(() => {
    getClientList();

  }, []);

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Toolbar>
        <Button
          variant="contained"
          size="small"
          component={Link}
          to="/master/createTable"
          onClick={() => {
            setTitle("Create Table");
          }}
        >
          <b>ADD NEW TABLE</b>
        </Button>
      </Toolbar>
      <hr/>

      <Table stickyHeader aria-label="sticky table" size="small" id="datatable-keytable">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                <b>{column.label}</b>
              </TableCell>
            ))}
            <TableCell>
              <b>Actions</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowDaata
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                  <TableCell>
                    <ButtonGroup size="small" aria-label="small button group">
                    <Tooltip title="Edit Table" placement="top-start">
                      <Button
                        component={Link}
                        to={`/master/editTable/${row.id}`}
                      >
                        <PreviewIcon />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Delete Table" placement="top-start">
                      <Button
                        onClick={(e) => {
                          if (
                            window.confirm("Really want to delete datasheet table?")
                          ) {
                            submitSiteDelete(row.id);
                          }
                        }}
                      >
                        <DeleteIcon style={{ color: "#dc3545" }} />
                      </Button>
                    </Tooltip>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rowDaata.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ToastContainer />
    </TableContainer>
  );
}
