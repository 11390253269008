import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Chip,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { useSnackbar } from "notistack";
import CButton from "./../../../utils/components/Button";
import { ConstructionOutlined } from "@mui/icons-material";
const axios = require("axios");
export default function CreateTable() {
  const { enqueueSnackbar } = useSnackbar();
  var [instrumentArray, setInstrumentArray] = React.useState([]);
  const [datasheetColumnName, setDatasheetColumnName] = React.useState([]);
  const [certificateColumnName, setCertificateColumnName] = React.useState([]);
  const [unitType, setUnitType] = React.useState("");
  const [instrument, setInstruments] = React.useState("");
  const [newTableName, setNewTableName] = React.useState("");
  const [parameterName, setParameterName] = React.useState("");
  const [rangeSize, setRangesize] = React.useState("");
  const [standardValue, setStandardValue] = React.useState('');
  const [testPoint, setTestPoint] = React.useState('');
  const [setPoint, setSetPoint] = React.useState('');
  const [ducValue, setDUCValue] = React.useState('');
  const [error, setError] = React.useState('');
  const [mean, setMean] = React.useState('');
  const [errorPercent, setErrorPercent] = React.useState('');
  const [expandedUncertainty, setExpandedUncertainty] = React.useState('');
  const [expandedUncertaintyRdg, setExpandedUncertaintyRdg] =
    React.useState('');
  const [expandedUncertaintyFsd, setExpandedUncertaintyFsd] =
    React.useState('');
  const [standardDeviation, setStandardDeviation] = React.useState('');
  const [repeatability, setRepeatability] = React.useState('');
  const [averageReading, setAverageReading] = React.useState('');
  const [hysteresis, setHysteresis] = React.useState('');
  const [coverageFactor, setCoverageFactor] = React.useState('');
  const [m1, setM1] = React.useState(false);
  const [m2, setM2] = React.useState(false);
  const [m3, setM3] = React.useState(false);
  const [m4, setM4] = React.useState(false);
  const [m5, setM5] = React.useState(false);
  const [m6, setM6] = React.useState(false);
  const [m7, setM7] = React.useState(false);
  const [m8, setM8] = React.useState(false);
  const [m9, setM9] = React.useState(false);
  const [m10, setM10] = React.useState(false);
  const [channel1, setChannel1] = React.useState(false);
  const [channel2, setChannel2] = React.useState(false);
  const [channel3, setChannel3] = React.useState(false);
  const [channel4, setChannel4] = React.useState(false);
  const [channel5, setChannel5] = React.useState(false);
  const [channel6, setChannel6] = React.useState(false);
  const [nominalValue, setNominalValue] = React.useState(false);
  const [channel7, setChannel7] = React.useState(false);
  const [channel8, setChannel8] = React.useState(false);
  const [channel9, setChannel9] = React.useState(false);
  const [channel10, setChannel10] = React.useState(false);
  const [expectedoutputinmA, setExpectedoutputinmA] = React.useState(false);
  const [AverageobsopinmA, setAverageobsopinmA] = React.useState(false);
  const [deviationinmA, setDeviationinmA] = React.useState(false);
  const [c1, setC1] = React.useState("");
  const [c2, setC2] = React.useState("");
  const [c3, setC3] = React.useState("");
  const [c4, setC4] = React.useState("");
  const [c5, setC5] = React.useState("");
  const [c6, setC6] = React.useState("");
  const [c7, setC7] = React.useState("");
  const [c8, setC8] = React.useState("");
  const [c9, setC9] = React.useState("");
  const [c10, setC10] = React.useState("");
  const [checked, setChecked] = React.useState(true);
  const [up1, setUp1] = React.useState(false);
  const [up2, setUp2] = React.useState(false);
  const [down1, setDown1] = React.useState(false);
  const [down2, setDown2] = React.useState(false);
  const [scaleReading, setScaleReading] = React.useState(false);
  const [nominalMassValue, setNominalMassValue] = React.useState(false);
  const [uucValue, setUucValue] = React.useState(false);
  const [conventionalMassValueObserved, setConventionalMassValueObserved] = React.useState(false);
  const [observedValue, setObservedValue] = React.useState(false);
  const [deviation, setDeviation] = React.useState(false);
  const [remark, setRemark] = React.useState(false);



  const params = useParams()
  const getStandardIdList = () => {
    let url = BASE_URL;
    axios
      .get(url + `datasheetTables/${params.id}`)
      .then((res) => {
        setUnitType(res.data[0].unitType);
        setInstruments(res.data[0].instrumentId);
        setNewTableName(res.data[0].tableName);
        setUnitType(res.data[0].unitType);
        setParameterName(res.data[0].parameterName);
        setRangesize(res.data[0].rangeSize);
        // setDatasheetColumnName(res.data[0].c1, res.data[0].c2, res.data[0].c3, res.data[0].c4, res.data[0].c5, res.data[0].c6, res.data[0].c7, res.data[0].c8, res.data[0].c9, res.data[0].c10);
        // setC1(res.data[0].c1);
        // setC2(res.data[0].c2);
        // setC3(res.data[0].c3);
        // setC4(res.data[0].c4);
        // setC5(res.data[0].c5);
        // setC6(res.data[0].c6);
        // setC7(res.data[0].c7);
        // setC8(res.data[0].c8);
        // setC9(res.data[0].c9);
        // setC10(res.data[0].c10);
        // setCompanyNanme(res.data[0].companyName);
        // setContact(res.data[0].contact);
        // setAddress(res.data[0].address);
        // setGstNumber(res.data[0].gstNumber);
        // setState(res.data[0].state);
        // setEmail(res.data[0].email);
      })
      .catch((error) => {
        console.log(error)
        toast.error("Something Went Wrong!");
      });
    }

    const geCertificateIdList = () => {
      let url = BASE_URL;
      axios
        .get(url + `certificateTables/${params.id}`)
        .then((res) => {
          setStandardValue(res.data[0].standardValue);
          setTestPoint(res.data[0].testPoint);
          setSetPoint(res.data[0].setPoint);
          setDUCValue(res.data[0].ducValue);
          setUucValue(res.data[0].uucValue);
          setError(res.data[0].error);
          setMean(res.data[0].mean);
          setError(res.data[0].error);
          setNominalValue(res.data[0].nominalValue);
          setUp1(res.data[0].up1);
          setUp2(res.data[0].up2);
          setDown1(res.data[0].down1);
          setDown2(res.data[0].down2);
          setErrorPercent(res.data[0].errorPercent);
          setExpandedUncertainty(res.data[0].expandedUncertainty);
          setExpandedUncertaintyRdg(res.data[0].expandedUncertaintyRdg);
          setExpandedUncertaintyFsd(res.data[0].expandedUncertaintyFsd);
          setStandardDeviation(res.data[0].stdDev);
          setRepeatability(res.data[0].repeatability);
          setAverageReading(res.data[0].avgReading);
          setHysteresis(res.data[0].hysteresis);
          setCoverageFactor(res.data[0].coverageFactor);
          setM1(res.data[0].m1);
          setM2(res.data[0].m2);
          setM3(res.data[0].m3);
          setM4(res.data[0].m4);
          setM5(res.data[0].m5);
          setM6(res.data[0].m6);
          setM7(res.data[0].m7);
          setM8(res.data[0].m8);
          setM9(res.data[0].m9);
          setM10(res.data[0].m10);
          setChannel1(res.data[0].ch1);
          setChannel2(res.data[0].ch2);
          setChannel3(res.data[0].ch3);
          setChannel4(res.data[0].ch4);
          setChannel5(res.data[0].ch5);
          setChannel6(res.data[0].ch6);
          setChannel7(res.data[0].ch7);
          setChannel8(res.data[0].ch8);
          setChannel9(res.data[0].ch9);
          setChannel10(res.data[0].ch10);
          setExpectedoutputinmA(res.data[0].expectedOutputInMa);
          setAverageobsopinmA(res.data[0].avgObsInMa);
          setDeviationinmA(res.data[0].deviationInMa);
          setScaleReading(res.data[0].scaleReading);
          setNominalMassValue(res.data[0].nominalMassValue);
          setConventionalMassValueObserved(res.data[0].conventionalMassValueObserved);
          setObservedValue(res.data[0].observedValue);
          setDeviation(res.data[0].deviation);
          setRemark(res.data[0].remark);
          // setCompanyNanme(res.data[0].companyName);
          // setContact(res.data[0].contact);
          // setAddress(res.data[0].address);
          // setGstNumber(res.data[0].gstNumber);
          // setState(res.data[0].state);
          // setEmail(res.data[0].email);
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
      }

      const getInstrumentsList = () => {
        let url = BASE_URL;
        axios
          .get(url + "instruments")
          .then((res) => {
            setInstrumentArray(res.data);
          })
          .catch((error) => {
            toast.error("Something Went Wrong!");
          });
      };
     
    useEffect(() => {
      geCertificateIdList();
      getStandardIdList();
      getInstrumentsList();
    }, []);

    var refresh = () => {
      window.location.reload(false);
    };

  const onSubmit = () => {
    if (datasheetColumnName.length) {
      var c1, c2, c3, c4, c5, c6, c7, c8, c9, c10;
      c1 = datasheetColumnName[0];
      c2 = datasheetColumnName[1];
      c3 = datasheetColumnName[2];
      c4 = datasheetColumnName[3];
      c5 = datasheetColumnName[4];
      c6 = datasheetColumnName[5];
      c7 = datasheetColumnName[6];
      c8 = datasheetColumnName[7];
      c9 = datasheetColumnName[8];
      c10 = datasheetColumnName[9];
    }

    var jsonData = {
      unitType: unitType,
      instrumentId: instrument,
      tableName: newTableName,
      rangeSize: parseInt( rangeSize),
      parameterName: parameterName,
      c1: c1,
      c2: c2,
      c3: c3,
      c4: c4,
      c5: c5,
      c6: c6,
      c7: c7,
      c8: c8,
      c9: c9,
      c10: c10,
    };

    Object.keys(jsonData).forEach((key) => {
      if (jsonData[key] === undefined || !jsonData[key]) {
        delete jsonData[key];
      }
    });
    
    let url = BASE_URL;
    axios
      .patch(url + `datasheetTables/${params.id}}`, jsonData)
      .then((res) => {
        // toast("Table updated!");
        var insertedID = res.data.insertId;
        certData(insertedID);
        // setTimeout(refresh, 1000);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something Went Wrong!");
      });


    var certData = (id) => {
      if (certificateColumnName.length) {
        var c1, c2, c3, c4, c5, c6, c7, c8, c9, c10;
        for (let i = 0; i < certificateColumnName.length; i++) {
          c1 = certificateColumnName[0];
          c2 = certificateColumnName[1];
          c3 = certificateColumnName[2];
          c4 = certificateColumnName[3];
          c5 = certificateColumnName[4];
          c6 = certificateColumnName[5];
          c7 = certificateColumnName[6];
          c8 = certificateColumnName[7];
          c9 = certificateColumnName[8];
          c10 = certificateColumnName[9];
        }
      }
      var jsonTableData2 = {
        tableName: newTableName,
        unitType: unitType,
        instrumentId: instrument,
        rangeSize: parseInt(rangeSize),
        parameterName: parameterName,
        m1: m1 == true ? '1' : '0',
        m2: m2== true ? '1' : '0',
        m3: m3== true ? '1' : '0',
        m4: m4== true ? '1' : '0',
        m5: m5== true ? '1' : '0',
        m6: m6== true ? '1' : '0',
        m7: m7== true ? '1' : '0',
        m8: m8== true ? '1' : '0',
        m9: m9== true ? '1' : '0',
        m10: m10== true ? '1' : '0',
        ch1: channel1== true ? '1' : '0',
        ch2: channel2== true ? '1' : '0',
        ch3: channel3== true ? '1' : '0',
        ch4: channel4== true ? '1' : '0',
        ch5: channel5== true ? '1' : '0',
        ch6: channel6== true ? '1' : '0',
        ch7: channel7== true ? '1' : '0',
        ch8: channel8== true ? '1' : '0',
        ch9: channel9== true ? '1' : '0',
        ch10: channel10== true ? '1' : '0',
        c1: c1,
        c2: c2,
        c3: c3,
        c4: c4,
        c5: c5,
        c6: c6,
        c7: c7,
        c8: c8,
        c9: c9,
        c10: c10,
        expandedUncertainty: expandedUncertainty == true ? '1' : '0',
        standardValue: standardValue== true ? '1' : '0',
        testPoint: testPoint== true ? '1' : '0',
        setPoint: setPoint== true ? '1' : '0',
        nominalValue: nominalValue== true ? '1' : '0',
        ducValue: ducValue == true ? '1' : '0',
        up1: up1 == true ? '1' : '0',
        up2: up2 == true ? '1' : '0',
        down1: down1 == true ? '1' : '0',
        down2: down2 == true ? '1' : '0',
        error: error== true ? '1' : '0',
        mean: mean== true ? '1' : '0',
        errorPercent: errorPercent== true ? '1' : '0',
        expandedUncertaintyRdg: expandedUncertaintyRdg== true ? '1' : '0',
        expandedUncertaintyFsd: expandedUncertaintyFsd== true ? '1' : '0',
        stdDev: standardDeviation== true ? '1' : '0',
        repeatability: repeatability== true ? '1' : '0',
        avgReading: averageReading == true ? '1' : '0',
        hysteresis: hysteresis== true ? '1' : '0',
        coverageFactor: coverageFactor== true ? '1' : '0',
        expectedOutputInMa: expectedoutputinmA== true ? '1' : '0',
        avgObsinMa: AverageobsopinmA== true ? '1' : '0',
        deviationinMa: deviationinmA== true ? '1' : '0',
        scaleReading: scaleReading== true ? '1' : '0',
        nominalMassValue: nominalMassValue== true ? '1' : '0',
        conventionalMassValueObserved: conventionalMassValueObserved== true ? '1' : '0',
        observedValue: observedValue== true ? '1' : '0',
        uucValue: uucValue== true ? '1' : '0',
        deviation: deviation== true ? '1' : '0',
        remark: remark== true ? '1' : '0',
        up1: up1== true ? '1' : '0',
        up2: up2== true ? '1' : '0',
        down1: down1== true ? '1' : '0',
        down2: down2== true ? '1' : '0',
      };

      Object.keys(jsonTableData2).forEach((key) => {
        if (jsonTableData2[key] === undefined) {
          delete jsonTableData2[key];
        }
      });


     console.log(jsonTableData2);
      axios
        .patch(url + `certificateTables/${params.id}}`, jsonTableData2)
        .then((res) => {
          toast("Table Updated!");
          setTimeout(refresh, 500);
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    };
  };


  const addDatasheetColumn = () => {
    setDatasheetColumnName([...datasheetColumnName, ""]);
  };

  const deleteDatasheetColumn = (index) => {
    let newDatasheetColumnName = [...datasheetColumnName];
    newDatasheetColumnName.splice(index, 1);
    setDatasheetColumnName([...newDatasheetColumnName]);
  };
  const updateDatasheetColumnName = (value, index) => {
    const newColumnName = [...datasheetColumnName];
    newColumnName[index] = value;
    setDatasheetColumnName(newColumnName);
  };

  const addCertificateColumn = () => {
    setCertificateColumnName([...certificateColumnName, ""]);
  };

  const deleteCertificateColumn = (index) => {
    let newCertificateColumnName = [...certificateColumnName];
    newCertificateColumnName.splice(index, 1);
    setCertificateColumnName([...newCertificateColumnName]);
  };
  const updateCertificateColumnName = (value, index) => {
    const newColumnName = [...certificateColumnName];
    newColumnName[index] = value;
    setCertificateColumnName(newColumnName);
  };


  var pushIArray = [];
  for (let i = 0; i < instrumentArray.length; i++) {
    if (instrumentArray[i].instrumentName) {
      pushIArray.push({
        label: instrumentArray[i].id + ", " + instrumentArray[i].instrumentName,
      });
    }
  }

    // defaultChecked={(matrixId.some(el => matrixId.includes(matrixData.id.toString()))) == true ? true : false}

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6">
        Update Table
      </Typography>
      <br/>
      <Grid container spacing={2} sx={{ mb: 3 }}>
      <Grid item xs={4}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={pushIArray}
            value={instrument}
            InputLabelProps={{ shrink: true }}
            onChange={(event, value) => {
              setInstruments(parseInt(value.label.split(",")[0]));
            }}
            renderInput={(params) => (
              <TextField {...params} label="Instrument" />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            label="New table name"
            size="small"
            fullWidth
            variant="outlined"
            value={newTableName}
            onChange={(e) => setNewTableName(e.target.value)}
          />
        </Grid>
      <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            label="Unit"
            size="small"
            value={unitType}
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            onChange={(e) => setUnitType(e.target.value)}
          />
        </Grid>
        
        
        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            label="parameter name"
            size="small"
            fullWidth
            variant="outlined"
            value={parameterName}
            onChange={(e) => setParameterName(e.target.value)}
          />
        </Grid>
      </Grid>
      <Divider sx={{ mt: 5 }}>
        <Chip
          label="Datasheet table configuration"
          variant="outlined"
          sx={{ fontSize: "16px" }}
        />
      </Divider>
      <br/>
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            label="Range size"
            size="small"
            fullWidth
            variant="outlined"
            value = {rangeSize}
            onChange={(e) => setRangesize(e.target.value)}
          />
        </Grid>

        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
                onChange={(e) => {
                  setTestPoint(e.target.checked);
                }}
              />
            }
            label="Test Point"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
                onChange={(e) => {
                  setSetPoint(e.target.checked);
                }}
              />
            }
            label="Set Point"
          />
        </Grid>
      </Grid>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        add custom columns
        <CButton
          variant="contained"
          size="small"
          label={<AddIcon />}
          sx={{ ml: 3 }}
          onClick={() => {
            addDatasheetColumn();
          }}
        ></CButton>
      </Typography>
      <Grid container spacing={1} sx={{ mb: 3 }}>
        {datasheetColumnName.map((column, index) => (
          <Grid item xs={4}>
            <OutlinedInput
              id="outlined-adornment-password"
              label={"column " + (index + 1)}
              value={column}
              placeholder={"column " + (index + 1)}
              onChange={(e) => {
                updateDatasheetColumnName(e.target.value, index);
              }}
              size="small"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      deleteDatasheetColumn(index);
                    }}
                    edge="end"
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
        ))}
      </Grid>

      <Divider sx={{ mt: 5 }}>
        <Chip
          label="Certificate table configuration"
          variant="outlined"
          sx={{ fontSize: "16px" }}
        />
      </Divider>
      <br />
      <Grid container spacing={2} sx={{ mb: 3 }} justify="flex-start">
      <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
              checked={testPoint == '1'? true: false}
                onChange={(e) => {
                  setTestPoint(e.target.checked);
                }}
              />
            }
            label="Test Point"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
              checked={setPoint == '1'? true: false}
                onChange={(e) => {
                  setSetPoint(e.target.checked);
                }}
              />
            }
            label="Set Point"
          />
        </Grid>
        <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                            checked={nominalValue == '1'? true: false}
                                onChange={(e) => {
                                    setNominalValue(e.target.checked);
                                }}
                            />
                        }
                        label="Nominal Value"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                            checked={scaleReading == '1'? true: false}
                                onChange={(e) => {
                                    setScaleReading(e.target.checked);
                                }}
                            />
                        }
                        label="Scale Reading"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                            checked={nominalMassValue == '1'? true: false}
                                onChange={(e) => {
                                    setNominalMassValue(e.target.checked);
                                }}
                            />
                        }
                        label="Nominal Mass Value"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                            checked={conventionalMassValueObserved == '1'? true: false}
                                onChange={(e) => {
                                    setConventionalMassValueObserved(e.target.checked);
                                }}
                            />
                        }
                        label="Conventional Mass Value Observed"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                            checked={observedValue == '1'? true: false}
                                onChange={(e) => {
                                    setObservedValue(e.target.checked);
                                }}
                            />
                        }
                        label="Observed Value"
                    />
                </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
              checked={standardValue == '1'? true: false}
                onChange={(e) => {
                  setStandardValue(e.target.checked);
                }}
              />
            }
            label="Standard value"
          />
        </Grid>
        
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
              checked={ducValue == '1'? true: false}
                onChange={(e) => {
                  setDUCValue(e.target.checked);
                }}
              />
            }
            label="DUC Value"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
              checked={uucValue == '1'? true: false}
                onChange={(e) => {
                  setUucValue(e.target.checked);
                }}
              />
            }
            label="UUC Value"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
              checked={error == '1'? true: false}
                onChange={(e) => {
                  setError(e.target.checked);
                }}
              />
            }
            label="Error"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
              checked={mean == '1'? true: false}
                onChange={(e) => {
                  setMean(e.target.checked);
                }}
              />
            }
            label="Mean"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
              checked={errorPercent == '1'? true: false}
                onChange={(e) => {
                  setErrorPercent(e.target.checked);
                }}
              />
            }
            label="Error %"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
        display: 'flex',
        gap: 2
      }}
            control={
              <Checkbox
              checked={expandedUncertainty == '1'? true: false}
                onChange={(e) => {
                  setExpandedUncertainty(e.target.checked);
                }}
              />
            }
            label="Expanded Uncertainty"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
              checked={expandedUncertaintyRdg == '1'? true: false}
                onChange={(e) => {
                  setExpandedUncertaintyRdg(e.target.checked);
                }}
              />
            }
            label="Expanded Uncertainty (% Rdg)"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
              checked={expandedUncertaintyFsd == '1'? true: false}

                onChange={(e) => {
                  setExpandedUncertaintyFsd(e.target.checked);
                }}
              />
            }
            label="Expanded Uncertainty (% FSD)"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
              checked={standardDeviation == '1'? true: false}

                onChange={(e) => {
                  setStandardDeviation(e.target.checked);
                }}
              />
            }
            label="Standard Deviation"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
              checked={repeatability == '1'? true: false}

                onChange={(e) => {
                  setRepeatability(e.target.checked);
                }}
              />
            }
            label="Repeatability"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
              checked={averageReading == '1'? true: false}

                onChange={(e) => {
                  setAverageReading(e.target.checked);
                }}
              />
            }
            label="Average Reading"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
              checked={hysteresis == '1'? true: false}

                onChange={(e) => {
                  setHysteresis(e.target.checked);
                }}
              />
            }
            label="Hysteresis"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
              checked={coverageFactor == '1'? true: false}
                onChange={(e) => {
                  setCoverageFactor(e.target.checked);
                }}
              />
            }
            label="Coverage factor"
          />
        </Grid>
        <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                            checked={up1 == '1'? true: false}
                                onChange={(e) => {
                                    setUp1(e.target.checked);
                                }}
                            />
                        }
                        label="up"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                            checked={down1 == '1'? true: false}
                                onChange={(e) => {
                                    setDown1(e.target.checked);
                                }}
                            />
                        }
                        label="down"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                            checked={up2 == '1'? true: false}
                                onChange={(e) => {
                                    setUp2(e.target.checked);
                                }}
                            />
                        }
                        label="up"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        sx={{
                            display: "flex",
                            gap: 2,
                        }}
                        control={
                            <Checkbox
                            checked={down2 == '1'? true: false}
                                onChange={(e) => {
                                    setDown2(e.target.checked);
                                }}
                            />
                        }
                        label="down"
                    />
                </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
              checked={scaleReading == '1'? true: false}

                onChange={(e) => {
                  setScaleReading(e.target.checked);
                }}
              />
            }
            label="Scale Reading"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
              checked={nominalMassValue == '1'? true: false}

                onChange={(e) => {
                  setNominalMassValue(e.target.checked);
                }}
              />
            }
            label="Nominal Mass Value"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
              checked={conventionalMassValueObserved == '1'? true: false}

                onChange={(e) => {
                  setConventionalMassValueObserved(e.target.checked);
                }}
              />
            }
            label="Conventional Mass Value Observed"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
              checked={observedValue == '1'? true: false}

                onChange={(e) => {
                  setObservedValue(e.target.checked);
                }}
              />
            }
            label="Observed Value"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
              checked={deviation == '1'? true: false}

                onChange={(e) => {
                  setDeviation(e.target.checked);
                }}
              />
            }
            label="Deviation"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
              checked={m1 == '1'? true: false}

                onChange={(e) => {
                  setM1(e.target.checked);
                }}
              />
            }
            label="m1"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
              checked={m2 == '1'? true: false}

                onChange={(e) => {
                  setM2(e.target.checked);
                }}
              />
            }
            label="m2"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
              checked={m3 == '1'? true: false}
                onChange={(e) => {
                  setM3(e.target.checked);
                }}
              />
            }
            label="m3"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
              checked={m4 == '1'? true: false}
                onChange={(e) => {
                  setM4(e.target.checked);
                }}
              />
            }
            label="m4"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
              checked={m5 == '1'? true: false}
                onChange={(e) => {
                  setM5(e.target.checked);
                }}
              />
            }
            label="m5"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
        display: 'flex',
        gap: 2
      }}
            control={
              <Checkbox
              checked={m6 == '1'? true: false}
                onChange={(e) => {
                  setM6(e.target.checked);
                }}
              />
            }
            label="m6"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
              checked={m7 == '1'? true: false}
                onChange={(e) => {
                  setM7(e.target.checked);
                }}
              />
            }
            label="m7"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
              checked={m8 == '1'? true: false}
                onChange={(e) => {
                  setM8(e.target.checked);
                }}
              />
            }
            label="m8"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
              checked={m9 == '1'? true: false}
                onChange={(e) => {
                  setM9(e.target.checked);
                }}
              />
            }
            label="m9"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
              checked={m10 == '1'? true: false}
                onChange={(e) => {
                  setM10(e.target.checked);
                }}
              />
            }
            label="m10"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
              checked={channel1 == '1'? true: false}
                onChange={(e) => {
                  setChannel1(e.target.checked);
                }}
              />
            }
            label="channel 1"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
              checked={channel2 == '1'? true: false}
                onChange={(e) => {
                  setChannel2(e.target.checked);
                }}
              />
            }
            label="channel 2"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
              checked={channel3 == '1'? true: false}
                onChange={(e) => {
                  setChannel3(e.target.checked);
                }}
              />
            }
            label="channel 3"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
              checked={channel4 == '1'? true: false}
                onChange={(e) => {
                  setChannel4(e.target.checked);
                }}
              />
            }
            label="channel 4"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
              checked={channel5 == '1'? true: false}
                onChange={(e) => {
                  setChannel5(e.target.checked);
                }}
              />
            }
            label="channel 5"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
              checked={channel6 == '1'? true: false}
              sx={{
                display: 'flex',
                gap: 2
              }}
                onChange={(e) => {
                  setChannel6(e.target.checked);
                }}
              />
            }
            label="channel 6"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
              checked={channel7 == '1'? true: false}
                onChange={(e) => {
                  setChannel7(e.target.checked);
                }}
              />
            }
            label="channel 7"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
              checked={channel8 == '1'? true: false}
                onChange={(e) => {
                  setChannel8(e.target.checked);
                }}
              />
            }
            label="channel 8"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
              checked={channel9 == '1'? true: false}
                onChange={(e) => {
                  setChannel9(e.target.checked);
                }}
              />
            }
            label="channel 9"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
              checked={channel10 == '1'? true: false}
                onChange={(e) => {
                  setChannel10(e.target.checked);
                }}
              />
            }
            label="channel 10"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
              checked={expectedoutputinmA == '1'? true: false}
                onChange={(e) => {
                  setExpectedoutputinmA(e.target.checked);
                }}
              />
            }
            label="Expected output in Ma"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
              checked={AverageobsopinmA == '1'? true: false}
                onChange={(e) => {
                  setAverageobsopinmA(e.target.checked);
                }}
              />
            }
            label="Average obs. o/p in Ma"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
              checked={deviationinmA == '1'? true: false}
                onChange={(e) => {
                  setDeviationinmA(e.target.checked);
                }}
              />
            }
            label="Deviation in Ma"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
           sx={{
            display: 'flex',
            gap: 2
          }}
            control={
              <Checkbox
              checked={remark == '1'? true: false}
                onChange={(e) => {
                  setRemark(e.target.checked);
                }}
              />
            }
            label="Remark"
          />
        </Grid>
      </Grid>
      
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        add custom columns
        <CButton
          variant="contained"
          size="small"
          label={<AddIcon />}
          sx={{ ml: 3 }}
          onClick={() => {
            addCertificateColumn();
          }}
        ></CButton>
      </Typography>
      
      <Grid container spacing={1} sx={{ mb: 3 }}>
        {certificateColumnName.map((column, index) => (
          <Grid item xs={4}>
            <OutlinedInput
              id="outlined-adornment-password"
              label={"column " + (index + 1)}
              value={column}
              placeholder={"column " + (index + 1)}
              onChange={(e) => {
                updateCertificateColumnName(e.target.value, index);
              }}
              size="small"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      deleteCertificateColumn(index);
                    }}
                    edge="end"
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
        ))}
      </Grid>
      <br />
      <hr />
      <Toolbar style={{ padding: "0px" }}>
        <CButton
          variant="contained"
          label="SAVE"
          // onClick={() => {
          //     enqueueSnackbar("Table saved successfully !", {
          //         variant: "success",
          //     });
          // }}
          onClick={() => {
            // enqueueSnackbar("Standard added successfully !", {
            //     variant: "success",
            // });
            onSubmit();
          }}
        >
          Update Table
        </CButton>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
