import React, { useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../global";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { Grid, TextField, Autocomplete } from "@mui/material";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Tooltip from "@mui/material/Tooltip";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const axios = require("axios");

const columns = [
  { id: "id", label: "Sr. No.", minWidth: 40 },
  {
    id: "checkMark",
    label: "Payment Status",
    align: "center",
    minWidth: 10,
  },

  { id: "clientName", label: "Company Name", minWidth: 40 },
  {
    id: "address",
    label: "Address",
    align: "left",
    minWidth: 40,
  },
  {
    id: "contactNumber",
    label: "Contact Number",
    align: "left",
    minWidth: 40,
  },

  {
    id: "invoiceDate",
    label: "Invoice Date",
    minWidth: 40,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "invoiceNumber",
    label: "Invoice Number",
    minWidth: 40,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "subject",
    label: "Subject",
    minWidth: 40,
    align: "left",
    format: (value) => value.toFixed(2),
  },
];

function createData(
  id,
  checkMark,
  clientName,
  address,
  contactNumber,
  invoiceDate,
  invoiceNumber,
  subject
) {
  return {
    id,
    checkMark,
    clientName,
    address,
    contactNumber,
    invoiceDate,
    invoiceNumber,
    subject,
  };
}

export default function PaymentMasterList() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [paymentList, setPaymentList] = React.useState([]);
  const [paymentData, setPaymentData] = React.useState([]);
  const [client, setClient] = React.useState(0);
  const [fromDate, setFromDate] = React.useState(0);
  const [toDate, setToDate] = React.useState(0);
  const [pushArrays, setPushArray] = React.useState([]);
  const [dateData, setDateData] = React.useState([]);
  const [companyName, setCompanyName] = React.useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getPaymentList = (event) => {
    let url = BASE_URL;
    axios
      .get(
        url +
          "xjoin?_join=payment.paymentMaster,_j,client.clients&_on1=(payment.clientId,eq,client.id)&_fields=payment.id,payment.totalAmount,payment.address,payment.contactNumber,payment.conveyanceCharges,payment.discount,payment.gstType,payment.invoiceDate,payment.invoiceNumber,payment.poNumber,payment.subject,client.companyName"
      )
      .then((res) => {
        setPaymentList(res.data);
        setDateData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const initializeDataTable = () => {
    $("#datatable-keytable").DataTable({
      destroy: true,
      keys: true,
      dom:
        "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
        "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
        "<'row'<'col-sm-12' tr>>" +
        "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
      bPaginate: false,
      bLengthChange: true,
      bFilter: true,
      bInfo: false,
      bAutoWidth: true,
      language: {
        zeroRecords: " ",
      },

      buttons: ["copy", "csv", "excel", "print"],
    });
  };
  const initiatInvoiceList = () => {
    var rowData = [];
    if (dateData.length) {
      for (let i = 0; i < dateData.length; i++) {
        rowData.push(
          createData(
            dateData[i].payment_id,
            dateData[i].payment_totalAmount == 0 ? (
              <DoneRoundedIcon color="success" />
            ) : (
              <CloseRoundedIcon style={{ color: "red" }} />
            ),
            dateData[i].client_companyName,
            dateData[i].payment_address,
            dateData[i].payment_contactNumber,
            moment(
              dateData[i].payment_invoiceDate
                ? dateData[i].payment_invoiceDate
                : ""
            ).format("DD-MM-YYYY"),
            dateData[i].payment_invoiceNumber,
            dateData[i].payment_subject
          )
        );
      }
    }
    setPaymentData(rowData);
  };
  const FilterData = () => {
    if (companyName == null) {
      var resultProductData = paymentList.filter(
        (data) =>
          new Date(data.payment_invoiceDate) >= new Date(fromDate) &&
          new Date(data.payment_invoiceDate) <= new Date(toDate)
      );
    } else {
      var resultProductData = paymentList.filter(
        (data) =>
          new Date(data.payment_invoiceDate) >= new Date(fromDate) &&
          new Date(data.payment_invoiceDate) <= new Date(toDate) &&
          data.client_companyName == companyName.label
      );
    }
    setDateData(resultProductData);
    initializeDataTable(resultProductData);
  };

  const getClient = (event) => {
    let url = BASE_URL;
    axios
      .get(url + "clients?_where=(status,eq,1)")
      .then((res) => {
        setClient(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const intializeClient = () => {
    var pushArray = [];

    for (let i = 0; i < client.length; i++) {
      pushArray.push({
        label: client[i].companyName,
      });
    }
    setPushArray(pushArray);
  };

  useEffect(() => {
    initiatInvoiceList();
  }, [dateData]);

  useEffect(() => {
    intializeClient();
  }, [client]);

  useEffect(() => {
    getPaymentList();
    getClient();
  }, []);

  console.log(companyName);

  return (
    <>
      <div style={{ marginTop: "2em" }}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Autocomplete
              size="small"
              id="combo-box-demo"
              options={pushArrays}
              renderInput={(params) => (
                <TextField {...params} label="Company Name" />
              )}
              onChange={(event, value) => setCompanyName(value)}
            />
          </Grid>
          <Grid item xs={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="From Date"
                inputFormat="dd/MM/yyyy"
                value={fromDate ? fromDate : new Date()}
                onChange={(e) => {
                  setFromDate(e);
                  console.log(fromDate);
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Due Date"
                inputFormat="dd/MM/yyyy"
                value={toDate ? toDate : new Date()}
                onChange={(newValue) => {
                  setToDate(newValue);
                  console.log(toDate);
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            onClick={(e) => {
              FilterData();
            }}
            style={{ marginLeft: "10px", height: "40px", marginTop: "1em" }}
          >
            Search
          </Button>
          <Button
            justifyContent="flex-end"
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            component={Link}
            to={`/paymentMaster/PaymentList/${
              companyName == null ? 0 : companyName.label
            }/${fromDate}/${toDate}`}
            style={{ marginLeft: "10px", height: "40px", marginTop: "1em" }}
          >
            Print
          </Button>
        </Grid>
      </div>
      <br />
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          size="small"
          id="datatable-keytable"
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <b>{column.label}</b>
                </TableCell>
              ))}
              <TableCell>
                <b>Actions</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paymentData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      let value = row[column.id];
                      if (column.id == "id") {
                        value = index + 1;
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                    <TableCell>
                      <ButtonGroup size="small" aria-label="small button group">
                        <Tooltip title="Edit Payment" placement="top-start">
                          <Button
                            style={{
                              borderColor: "#dc3545",
                            }}
                            component={Link}
                            to={`/paymentmaster/editPayment/${row.id}`}
                          >
                            <PreviewIcon />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Delete Payment" placement="top-start">
                          <Button
                            onClick={(e) => {
                              if (
                                window.confirm("Really want to delete payment?")
                              ) {
                                // submitSiteDelete(row.id);
                              }
                            }}
                            style={{
                              borderColor: "#dc3545",
                            }}
                          >
                            <DeleteIcon style={{ color: "#dc3545" }} />
                          </Button>
                        </Tooltip>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={paymentData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <ToastContainer />
      </TableContainer>
    </>
  );
}
