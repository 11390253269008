import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
} from "@mui/material";
import moment from "moment";
const axios = require("axios");

export default function CreateDispatch() {
  const [companyName, setCompanyName] = React.useState();
  const [address, setAddress] = React.useState();
  const [instruments, setInstruments] = React.useState();
  const [dispatchMedium, setDispatchMedium] = React.useState();
  const [dispatchDate, setDispatchDate] = React.useState();
  var [clientArray, setClientArray] = React.useState([]);
  var [standardArray, setStandardArray] = React.useState([]);

  const getClientList = () => {
    let url = BASE_URL;
    axios
      .get(url + "clients?_where=(status,eq,1)")
      .then((res) => {
        setClientArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const customerList = [];
  for (let i = 0; i < clientArray.length; i++) {
    customerList.push({
      id: clientArray[i].id,
      label: clientArray[i].companyName,
      address: clientArray[i].address,
    });
  }

  const getInstrumentList = () => {
    let url = BASE_URL;
    axios
      .get(url + "instruments?_where=(status,eq,1)")
      .then((res) => {
        setStandardArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const standardList = [];
  for (let i = 0; i < standardArray.length; i++) {
    standardList.push({
      id: standardArray[i].id,
      label: standardArray[i].instrumentName,
    });
  }

  useEffect(() => {
    getClientList();
    getInstrumentList();
  }, []);

  const readingColumns = [
    [
      {
        field: "id",
        headerName: "Sr. No.",
        editable: false,
      },
      {
        field: "range",
        headerName: "Range",
        editable: true,
      },
      {
        field: "uncertainty",
        headerName: "Uncertainty",
        editable: true,
      },
      {
        field: "accuracy",
        headerName: "Accuracy",
        editable: true,
      },
      {
        field: "stability",
        headerName: "Stability",
        editable: true,
      },
      {
        field: "uniformity",
        headerName: "Uniformity",
        editable: true,
      },

      {
        field: "reaction",
        headerName: "reaction",
        editable: true,
      },
      {
        field: "gravity",
        headerName: "Gravity",
        editable: true,
      },
      {
        field: "drift",
        headerName: "Drift",
        editable: true,
      },

      {
        field: "Action",
      },
    ],
  ];

  var refresh = () => {
    window.location.reload(false);
  };

  const handleSubmit = (event) => {
    let row = {
      companyName: companyName,
      address: address,
      instruments: instruments,
      dispatchMedium: dispatchMedium,
      dispatchDate: moment(dispatchDate).format("YYYY-MM-DD"),
    };
    console.log(row);
    let url = BASE_URL;
    axios
      .post(url + "dispatch", row)
      .then((res) => {
        toast("dispatch created successfully !");
        setTimeout(refresh, 1000);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  //ToDo: fetch cutomers from database
  var refresh = () => {
    window.location.reload(false);
  };

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Create Dispatch
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={customerList}
            renderInput={(params) => (
              <TextField {...params} label="Company Name *" />
            )}
            onChange={(event, value) => {
              if (value != null) {
                setCompanyName(value.label);
                setAddress(value.address);
              } else {
                setCompanyName("");
                setAddress("");
              }
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Address"
            size="small"
            value={address}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setAddress(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            multiple
            id="tags-standard"
            options={standardList}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Standards"
                placeholder="standards"
                style={{ whiteSpace: "nowrap" }}
              />
            )}
            onChange={(event, value) => {
              if (value.length) {
                var arrToStr = Array.prototype.map
                  .call(value, function (item) {
                    return item.label;
                  })
                  .join(",");
                setInstruments(arrToStr);
                console.log(instruments);
              } else {
                setInstruments("");
              }
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="dispatch Medium *"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setDispatchMedium(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="dispatch date "
            type="date"
            InputLabelProps={{ shrink: true }}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setDispatchDate(e.target.value);
            }}
          />
        </Grid>
      </Grid>

      <br />
      <Toolbar style={{ padding: "0px", overflow: "auto" }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            handleSubmit();
          }}
        >
          Save
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
