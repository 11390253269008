import React, { useState } from "react";
import Box from "@mui/material/Box";
import { HashRouter, Routes, Route } from "react-router-dom";

import Navbar from "./../common/navbar";
import ClientNavbar from "./../common/clientNavbar";
import InstrumentsList from "./../master/instruments/instrumentsList";
import CreateInstrument from "../master/instruments/createInstrument";
import EditInstrument from "../master/instruments/editInstrument";
import StandardsList from "../master/standard/standardsList";
import CreateStandard from "../master/standard/createStandard";
import Edittandard from "../master/standard/editStandard";
import SrfsList from "../srf/srfsList";
import EditSrf from "../srf/editSrf";
import CreateSRF from "../srf/createSRF";
import TablesList from "./../master/table/tablesList";
import useGlobalStorage from "use-global-storage";
import DatasheetList from "../datasheet/datasheetList";
import EditDatasheet from "../datasheet/editDatasheet";
import CertificateList from "./../certificate/certificateList";
import EditCertificate from "./../certificate/editCertificate";
import ViewCertificate from "./../certificate/viewCertificate";
import EditTypeA from "../typeA/editTypeA";
import EditTypeB from "../typeB/editTypeB";
import EditBudget from "../budget/editBudget";
import Dashboard from "./../dashboard/Dashboard";
import DisciplineList from "../master/discipline/disciplineList";
import AddUserMaster from "../master/userMaster/addUser";
import CreateDiscipline from "../master/discipline/createDiscipline";
import EditDiscipline from "../master/discipline/editDiscipline";
import ClientsList from "../master/clients/clientsList";
import UpdateClient from "../master/clients/editClients";
import CreateClient from "../master/clients/createClient";
import CreateTable from "./../master/table/createTable";
import EditTable from "./../master/table/editTable";
import Login from "./../login/Login";
import RenewCertificate from "./../renewCertificates/renewCertificate";
import SummaryReport from "./../summaryReport/summaryReport";
import CreateQuotation from "./../quotation/createQuotation";
import QuotationList from "./../quotation/quotationList";
import EditQuotation from "./../quotation/editQuotation";
import CreateProduct from "./../master/product/createProduct";
import ProductsList from "./../master/product/productList";
import EditProduct from "./../master/product/editProduct";
import CreateInvoice from "./../invoice/createInvoice";
import InvoiceList from "./../invoice/invoiceList";
import EditInvoice from "./../invoice/editInvoice";
import StandardsInOutList from "../standardInOut/standardsInOutList";
import CreateStandardInOut from "../standardInOut/createStandardInOut";
import Dispatchlist from "../dispatch/dispatchList";
import CreateDispatch from "../dispatch/createDispatch";
import ViewDispatch from "../dispatch/viewDispatch";
import ClientLogin from "../login/clientLogin";
import ClientDashboard from "../clientDashboard/clientDashboard";
import ClientCertificateList from "../clientCertificate/clientCertificate";
import ClientHistoryCardList from "../clientHistoryCard/clientHistoryCard";
import ClientRenewalList from "../clientRenewal/clientRenewal";
import EditStandardInOut from "../standardInOut/editStandardInOut";
import EditDispatch from "../dispatch/editDispatch";
import ViewSummary from "../summaryReport/viewSummary";
import CreateRange from "./../master/range/createRange";
import RangeList from "./../master/range/rangeList";
import EditRange from "./../master/range/editRange";
import PaymentMasterList from "../paymentMaster/paymentMasterList";
import PaymentList from "../paymentMaster/viewList";
import EditPayment from "../paymentMaster/editPayment.js";
import CreateExpense from "../master/expenseMaster/createExpense";
import PaymentVoucher from "../master/expenseMaster/paymentVoucher";
import AccountStatement from "../paymentMaster/accountStatement";
import GstReport from "../paymentMaster/gstReport";
import ExpenseList from "../master/expenseMaster/expenseList";
import EditExpense from "../master/expenseMaster/editExpense";
import { TablesList as StaticTableList } from "../master/staticTable/tablesList";
import { CreateTable as CreateStaticTable } from "./../master/staticTable/createTable";
import { UpdateTable as UpdateStaticTable } from "./../master/staticTable/editTable";
import CreateIntermediateProcedure from "../master/intermediateProcedure/createIntermediateProcedure";
import IntermediateProcedureList from "../master/intermediateProcedure/intermediateProcedureList";
import UpdateIntermediateProcedure from "../master/intermediateProcedure/updateIntermediateProcedure";
import CheckRecords from "../master/intermediateProcedure/checkRecords";
import MaintenanceData from "../master/standardMaintenance/maintence";
import CreateUncertainty from "../master/uncertinityFactors/createUncertinity";
import UncertinityList from "../master/uncertinityFactors/uncertinityList";
import UpdateUncertinity from "../master/uncertinityFactors/editUncertinity";
import CreateSupportiveInstruments from "../master/supportiveInstruments/createSupportiveInstruments";
import SupportiveInstrumentsList from "../master/supportiveInstruments/supportiveInstrumentsList";
import UpdateSupportiveInstruments from "../master/supportiveInstruments/editSupportiveInstruments";
import CreateFeedback from "../master/feedback/createFeedback";
import FeedbackList from "../master/feedback/feedbackList";
import UpdateFeedback from "../master/feedback/editFeedback";
import CreateScope from "../master/nableScope/createNableScope";
import ScopeList from "../master/nableScope/nableScopeList";
import UpdateScope from "../master/nableScope/nableScopeList";
import CreateUnit from "../master/unit/createUnit";
import UnitList from "../master/unit/unitList";
import UpdateUnit from "../master/unit/editUnit";
import MultiQr from "../certificate/qrCodeMultiplePrint";
import CreatePickup from "../master/pickupForm/createPickup";
import PickupList from "../master/pickupForm/pickupList";
import UpdatePickup from "../master/pickupForm/editPickup";
import CreatePurchase from "../master/purchase/createPurchase";
import PurchaseList from "../master/purchase/purchaseList";
import UpdatePurchase from "../master/purchase/editPurchase";
import CreateEnquiry from "../master/enquiry/createEnquiry";
import EnquiryList from "../master/enquiry/enquiryList";
import UpdateEnquiry from "../master/enquiry/editEnquiry";
import CustomInvoiceList from "./../customInvoice/customInvoiceList";
import CreateCustomInvoice from "./../customInvoice/createCustomInvoice";
import EditCustomInvoice from "./../customInvoice/editCustomInvoice";
import CreateChallan from "./../challanMaster/createChallan";
import ChallanList from "./../challanMaster/challanMasterList";
import EditChallan from "../challanMaster/editChallan";

import SettingList from "../master/settings/settingList";
import EditSetting from "../master/settings/editSetting";

import CourierList from "./../master/courierMenu/CourierList";
import CreateCourier from "./../master/courierMenu/createCourier";
import UpdateCourier from "./../master/courierMenu/editCourier";
import ViewAdminHistoryCard from "../master/historyCard/historyCard";
import ViewHistoryCard from "../master/historyCard/viewHistoryCard";
import ViewCustomInvoice from "./../customInvoice/viewCustomInvoice";
import ViewQuotation from "./../quotation/viewQuotation";
import ViewSrf from "../srf/viewSrf";

import { ToastContainer } from "react-toastify";
import ViewMultipleCertificate from "../certificate/viewMultipleCertificate";
import WorkReport from "../workReport/workReport";
import ViewAll from "../ViewAll";
import MastersDue from "../mastersDue/mastersDue";
import FollowUpReport from "../master/enquiry/followUpReport";
import FeedbackReport from "../master/feedbackReport/feedbackReport";
import GenerateObservationPdf from "../certificate/generateObservationPdf";

export default function Main(props) {
  const useStorage = useGlobalStorage({
    storageOptions: { name: "test-db" },
  });
  const [title, setTitle] = useStorage("title");
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("isLoggedIn")
  );

  return (
    <Box sx={{ display: "flex" }}>
      <HashRouter>
        {localStorage.getItem("isLoggedIn") &&
          localStorage.getItem("type") != 3 && (
            <Navbar title={title} setIsLoggedIn={setIsLoggedIn} />
          )}
        {localStorage.getItem("isLoggedIn") &&
          localStorage.getItem("type") == 3 && (
            <ClientNavbar title={title} setIsLoggedIn={setIsLoggedIn} />
          )}

        <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 3 }}>
          <Routes>
            {!localStorage.getItem("isLoggedIn") && (
              <Route index element={<Login setIsLoggedIn={setIsLoggedIn} />} />
            )}
            {localStorage.getItem("isLoggedIn") && (
              <Route index element={<Dashboard />} />
            )}
            <Route path="/clientLogin" element={<ClientLogin />} />
            <Route path="/dashboard" element={<Dashboard />}></Route>
            <Route
              path="/clientDashboard"
              element={<ClientDashboard />}
            ></Route>
            <Route
              path="/clientCertificate"
              element={<ClientCertificateList />}
            ></Route>
            <Route
              path="/clientHistoryCard"
              element={<ClientHistoryCardList />}
            ></Route>
            <Route
              path="/clientRenewal"
              element={<ClientRenewalList />}
            ></Route>
            <Route
              path="/master/instrument"
              element={<InstrumentsList />}
            ></Route>
            <Route
              path="/master/instrument/createInstrument"
              element={<CreateInstrument />}
              onEnter={() => setTitle("Instrument / Create instrument")}
            ></Route>
            <Route
              path="/master/standard"
              element={<StandardsList setLoader={props.setLoader} />}
              onEnter={() => setTitle("Standard")}
            ></Route>
            <Route
              path="/master/standard/createStandard"
              element={<CreateStandard setLoader={props.setLoader} />}
              onEnter={() => setTitle("Standard / Create standard")}
            ></Route>
            <Route
              path="/master/users/addUser"
              element={<AddUserMaster setLoader={props.setLoader} />}
              onEnter={() => setTitle("Master/ add new user")}
            ></Route>
            <Route
              path="/master/discipline/editDiscipline/:id"
              element={<EditDiscipline setLoader={props.setLoader} />}
              onEnter={() => setTitle("Discipline / Create discipline")}
            ></Route>
            <Route
              path="/master/standard/editStandard/:id"
              element={<Edittandard setLoader={props.setLoader} />}
              onEnter={() => setTitle("Standard / Edit standard")}
            ></Route>
            <Route
              path="/master/instrument/editInstrument/:id"
              element={<EditInstrument />}
              onEnter={() => setTitle("Instrument / Edit instrument")}
            ></Route>
            <Route
              path="/master/discipline"
              element={<DisciplineList />}
              onEnter={() => setTitle("Discipline")}
            ></Route>
            <Route
              path="/master/discipline/createDiscipline"
              element={<CreateDiscipline setLoader={props.setLoader} />}
              onEnter={() => setTitle("Discipline / Create discipline")}
            ></Route>{" "}
            <Route
              path="/master/range/createRange"
              element={<CreateRange setLoader={props.setLoader} />}
              onEnter={() => setTitle("Range / Create Range")}
            ></Route>
            <Route
              path="/master/range"
              element={<RangeList />}
              onEnter={() => setTitle("Ranges")}
            ></Route>
            <Route
              path="/master/editRange/:id"
              element={<EditRange setLoader={props.setLoader} />}
              onEnter={() => setTitle("Ranges")}
            ></Route>{" "}
            <Route
              path="/master/product"
              element={<ProductsList setLoader={props.setLoader} />}
              onEnter={() => setTitle("Product")}
            ></Route>
            <Route
              path="/master/product/createProduct"
              element={<CreateProduct setLoader={props.setLoader} />}
              onEnter={() => setTitle("Product / Create Product")}
            ></Route>{" "}
            <Route
              path="/master/editProduct/:id"
              element={<EditProduct setLoader={props.setLoader} />}
              onEnter={() => setTitle("Product ")}
            ></Route>{" "}
            <Route
              path="/master/client"
              element={<ClientsList />}
              onEnter={() => setTitle("Client")}
            ></Route>
            <Route
              path="/master/editClient/:id"
              element={<UpdateClient setLoader={props.setLoader} />}
              onEnter={() => setTitle("Client")}
            ></Route>
            <Route
              path="/master/client/createClient"
              element={<CreateClient setLoader={props.setLoader} />}
              onEnter={() => setTitle("Discipline / Create discipline")}
            ></Route>
            <Route
              path="/enquiry/followUpReport"
              element={<FollowUpReport setLoader={props.setLoader} />}
              onEnter={() => setTitle("Follow Up Report")}
            ></Route>
            <Route
              path="/feedback/feedbackReport"
              element={<FeedbackReport setLoader={props.setLoader} />}
              onEnter={() => setTitle("Feedback Report")}
            ></Route>
            <Route
              path="/master/setting"
              element={<SettingList />}
              onEnter={() => setTitle("Settings")}
            ></Route>
            <Route
              path="/master/client/editSetting/:id"
              element={<EditSetting  setLoader={props.setLoader}/>}
              onEnter={() => setTitle("Setting / Create setting")}
            ></Route>
            <Route
              path="/master/expense/paymentVoucher/:id"
              element={<PaymentVoucher />}
              onEnter={() => setTitle("Payment Voucher")}
            ></Route>
            <Route
              path="/master/uncertinity/EditUncertinity/:id"
              element={<UpdateUncertinity setLoader={props.setLoader} />}
              onEnter={() => setTitle("Update Uncertinity")}
            ></Route>
            <Route
              path="/master/uncertinity/createUncertinity"
              element={<CreateUncertainty setLoader={props.setLoader} />}
              onEnter={() => setTitle("Create Uncertainty")}
            ></Route>
            <Route
              path="/master/uncertinity/uncertinityList"
              element={<UncertinityList />}
              onEnter={() => setTitle("Uncertinity List")}
            ></Route>
            <Route
              path="/master/unit/EditUnit/:id"
              element={<UpdateUnit setLoader={props.setLoader} />}
              onEnter={() => setTitle("Update Unit")}
            ></Route>
            <Route
              path="/master/unit/createUnit"
              element={<CreateUnit setLoader={props.setLoader} />}
              onEnter={() => setTitle("Create Unit")}
            ></Route>
            <Route
              path="/master/unit/unitList"
              element={<UnitList />}
              onEnter={() => setTitle("Unit List")}
            ></Route>
            <Route
              path="/master/supportiveInstruments/supportiveInstrumentsList"
              element={<SupportiveInstrumentsList />}
              onEnter={() => setTitle("Supportive Instruments List")}
            ></Route>
            <Route
              path="/master/supportiveInstruments/EditSupportiveInstruments/:id"
              element={<UpdateSupportiveInstruments setLoader={props.setLoader} />}
              onEnter={() => setTitle("Update Supportive Instruments")}
            ></Route>
            <Route
              path="/master/supportiveInstruments/createSupportiveInstruments"
              element={<CreateSupportiveInstruments setLoader={props.setLoader} />}
              onEnter={() => setTitle("Create Supportive Instruments")}
            ></Route>
            <Route
              path="/master/pickup/pickupList"
              element={<PickupList />}
              onEnter={() => setTitle("Pickup List")}
            ></Route>
            <Route
              path="/master/pickup/EditPickup/:id"
              element={<UpdatePickup setLoader={props.setLoader} />}
              onEnter={() => setTitle("Update Pickup")}
            ></Route>
            <Route
              path="/master/pickup/createPickup"
              element={<CreatePickup setLoader={props.setLoader} />}
              onEnter={() => setTitle("Create Pickup")}
            ></Route>
            <Route
              path="/master/purchase/purchaseList"
              element={<PurchaseList />}
              onEnter={() => setTitle("Purchase List")}
            ></Route>
            <Route
              path="/master/purchase/EditPurchase/:id"
              element={<UpdatePurchase setLoader={props.setLoader} />}
              onEnter={() => setTitle("Update Purchase")}
            ></Route>
            <Route
              path="/master/purchase/createPurchase"
              element={<CreatePurchase setLoader={props.setLoader} />}
              onEnter={() => setTitle("Create Purchase")}
            ></Route>
            <Route
              path="/master/enquiry/enquiryList"
              element={<EnquiryList />}
              onEnter={() => setTitle("Enquiry List")}
            ></Route>
            <Route
              path="/master/enquiry/EditEnquiry/:id"
              element={<UpdateEnquiry setLoader={props.setLoader} />}
              onEnter={() => setTitle("Update Enquiry")}
            ></Route>
            <Route
              path="/master/enquiry/createEnquiry"
              element={<CreateEnquiry setLoader={props.setLoader} />}
              onEnter={() => setTitle("Create Enquiry")}
            ></Route>
            <Route
              path="/master/feedback/feedbackList"
              element={<FeedbackList />}
              onEnter={() => setTitle("Feedback List")}
            ></Route>
            <Route
              path="/master/feedback/EditFeedback/:id"
              element={<UpdateFeedback setLoader={props.setLoader} />}
              onEnter={() => setTitle("Update Feedback")}
            ></Route>
            <Route
              path="/master/feedback/createFeedback"
              element={<CreateFeedback setLoader={props.setLoader} />}
              onEnter={() => setTitle("Create Feedback")}
            ></Route>
            <Route
              path="/master/scope/scopes"
              element={<ScopeList />}
              onEnter={() => setTitle("Scope List")}
            ></Route>
            <Route
              path="/master/scope/EditScope/:id"
              element={<UpdateScope />}
              onEnter={() => setTitle("Update Scope")}
            ></Route>
            <Route
              path="/master/scope/createScope"
              element={<CreateScope />}
              onEnter={() => setTitle("Create Scope")}
            ></Route>
            <Route path="/master/table" element={<TablesList />}></Route>
            <Route path="/master/createTable" element={<CreateTable />}></Route>
            <Route path="/master/editTable/:id" element={<EditTable />}></Route>
            <Route
              path="/master/staticTable"
              element={<StaticTableList />}
            ></Route>
            <Route
              path="/master/createStaticTable"
              element={<CreateStaticTable setLoader={props.setLoader}/>}
            ></Route>
            <Route
              path="/master/editStaticTable/:id"
              element={<UpdateStaticTable setLoader={props.setLoader}/>}
            ></Route>
            <Route
              path="/srf"
              element={<SrfsList />}
              onEnter={() => setTitle("SRF")}
            ></Route>
            <Route
              path="/srf/editSrf/:id"
              element={<EditSrf setLoader={props.setLoader} />}
              onEnter={() => setTitle("Edit SRF")}
            ></Route>
            <Route
              path="/srf/createSRF"
              element={<CreateSRF setLoader={props.setLoader} />}
              onEnter={() => setTitle("SRF / Create SRF")}
            ></Route>{" "}
            <Route
              path="/srf/dispatch/:id/:clientId"
              element={<Dispatchlist />}
              onEnter={() => setTitle("Dispatch")}
            ></Route>
            <Route
              path="/srf/viewSrf/:id"
              element={<ViewSrf />}
              onEnter={() => setTitle("View SRF")}
            ></Route>
            <Route
              path="/customInvoice/viewCustomInvoice/:id/:poNumber/:mainId"
              element={<ViewCustomInvoice />}
              onEnter={() => setTitle("Invoice")}
            ></Route>
            <Route
              path="/dispatch/createDispatch/:id"
              element={<CreateDispatch />}
              onEnter={() => setTitle("Dispatch")}
            ></Route>{" "}
            <Route
              path="/dispatch/viewDispatch/:id/:instQnt"
              element={<ViewDispatch />}
              onEnter={() => setTitle("Dispatch")}
            ></Route>{" "}
            <Route
              path="/dispatch/editDispatch/:id"
              element={<EditDispatch />}
              onEnter={() => setTitle("Dispatch")}
            ></Route>{" "}
            <Route
              path="/datasheet"
              element={<DatasheetList />}
              onEnter={() => setTitle("Datasheet")}
            ></Route>
            <Route
              path="/datasheet/edit/:datasheetId/:instrumentId"
              element={<EditDatasheet setLoader={props.setLoader} />}
              onEnter={() => setTitle("Datasheet / Edit")}
            ></Route>{" "}
            <Route
              path="/certificate"
              element={<CertificateList />}
              onEnter={() => setTitle("CERTIFICATE")}
            ></Route>
            <Route
              path="/certificate/edit/:certificateId"
              element={<EditCertificate  setLoader={props.setLoader} />}
              onEnter={() => setTitle("Certificate / Edit")}
            ></Route>{" "}
            <Route
              path="/certificate/generateObservationPdf/:certificateId"
              element={<GenerateObservationPdf  setLoader={props.setLoader} />}
              onEnter={() => setTitle("Generate Observation Pdf")}
            ></Route>{" "}
            {/* <Route
              path="/certificate/letterHead/:certificateId"
              element={<LetterHead />}
              onEnter={() => setTitle("Letter Head")}
            ></Route>{" "} */}
            <Route
              path="/certificate/multiqr/:certificateId"
              element={<MultiQr />}
              onEnter={() => setTitle("Multiple QR Code")}
            ></Route>{" "}
            <Route
              path="/certificate/viewCertificate/:certificateId"
              element={<ViewCertificate />}
              onEnter={() => setTitle("Datasheet / ViewCertificate")}
            ></Route>
            <Route
              path="/certificate/viewMultipleCertificate/:certificateIds"
              element={<ViewMultipleCertificate />}
              onEnter={() => setTitle("Datasheet / ViewCertificate")}
            ></Route>
            <Route
              path="/standardInOutList"
              element={<StandardsInOutList />}
              onEnter={() => setTitle("STANDARD IN/OUT")}
            ></Route>
            <Route
              path="/standardInOutList/createStandardInOut"
              element={<CreateStandardInOut />}
              onEnter={() => setTitle("CREATE STANDARD IN/OUT")}
            ></Route>
            <Route
              path="/standardInOutList/editStandardInOut/:id"
              element={<EditStandardInOut />}
              onEnter={() => setTitle("EDIT STANDARD IN/OUT")}
            ></Route>
            <Route
              path="/renewCertificate"
              element={<RenewCertificate />}
            ></Route>{" "}
            <Route
              path="/workReport"
              element={<WorkReport />}
              onEnter={() => setTitle("WORK REPORT")}
            ></Route>
            <Route
              path="/quotation/createQuotation"
              element={<CreateQuotation />}
              onEnter={() => setTitle("Quotation")}
            ></Route>
            <Route
              path="/quotation/editQuotation/:clientId/:quotationNumber"
              element={<EditQuotation />}
              onEnter={() => setTitle("Quotation")}
            ></Route>
            <Route
              path="/quotation"
              element={<QuotationList />}
              onEnter={() => setTitle("Quotation")}
            ></Route>
            <Route
              path="/quotation/viewQuotation/:clientId/:quotationNumber"
              element={<ViewQuotation />}
              onEnter={() => setTitle("Quotation")}
            ></Route>
            <Route
              path="/invoice/createInvoice"
              element={<CreateInvoice />}
              onEnter={() => setTitle("Invoice")}
            ></Route>
            <Route
              path="/invoice"
              element={<InvoiceList />}
              onEnter={() => setTitle("Invoice")}
            ></Route>
            <Route
              path="/invoice/editInvoice/:id/:poNumber"
              element={<EditInvoice />}
              onEnter={() => setTitle("Invoice")}
            ></Route>
            <Route
              path="/customInvoice/createInvoice"
              element={<CreateCustomInvoice setLoader={props.setLoader} />}
              onEnter={() => setTitle("Invoice")}
            ></Route>
            <Route
              path="/viewCustomInvoice"
              element={<CustomInvoiceList />}
              onEnter={() => setTitle("Custom Invoice")}
            ></Route>
            <Route
              path="/challan/createChallan"
              element={<CreateChallan />}
              onEnter={() => setTitle("Create Challan")}
            ></Route>
            <Route
              path="/challan/challanList"
              element={<ChallanList />}
              onEnter={() => setTitle("Challan List")}
            ></Route>
            <Route
              path="/challan/editChallan/:id"
              element={<EditChallan />}
              onEnter={() => setTitle("Edit Challan")}
            ></Route>
            <Route
              path="/courier/courierList"
              element={<CourierList />}
              onEnter={() => setTitle("Courier List")}
            ></Route>
            <Route
              path="/courier/createCourier"
              element={<CreateCourier />}
              onEnter={() => setTitle("Create Courier")}
            ></Route>
            <Route
              path="/courier/editCourier/:id"
              element={<UpdateCourier />}
              onEnter={() => setTitle("Update Courier")}
            ></Route>
            <Route
              path="/mastersDue"
              element={<MastersDue />}
              onEnter={() => setTitle("Masters Due")}
            ></Route>
            <Route
              path="/customInvoice/editInvoice/:id/:poNumber/:mainId"
              element={<EditCustomInvoice setLoader={props.setLoader} />}
              onEnter={() => setTitle("Invoice")}
            ></Route>
            <Route
              path="/master/createIntermediateProcedure"
              element={<CreateIntermediateProcedure />}
              onEnter={() => setTitle("Invoice")}
            ></Route>
            <Route
              path="/master/intermediateProcedure"
              element={<IntermediateProcedureList />}
              onEnter={() => setTitle("Invoice")}
            ></Route>
            <Route
              path="/master/editIntermediateProcedure/:id"
              element={<UpdateIntermediateProcedure />}
              onEnter={() => setTitle("Update Intermediate Procedure")}
            ></Route>
            <Route
              path="/master/checkRecords"
              element={<CheckRecords />}
              onEnter={() => setTitle("Check Records")}
            ></Route>
            <Route
              path="/paymentMaster"
              element={<PaymentMasterList />}
              onEnter={() => setTitle("PaymentMaster")}
            ></Route>
            <Route
              path="/paymentMaster/EditPayment/:id"
              element={<EditPayment setLoader={props.setLoader} />}
              onEnter={() => setTitle("PaymentMaster")}
            ></Route>
            <Route
              path="/paymentMaster/PaymentList/:companyId/:fromDate/:toDate"
              element={<PaymentList />}
              onEnter={() => setTitle("PaymentMaster")}
            ></Route>
            <Route
              path="/maintenance/:standardId"
              element={<MaintenanceData />}
              onEnter={() => setTitle("Maintenance")}
            ></Route>
            <Route
              path="/accountStatement"
              element={<AccountStatement />}
              onEnter={() => setTitle("Account Statement")}
            ></Route>
            <Route
              path="/gstReport"
              element={<GstReport />}
              onEnter={() => setTitle("Gst Report")}
            ></Route>
            <Route
              path="/master/expense/editExpense/:id"
              element={<EditExpense />}
              onEnter={() => setTitle("Expense")}
            ></Route>
            <Route
              path="/master/expense"
              element={<ExpenseList />}
              onEnter={() => setTitle("Expense")}
            ></Route>
            <Route
              path="/master/expense/createExpense"
              element={<CreateExpense />}
              onEnter={() => setTitle("Expense")}
            ></Route>
            <Route path="/summaryReport" element={<SummaryReport />}></Route>{" "}
            <Route
              path="/adminHistoryCard"
              element={<ViewAdminHistoryCard />}
            ></Route>{" "}
            <Route
              path="/viewHistoryCard/:id"
              element={<ViewHistoryCard />}
            ></Route>{" "}
            <Route
              path="/summaryReport/viewSummary/:id"
              element={<ViewSummary />}
            ></Route>{" "}
            <Route
              path="/typeA/edit/:datasheetReadingId/:datasheetId/:tableId"
              element={<EditTypeA />}
              onEnter={() => setTitle("Type-A / Edit")}
            ></Route>{" "}
            <Route
              path="/typeB/edit/:tableId/:datasheetReadingId/:datasheetId/:instrumentId/:lc/:nominalVal"
              element={<EditTypeB />}
              onEnter={() => setTitle("Type-B / Edit")}
            ></Route>{" "}
            <Route
              path="/budget/edit/:instrumentId/:lc/:range/:datasheetId/:tableId/:nominalVal/:datasheetReadingId"
              element={<EditBudget />}
              onEnter={() => setTitle("Budget / Edit")}
            ></Route>
            <Route
              path="/viewAll/:instrumentId/:lc/:range/:datasheetId/:tableId"
              element={<ViewAll />}
              onEnter={() => setTitle("View All / Edit")}
            ></Route>
          </Routes>
        </Box>
      </HashRouter>
      <ToastContainer />
    </Box>
  );
}
