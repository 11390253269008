import React, { useState } from "react"
import { Link, Navigate, useNavigate } from "react-router-dom"

import { toast } from "react-toastify"
import { LinearProgress } from "@mui/material"
import { Form, Button, Card } from "react-bootstrap"
import logo from "../image/logo.jpeg"
import "react-toastify/dist/ReactToastify.css"

import "./Login.css"

//API handling components
import { BASE_URL } from "./../../global"
const axios = require("axios")

const data = { userName: "admin", password: "admin" }

function Login(props) {
    const navigate = useNavigate()

    const [userName, setUserName] = useState("")
    const [password, setPassword] = useState("")
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const configure = (_id, _userName, type, modules="", clientId=0) => {

            localStorage.setItem("isLoggedIn", true)            
            localStorage.setItem("id", _id)
            localStorage.setItem("clientId", clientId)
            localStorage.setItem("userName", _userName)
            localStorage.setItem("type", type)
            localStorage.setItem("modules", modules)
           
            toast.success("Login Successful!")

            props.setIsLoggedIn(true)
            type != 3 && navigate("/dashboard", { replace: true });
            type == 3 && navigate("/clientDashboard", { replace: true });
        
    }

    const handleSubmit = (e) => {
        axios
            .get(BASE_URL + `users?_where=(userName,eq,${userName})~and(password,eq,${password})`)
            .then((res) => {
                if (res.status === 200 && res.data.length > 0) {
                    configure(
                        res.data[0].id,
                        res.data[0].userName,
                        res.data[0].type,
                        res.data[0].modules,
                        res.data[0].clientId
                    )
                } else {
                    toast.error("Incorrect Username / password")
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <>
            <div class="limiter">
                <div class="container-login100">
                    <div class="wrap-login100">
                        <div class="login100-pic js-tilt" data-tilt>
                            <img className="circle" src={logo} alt="IMG" />
                        </div>

                        <div
                            class="login100-form validate-form"
                       
                        >
                            <span class="login100-form-title">Admin Login</span>

                            <div
                                class="wrap-input100 validate-input"
                                data-validate="Valid email is required: ex@abc.xyz"
                            >
                                <input
                                    class="input100"
                                    type="text"
                                    name="email"
                                    placeholder="Username"
                                    onChange={(e) =>
                                        setUserName(e.target.value)
                                    }
                                />
                                <span class="focus-input100"></span>
                                <span class="symbol-input100">
                                    <i
                                        class="fa fa-envelope"
                                        aria-hidden="true"
                                    ></i>
                                </span>
                            </div>

                            <div
                                class="wrap-input100 validate-input"
                                data-validate="Password is required"
                            >
                                <input
                                    class="input100"
                                    type="password"
                                    name="pass"
                                    placeholder="Password"
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                />
                                <span class="focus-input100"></span>
                                <span class="symbol-input100">
                                    <i
                                        class="fa fa-lock"
                                        aria-hidden="true"
                                    ></i>
                                </span>
                            </div>

                            <div class="container-login100-form-btn">
                                <button class="login100-form-btn" onClick={()=>{handleSubmit()}}>Login</button>
                            </div>
                            {/* <Link to="/clientLogin">
                            <div class="container-login100-form-btn" >
                                <button class="login100-form-btn" style={{backgroundColor:'cornflowerblue'}}>Client Login</button>
                            </div>
                            </Link> */}
                            {/* <div>
                                
                                <a class="txt2" style={{fontWeight:'bold',fontSize:'20px'}}>
                                    Client Login
                                </a>
                               
                            </div> */}

                            <div class="text-center p-t-12">
                                <span class="txt1">Forgot</span>
                                <a class="txt2" href="#">
                                    Username / Password?
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login
