import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AddIcon from "@mui/icons-material/Add";
import { ClassicTable } from "../../utils/components/Styles";

const axios = require("axios");

export default function EditStandardInOut() {
  const [companyName, setCompanyName] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [standards, setStandards] = React.useState('');
  const [purpose, setPurpose] = React.useState("");
  const [personName, setPersonName] = React.useState("");
  const [remark, setRemark] = React.useState("");
  const [actualReturnDate, setActualReturnDate] = React.useState("");

  const [standardArray, setStandardArray] = React.useState([]);
  const [srfObject, createSrfObject] = React.useState({});
  const [checkPoint, setCheckPoint] = React.useState("");
  const [standardsArray, setStandardsArray] = React.useState([]);
  const [readingRows, setReadingRows] = React.useState([]);

  const readingColumns = [
    [
      {
        field: "id",
        headerName: "Sr. No.",
        editable: false,
      },
      {
        field: "standards",
        headerName: "standards",
        editable: true,
      },
      {
        field: "checkPoint",
        headerName: "check Point",
        editable: true,
      },
      {
        field: "returnDate",
        headerName: "Return Date",
        editable: true,
      },
    ],
  ];

  const params = useParams();

  // const [readingRows, setReadingRows] = React.useState([
  //   ["", checkPoint, `${standards}`],
  // ]);

  const initiateChData = () => {
    try {
      let rows = [];
      for (let i = 0; i < standardsArray.length; i++) {
        rows.push([
          standardsArray[i].id,
          standardsArray[i].standardName,
          checkPoint,
          ""
        ]);
      }
      console.log(rows)
      setReadingRows(rows);
    } catch (error) {
      console.log(error);
    }
  };
  const customerList = [];
  for (let i = 0; i < standardArray.length; i++) {
    customerList.push({
      label: standardArray[i].id + ", " + standardArray[i].name,
    });
  }

  // submit form
  const submitDescriptionRows = (readingRows, id) => {
    // let rows = readingRows.map((row) => ({
    //   standardId: id,
    //   rangeName: row[1],
    //   uncertainty: row[2],
    //   accuracy: row[3],
    //   stability: row[4],
    //   uniformity: row[5],
    //   reactionTime: row[6],
    //   gravity: row[7],
    //   drift: row[8],
    // }));
    // rows.map((rows) => {
    //   let url = BASE_URL;
    //   axios
    //   .post(url + "standardRanges", rows)
    //   .then((res) => {
    //     toast("Standard Ranges created successfully !");
    //     setTimeout(refresh, 1000);
    //   })
    //   .catch((error) => {
    //     toast.error("Something Went Wrong!");
    //   });
    // });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const getStandardInOutList = () => {
    let url = BASE_URL;
    axios
      .get(
        url +
          `xjoin?_join=stInOut.standardInOut,_j,client.clients&_on1=(stInOut.clientId,eq,client.id)&_fields=client.companyName,client.address,stInOut.clientId,stInOut.standardIds,stInOut.standards,stInOut.purpose,stInOut.outwardDate,stInOut.expectedReturnDate,stInOut.personName,stInOut.actualReturnDate,stInOut.remark,stInOut.checkPoint&_where=(stInOut.id,eq, ${params.id})`
      )
      .then((res) => {
        console.log(res.data)
        setCompanyName(res.data[0].client_companyName);
        setAddress(res.data[0].client_address);
        setStandards(res.data[0].stInOut_standards);
        setPurpose(res.data[0].stInOut_purpose);
        setPersonName(res.data[0].stInOut_personName);
        setRemark(res.data[0].stInOut_remark);
        setActualReturnDate(res.data[0].stInOut_actualReturnDate);
        setCheckPoint(res.data[0].stInOut_checkPoint);
        if (res.data[0].stInOut_standards != null){
          axios
            .get(url + `standards/bulk?_ids=${res.data[0].stInOut_standards}`)
            .then((res) => {
              setStandardsArray(res.data);
            })
            .catch((error) => {
              toast.error("Something Went Wrong!");
            });
          }
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };


  const handleSubmit = (event) => {
    let row = {
      // address: address,
      standards: standards,
      purpose: purpose,
      personName: personName,
      remark: remark,
      actualReturnDate: moment(actualReturnDate).format("YYYY-MM-DD"),
    };

    // let rows = readingRows.map((row) => ({
    //   contactPersonName: row[1],
    //   emailId: row[2],
    // }));
    console.log(readingRows)
    console.log(row)
    return
    let url = BASE_URL;
    axios
      .patch(url + `standardInOut/${params.id}`, row)
      .then((res) => {
        toast("standard In/Out Updated successfully !");
        // var insertedID = res.data.insertId;
        // submitDescriptionRows(readingRows, insertedID);
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  //ToDo: fetch cutomers from database

  // data operations
  const updateSrfObject = (key, value) => {
    let newSrfObject = {
      ...srfObject,
    };
    newSrfObject[key] = value;
    createSrfObject(newSrfObject);
  };
  const updateCellValue = (rowIndex, colIndex, value) => {
    const newRows = [...readingRows];
    newRows[rowIndex][colIndex] = value;
    setReadingRows([...newRows]);
  };

  const addDescriptionRow = (tableIndex) => {
    let newReadingRows = [...readingRows];
    newReadingRows.push(["", "", "",]);
    setReadingRows([...newReadingRows]);
  };

  const deleteReadingRow = (tableIndex, rowIndex) => {
    let newReadingRows = [...readingRows];
    newReadingRows.splice(rowIndex, 0);
    setReadingRows([...newReadingRows]);
  };

  var refresh = () => {
    window.location.reload(false);
    // <Link to="/master/standard">Home</Link>
  };

  useEffect(() => {
    getStandardInOutList();
  }, []);

  useEffect(() => {
    initiateChData();
  }, [standardsArray]);

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Edit standard In/Out
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Company Name"
            size="small"
            disabled
            value={companyName}
            fullWidth
            variant="outlined"
            onChange={(e) => setCompanyName(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Address"
            size="small"
            disabled
            value={address}
            fullWidth
            variant="outlined"
            onChange={(e) => setAddress(e.target.value)}
          />
        </Grid>
        {/* <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Standards"
            size="small"
            value={standards}
            fullWidth
            variant="outlined"
            onChange={(e) => setStandards(e.target.value)}
          />
        </Grid> */}

        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Purpose"
            size="small"
            value={purpose}
            fullWidth
            variant="outlined"
            onChange={(e) => setPurpose(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Person Name"
            size="small"
            value={personName}
            fullWidth
            variant="outlined"
            onChange={(e) => setPersonName(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Remarks"
            size="small"
            value={remark}
            fullWidth
            variant="outlined"
            onChange={(e) => setRemark(e.target.value)}
          />
        </Grid>
      </Grid>
      <br />
      <hr />
      <h4 style={{ "margin-bottom": "0px" }}>Master Records</h4>
      <Button
        style={{ float: "right" }}
        onClick={(e) => {
          addDescriptionRow(0);
        }}
      >
        <AddIcon />
      </Button>
      <div style={{ width: "100%", overflow: "auto" }}>
        <ClassicTable>
          <Table sx={{ minWidth: 660 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {readingColumns[0].map((column) => (
                  <TableCell key={column.field}>
                    {" "}
                    <Typography noWrap>{column.headerName}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {readingRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  {row.map((cell, cellIndex) => {
                    if (cellIndex > 0 && cellIndex > 1 &&cellIndex != 3) {
                      return (
                        <TableCell>
                          <TextField
                            size="small"
                            value={cell}
                            onChange={(e) => {
                              updateCellValue(index, cellIndex, e.target.value);
                            }}
                          ></TextField>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 1) {
                      return (
                        <TableCell>
                          <TextField
                            size="small"
                            value={cell}
                            disabled
                            onChange={(e) => {
                              updateCellValue(index, cellIndex, e.target.value);
                            }}
                          ></TextField>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 3) {
                      return (
                        <TableCell style={{ width: "200px" }}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                              label="Actual Return Date *"
                              inputFormat="dd/MM/yyyy"
                              InputLabelProps={{ shrink: true }}
                              value={ new Date()}
                              onChange={(newValue) => {
                                updateCellValue(index, cellIndex, newValue);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} size="small" fullWidth />
                              )}
                            />
                          </LocalizationProvider>
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ClassicTable>
      </div>

      <br />
      <Toolbar style={{ padding: "0px", overflow: "auto" }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            handleSubmit();
          }}
        >
          Update Standard In/Out
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
