import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectTypeA } from "../typeA/typeASlice";

import { useParams } from "react-router-dom";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableFooter,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
  Chip,
} from "@mui/material";

import { useSnackbar } from "notistack";

import { ClassicTable } from "./../../utils/components/Styles";
import { useReactToPrint } from "react-to-print";
import { BASE_URL } from "./../../global";
import { useEffect } from "react";
const axios = require("axios");

export default function EditBudget(props) {
  let { instrumentId, lc, range, datasheetId, nominalVal, datasheetReadingId } =
    useParams();

  if (props.viewAll == true) {
    datasheetId = props.datasheetId;
    instrumentId = props.instrumentId;
    lc = props.lc;
    nominalVal = props.nominalVal;
    datasheetReadingId = props.datasheetReadingId;
    range = props.range;
  }

  const typeAValues = useSelector(selectTypeA);

  const [stdDevOfMean, setStdDevOfMean] = useState(0);
  const [meanOfStdDev, setMeanOfStdDev] = useState(0);
  const [customValues, setCustomValues] = useState({});
  const [standardIds, setStandardIds] = useState([]);
  const [subStandardIds, setSubStandardIds] = useState([]);
  const [standards, setStandards] = useState([]);
  const [options, setOptions] = useState([]);
  const [supportiveInstruments, setSupportiveInstruments] = useState([]);
  const [ucData, setUcData] = useState({
    standards: [],
    supportives: [],
  });
  const [uc, setUc] = useState(0);
  const [freedom, setFreedom] = useState(0);
  const [uncertainty, setUncertainty] = useState(0);
  const [fieldRows, setFieldRows] = useState({
    standardRows: null,
    supportiveRows: null,
  });
  const [typeA, setTypeA] = useState(null);

  const printComponentRef = React.useRef();
  const { enqueueSnackbar } = useSnackbar();

  const [derivedValues, setDerivedValues] = useState({});

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
  });

  const parseIds = (ids) => {
    ids = ids.split(",");
    let sids = [];
    let subsids = [];
    for (let i = 0; i < ids.length; i++) {
      let id = ids[i].split(":");
      sids.push(id[0]);
      subsids.push(id[1]);
    }
    setStandardIds(sids);
    setSubStandardIds(subsids);
  };

  // api calls
  function fetchTypeB() {
    axios
      .get(BASE_URL + `typeB?_where=(datasheetReadingId,eq,${datasheetReadingId})`)
      .then((res) => {
        if (res.data?.length > 0) {
          setCustomValues(JSON.parse(res.data[0].customValues));
        }
      })
      .catch((err) => {
        console.error("typeB data fetching error: ", err);
      });
  }

  function fetchTypeA() {
    axios
      .get(
        BASE_URL + `typeA?_where=(datasheetReadingId,eq,${datasheetReadingId})`
      )
      .then((res) => {
        if (res.data?.length > 0) {
          setTypeA(res.data[0]);
        }
      })
      .catch((err) => {
        console.error("typeB data fetching error: ", err);
      });
  }

  function fetchTypeBValues() {
    axios
      .get(
        BASE_URL +
          `datasheetStaticReadings?_where=(id,eq,${datasheetReadingId})`
      )
      .then((res) => {
        setDerivedValues(JSON.parse(res?.data[0]?.typeBValues));
      })
      .catch((err) => {
        console.error("datasheet data fetching error: ", err);
      });
  }

  function fetchInstrument(additionalStandardIds) {
    let url = BASE_URL;
    axios
      .get(url + `instruments?_where=(id,eq,${instrumentId})`)
      .then((res) => {
        parseIds(additionalStandardIds || res.data[0].standardMasterIds);
        fetchUncertaintyFactors(res.data[0].uncertaintyFactors);
        fetchSupportiveInstruments(res.data[0].supportiveInstrumentMasterData);
      })
      .catch((err) => {
        console.error("instrument data fetching error: ", err);
      });
  }

  function fetchDatasheet() {
    let url = BASE_URL;
    axios
      .get(url + `datasheets?_where=(id,eq,${datasheetId})`)
      .then((res) => {
        fetchInstrument(res.data[0].additionalStandardIds);
        fetchUncertaintyFactors(res.data[0].uncertaintyFactors);
        fetchSupportiveInstruments(res.data[0].supportiveInstrumentMasterData);
      })
      .catch((err) => {
        console.error("instrument data fetching error: ", err);
      });
  }

  function fetchStandards() {
    let url = BASE_URL;
    axios
      .get(
        url +
          `standardRanges?_where=(standardId,in,${standardIds.toString()})~and(id,in,${subStandardIds.toString()})`
      )
      .then((res) => {
        setStandards([...res.data]);
      })
      .catch((err) => {
        console.error("standards data fetching error: ", err);
      });
  }

  function fetchSupportiveInstruments(supportiveInstruments) {
    axios
      .get(
        BASE_URL +
          `supportiveInstruments?_where=(id,in,${supportiveInstruments?.toString()})`
      )
      .then((res) => {
        setSupportiveInstruments(res.data);
      })
      .catch((err) => {
        console.error("supportiveInstruments fetching error: ", err);
      });
  }
  function fetchUncertaintyFactors(uncertaintyFactors) {
    axios
      .get(
        BASE_URL +
          `uncertainty?_where=(id,in,${uncertaintyFactors?.toString()})`
      )
      .then((res) => {
        setOptions([...res.data]);
      })
      .catch((err) => {
        console.error("uncertainty factors data fetching error: ", err);
      });
  }

  const updateDatasheetUncertainy = () => {
    axios
      .patch(BASE_URL + `/datasheetStaticReadings/${datasheetReadingId}`, {
        uncertainty: uncertainty,
      })
      .then((res) => {})
      .catch((err) => {
        console.error("uncertainty update error: ", err);
      });
    axios
      .patch(BASE_URL + `/certificateStaticReadings/${datasheetReadingId}`, {
        uncertainty: uncertainty,
      })
      .then((res) => {})
      .catch((err) => {
        console.error("uncertainty update error: ", err);
      });
  };

  const processStandardInstrument = () => {
    let rows = [];
    let _ucData = [];
    let hasLCDisplayed = false;
    let unlinkedRangesDisplayStatus = [];

    standards?.map((standard, index) => {
      for (let i = 0; i < options?.length; i++) {
        // unlinked factors should display only once
        if (!options[i].linkedRanges) {
          if (unlinkedRangesDisplayStatus[options[i].name] === true) continue;
          else {
            unlinkedRangesDisplayStatus[options[i].name] = true;
          }
        }

        // linked range value
        let actualVal = standard[options[i]?.linkedRanges];
        let processedValue = actualVal;
        let val = actualVal?.match(/[\d\.]+/g);
        val = val?.length > 0 ? val[0] : 0;

        // default value
        if (options[i]?.defaultValue) {
          actualVal = options[i]?.defaultValue;
          val = actualVal?.match(/[\d\.]+/g);
        }

        // derived from datasheet
        if (derivedValues && derivedValues[options[i].id]) {
          actualVal = derivedValues[options[i].id];
          val = actualVal?.match(/[\d\.]+/g);
        }

        if (customValues["s:" + index + ":" + (i + 1)]) {
          actualVal = customValues["s:" + index + ":" + (i + 1)];
          val = actualVal?.match(/[\d\.]+/g);
        }
        
        let formula = Number(options[i]?.formula);

        if (formula) {
          val = (Number(val) / formula).toFixed(4);
        }

        if (options[i]?.linkedRanges == "leastCount") {
          if (hasLCDisplayed) continue;
          actualVal = Number((lc?.match(/[\d\.]+/g) || [])[0]);
          actualVal = actualVal;
          processedValue = actualVal / 2;
          val = (processedValue / formula).toFixed(4);
          _ucData.push(val);
          hasLCDisplayed = true;
        } else {
          _ucData.push(val);
        }

        let row = [
          options[i].name,
          actualVal,
          processedValue,
          options[i].distribution,
          val,
          1,
          val,
          "∞",
        ];
        rows.push(row);
      }

      if (
        standard["percentOfOutput"] != "" &&
        standard["percentOfOutput"] != null &&
        standard["plusValue"] != "" &&
        standard["plusValue"] != null
      ) {
        let poo = Number(
          (String(standard["percentOfOutput"])?.match(/[\d\.]+/g) || [])[0]
        );
        let pv = Number(
          (String(standard["plusValue"])?.match(/[\d\.]+/g) || [])[0]
        );
        let actualVal = (poo * Number(nominalVal)) / 100 + pv;
        let val = actualVal;

        let row = [actualVal, actualVal, "√3", val, 1, val, "∞"];
        rows.push(row);
      }
    });

    setUcData({ ...ucData, standards: _ucData });
    setFieldRows({ ...fieldRows, standardRows: [...rows] });
  };

  const processSupportiveInstrument = () => {
    // let rows = [];
    // let parameter = ["axialUniformity", "radialUniformity", "stability"];
    // let _ucData = [];
    // supportiveInstruments.length > 0 &&
    //   supportiveInstruments.map((factor, index) => {
    //     for (let i = 0; i < parameter.length; i++) {
    //       if (factor[parameter[i]] != "" && factor[parameter[i]] != null) {
    //         let dv = factor[parameter[i]];
    //         if (customValues["si:" + index + ":" + (i + 1)]) {
    //           dv = customValues["si:" + index + ":" + (i + 1)];
    //         }
    //         let value = (dv / 1.7320508075688772).toFixed(4); // dividing by root 3
    //         _ucData.push(value);
    //         let row = [dv, dv, "1/√3", value, 1, value, "∞"];
    //         rows.push(row);
    //       }
    //     }
    //     if (
    //       factor["percentOfOutput"] != "" &&
    //       factor["percentOfOutput"] != null &&
    //       factor["plusValue"] != "" &&
    //       factor["plusValue"] != null
    //     ) {
    //       let specification =
    //         (Number(factor["percentOfOutput"]) * Number(nominalVal)) / 100 +
    //         Number(factor["plusValue"]);
    //       let value = (specification / 1.7320508075688772).toFixed(4); // dividing by root 3
    //       let row = [
    //         specification,
    //         specification,
    //         "1/√3",
    //         value,
    //         1,
    //         value,
    //         "∞",
    //       ];
    //       _ucData.push(value);
    //       rows.push(row);
    //     }
    //   });
    // setUcData({ ...ucData, supportives: _ucData });
    // setFieldRows({ ...fieldRows, supportiveRows: [...rows] });
  };

  const calcUncertainty = () => {
    // calc uc
    let rept = Number(meanOfStdDev);

    let k = 2.0;
    let sum = 0;
    ucData.standards?.forEach((element) => {
      sum += Number(element) * Number(element);
    });

    ucData.supportives?.forEach((element) => {
      sum += Number(element) * Number(element);
    });
    sum += rept * rept;
    let res = Number(Math.sqrt(sum).toFixed(4));
    setUc(res);

    setUncertainty((res * k).toFixed(4));
    setFreedom(
      ((Math.pow(0.440820639 * 2, 4) * range) / Math.pow(0.012, 4)).toFixed(4)
    );
    updateDatasheetUncertainy((res * k).toFixed(4));
  };

  React.useEffect(() => {
    fetchTypeB();
    fetchDatasheet();
    fetchTypeA();
    fetchTypeBValues();
  }, []);

  React.useEffect(() => {
    if (stdDevOfMean != null && typeA != null) {
      setStdDevOfMean(typeA.stdDevOfMean);
      setMeanOfStdDev(typeA.meanOfStdDev);
    }
  }, [typeA, stdDevOfMean]);

  React.useEffect(() => {
    if (typeAValues.value) {
      let stdDevOfMean = typeAValues?.value[datasheetReadingId]?.stdDevOfMean;
      let meanOfStdDev = typeAValues?.value[datasheetReadingId]?.meanOfStdDev;
      setStdDevOfMean(stdDevOfMean);
      setMeanOfStdDev(meanOfStdDev);
    }
  }, [typeAValues.value, datasheetReadingId]);

  React.useEffect(() => {
    if (standardIds?.length > 0 && subStandardIds?.length > 0) fetchStandards();
  }, [standardIds, subStandardIds]);

  React.useEffect(() => {
    if (options?.length > 0 && standards?.length > 0)
      processStandardInstrument();
  }, [standards, options]);

  useEffect(() => {
    processSupportiveInstrument();
  }, [supportiveInstruments]);

  React.useEffect(() => {
    if (Number(uc) > 0) updateDatasheetUncertainy();
  }, [uc]);

  React.useEffect(() => {
    calcUncertainty();
  }, [ucData]);

  const renderFieldRows = () => {
    let rows = [];
    let uCount = 1;
    fieldRows.standardRows?.forEach((row) => {
      rows.push(
        <TableRow key="1">
          <TableCell>
            {"U" + uCount}
            <br />
            {`${row[0]}`}
          </TableCell>
          {row.slice(1, row.length).map((cell) => (
            <TableCell>{cell}</TableCell>
          ))}
        </TableRow>
      );
      uCount++;
    });
    fieldRows.supportiveRows?.forEach((row) => {
      rows.push(
        <TableRow key="1">
          <TableCell>{"U" + uCount}</TableCell>
          {row.map((cell) => (
            <TableCell>{cell}</TableCell>
          ))}
        </TableRow>
      );
      uCount++;
    });

    // fixed rows
    rows.push(
      <TableRow key="1">
        <TableCell>Repeatability</TableCell>
        <TableCell>{stdDevOfMean}</TableCell>
        <TableCell>{stdDevOfMean}</TableCell>
        <TableCell>√{Number(range) + 1}</TableCell>
        <TableCell>{meanOfStdDev}</TableCell>
        <TableCell>1</TableCell>
        <TableCell>{meanOfStdDev}</TableCell>
        <TableCell>{range}</TableCell>
      </TableRow>
    );
    rows.push(
      <TableRow key="1">
        <TableCell></TableCell>
        <TableCell>Uc</TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell>{uc}</TableCell>
        <TableCell>{freedom}</TableCell>
      </TableRow>
    );
    rows.push(
      <TableRow key="1">
        <TableCell></TableCell>
        <TableCell>Expanded Uncertainty</TableCell>
        <TableCell>K=</TableCell>
        <TableCell>2</TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell>{(uc * 2).toFixed(4)}</TableCell>
        <TableCell>∞</TableCell>
      </TableRow>
    );

    return rows;
  };

  return (
    <Paper sx={{ mx: 5, mt: 2, p: 2 }} ref={printComponentRef}>
      <Typography variant="h5" align="center" component="div" sx={{ mb: 2 }}>
        Uncertainty Budget
      </Typography>
      <div>
        {meanOfStdDev == null && (
          <p style={{ color: "red" }}>please verifiy Type-A</p>
        )}
        <ClassicTable>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Sources of Uncertainty</TableCell>
                <TableCell>Estimate</TableCell>
                <TableCell>Limits Xi</TableCell>
                <TableCell>Probability Distribution</TableCell>
                <TableCell>Standard Uncertainty</TableCell>
                <TableCell>Sensitivity Coefficient</TableCell>
                <TableCell>Uncertainty Contribution </TableCell>
                <TableCell>Degrees of freedom</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderFieldRows()}</TableBody>
          </Table>
        </ClassicTable>
      </div>
      <Grid container spacing={2} sx={{ my: 2 }}>
        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            label="COMBINED UNCERTAINTY (Uc)"
            value={uc}
            size="small"
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            label="EFFECTIVE DEGREES OF FREEDOM"
            value={freedom}
            size="small"
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            label="EXPANDED UNCERTAINTY"
            value={uncertainty}
            size="small"
            fullWidth
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 2 }} style={{ marginTop: "80px" }}>
        <Grid item xs={4}>
          <Typography align="center">Calibrated By</Typography>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <Typography align="center">Approved By</Typography>
        </Grid>
      </Grid>{" "}
      <Divider sx={{ mb: 1, mt: 2, displayPrint: "none" }}></Divider>
      <Toolbar
        sx={{ displayPrint: "none" }}
        style={{ padding: "0px", width: "100%" }}
      >
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0, displayPrint: "none" }}
          onClick={() => {
            enqueueSnackbar("Budget updated successfully !", {
              variant: "success",
            });
          }}
        >
          Save
        </Button>
        <Button
          variant="contained"
          size="small"
          sx={{ ml: 3, displayPrint: "none" }}
          onClick={handlePrint}
        >
          Print
        </Button>
      </Toolbar>
    </Paper>
  );
}
