import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ViewCertificate from "./viewCertificate";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  ButtonGroup,
} from "@mui/material";
import { useReactToPrint } from "react-to-print";

export default function ViewMultipleCertificate(props) {
  const printComponentRef = React.useRef();

  const { certificateIds } = useParams();
  let ids = certificateIds?.split(",");
  let [cert, setCert] = useState([]);

  const { certificateId } = useParams();

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
  });

  // let ids = certificateId.split(",");
  // let [cert, setCert] = useState([]);
  const render = () => {
    let certs = [];
    ids.forEach((id) => {
      certs.push(<ViewCertificate id={id} />);
    });
    setCert([...certs]);
    console.log("certs", certs)

  };
  useEffect(() => {
    render();
  }, []);
  return <div ref={printComponentRef}>{cert}  <Toolbar className="bt" style={{ padding: "0px", width: "100%" }}>

    <Button
      
      variant="contained"
      size="small"
      sx={{ ml: 3 }}
      onClick={handlePrint}
    >
      Download
    </Button>
  </Toolbar>
  </div>;
}
