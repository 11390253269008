import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";
import useGlobalStorage from "use-global-storage";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../global";
import Card from "react-bootstrap/Card";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import moment from "moment";

var $ = require("jquery");
$.DataTable = require("datatables.net")
const axios = require("axios");

export default function ViewList() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [disciplines, setDiscList] = React.useState("");
    const useStorage = useGlobalStorage({
        storageOptions: { name: "test-db" },
    });
    const [pushArrays, setPushArray] = React.useState([]);
    const [dateData, setDateData] = React.useState([]);
    const [paymentList, setPaymentList] = React.useState([]);
    const [paymentData, setPaymentData] = React.useState([]);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };
    
    const params = useParams();

    
const columns = [
    { id: "id", label: "Sr. No.",minWidth: 40, },
    {
      id: "checkMark",
      label: "Payment Status",
      align: "center",
      minWidth: 10,
  },
  
    { id: "clientName", label: "Company Name", minWidth: 40, },
    {
      id: "address",
      label: "Address",
      align: "left",
      minWidth: 40,
    },
    {
      id: "contactNumber",
      label: "Contact Number",
      align: "left",
      minWidth: 40,
    },
  
    {
      id: "invoiceDate",
      label: "Invoice Date",
      minWidth: 40,
      align: "left",
      format: (value) => value.toFixed(2),
    },
    {
      id: "invoiceNumber",
      label: "Invoice Number",
      minWidth: 40,
      align: "left",
      format: (value) => value.toFixed(2),
    },
    {
      id: "subject",
      label: "Subject",
      minWidth: 40,
      align: "left",
      format: (value) => value.toFixed(2),
    },
  ];
  
  function createData(
    id,
    checkMark,
    clientName,
    address,
    contactNumber,
    invoiceDate,
    invoiceNumber,
    subject,
  ) {
    return {
      id,
      checkMark,
      clientName,
      address,
      contactNumber,
      invoiceDate,
      invoiceNumber,
      subject,
    };
  }

  const getPaymentList = (event) => {
    let url = BASE_URL;
    axios
      // .get(url + "xjoin?_join=payment.paymentMaster,_j,client.clients,_j,inst.instruments&_on1=(payment.clientId,eq,client.id)&_on2=(payment.instrumentId,eq,inst.id)&_fields=payment.id,payment.totalAmount,payment.address,payment.contactNumber,payment.conveyanceCharges,payment.discount,payment.gstType,payment.invoiceDate,payment.invoiceNumber,payment.poNumber,payment.subject,client.companyName,inst.instrumentName")
      .get(url + "xjoin?_join=payment.paymentMaster,_j,client.clients&_on1=(payment.clientId,eq,client.id)&_fields=payment.id,payment.totalAmount,payment.address,payment.contactNumber,payment.conveyanceCharges,payment.discount,payment.gstType,payment.invoiceDate,payment.invoiceNumber,payment.poNumber,payment.subject,client.companyName,payment.paymentDate,payment.paymentMode")
      .then((res) => {
        var rowData = [];
        var resultProductData = res.data.filter(data => new Date(data.payment_invoiceDate) >= new Date(params.fromDate) &&
        new Date(data.payment_invoiceDate) <= new Date(params.toDate) && data.client_companyName == params.companyId
        );
        setDateData(resultProductData);
        setPaymentList(resultProductData);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const initiatInvoiceList = () => {
    var rowData = [];
    if(dateData.length){
    for (let i = 0; i < dateData.length; i++) {
       rowData.push(
         createData(
           dateData[i].payment_id, 
           dateData[i].payment_totalAmount,
           dateData[i].client_companyName,
           dateData[i].payment_address,
           dateData[i].payment_contactNumber,
           moment(dateData[i].payment_invoiceDate ? dateData[i].payment_invoiceDate : "").format("DD-MM-YYYY"),
           dateData[i].payment_invoiceNumber,
           dateData[i].payment_subject,
           // invoiceList[i].validUpto ? moment(standardList[i].validUpto).format("DD-MM-YYYY") : ""
         )
       );
     }
   }
     
     // setInvoiceData(rowData.filter((v,i,a)=>a.findIndex(v2=>(v2.clientId===v.clientId))===i)
     // );
     setPaymentData(rowData);
   };

   useEffect(() => {
    initiatInvoiceList();
  }, [dateData]);


  useEffect(() => {
    getPaymentList();
  }, []);

    return (
        <>
            
    <TableContainer component={Paper} sx={{ mt: 2 }}>
    {params.companyId !=0 && <h3 style={{ float: 'center' }}>{params.companyId}</h3>}
      <Table stickyHeader aria-label="sticky table" size="small" id="datatable-keytable">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                <b>{column.label}</b>
              </TableCell>
            ))}
            <TableCell>
              <b>Actions</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paymentData
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    let value = row[column.id];
                    if (column.id == "id") {
                      value = index + 1;
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                  <TableCell>
                    <ButtonGroup size="small" aria-label="small button group">
                      
                    <Button
                     style={{
                          borderColor: "#dc3545",
                        }}
                      component={Link}
                      to={`/paymentmaster/editPayment/${row.id}`}
                    >
                      <PreviewIcon />
                    </Button>
                      <Button
                       onClick={(e) => {
                        if (
                            window.confirm(
                                "Really want to delete standard?"
                            )
                            ) {
                                // submitSiteDelete(row.id);
                            }
                        }}
                        style={{
                          borderColor: "#dc3545",
                        }}
                      >
                        <DeleteIcon style={{ color: "#dc3545" }} />
                      </Button>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              );
            })}

        </TableBody>
      </Table>
         
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={paymentData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ToastContainer />
    </TableContainer>

        </>
    );
}
