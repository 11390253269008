import moment from 'moment'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import html2pdf from 'html2pdf.js'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextareaAutosize,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material'
import { Image } from 'react-bootstrap'
import { useSnackbar } from 'notistack'
import QRCode from 'react-qr-code'
import { useReactToPrint } from 'react-to-print'
import { generateNumber } from '../../utils/utils'
import { BASE_URL, MAX_COLS, DOMAIN, ID_BASE } from './../../global'
import { ClassicTable } from './../../utils/components/Styles'

import Global from '../image/globalLetterHead.png'

import './certificate.css'

const axios = require('axios')

export default function EditCertificate(props) {
  const printComponentRef = React.useRef()
  const { enqueueSnackbar } = useSnackbar()

  const { certificateId } = useParams()
  const [staticReadingRows, setStaticReadingRows] = React.useState([])
  const [
    staticCertificateReadings,
    setStaticCertificateReadings,
  ] = React.useState([])

  const [instrument, setInstrument] = React.useState(null)
  const [tables, setTables] = React.useState(null)
  const [staticTables, setStaticTables] = React.useState(null)
  const [configuration, setConfiguration] = React.useState({})
  const [clientId, setClientId] = React.useState('')
  const [instrumentArray, setInstrumentArray] = React.useState([])
  const [standardMasterArray, setStandardMasterArray] = React.useState([])
  const [instrumentName, setInstrumentName] = React.useState('')
  const [make, setMake] = React.useState('')
  const [calibrationProcedureNo, setCalibrationProcedureNo] = React.useState('')
  const [referenceStandards, setReferenceStandards] = React.useState('')
  const [lc, setLC] = React.useState('')
  const [dcNumber, setDcNumber] = React.useState('')
  const [ranges, setRanges] = React.useState('')
  const [address, setAddress] = React.useState('')
  const [calibrationDate, setCalibrationDate] = React.useState(null)
  const [dcDate, setDcDate] = React.useState(null)
  const [nextDueDate, setNextDueDate] = React.useState(null)
  const [receiptDate, setReceiptDate] = React.useState(null)
  const [poDate, setPoDate] = React.useState(null)
  const [dateOfIssue, setDateOfIssue] = React.useState('')

  const [calProcRefNo, setCalProcRefNo] = React.useState('')
  const [mfgNo, setMfgNo] = React.useState('')
  const [rangeDbData, setRangeData] = React.useState([])
  const [allRangeData, setRangeDataArray] = React.useState([])
  const [specificValuesFromTable, setSpecificValuesFromTable] = React.useState(
    [],
  )
  const [identificationNo, setIdentificationNo] = React.useState('')
  const [DUCID, setDUCID] = React.useState([])
  const [calType, setCalType] = React.useState('')
  const [tableName, setTableName] = React.useState('')
  const [unitName, setUnitName] = React.useState('')
  const [location, setLocation] = React.useState('')
  const [accuracy, setAccuracy] = React.useState('')
  const [filterRangeData, setFilterRangeData] = React.useState([])
  const [serialNo, setSerialNo] = React.useState('')
  const [selectedStandards, setSelectedStandards] = React.useState('')
  const [ulrNumber, setUlrNumber] = React.useState(null)
  const [certificateNumber, setCertificateNumber] = React.useState(null)
  const [serviceReqNumber, setServiceReqNumber] = React.useState('')
  const [jobNumber, setJobNumber] = React.useState('')
  const [workOrderNumber, setWorkOrderNumber] = React.useState('')
  const [poNo, setPoNo] = React.useState(null)
  const [startTemp, setStartTemp] = React.useState('')
  const [endTemp, setEndTemp] = React.useState('')
  const [models, setModel] = React.useState('')
  const [ConOfDuc, setConOfDuc] = React.useState('')
  const [calPoint, setCalPoint] = React.useState('')
  const [calMethod, setCalMethod] = React.useState('')
  const [locationOfInstrument, setLocationOfInstrument] = React.useState('')
  const [srnNo, setSrnNo] = React.useState('')
  const [atmosphericPressure, setAtmosphericPressure] = React.useState('')
  const [disciplineName, setDisciplineName] = React.useState('')
  const [defaultReamrk, setDefaultRemark] = React.useState(null)
  const userType = localStorage.getItem('type')
  const [tableDisplayStatus, setTableDisplayStatus] = React.useState(1)
  const [settingList, setSettingList] = React.useState({})
  const [isPrinting, setisPrinting] = React.useState(false)

  // utils
  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
  })

  // api calls
  function fetchSettings() {
    axios.get(BASE_URL + `settings`).then((res) => {
      let remark = null,
        settings = res.data
      let _settingList = {}
      settings.forEach((setting) => {
        if (setting?.keyName?.toLowerCase() === 'remarks') {
          remark = setting.value
        }
        _settingList[setting.keyName] = setting.value
      })
      setSettingList(_settingList)
      setDefaultRemark(remark)
    })
  }

  function fetchCertificate() {
    let url = BASE_URL
    axios
      .get(
        url +
          `xjoin?_join=cer.certificates,_j,ins.instruments,_j,cust.clients,_j,disc.discipline&_on1=(cer.instrumentId,eq,ins.id)&_on2=(cust.id,eq,cer.clientId)&_on3=(ins.disciplineId,eq,disc.id)&_fields=cer.clientId,cer.id,cer.id,cer.instrumentId,cer.lc,cer.make,cer.model,cer.ConOfDuc,cer.ranges,cer.mfgNo,cer.deletedTableIds,cust.companyName,ins.instrumentName,ins.standardMasterIds,ins.calibrationProcedureNo,ins.referenceStandards,cust.address,cer.dcNumber,cer.calibrationDate,cer.dcDate,cer.nextDueDate,cer.receiptDate,cer.calProcRefNo,cer.mfgNo,cer.identificationNo,cer.DUCID,cer.DUCID,cer.calibrationType,cer.specificValues,cer.location,cer.serialNumber,cer.additionalStandardIds,cer.serviceReqNumber,cer.jobNumber,cer.accuracy,cer.poNumber,cer.poDate,cer.startTemp,cer.endTemp,cer.dateOfIssue,cer.model,cer.calPoint,cer.calMethod,cer.locationOfInstrument,cer.srnNo,cer.atmosphericPressure,cer.configuration,cer.workOrderNumber,cer.certificateNumber,cer.ULRNo,cer.tableDisplayStatus,disc.name&_where=(cer.id,eq, ${certificateId})`,
      )
      .then((res) => {
        if (res.data.length && res.data[0] != null) {
          setClientId(res.data[0].cust_companyName)
          setInstrumentName(res.data[0].ins_instrumentName)
          setMake(res.data[0].cer_make)
          setModel(res.data[0].cer_model)
          setLC(res.data[0].cer_lc)
          setRanges(res.data[0].cer_ranges)
          setReferenceStandards(res.data[0].ins_referenceStandards)
          setSelectedStandards(res.data[0].cer_additionalStandardIds)
          setCalibrationProcedureNo(res.data[0].ins_calibrationProcedureNo)
          setAddress(res.data[0].cust_address)
          setDcNumber(res.data[0].cer_dcNumber)
          setUlrNumber(res.data[0].cer_ULRNo)
          setCertificateNumber(res.data[0].cer_certificateNumber)
          setCalibrationDate(res.data[0].cer_calibrationDate)
          setDcDate(res.data[0].cer_dcDate)
          setNextDueDate(res.data[0].cer_nextDueDate)
          setReceiptDate(res.data[0].cer_receiptDate)
          setCalProcRefNo(res.data[0].cer_calProcRefNo)
          setMfgNo(res.data[0].cer_mfgNo)
          setIdentificationNo(res.data[0].cer_identificationNo)
          setDUCID(res.data[0].cer_DUCID)
          setCalType(res.data[0].cer_calibrationType)
          res.data[0].cer_specificValues
            ? setSpecificValuesFromTable(
                res.data[0].cer_specificValues.split(','),
              )
            : setSpecificValuesFromTable([])
          setLocation(res.data[0].cer_location)
          setAccuracy(res.data[0].cer_accuracy)
          setSerialNo(res.data[0].cer_serialNumber)
          setServiceReqNumber(res.data[0].cer_serviceReqNumber)
          setJobNumber(res.data[0].cer_jobNumber)
          setWorkOrderNumber(res.data[0].cer_workOrderNumber)
          setPoNo(res.data[0].cer_poNumber)
          setPoDate(res.data[0].cer_poDate)
          setStartTemp(res.data[0].cer_startTemp)
          setEndTemp(res.data[0].cer_endTemp)
          setDateOfIssue(res.data[0].cer_dateOfIssue)
          setModel(res.data[0].cer_model)
          setConOfDuc(res.data[0].cer_ConOfDuc)
          setCalPoint(res.data[0].cer_calPoint)
          setCalMethod(res.data[0].cer_calMethod)
          setLocationOfInstrument(res.data[0].cer_locationOfInstrument)
          setSrnNo(res.data[0].cer_srnNo)
          setAtmosphericPressure(res.data[0].cer_atmosphericPressure)
          setConfiguration(JSON.parse(res.data[0].cer_configuration))
          setDisciplineName(res.data[0].disc_name)
          setTableDisplayStatus(res.data[0].cer_tableDisplayStatus)
        }

        fetchCertificateStaticTables(
          res.data[0].cer_instrumentId,
          res.data[0].cer_deletedTableIds,
        )
        fetchRangesData(res.data[0].cer_instrumentId)

        fetchInstrument(res.data[0].cer_instrumentId)
        if (res.data[0].cer_additionalStandardIds) {
          let standardMasterIds = res.data[0].cer_additionalStandardIds

          var splitStData = standardMasterIds.split(',')
          var combineArray = []
          splitStData.forEach(function (element) {
            var splitByCol = element.split(':')[0]
            combineArray.push(splitByCol)
          })
          axios
            .get(url + `standards?_where=(id,in,${combineArray.toString()})`)
            .then((res) => {
              setStandardMasterArray(res.data)
            })
        } else if (res.data[0].ins_standardMasterIds) {
          var splitStData = res.data[0].ins_standardMasterIds.split(',')
          var combineArray = []
          splitStData.forEach(function (element) {
            var splitByCol = element.split(':')[0]
            combineArray.push(splitByCol)
          })
          console.log(combineArray.toString())
          axios
            .get(url + `standards?_where=(id,in,${combineArray.toString()})`)
            .then((res) => {
              setStandardMasterArray(res.data)
              console.log('standards', res.data)
            })
        }
      })
      .catch((err) => {
        console.log('certificate data fetching error: ', err)
      })
  }

  function fetchRangesData(instrumentId) {
    let url = BASE_URL
    axios
      .get(url + `ranges?_where=(instrumentId,eq,${parseInt(instrumentId)})`)
      .then((res) => {
        setRangeData(res.data)
      })
  }

  function fetchCertificateStaticTables(instrumentId, deletedTableIds) {
    deletedTableIds = deletedTableIds?.split(',')?.map((id) => Number(id))
    axios
      .get(
        BASE_URL +
          `certificateStaticTables?_where=(instrumentId,eq,${instrumentId})`,
      )
      .then((res) => {
        if (deletedTableIds) {
          res.data = res.data.filter((row) => !deletedTableIds.includes(row.id))
        }
        setStaticTables(res.data)
      })
      .catch((err) => {
        console.log('datasheet static tables data fetching error: ', err)
      })
  }

  function fetchStaticReadingRows() {
    let url = BASE_URL
    axios
      .get(
        url +
          `certificateStaticReadings?_where=(certificateId,eq,${certificateId})`,
      )
      .then((res) => {
        setStaticCertificateReadings(res.data)
      })
      .catch((err) => {
        console.log('static certificate tables data fetching error: ', err)
      })
  }

  function fetchInstrument(instrumentId) {
    let url = BASE_URL
    axios
      .get(url + `instruments?_where=(id,eq,${instrumentId})`)
      .then((res) => {
        setInstrument(res.data)
      })
      .catch((err) => {
        console.log('certificate tables data fetching error: ', err)
      })
  }

  const getTableIndex = (tableId, isStatic = false) => {
    if (!isStatic) {
      for (let i = 0; i < tables.length; i++) {
        if (tables[i]['id'] == tableId) {
          return i
        }
      }
    } else {
      for (let i = 0; i < staticTables.length; i++) {
        if (staticTables[i]['id'] == tableId) {
          return i
        }
      }
    }
    return -1
  }

  const initiateStaticReadingRows = () => {
    if (!(staticCertificateReadings && staticTables)) return null

    let colsToSkip = [
      'id', // avoide duplicate
      'unitType',
      'instrumentId',
      'tableName',
      'parameterName',
      'defaultConfiguration',
      'conditionFormatting',
      'lastModified',
      'status',
      'isUncertainty',
    ]

    let readingRows = []
    let tempStaticCertReadings = [...staticCertificateReadings]

    tempStaticCertReadings.forEach((reading, readingIndex) => {
      let tableIndex = getTableIndex(reading.tableId, true)

      if (tableIndex == -1) return null
      let row = []
      row.push(staticTables[tableIndex]['id'])
      row.push(reading['id'])

      for (var col in staticTables[tableIndex]) {
        if (
          !(
            staticTables[tableIndex][col] == 0 ||
            staticTables[tableIndex][col] == null
          ) &&
          !colsToSkip.includes(col)
        ) {
          let data = reading[col]
          row.push(data)
        }
      }
      if (staticTables[tableIndex]['isUncertainty'] == 1) {
        row.push(reading['uncertainty'] ?? 'null')
      }

      readingRows.push(row)

      setTableName(staticTables[tableIndex]['tableName'])
      setUnitName(staticTables[tableIndex]['unitType'])
    })
    setStaticReadingRows(readingRows)
  }

  const getInstrumentsList = () => {
    let url = BASE_URL
    axios
      .get(url + 'instruments')
      .then((res) => {
        setInstrumentArray(res.data)
      })
      .catch((error) => {
        console.log('Something Went Wrong!')
      })
  }

  const submitCertificate = () => {
    props.setLoader(true)

    let data = {
      certificateNumber: certificateNumber,
      dcNumber: dcNumber,
      workOrderNumber: workOrderNumber,
      serviceReqNumber: serviceReqNumber,
      ULRNo: ulrNumber,
      poNumber: poNo,
      poDate: poDate
        ? moment(poDate).format('YYYY-MM-DD')
        : moment(new Date()).format('YYYY-MM-DD'),

      dateOfIssue: dateOfIssue
        ? moment(dateOfIssue).format('YYYY-MM-DD')
        : moment(new Date()).format('YYYY-MM-DD'),

      calibrationDate: calibrationDate
        ? moment(calibrationDate).format('YYYY-MM-DD')
        : moment(new Date()).format('YYYY-MM-DD'),

      nextDueDate: nextDueDate
        ? moment(nextDueDate).format('YYYY-MM-DD')
        : moment(new Date()).format('YYYY-MM-DD'),

      receiptDate: receiptDate
        ? moment(receiptDate).format('YYYY-MM-DD')
        : moment(new Date()).format('YYYY-MM-DD'),

      dcDate: dcDate
        ? moment(dcDate).format('YYYY-MM-DD')
        : moment(new Date()).format('YYYY-MM-DD'),
    }

    axios
      .patch(BASE_URL + 'certificates/' + certificateId, data)
      .then((res) => {
        enqueueSnackbar('Certificate updated successfully!', {
          variant: 'success',
        })

        props.setLoader(false)
      })
      .catch((err) => {
        props.setLoader(false)
      })
  }

  const initiateRangesList = () => {
    let rangeDataArray = []
    for (let i = 0; i < rangeDbData.length; i++) {
      rangeDataArray.push(
        rangeDbData[i].specificPoint1,
        rangeDbData[i].specificPoint2,
        rangeDbData[i].specificPoint3,
        rangeDbData[i].specificPoint4,
        rangeDbData[i].specificPoint5,
        rangeDbData[i].specificPoint6,
        rangeDbData[i].specificPoint7,
        rangeDbData[i].specificPoint8,
      )
    }
    var filtered = rangeDataArray.filter(function (el) {
      return el != null && el != ''
    })
    setRangeDataArray(filtered)
  }

  useEffect(() => {
    initiateRangesList()
  }, [rangeDbData])

  useEffect(() => {
    initiateStaticReadingRows()
  }, [staticCertificateReadings, staticTables])

  useEffect(() => {
    fetchRangesData()
    getInstrumentsList()
    fetchCertificate()
    fetchStaticReadingRows()
    fetchSettings()
  }, [])

  //
  const renderCommonHeader = (defaultConfiguration) => {
    if (!defaultConfiguration) return null
    defaultConfiguration = JSON.parse(defaultConfiguration)
    let hasCommonHeader = defaultConfiguration.hasCommonHeader
    if (hasCommonHeader) {
      let commonHeaderConfig = defaultConfiguration.commonHeaderConfig?.split(
        ',',
      )
      let cols = []
      commonHeaderConfig.forEach((col) => {
        let [name, size] = col?.split(':')
        let classNames =
          'header-border ' + (name != '$' ? 'header-border-bottom' : '')
        cols.push(
          <TableCell
            className={classNames + ' printOnlytableColumn '}
            style={{ textAlign: 'center' }}
            colSpan={Number(size)}
          >
            {name != '$' ? name : ''}
          </TableCell>,
        )
      })

      return (
        <TableRow>
          {/* for SN */}
          <TableCell
            className="header-border printOnlytableColumn"
            align="center"
          ></TableCell>
          {cols}
        </TableRow>
      )
    }
    return null
  }

  const renderTableCell = (table, row, rowIndex, cell, cellIndex, onChange) => {
    let field = null
    if (String(cell)?.includes('_unit_')) {
      if (table['c' + (cellIndex + 1)]?.toLowerCase()?.match(/m+\d+/g)) {
        field = <div></div>
      } else {
        let unit = cell.split('_unit_')[1]
        field = (
          <span>
            <b>{unit}</b>
          </span>
        )
      }
    } else if (cell && cell[0] == '$') {
      field = (
        <span>
          {!isNaN(cell.slice(1))
            ? Number(cell.slice(1)).toFixed(2)
            : cell.slice(1)}
        </span>
      )
    } else if (cell && cell == 'null') {
      field = (
        <span>
          {!isNaN(cell.slice(1)) ? Number(cell.slice(1)).toFixed(2) : ''}
        </span>
      )
    } else {
      field = <span>{row[cellIndex + 2]}</span>
    }
    return (
      <td
        key={cellIndex}
        className="tableCss"
        style={{
          padding: '4px',
          textAlign: 'center',
        }}
      >
        {field}
      </td>
    )
  }
  // render static table
  const renderRows = (table, staticReadingRows) => {
    let i = 1
    return staticReadingRows?.map((row, rowIndex) => {
      if (row[0] == table.id) {
        if (row[2]?.includes('_rh_')) {
          i = 1
          return (
            <tr key={rowIndex}>
              <td
                className="tableCss"
                colSpan={row.length - 2 + 1}
                style={{ textAlign: 'center', fontWeight: '400' }}
              >
                {row[0 + 2].split('_rh_')[1]}
              </td>
            </tr>
          )
        } else if (row[2]?.includes('_unit_')) {
          return (
            <tr key={rowIndex}>
              <td className="tableCss"></td>
              {row
                .slice(2, row.length) // 2: tableId + datasheetId
                .map((cell, cellIndex) =>
                  renderTableCell(table, row, rowIndex, cell, cellIndex),
                )}
            </tr>
          )
        }
        // else normal row
        return (
          <tr key={rowIndex}>
            <td className="tableCss">{i++}</td>
            {row
              .slice(2, row.length) // 2: tableId + datasheetId
              .map((cell, cellIndex) =>
                renderTableCell(table, row, rowIndex, cell, cellIndex),
              )}
          </tr>
        )
      }
    })
  }
  const renderStaticTables = () => {
    if (!staticTables) return null

    return staticTables
      .filter((table) => table.status == 1)
      .map((table, tableIndex) => {
        if (tableDisplayStatus === 1 && table.isAutomated !== 0) return null
        else if (tableDisplayStatus === 2 && table.isAutomated !== 1)
          return null

        let i = 0
        return (
          <div
            key={tableIndex}
            sx={{ mb: 5 }}
            style={{ pageBreakAfter: 'always' }}
          >
            <Typography variant="h6" component="div" style={{ float: 'left' }}>
              {tableIndex +
                1 +
                '. ' +
                (configuration?.customTableNames?.[table.id] ||
                  table.tableName)}
            </Typography>
            <div style={{ width: '100%', overflow: 'auto' }}>
              <ClassicTable>
                <table sx={{ minWidth: 650 }} aria-label="simple table">
                  <thead>
                    {renderCommonHeader(table?.defaultConfiguration)}

                    <tr>
                      <th className="header-border tableCss" align="center">
                        <Typography noWrap style={{ textAlign: 'center' }}>
                          <b>S.N.</b>
                        </Typography>
                      </th>
                      {/* render columns*/}
                      {Array.from({ length: MAX_COLS + 1 }, (_, i) => {
                        if (table['c' + i] != null) {
                          return (
                            <TableCell
                              className="header-border tableCss"
                              align="center"
                              style={{ textAlign: 'center' }}
                            >
                              <Typography
                                noWrap
                                style={{
                                  textAlign: 'center',
                                  color: 'black',
                                }}
                              >
                                <b>{table['c' + i]}</b>
                              </Typography>
                            </TableCell>
                          )
                        }
                      })}
                      {table['isUncertainty'] == 1 && (
                        <TableCell
                          className="header-border tableCss black"
                          align="center"
                          style={{ textAlign: 'center', fontWeight: '600' }}
                        >
                          <Typography
                            noWrap
                            style={{
                              fontSize: '14px',
                              textAlign: 'center',
                              fontWeight: '600',
                              color: 'black',
                            }}
                          >
                            {'Expanded Uncertainty'}
                          </Typography>
                        </TableCell>
                      )}
                    </tr>
                  </thead>
                  <tbody>{renderRows(table, staticReadingRows)}</tbody>
                </table>
              </ClassicTable>
            </div>
            <br />
          </div>
        )
      })
  }

  const renderRangeData = () => {
    return allRangeData.map((rdata, index) => {
      return (
        <Grid item xs={2}>
          <TextField
            id="outlined-basic"
            label={rdata}
            value={specificValuesFromTable[index]}
            InputLabelProps={{ shrink: true }}
            className="textfield"
            // onChange={(e) =>
            //     updateSpecificDataName(e.target.value, index)
            // }
            onChange={(e) => {
              setFilterRangeData([
                ...specificValuesFromTable,
                (specificValuesFromTable[index] = e.target.value),
              ])
            }}
            size="small"
            fullWidth
            variant="outlined"
          />
        </Grid>
      )
    })
  }

  function spaceStr(n) {
    return ' '.repeat(n)
  }

  function insertBreaks(element) {
    let currentPageHeight = 0;
    // this mawPageHeight depends of the dpi of your pdf, your format, etc.
    let maxPageHeight = 650;
    // select all elements from our created html element which contains our text
    let allElem = element.getElementsByTagName('*');

    // iterate through the elements to calculate the height and then avoid height repetition from 
    // elements within other elements by setting their height to 0
    for (let i = 2; i < allElem.length; i++) {

      // get the height of each element
      let lineHeight = allElem[i].offsetHeight;

      if (allElem[i].tagName === 'TBODY') {
        lineHeight = 0;
      }
      else if (allElem[i].tagName === 'UL') {
        lineHeight = 0;
      }
      else if (allElem[i].tagName === 'TABLE') {
        lineHeight = 0;
      }
      else if (allElem[i].tagName === 'DIV') {
        lineHeight = 0;
      }
      else if (allElem[i].tagName === 'TD') {
        lineHeight = 0;
      }
      else if (allElem[i].tagName === 'SPAN') {
        lineHeight = 0;
      }
      else if (allElem[i].tagName === 'STRONG') {
        lineHeight = 0;
      }
      else if (allElem[i].tagName === 'U') {
        lineHeight = 0;
      }
      else if (allElem[i].tagName === 'EM') {
        lineHeight = 0;
      }
      else if (allElem[i].tagName === 'P' && allElem[i].parentElement.tagName === 'TD') {
        lineHeight = 0;
      }

      // calculate the total height
      currentPageHeight = currentPageHeight + lineHeight;

      if (currentPageHeight > maxPageHeight) {
        currentPageHeight = 0;
        // insert an html page break when max height page is reached
        allElem[i].insertAdjacentHTML('beforebegin', '<div class="html2pdf__page-break"></div>');
      }
    }
    return element;
  }

  const generatePDF = () => {
    const element = printComponentRef.current
    // const element = insertBreaks(printComponentRef.current)
    html2pdf()
      .from(element)
      .set({
        margin: [155, 1, 140, 1],
        filename: `certificate_${certificateId}.pdf`,
        // pagebreak: {
        //   mode: ['avoid-all', 'css', 'legacy'],
        // },
        pagebreak: { after: 'section', mode: ['avoid-all', 'css', 'legacy'] },
        jsPDF: {
          orientation: 'portrait',
          unit: 'pt',
          format: 'a4',
        },
      })
      .toPdf()
      .get('pdf')
      .then(function (pdf) {
        const totalPages = pdf.internal.getNumberOfPages()
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i)
          pdf.setFontSize(9)
          pdf.setTextColor(0, 0, 0)
          pdf.setFont('Courier')

          // header section
          let fieldMaxLen = 23
          let fieldGap = 0
          let certNumber =
            certificateNumber ||
            generateNumber(
              settingList['Certificate Number'],
              ID_BASE + Number(certificateId),
            )

          let fields = [
            'ULR NO',
            'Cal. Cert. No',
            'Cert. Issue Date',
            'Cal. Date',
            'Cal. Due Date',
            'Page No.',
          ]
          let headerText =
            '\n_______________________________________________________________________________________________________\n'
          headerText += spaceStr(1)
          if (ulrNumber != null)
            headerText += fields[0] + spaceStr(fieldMaxLen - fields[0].length)

          headerText +=
            fields[1] + spaceStr(fieldMaxLen - fields[1].length + fieldGap)
          headerText += fields[2] + spaceStr(18 - fields[2].length + fieldGap)
          headerText += fields[3] + spaceStr(15 - fields[3].length + fieldGap)
          headerText += fields[4] + spaceStr(18 - fields[4].length + fieldGap)
          headerText += fields[5] + spaceStr(8 - fields[5].length + fieldGap)
          headerText += spaceStr(4)

          headerText += '\n'

          headerText += spaceStr(1)
          if (ulrNumber != null)
            headerText +=
              ulrNumber +
              spaceStr(fieldMaxLen - ulrNumber.length || 0 + fieldGap)

          headerText +=
            certificateNumber +
            spaceStr(fieldMaxLen - certificateNumber.length || 0 + fieldGap)
          headerText +=
            moment(dateOfIssue || new Date()).format('DD-MM-YYYY') +
            spaceStr(
              18 -
                moment(dateOfIssue || new Date()).format('DD-MM-YYYY')
                  ?.length || 0 + fieldGap,
            )
          headerText +=
            moment(calibrationDate || new Date()).format('DD-MM-YYYY') +
            spaceStr(
              15 -
                moment(calibrationDate || new Date()).format('DD-MM-YYYY')
                  ?.length || 0 + fieldGap,
            )
          headerText +=
            moment(nextDueDate || new Date()).format('DD-MM-YYYY') +
            spaceStr(
              18 -
                moment(nextDueDate || new Date()).format('DD-MM-YYYY')
                  ?.length || 0 + fieldGap,
            )
          headerText +=
            '' +
            i +
            ' / ' +
            totalPages +
            spaceStr(8 - fields[5].length + fieldGap)

          //  ${certNumber}      ${ulrNumber || ""}         ${moment(dateOfIssue).format('DD-MM-YYYY')}      |    ${moment(calibrationDate).format('DD-MM-YYYY')}   |      ${i} / ${totalPages}     |
          headerText +=
            '\n________________________________________________________________________________________________________'

          pdf.text(headerText, 17, 111)

          // footer section
          let footerText =
            '\n________________________________________________________________________________________________________'
          footerText +=
            '\n\n     Calibrated by' + spaceStr(60) + 'Quality manager'
          footerText += '\n' + spaceStr(78) + '(J Malhotra)'
          pdf.text(footerText, 18, 690)
        }
      })
      .save()
  }

  useEffect(() => {
    isPrinting && generatePDF()
  }, [isPrinting])
  // print view
  const printView = () => {
    return (
      <>
        <div id="printOnly2" ref={printComponentRef} className="printWrapper">
          <table style={{ padding: '10px' }}>
            <tbody>
              <tr>
                <td style={{ border: 'none' }}>
                  <div
                    class="content"
                    style={{
                      fontSize: '15px',
                      margin: '20px',
                      marginTop: '0px',
                      padding: '10px',
                      border: '1px solid black',
                    }}
                  >
                    <h3 style={{ textAlign: 'center' }}>
                      <b>Calibration Certificate</b>
                    </h3>
                    <div style={{ pageBreakAfter: 'always' }}>
                      <table style={{ width: '100%' }}>
                        <thead>
                          <tr>
                            <th rowspan="3" width="45%">
                              Customer Details : <br />
                              <span>
                                M/S.{clientId},{address}
                              </span>
                            </th>
                            <th colspan="4">Details Of Service Request Form </th>
                          </tr>
                          <tr>
                            <td>SRF No</td>
                            <td>SRF Date</td>
                            <td>Date Of Receipt Of Instrument</td>
                            <td>Job No</td>
                          </tr>
                          <tr>
                            <td>{serviceReqNumber}</td>
                            <td>{moment(dcDate).format('DD-MM-YYYY')}</td>
                            <td>{moment(receiptDate).format('DD-MM-YYYY')}</td>
                            <td>{dcNumber}</td>
                          </tr>
                        </thead>
                      </table>
                      <h6 style={{ width: '100%', textAlign: 'center' }}>
                        <b>DETAILS OF THE INSTRUMENT UNDER CALIBRATION</b>
                      </h6>
                      <hr style={{ marginTop: '-1px' }} />
                      <div className="row">
                        <div className="col-3 text-start">
                          <span>
                            <b>1. Instrument Name :</b>
                          </span>
                          <br />
                          <span>
                            <b>2. Make :</b>
                          </span>
                          <br />
                          <span>
                            <b>3. Model :</b>
                          </span>
                          <br />
                          <span>
                            <b>4. Serial Number  :</b>
                          </span>
                          <br />
                        </div>
                        <div className="col-3 text-start">
                          <span>
                            <b>{instrumentName}</b>
                          </span>
                          <br />
                          <span>{make}</span>
                          <br />
                          <span>{models}</span>
                          <br />
                          <span>{serialNo}</span>
                          <br />
                        </div>
                        <div className="col-3 text-start">
                          <span>
                            <b>5. Range :</b>
                          </span>
                          <br />
                          <span>
                            <b>6. Resolution/L/C :</b>
                          </span>
                          <br />
                          <span>
                            <b>7. Accuracy :</b>
                          </span>
                          <br />
                          <span style={{}}>
                            <b>8. UUC ID :</b>
                          </span>
                        </div>
                        <div className="col-3 text-start">
                          <span>{ranges}</span>
                          <br />
                          <span>{lc}</span>
                          <br />
                          <span>{accuracy}</span>
                          <br />
                          <span style={{}}>{DUCID}</span>
                        </div>
                      </div>
                      <hr
                        style={{
                          marginTop: '4px',
                          marginBottom: '4px',
                          paddingTop: '0px',
                          paddingBottom: '0px',
                        }}
                      />
                      <h6
                        className="text-start"
                        style={{
                          marginTop: '0px',
                          marginBottom: '0px',
                          paddingTop: '0px',
                          paddingBottom: '0px',
                        }}
                      >
                        <b>Inst. Condition at the time of Receipt :</b>{' '}
                        {ConOfDuc}{' '}
                      </h6>
                      <hr
                        style={{
                          marginTop: '4px',
                          marginBottom: '4px',
                          paddingTop: '0px',
                          paddingBottom: '0px',
                        }}
                      />
                      <div className="row">
                        <div className="col-6 text-start">
                          <h6>
                            <b>Calibration Carried Out At :</b> {location}
                          </h6>
                        </div>
                        <div className="col-6 text-start">
                          <h6>
                            <b>Calibration Method :</b> {calMethod}{' '}
                          </h6>
                        </div>
                      </div>
                      <hr
                        style={{
                          marginTop: '4px',
                          marginBottom: '4px',
                          paddingTop: '0px',
                          paddingBottom: '0px',
                        }}
                      />
                      <div className="text-start">
                        <h6>
                          <b>Calibration Procedure / IS Standards Used :</b>{' '}
                          {calibrationProcedureNo} / {referenceStandards}
                        </h6>
                      </div>
                      <hr
                        style={{
                          marginTop: '4px',
                          marginBottom: '4px',
                          paddingTop: '0px',
                          paddingBottom: '0px',
                        }}
                      />

                      <h6
                        style={{
                          fontWeight: 'bold',
                          marginRight: '3px',
                          width: '100%',
                          textAlign: 'center',
                        }}
                      >
                        ENVIRONMENTAL CONDITION AT TIME OF CALIBRATION
                      </h6>
                      <hr
                        style={{
                          marginTop: '4px',
                          marginBottom: '4px',
                          paddingTop: '0px',
                          paddingBottom: '0px',
                        }}
                      />
                      <div className="row">
                        <div className="col-6 text-start">
                          <span style={{}}>
                            <b>Temperature. :</b> {startTemp}°C,
                          </span>
                        </div>
                        <div className="col-6 text-end">
                          <span style={{ marginLeft: '4px' }}>
                            <b>Humidity :</b> ({endTemp} % RH)
                          </span>
                        </div>
                      </div>
                      <hr
                        style={{
                          marginTop: '4px',
                          marginBottom: '4px',
                          paddingTop: '0px',
                          paddingBottom: '0px',
                        }}
                      />
                      <h6 style={{ fontWeight: 'bold' }}>
                        DETAILS OF REFERENCE STANDARDS AND MAJOR EQUIPMENTS USED
                        FOR CALIBRATION
                      </h6>
                      <table style={{ width: '100%' }}>
                        <thead style={{ fontSize: '13px' }}>
                          <tr>
                            <th>S. No</th>
                            <th>Instrument name</th>
                            <th>Make / Model</th>
                            <th>S NO / Asset No</th>
                            <th>Traceble to NPL via Certificate No</th>
                            <th style={{}}>Calibrated By.</th>
                            <th style={{}}>Valid Upto</th>
                          </tr>
                        </thead>
                        {standardMasterArray.map((row, index) => (
                          <tbody key="sma">
                            <tr key={row.name}>
                              <td style={{ padding: '3px', width: '10px' }}>
                                {index + 1}
                              </td>
                              <td style={{ padding: '3px', width: '10px' }}>
                                {row.standardName}
                              </td>
                              <td style={{ padding: '3px', width: '10px' }}>
                                {row.make}
                              </td>
                              <td style={{ padding: '3px', width: '10px' }}>
                                MY41046838
                              </td>
                              <td style={{ padding: '3px', width: '10px' }}>
                                {row.certificateNumber}
                              </td>
                              <td style={{ padding: '3px', width: '10px' }}>
                                ANSHAANKAN
                              </td>
                              <td
                                style={{
                                  padding: '5px',

                                  width: '10px',
                                }}
                              >
                                {moment(row.validUpto).format('DD-MM-YYYY')}
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    </div>

                    <div style={{ padding: '10px' }}>
                      <div className="row text-start">
                        <span style={{ fontSize: '15px', fontWeight: 'bold' }}>
                          Discipline/Group : {disciplineName}
                        </span>
                      </div>
                      <hr />
                      <div style={{ fontSize: '10px' }}>
                        <div className="row">{renderStaticTables()}</div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-md-6">
                          <div className="">
                            <h5 style={{ float: 'left' }}>Remarks :</h5>
                            <br />
                            <br />
                          </div>
                        </div>
                      </div>

                      <div className="text-start" style={{}}>
                        <pre style={{ fontFamily: ' Calibri, sans-serif' }}>
                          {configuration?.remark || defaultReamrk}
                        </pre>
                      </div>
                      <div className="row mt-2 pt-5">
                        <h5
                          style={{
                            fontSize: '15px',
                            fontWeight: 'bold',
                            width: '100%',
                            textAlign: 'center',
                          }}
                        >
                          ----------END OF CALIBRATION CERTIFICATE----------
                        </h5>
                      </div>
                      <div className="row mt-2">
                        <div
                          className="col col-12"
                          style={{ textAlignLast: 'center' }}
                        >
                          <QRCode
                            value={`${DOMAIN}/#/certificate/viewCertificate/${certificateId}`}
                            align="center"
                            size={80}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    )
  }
  return (
    <>
      {!isPrinting && (
        <div id="screenOnly" sx={{ p: 2 }}>
          <Grid container spacing={2} sx={{ my: 2 }}>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Customer Name"
                className="textfield"
                size="small"
                value={clientId}
                disabled
                fullWidth
                variant="outlined"
                onChange={(e) => setClientId(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Work order number"
                className="textfield"
                size="small"
                value={workOrderNumber}
                onChange={(e) => setWorkOrderNumber(e.target.value)}
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="ULR Number"
                className="textfield"
                size="small"
                value={ulrNumber}
                onChange={(e) => setUlrNumber(e.target.value)}
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Certificate Number"
                className="textfield"
                size="small"
                value={
                  certificateNumber ||
                  generateNumber(
                    settingList['Certificate Number'],
                    ID_BASE + Number(certificateId),
                  )
                }
                onChange={(e) => setCertificateNumber(e.target.value)}
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="outlined-basic"
                label="Address"
                className="textfield"
                value={address}
                InputLabelProps={{ shrink: true }}
                disabled
                onChange={(e) => setAddress(e.target.value)}
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                InputLabelProps={{ shrink: true }}
                label="PO Number"
                className="textfield"
                size="small"
                value={poNo}
                onChange={(e) => setPoNo(e.target.value)}
                fullWidth
                variant="outlined"
              />
            </Grid>

            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="PO Date"
                  inputFormat="MM/dd/yyyy"
                  InputLabelProps={{ shrink: true }}
                  value={poDate ? poDate : new Date()}
                  onChange={(e) => setPoDate(e)}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Service Request No."
                InputLabelProps={{ shrink: true }}
                className="textfield"
                size="small"
                value={serviceReqNumber}
                onChange={(e) => setServiceReqNumber(e.target.value)}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Job Number."
                InputLabelProps={{ shrink: true }}
                className="textfield"
                size="small"
                value={jobNumber}
                onChange={(e) => setJobNumber(e.target.value)}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="DC Number"
                className="textfield"
                size="small"
                value={dcNumber}
                onChange={(e) => setDcNumber(e.target.value)}
                InputLabelProps={{ shrink: true }}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Date Of Issue"
                  inputFormat="dd/MM/yyyy"
                  value={dateOfIssue ? dateOfIssue : new Date()}
                  onChange={(e) => setDateOfIssue(e)}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Date of calibration"
                  InputLabelProps={{ shrink: true }}
                  inputFormat="dd/MM/yyyy"
                  value={calibrationDate ? calibrationDate : new Date()}
                  onChange={(e) => setCalibrationDate(e)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="textfield"
                      size="small"
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Next due date"
                  inputFormat="dd/MM/yyyy"
                  value={nextDueDate ? nextDueDate : new Date()}
                  onChange={(e) => setNextDueDate(e)}
                  InputLabelProps={{ shrink: true }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="textfield"
                      size="small"
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Date of receipt"
                  inputFormat="dd/MM/yyyy"
                  value={receiptDate ? receiptDate : new Date()}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setReceiptDate(e.target.value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="textfield"
                      size="small"
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Date of DC"
                  inputFormat="dd/MM/yyyy"
                  value={dcDate ? dcDate : new Date()}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setDcDate(e)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="textfield"
                      size="small"
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <hr className="textfield" />
          <Grid container spacing={2} sx={{ my: 2 }}>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Instrument Name"
                disabled
                size="small"
                className="textfield"
                InputLabelProps={{ shrink: true }}
                value={instrumentName}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Calibration Procedure No."
                value={calibrationProcedureNo}
                InputLabelProps={{ shrink: true }}
                className="textfield"
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Referencer Standards"
                size="small"
                InputLabelProps={{ shrink: true }}
                value={referenceStandards}
                className="textfield"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="DUC ID"
                value={DUCID}
                InputLabelProps={{ shrink: true }}
                className="textfield"
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Make"
                value={make}
                className="textfield"
                onChange={(e) => setMake(e.target.value)}
                size="small"
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Model"
                value={models}
                className="textfield"
                onChange={(e) => setModel(e.target.value)}
                size="small"
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Con Of Duc"
                value={ConOfDuc}
                className="textfield"
                onChange={(e) => setConOfDuc(e.target.value)}
                size="small"
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Range"
                value={ranges}
                onChange={(e) => setRanges(e.target.value)}
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                className="textfield"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="LC"
                value={lc}
                onChange={(e) => setLC(e.target.value)}
                className="textfield"
                InputLabelProps={{ shrink: true }}
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Serial Number"
                value={serialNo}
                onChange={(e) => setSerialNo(e.target.value)}
                className="textfield"
                InputLabelProps={{ shrink: true }}
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Location"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                className="textfield"
                InputLabelProps={{ shrink: true }}
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Accuracy"
                value={accuracy}
                onChange={(e) => setAccuracy(e.target.value)}
                className="textfield"
                InputLabelProps={{ shrink: true }}
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Cal Point"
                value={calPoint}
                disabled
                className="textfield"
                onChange={(e) => setCalPoint(e.target.value)}
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Cal Method"
                value={calMethod}
                disabled
                className="textfield"
                onChange={(e) => setCalMethod(e.target.value)}
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Location Of Instrument"
                value={locationOfInstrument}
                disabled
                className="textfield"
                onChange={(e) => setLocationOfInstrument(e.target.value)}
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                id="outlined-basic"
                InputLabelProps={{ shrink: true }}
                label="Temprature"
                value={startTemp}
                size="small"
                className="textfield"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                id="outlined-basic"
                InputLabelProps={{ shrink: true }}
                label="Humidity"
                className="textfield"
                value={endTemp}
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Atmospheric Pressure"
                value={atmosphericPressure}
                onChange={(e) => setAtmosphericPressure(e.target.value)}
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Discipline"
                disabled
                value={disciplineName}
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
          </Grid>

          <hr />
          <div>
            <Typography
              variant="h6"
              className="textfield"
              component="div"
              sx={{ mb: 2 }}
            >
              Master Equipments Used
            </Typography>
            <ClassicTable className="textfield">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Sr. No.</TableCell>
                    <TableCell>Name of Standard</TableCell>
                    <TableCell>Make/Model</TableCell>
                    <TableCell>Certificate No.</TableCell>
                    <TableCell>Tracability</TableCell>
                    <TableCell>Cal.dt</TableCell>
                    <TableCell>Due Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {standardMasterArray.map((row, index) => (
                    <TableRow key={row.name}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{row.standardName}</TableCell>
                      <TableCell>{row.make}</TableCell>
                      <TableCell>{row.certificateNumber}</TableCell>
                      <TableCell>{row.traceability}</TableCell>
                      <TableCell>
                        {row.calDate
                          ? moment(row.calDate).format('DD-MM-YYYY')
                          : ''}
                      </TableCell>
                      <TableCell>
                        {moment(row.validUpto).format('DD-MM-YYYY')}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </ClassicTable>
          </div>
          <br />
          <Grid container spacing={2} sx={{ my: 2 }}>
            {renderRangeData()}
          </Grid>
          <hr className="textfield" />
          <div className="textfield">
            <Typography variant="h6" className="textfield" component="div">
              Observed Readings
            </Typography>

            {renderStaticTables()}
          </div>
          <div className="row">
            <div className="col-md-6">
              <div>
                <h5 style={{ float: 'left' }}>Remarks :</h5>
                <br />
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={5}
                  placeholder="Enter remark here"
                  style={{ width: 900, float: 'left', border: 'none' }}
                  value={configuration?.remark || defaultReamrk}
                />
              </div>
            </div>
          </div>
          <hr className="textfield" />
          <Grid container spacing={2} sx={{ my: 2 }}>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Calibrated by"
                className="textfield"
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                className="textfield"
                label="Checked / Approved by"
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Issue Number"
                size="small"
                className="textfield"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="outlined-basic"
                label="Rev. Number"
                className="textfield"
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="outlined-basic"
                label="Form Number"
                className="textfield"
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Issue Date"
                  inputFormat="MM/dd/yyyy"
                  onChange={(e) => {
                    // console.log(e);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="textfield"
                      size="small"
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Revision Date"
                  inputFormat="MM/dd/yyyy"
                  onChange={(e) => {
                    // console.log(e);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="textfield"
                      size="small"
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <div
            align="right footer"
            className="textfield"
            style={{ marginTop: '20px' }}
          >
            <hr className="textfield" />
            <QRCode
              value={`${DOMAIN}/#/certificate/viewCertificate/${certificateId}`}
              align="center"
              size={80}
            />
          </div>
        </div>
      )}
      <Toolbar style={{ padding: '0px', width: '100%' }}>
        {userType != 3 && (
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            onClick={() => {
              submitCertificate()
            }}
          >
            Save
          </Button>
        )}
        <Button
          variant="contained"
          size="small"
          sx={{ ml: 3 }}
          onClick={() => {
            setisPrinting(true)
          }}
        >
          Print
        </Button>
      </Toolbar>

      {isPrinting && printView()}
    </>
  )
}
