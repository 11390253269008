import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import { useParams } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import { ClassicTable } from "../../utils/components/Styles";
const axios = require("axios");

export default function EditQuotation() {
  const [srfObject, createSrfObject] = React.useState({});
  var [ProductsList, setProductList] = React.useState([]);
  const [customer, setCustomer] = React.useState("");
  const [clientId, setClientId] = React.useState("");
  const [rate, setRate] = React.useState("");
  const [contact, setContact] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [productArray, setProducts] = React.useState([]);
  var [amount, setAmount] = React.useState(0);
  var [totalAmount, setTotal] = React.useState("");
  const [clientList, setClientList] = React.useState([]);
  const [clientArray, setCLientListArray] = React.useState([]);
  const [quotationsReadings, setQuotationsReadings] = React.useState([]);
  const [deletedIds, setDeletedIds] = React.useState([]);
  const [quotationNumber, setQuotationNumber] = React.useState("");
  const [referenceNumber, setReferenceNumber] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [date, setDate] = React.useState("");
  const [thankYouForEnquiry, setThankYouForEnquiry] = React.useState("");
  const [conveyanceCharges, setConveyanceCharges] = React.useState("");
  const [termsConditions, setTermsConditions] = React.useState("");
  const userType = localStorage.getItem("type");
  const [gstType, setGstType] = React.useState(null);
  const [cGst, setCGST] = React.useState(0);
  const [sGst, setSGST] = React.useState(0);
  const [clientName, setClientName] = React.useState("");
  const [totalAmt, setTotalAmt] = React.useState(0);
  const [qtId, setId] = React.useState(null);
  const params = useParams();
  console.log(totalAmount);
  const readingColumns = [
    [
      {
        field: "id",
        headerName: "Sr. No.",
        editable: false,
        width: 10,
      },
      {
        field: "srfInstrumentId",
        headerName: "Product Name",
        editable: true,
      },
      {
        field: "discription",
        headerName: "Discription",
        editable: true,
      },
      {
        field: "rate",
        headerName: "Rate",
        editable: true,
      },
      {
        field: "quantity",
        headerName: "Quantity",
        editable: true,
      },
      {
        field: "totalAmount",
        headerName: "Amount",
        editable: true,
      },
      {
        field: "action",
        headerName: "Action",
        editable: true,
      },
    ],
  ];

  const [readingRows, setReadingRows] = React.useState([["", "", "", "", ""]]);

  var refresh = () => {
    window.location.reload(false);
  };

  const handleSubmit = (event) => {
    var totalAmounts = 0;
    let rangeIds = [];
    if (readingRows.length) {
      readingRows.map((row) => {
        totalAmounts = totalAmounts + row[5];
      });
      var rows = readingRows.map((row) => ({
        srfInstrumentId: parseInt(row[1]),
        discription: row[2],
        rate: row[3],
        quantity: row[4],
        totalAmount: totalAmounts + parseInt(conveyanceCharges),
        quotationNumber: quotationNumber,
        clientId: parseInt(clientId),
        referenceNumber: referenceNumber,
        subject: subject,
        date: moment(date).format("YYYY-MM-DD"),
        thankYouForEnquiry: thankYouForEnquiry,
        termsConditions: termsConditions,
        conveyanceCharges: conveyanceCharges,
        gstType: gstType,
        id: Number(row[0]) && rangeIds.push(row[0]),
      }));
    }
    console.log(rows);

    rows.map((rows) => {
      if (rows.id == 0 && rows.id =='') {
        console.log(rows)
        let url = BASE_URL;
        axios
          .post(url + `quotation`, rows)
          .then((res) => {
            // toast("Quotation updated successfully !");
            // setTimeout(refresh, 500);
          })
          .catch((error) => {
            toast.error("Something Went Wrong!");
          });
      } else {
        // console.log(rows)
        let url = BASE_URL;
        axios
          .patch(url + `quotation/${qtId}`, rows)
          .then((res) => {
            toast("Quotation updated successfully !");
            setTimeout(refresh, 500);
          })
          .catch((error) => {
            // toast.error("Something Went Wrong!");
          });
      }
    });
  };

  const invoicesData = () => {
    if (gstType === 1) {
      return (
        <Grid
          container
          spacing={2}
          style={{ marginBottom: "10px", marginTop: "10px" }}
          justifyContent="flex-end"
        >
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="SGST"
              InputLabelProps={{ shrink: true }}
              value={(9 * totalAmount) / 100}
              disabled
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="CGST"
              disabled
              value={(9 * totalAmount) / 100}
              InputLabelProps={{ shrink: true }}
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
        </Grid>
      );
    } else if (gstType === 2) {
      return (
        <Grid
          container
          spacing={2}
          style={{ marginBottom: "10px", marginTop: "10px" }}
          justifyContent="flex-end"
        >
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="IGST"
              value={(18 * totalAmount) / 100}
              InputLabelProps={{ shrink: true }}
              disabled
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
        </Grid>
      );
    } else {
      <Grid
        container
        spacing={2}
        style={{ marginBottom: "10px", marginTop: "10px" }}
        justifyContent="flex-end"
      >
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="SGST"
            InputLabelProps={{ shrink: true }}
            value={0}
            disabled
            size="small"
            fullWidth
            variant="outlined"
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="CGST"
            disabled
            value={0}
            InputLabelProps={{ shrink: true }}
            size="small"
            fullWidth
            variant="outlined"
          />
        </Grid>
      </Grid>;
    }
  };

  // data operations
  const updateSrfObject = (key, value) => {
    let newSrfObject = {
      ...srfObject,
    };
    newSrfObject[key] = value;
    createSrfObject(newSrfObject);
  };

  const updateCellValue = (rowIndex, colIndex, value) => {
    const newRows = [...readingRows];
    newRows[rowIndex][colIndex] = value;
    setReadingRows([...newRows]);
  };

  const addDescriptionRow = () => {
    let newReadingRows = [...readingRows];
    newReadingRows.push(["", "", "", "", "", ""]);
    setReadingRows([...newReadingRows]);
  };

  const deleteReadingRow = (rowIndex) => {
    let newReadingRows = [...readingRows];
    if (newReadingRows[rowIndex] != undefined) {
      setDeletedIds([...deletedIds, newReadingRows[rowIndex][0]]);
      newReadingRows.splice(rowIndex, 1);
      setReadingRows([...newReadingRows]);
    }
  };

  const updateAmount = (value) => {
    var newRowsData = [...amount];
    newRowsData += parseInt(value);
    setAmount(...newRowsData);
  };

  const getCustomer = (event) => {
    let url = BASE_URL;
    axios
      .get(url + "clients?_where=(status,eq,1)")
      .then((res) => {
        setCustomer(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getQuotations = (event) => {
    let url = BASE_URL;
    axios
      .get(
        url +
          `xjoin?_join=qt.quotation,_j,client.clients&_on1=(qt.clientId,eq,client.id)&_fields=qt.id,qt.gstType,qt.productId,qt.quotationNumber,qt.referenceNumber,qt.date,qt.totalAmount,client.companyName,client.contact,client.address,client.id,qt.srfInstrumentId,qt.subject,qt.termsConditions,qt.thankYouForEnquiry,qt.conveyanceCharges&_where=(qt.clientId,eq, ${params.clientId})~and(qt.quotationNumber,eq,${params.quotationNumber})`
      )
      .then((res) => {
        console.log(res.data)
        setId(res.data[0].qt_id);
        setClientId(res.data[0].client_id);
        setConveyanceCharges(res.data[0].qt_conveyanceCharges);
        setQuotationNumber(res.data[0].qt_quotationNumber);
        setReferenceNumber(res.data[0].qt_referenceNumber);
        setSubject(res.data[0].qt_subject);
        setDate(res.data[0].qt_date);
        setThankYouForEnquiry(res.data[0].qt_thankYouForEnquiry);
        setTermsConditions(res.data[0].qt_termsConditions);
        setClientName(
          res.data[0].client_id + ", " + res.data[0].client_companyName
        );
        setContact(res.data[0].client_contact);
        setAddress(res.data[0].client_address);
        setTotal(res.data[0].qt_totalAmount);
        setGstType(res.data[0].qt_gstType);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getQuotationsReadings = (event) => {
    let url = BASE_URL;
    axios
      .get(
        url +
          `xjoin?_join=qt.quotation,_j,client.clients&_on1=(qt.clientId,eq,client.id)&_fields=qt.id,qt.productId,qt.quotationNumber,qt.referenceNumber,qt.date,qt.totalAmount,client.companyName,client.contact,qt.srfInstrumentId,qt.rate,qt.quantity,qt.discription&_where=(qt.clientId,eq, ${params.clientId})~and(qt.quotationNumber,eq,${params.quotationNumber})`
      )
      .then((res) => {
        setQuotationsReadings(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const initiateRanges = () => {
    let rows = [];
    for (let i = 0; i < quotationsReadings.length; i++) {
      rows.push([
        quotationsReadings[i].qt_id,
        quotationsReadings[i].qt_srfInstrumentId,
        quotationsReadings[i].qt_discription,
        quotationsReadings[i].qt_rate,
        quotationsReadings[i].qt_quantity,
        quotationsReadings[i].qt_rate * quotationsReadings[i].qt_quantity,
      ]);
    }
    setReadingRows(rows);
  };

  const initiateClients = () => {
    var pushArray = [];
    var pushCArray = [];

    for (let i = 0; i < customer.length; i++) {
      pushArray.push({
        id: customer[i].id,
        label: customer[i].companyName,
        contact: customer[i].contact,
        address: customer[i].address,
      });
      pushCArray.push({
        label: customer[i].id + ", " + customer[i].companyName,
      });
    }
    setClientList(pushArray);
    setCLientListArray(pushCArray);
  };

  const getProductList = () => {
    let url = BASE_URL;
    axios
      .get(url + "customProducts")
      .then((res) => {
        setProductList(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const initiateProducts = () => {
    let productsArray = [];

    for (let i = 0; i < ProductsList.length; i++) {
      productsArray.push({
        label: ProductsList[i].id + "," + ProductsList[i].productName,
        rate: ProductsList[i].rate,
        key: ProductsList[i].id,
        name: ProductsList[i].productName,
      });
    }
    setProducts(productsArray);
  };

  var refresh = () => {
    window.location.reload(false);
    // <Link to="/master/standard">Home</Link>
  };

  useEffect(() => {
    initiateRanges();
  }, [quotationsReadings]);

  useEffect(() => {
    initiateProducts();
  }, [ProductsList]);

  useEffect(() => {
    initiateClients();
  }, [customer]);

  useEffect(() => {
    getQuotationsReadings();
    getQuotations();
    getCustomer();
    getProductList();
  }, []);

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Update Quotation
      </Typography>
      <Grid container spacing={2} style={{ marginBottom: "10px" }}>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={clientArray}
            value={clientName}
            renderInput={(params) => (
              <TextField {...params} label="Company Name *" />
            )}
            onInputChange={(event, value) => {
              clientList.map((client) => {
                if (client.id === parseInt(value.split(",")[0])) {
                  if (client.address && client.contact) {
                    setClientId(client.id);
                    setAddress(client.address);
                    setContact(client.contact);
                  } else if (client.address && !client.contact) {
                    setClientId(client.id);
                    setAddress(client.address);
                    setContact("");
                  } else if (client.contact && !client.address) {
                    setClientId(client.id);
                    setContact(client.contact);
                    setAddress("");
                  } else {
                    setAddress("");
                    setContact("");
                  }
                }
              });
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Company Number *"
            size="small"
            // value={contact}
            disabled
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Address"
            size="small"
            disabled
            value={address}
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Quotation Number *"
            size="small"
            value={quotationNumber}
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setQuotationNumber(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Reference Number *"
            size="small"
            value={referenceNumber}
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            onChange={(e) => {
              setReferenceNumber(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Subject"
            size="small"
            value={subject}
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setSubject(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Date *"
              inputFormat="dd/MM/yyyy"
              InputLabelProps={{ shrink: true }}
              value={date ? date : new Date()}
              onChange={(newValue) => {
                setDate(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Thank you for enquiry"
            size="small"
            InputLabelProps={{ shrink: true }}
            value={thankYouForEnquiry}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setThankYouForEnquiry(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Terms Conditions"
            InputLabelProps={{ shrink: true }}
            fullWidth
            rows={3}
            multiline
            value={termsConditions}
            variant="outlined"
            maxRows={5}
            onChange={(e) => {
              setTermsConditions(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            value={
              gstType == 1
                ? "State GST"
                : gstType == 2
                ? "Central Gst"
                : "Non Gst"
            }
            options={[
              { value: 1, label: "State GST" },
              { value: 2, label: "Central Gst" },
              { value: 3, label: "Non Gst" },
            ]}
            renderInput={(params) => (
              <TextField {...params} label="GST Type *" />
            )}
            onInputChange={(event, newInputValue) => {
              if (newInputValue == "State GST") {
                setGstType(1);
              } else if (newInputValue == "Central Gst") {
                setGstType(2);
              } else {
                setGstType(3);
              }
              if (newInputValue == "State GST") {
                setCGST((9 * totalAmount) / 100);
                setSGST((9 * totalAmount) / 100);
              } else if (newInputValue == "Central Gst") {
                setCGST((18 * totalAmount) / 100);
              } else {
                setCGST(0);
                setSGST(0);
              }
            }}
          />
        </Grid>
      </Grid>
      <hr />
      <h4 style={{ "margin-bottom": "0px" }}>Product Details </h4>
      <Button
        style={{ float: "right" }}
        onClick={(e) => {
          addDescriptionRow(0);
        }}
      >
        <AddIcon />
      </Button>
      <div style={{ width: "100%", overflow: "auto" }}>
        <ClassicTable>
          <Table sx={{ minWidth: 660 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {readingColumns[0].map((column) => (
                  <TableCell key={column.field}>
                    {" "}
                    <Typography noWrap>{column.headerName}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {readingRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  {row.map((cell, cellIndex) => {
                    console.log("cellIndex", cellIndex);
                    if (cellIndex == 1) {
                      return (
                        <TableCell style={{ width: "200px" }}>
                          <Autocomplete
                            size="small"
                            id="combo-box-demo"
                            options={productArray}
                            value={
                              productArray.find(
                                (element) =>
                                  element.key == readingRows[index][cellIndex]
                              )?.name !== undefined ?
                              readingRows[index][cellIndex] +
                              "," +
                              productArray.find(
                                (element) =>
                                  element.key == readingRows[index][cellIndex]
                              )?.name:''
                            }
                            renderInput={(params) => <TextField {...params} />}
                            onChange={(event, value) => {
                              if (value != null) {
                                updateCellValue(
                                  index,
                                  cellIndex,
                                  parseInt(value.label.split(",")[0])
                                );
                                updateCellValue(index, 3, value.rate);
                              }
                            }}
                          />
                        </TableCell>
                      );
                    }
                    if (cellIndex == 2) {
                      return (
                        <TableCell style={{ width: "200px" }}>
                          <Grid item xs={3}>
                            <TextField
                              id="outlined-basic"
                              size="small"
                              fullWidth
                              value={readingRows[index][cellIndex]}
                              InputLabelProps={{ shrink: true }}
                              variant="outlined"
                              onChange={(e) => {
                                updateCellValue(
                                  index,
                                  cellIndex,
                                  e.target.value
                                );
                              }}
                            />
                          </Grid>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 3) {
                      return (
                        <TableCell style={{ width: "200px" }}>
                          <Grid item xs={3}>
                            <TextField
                              id="outlined-basic"
                              size="small"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              value={readingRows[index][cellIndex]}
                              variant="outlined"
                              // onChange={(e) => {
                              //   updateCellValue(index, cellIndex, rate);
                              // }}
                            />
                          </Grid>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 4) {
                      return (
                        <TableCell style={{ width: "200px" }}>
                          <Grid item xs={3}>
                            <TextField
                              id="outlined-basic"
                              size="small"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              variant="outlined"
                              value={readingRows[index][cellIndex]}
                              onChange={(e) => {
                                if (e.target.value) {
                                  setTotal(
                                    parseInt(readingRows[index][3]) *
                                      parseInt(e.target.value)
                                  );
                                  updateCellValue(
                                    index,
                                    cellIndex,
                                    parseInt(e.target.value)
                                  );
                                  updateCellValue(
                                    index,
                                    5,
                                    parseInt(readingRows[index][3]) *
                                      parseInt(e.target.value)
                                  );
                                }
                              }}
                            />
                          </Grid>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 5) {
                      return (
                        <TableCell style={{ width: "200px" }}>
                          <Grid item xs={3}>
                            <TextField
                              id="outlined-basic"
                              size="small"
                              fullWidth
                              // disabled
                              InputLabelProps={{ shrink: true }}
                              value={readingRows[index][cellIndex]}
                              variant="outlined"
                              onChange={(e) => {
                                setTotal(
                                  parseInt(amount) + parseInt(e.target.value)
                                );
                                updateCellValue(
                                  index,
                                  cellIndex,
                                  parseInt(amount) + parseInt(e.target.value)
                                );
                              }}
                            />
                          </Grid>
                        </TableCell>
                      );
                    }
                  })}
                  <TableCell>
                    <DeleteIcon
                      style={{ color: "#dc3545" }}
                      onClick={(e) => {
                        deleteReadingRow(0, index);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
              <br />
            </TableBody>
          </Table>
        </ClassicTable>
      </div>
      <Grid
        container
        spacing={2}
        style={{ marginBottom: "10px", marginLeft: "74.5%" }}
      >
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Conveyance Charges"
            size="small"
            value={conveyanceCharges}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setConveyanceCharges(parseInt(e.target.value));
            }}
          />
        </Grid>
      </Grid>
      {invoicesData()}
      <br />
      <Grid
        container
        spacing={2}
        style={{ marginBottom: "10px", marginLeft: "74.5%" }}
      >
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Total Amount"
            size="small"
            value={totalAmount}
            fullWidth
            variant="outlined"
          />
        </Grid>
      </Grid>
      <br />

      <Toolbar style={{ padding: "0px", overflow: "auto" }}>
        {userType !== "3" ? (
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            onClick={() => {
              gstType != null
                ? handleSubmit()
                : toast.error("Please select GST Type!");
            }}
          >
            Save
          </Button>
        ) : (
          console.log("")
        )}
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0, ml: 2 }}
          onClick={() => {
            // handleSubmit();
          }}
        >
          Print
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
