import React, { useEffect } from "react";
import { BASE_URL, ID_BASE, SRF_ID_BASE } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import * as XLSX from "xlsx";
import {generateNumber} from "./../../utils/utils"

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextareaAutosize,
  Stack,
  Input,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { ClassicTable } from "./../../utils/components/Styles";

const axios = require("axios");

export default function CreateSRF(props) {
  const [srfObject, createSrfObject] = React.useState({});
  var [clientArray, setClientArray] = React.useState([]);
  var [instrumentArray, setInstrumentArray] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [instrumentList, setInstrumentList] = React.useState([]);
  const [image, setImage] = React.useState("");
  const [unitArray, setUnitArray] = React.useState([]);
  const [unitFilter, setUnitFilter] = React.useState([]);
  const [readingRangeValue, setReadingRangeValue] = React.useState([
    ["", "", "", "", "", "", "", "", "", "", ""],
  ]);
  const handleChange = async (e) => {
    setImage(e.target.files[0]);
  };

  const updateRangeCellValue = (rowIndex, colIndex, value) => {
    const newRowss = [...readingRangeValue];
    newRowss[rowIndex][colIndex] = value;
    setReadingRangeValue([...newRowss]);
  };
  const getInstrumentsList = () => {
    let url = BASE_URL;
    axios
      .get(url + "instruments?_where=(status,eq,1)")
      .then((res) => {
        setInstrumentArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const getClientIdList = () => {
    let url = BASE_URL;
    axios
      .get(url + "clients?_where=(status,eq,1)")
      .then((res) => {
        setClientArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const initiateInstrumentList = () => {
    var pushIArray = [];
    for (let i = 0; i < instrumentArray.length; i++) {
      if (instrumentArray[i].instrumentName) {
        pushIArray.push({
          label:
            instrumentArray[i].id + ", " + instrumentArray[i].instrumentName,
          id: instrumentArray[i].id,
        });
      }
    }
    setInstrumentList(pushIArray);
  };

  var pushCArray = [];
  var clientsArrays = [];
  for (let i = 0; i < clientArray.length; i++) {
    if (clientArray[i].companyName) {
      pushCArray.push({
        label: clientArray[i].id + ", " + clientArray[i].companyName,
      });
    }
    clientsArrays.push({
      id: clientArray[i].id,
      address: clientArray[i].address,
    });
  }



  const readingColumns = [
    [
      {
        field: "id",
        headerName: "Sr. No.",
        editable: false,
      },
      {
        field: "description",
        headerName: "Instruments",
        editable: true,
      },
      {
        field: "range",
        headerName: "DUC Range",
        editable: true,
      },
      {
        field: "lc",
        headerName: "Least Count",
        editable: true,
      },
      {
        field: "serialNumber",
        headerName: "Serial number",
        editable: true,
      },
      {
        field: "make",
        headerName: "DUC Make",
        editable: true,
      },
      {
        field: "model",
        headerName: "Model",
        editable: true,
      },
      {
        field: "ducID",
        headerName: "DUCID",
        editable: true,
      },
      {
        field: "conOfDuc",
        headerName: "Con. Of Duc",
        editable: true,
      },
      {
        field: "calibrationFrequency",
        headerName: "Calibration frequency",
        editable: true,
      },
      {
        field: "accuracy",
        headerName: "Accuracy",
        editable: true,
      },
      {
        field: "calibrationType",
        headerName: "Calibration Type",
        editable: true,
      },
      {
        field: "location",
        headerName: "Location",
        editable: true,
      },
      {
        field: "locationOfInstrument",
        headerName: "Location Of Instrument",
        editable: true,
      },
      {
        field: "calPoint",
        headerName: "Calibration Point",
        editable: true,
      },
      {
        field: "calMethod",
        headerName: "Calibration Method",
        editable: true,
      },
      {
        field: "labCapabilities",
        headerName: "Lab Capabilities",
        editable: true,
      },
    ],
  ];

  const [readingRows, setReadingRows] = React.useState([]);

  const onChange = (e) => {
    const [file] = e.target.files;
    const reader = new FileReader();

    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      let rows = data.split("\n");
      if (rows.length > 2) rows.shift();
      let finalRows = [];
      rows.forEach((row) => {
        finalRows.push(row.split(","));
      });
      console.log(finalRows);
      setReadingRows(finalRows);
    };
    reader.readAsBinaryString(file);
  };

  // submit form
  const submitSrfInstruments = (readingRows, ids) => {
    let rows = readingRows.map((row, id) => ({
      // srnNo: `${parseInt(srfObject.serviceReqNumber)}/${id + 1}`,
      srfsId: ids,
      instrumentId: row[0],
      ranges: row[1],
      lc: row[2],
      serialNumber: row[3],
      make: row[4],
      model: row[5],
      DUCID: row[6],
      ConOfDuc: row[7],
      calFrequency: row[8],
      accuracy: row[9],
      calibrationType: row[10],
      location: row[11],
      locationOfInstrument: row[12],
      calPoint: row[13],
      calMethod: row[14],
      labCapabilities: row[15],
    }));
    rows.map((rows) => {
      let url = BASE_URL;
      axios
        .post(url + "srfInstruments", rows)
        .then((res) => {
          // toast("SRF created successfully !");
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    });
    if (image != "") {
      //call the api
      const url =
        "http://login.globalcalibration.com/api/fileUpload/fileUpload.php";

      const data = new FormData();
      data.append("fileToUpload", image);
      data.append("submit", true);
      axios.post(url, data).then((res) => {
        if (res.status == 200) {
          toast("File Uploaded successfully !");
          setTimeout(refresh, 500);
        }
        // then print response status
        // this.setState({ activeDocPath: res.data }, this.onSubmit);
      });
    }
  };

  const submitDatasheets = (readingRows, row, srfId) => {
    let rows = readingRows.map((row, id) => ({
      // srnNo: `100/${id + 1}`,
      srfInstrumentId: row[0],
      receiptDate: moment(srfObject.receiptDate).format("YYYY-MM-DD"),
      clientId: srfObject.clientId,
      poNumber: srfObject.poNumber,
      poDate: moment(srfObject.poDate).format("YYYY-MM-DD"),
      instrumentId: row[0],
      address: address,
      serviceReqNumber: srfObject.serviceReqNumber,
      jobNumber: ""+(SRF_ID_BASE + Number(srfId)) + String.fromCharCode(65 + id),
      ranges: row[1],
      dcNumber: srfObject.poNumber,
      DCDate: moment(srfObject.dcDate).format("YYYY-MM-DD"),
      lc: row[2],
      serialNumber: row[3],
      make: row[4],
      model: row[5],
      DUCID: row[6],
      ConOfDuc: row[7],
      accuracy: row[9],
      calibrationType: row[10],
      location: row[11],
      locationOfInstrument: row[12],
      calPoint: row[13],
      calMethod: row[14],
    }));


    rows.map((rows) => {
      let url = BASE_URL;
      axios
        .post(url + "datasheets", rows)
        .then((res) => {
          console.log('submit callll')
          // toast("datasheets created successfully !");
          
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    });
  };
  const submitCertificate = (readingRows, row, srfId) => {

    let rows = readingRows.map((row, id) => ({
      // srnNo: `100/${id + 1}`,
      srfInstrumentId: row[0],
      receiptDate: moment(srfObject.receiptDate).format("YYYY-MM-DD"),
      clientId: srfObject.clientId,
      poNumber: srfObject.poNumber,
      poDate: moment(srfObject.poDate).format("YYYY-MM-DD"),
      instrumentId: row[0],
      address: address,
      serviceReqNumber: generateNumber("", (SRF_ID_BASE + Number(srfId))),
      jobNumber: ""+(SRF_ID_BASE + Number(srfId)) + String.fromCharCode(65 + id),
      workOrderNumber:  (ID_BASE + Number(srfId))+"/WO",
      ranges: row[1],
      DCDate: moment(srfObject.dcDate).format("YYYY-MM-DD"),
      lc: row[2],
      serialNumber: row[3],
      make: row[4],
      model: row[5],
      DUCID: row[6],
      ConOfDuc: row[7],
      accuracy: row[9],
      calibrationType: row[10],
      location: row[11],
      locationOfInstrument: row[12],
      calPoint: row[13],
      calMethod: row[14],
    }));
    rows.map((rows) => {
      let url = BASE_URL;
      axios
        .post(url + "certificates", rows)
        .then((res) => {
          // toast("SRF created successfully !");
          
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    });
  };

  const submitInvoice = (readingRows, row, srfId) => {
    let rowsd = [];
    let rows = readingRows.map((row) => ({
      srfsId: srfId,
      clientId: srfObject.clientId,
      srfInstrumentId: row[0],
      instrumentId: row[0],
      poNumber: srfObject.poNumber,
      billingId: srfObject.billingId,
      poDate: srfObject.poDate
        ? moment(srfObject.poDate).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),
    }));

    for (let i = 0; i < readingRows.length; i++) {
      rowsd.push([readingRows[i][0]]);
    }

    const newArr = [];
    for (let i = 0; i < rowsd.length; i++) {
      newArr.push(rowsd[i][0]);
    }

    const count = {};
    newArr.forEach((element) => {
      count[element] = (count[element] || 0) + 1;
    });
    var countArray = [];
    for (const key in count) {
      if (Object.hasOwnProperty.call(count, key)) {
        countArray.push(key + ":" + count[key]);
      }
    }

    const newArrs = [];
    for (let i = 0; i < countArray.length; i++) {
      newArrs.push({
        instrumentId: parseInt(countArray[i].split(":")[0]),
        instrumentCount: parseInt(countArray[i].split(":")[1]),
      });
    }
    var finalArray = [];
    for (let i = 0; i < rows.length; i++) {
      for (let j = 0; j < newArrs.length; j++) {
        if (rows[i].instrumentId == newArrs[j].instrumentId) {
          finalArray.push({
            billingId: rows[i].billingId,
            clientId: rows[i].clientId,
            instrumentId: rows[i].instrumentId,
            poDate: rows[i].poDate
              ? moment(rows[i].poDate).format("YYYY-MM-DD")
              : moment(new Date()).format("YYYY-MM-DD"),
            poNumber: rows[i].poNumber,
            srfInstrumentId: rows[i].srfInstrumentId,
            srfsId: rows[i].srfsId,
            instrumentDuplicateCount: newArrs[j].instrumentCount,
          });
        }
      }
    }
    const removeDuplicates = finalArray.filter(
      (v, i, a) => a.findIndex((v2) => v2.instrumentId === v.instrumentId) === i
    );
    removeDuplicates.map((rows) => {
      let url = BASE_URL;
      axios
        .post(url + "invoices", rows)
        .then((res) => {
          props.setLoader(false);
          // toast("SRF created successfully !");
          // setTimeout(refresh, 1000);
        })
        .catch((error) => {
          props.setLoader(false);
          toast.error("Something Went Wrong!");
        });
    });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const handleSubmit = (event) => {
    let row = {
      clientId: srfObject.clientId,
      receiptDate: moment(srfObject.receiptDate).format("YYYY-MM-DD"),
      dcNumber: srfObject.dcNumber,
      dcDate: moment(srfObject.dcDate).format("YYYY-MM-DD"),
      entryDate: moment(srfObject.entryDate).format("YYYY-MM-DD"),
      committedDeliveryDate: moment(srfObject.committedDeliveryDate).format(
        "YYYY-MM-DD"
      ),
      serviceReqNumber: srfObject.serviceReqNumber,
      description: srfObject.description,
      address: address,
      poNumber: srfObject.poNumber,
      poDate: moment(srfObject.poDate).format("YYYY-MM-DD"),
      billingId: srfObject.billingId,
      jobNumber: srfObject.jobNumber
    };


    let url = BASE_URL;
    axios
      .post(url + "srfs", row)
      .then((res) => {
        // toast("SRF created successfully !");
        submitSrfInstruments(readingRows, res.data.insertId);
        submitDatasheets(readingRows, row, res.data.insertId);
        submitCertificate(readingRows, row, res.data.insertId);
        submitInvoice(readingRows, row, res.data.insertId);
        toast("SRF created successfully !");
        // setTimeout(refresh, 1000);
        // props.setLoader(false);
      })
      .catch((error) => {
        // props.setLoader(false);
        toast.error("Something Went Wrong!");
      });
  };

  //ToDo: fetch cutomers from database

  // data operations
  const updateSrfObject = (key, value) => {
    let newSrfObject = {
      ...srfObject,
    };
    newSrfObject[key] = value;
    createSrfObject(newSrfObject);
  };
  const updateCellValue = (rowIndex, colIndex, value) => {
    const newRows = [...readingRows];
    newRows[rowIndex][colIndex] = value;
    setReadingRows([...newRows]);
  };

  const addDescriptionRow = (tableIndex) => {
    let newReadingRows = [...readingRows];
    newReadingRows.push([
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ]);
    setReadingRows([...newReadingRows]);
  };

  const deleteReadingRow = (tableIndex, rowIndex) => {
    let newReadingRows = [...readingRows];
    newReadingRows.splice(rowIndex, 1);
    setReadingRows([...newReadingRows]);
  };


  const getInstrument = (id) => {
    for (let instrument in instrumentList) {
      if (instrumentList[instrument].id == id)
        return instrumentList[instrument];
    }
    return null;
  };

  
  const getUnitMasterArray = () => {
    let url = BASE_URL;
    axios
      .get(url + "unit?_where=(status,eq,1)")
      .then((res) => {
        setUnitArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };


  const UnitList = () => {
    var uArr = [];
    for (let i = 0; i < unitArray.length; i++) {
      uArr.push({
        label: unitArray[i].id + ", " + unitArray[i].symbol,
      });
    }
    setUnitFilter(uArr);
  };

  useEffect(() => {
    getInstrumentsList();
    getClientIdList();
    getUnitMasterArray()
  }, []);

  useEffect(() => {
    UnitList();
  }, [unitArray]);

  useEffect(() => {
    initiateInstrumentList();
  }, [instrumentArray]);

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Create SRF
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={pushCArray}
            renderInput={(params) => (
              <TextField {...params} label="Company Name *" />
            )}
            onInputChange={(event, newInputValue) => {
              updateSrfObject(
                "clientId",
                parseInt(newInputValue.split(",")[0])
              );
              clientsArrays.map((client) => {
                if (client.id === parseInt(newInputValue.split(",")[0])) {
                  if (client.address) {
                    setAddress(client.address);
                  } else {
                    setAddress("");
                  }
                }
              });
            }}
          />
        </Grid>

        <Grid item xs={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Receipt Date *"
              inputFormat="dd/MM/yyyy"
              value={srfObject.receiptDate ? srfObject.receiptDate : new Date()}
              onChange={(newValue) => {
                updateSrfObject("receiptDate", newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="outlined-basic"
            label="Dc No./RGP No. *"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("dcNumber", e.target.value);
            }}
          />
        </Grid>

        <Grid item xs={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="DC Dt./RGP Dt. *"
              value={srfObject.dcDate ? srfObject.dcDate : new Date()}
              inputFormat="dd/MM/yyyy"
              onChange={(newValue) => {
                updateSrfObject("dcDate", newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Entry Date *"
              value={srfObject.entryDate ? srfObject.entryDate : new Date()}
              inputFormat="dd/MM/yyyy"
              onChange={(newValue) => {
                updateSrfObject("entryDate", newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Committed delivery date *"
              value={
                srfObject.committedDeliveryDate
                  ? srfObject.committedDeliveryDate
                  : new Date()
              }
              inputFormat="dd/MM/yyyy"
              onChange={(newValue) => {
                updateSrfObject("committedDeliveryDate", newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="outlined-basic"
            label="Service request number *"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("serviceReqNumber", e.target.value);
            }}
          />
        </Grid>
       
        <Grid item xs={2}>
          <TextField
            id="outlined-basic"
            label="Address"
            size="small"
            disabled
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={address}
            variant="outlined"
            onChange={(e) => {
              setAddress(e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <TextField
            id="outlined-basic"
            label="PO Number *"
            size="small"
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("poNumber", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="PO Date *"
              value={srfObject.poDate ? srfObject.poDate : new Date()}
              inputFormat="dd/MM/yyyy"
              onChange={(newValue) => {
                updateSrfObject("poDate", newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={pushCArray}
            renderInput={(params) => (
              <TextField {...params} label="Billing Name *" />
            )}
            onInputChange={(event, newInputValue) => {
              updateSrfObject(
                "billingId",
                parseInt(newInputValue.split(",")[0])
              );
            }}
          />
        </Grid>
      </Grid>
      <br />
      <hr />
      <h4 style={{ "margin-bottom": "0px" }}>DUC Inward Details</h4>
      <Button
        style={{ float: "left" }}
        onClick={(e) => {
          addDescriptionRow(0);
        }}
      >
        <b style={{ fontSize: "18px" }}>ADD</b>
        <AddIcon />
      </Button>
      <div style={{ width: "102%", overflow: "auto" }}>
        <ClassicTable>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Actions</TableCell>
                {readingColumns[0].map((column) => (
                  <TableCell key={column.field}>
                    {" "}
                    <Typography noWrap>{column.headerName}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {readingRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <DeleteIcon
                      style={{ color: "#dc3545" }}
                      onClick={(e) => {
                        deleteReadingRow(0, index);
                      }}
                    />
                  </TableCell>
                  <TableCell>{index + 1}</TableCell>
                  {row.map((cell, cellIndex) => {
                    if (
                      cellIndex != 0 &&
                      cellIndex != 1 &&
                      cellIndex != 2 &&
                      cellIndex != 9 &&
                      cellIndex != 10 &&
                      cellIndex != 11 &&
                      cellIndex != 8 &&
                      cellIndex != 13 &&
                      cellIndex != 14
                    ) {
                      return (
                        <TableCell>
                          <TextField
                            size="small"
                            value={cell}
                            onChange={(e) => {
                              updateCellValue(index, cellIndex, e.target.value);
                            }}
                          ></TextField>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 0) {
                      return (
                        <TableCell style={{ width: "200px" }}>
                          <Autocomplete
                            defaultValue={getInstrument(cell)}
                            size="small"
                            id="combo-box-demo"
                            options={instrumentList}
                            renderInput={(params) => <TextField {...params} />}
                            onInputChange={(event, newInputValue) => {
                              var splArr = newInputValue.split(",");
                              updateCellValue(
                                index,
                                cellIndex,
                                parseInt(splArr[0])
                              );
                            }}
                          />
                        </TableCell>
                      );
                    }
                    if(cellIndex == 1){
                      return(
                        <TableCell>
                          <TextField
                            size="small"
                            value={cell}
                            onChange={(e) => {
                              updateCellValue(index, cellIndex, e.target.value);
                            }}
                          ></TextField>
                          <select
                            styles={{ width: "100%" }}
                            onChange={(e) => {
                              updateRangeCellValue(
                                index,
                                cellIndex,
                                e.target.value
                              );
                            }}
                          >
                            {unitArray.map((option) => (
                              <option value={option.symbol}>
                                {option.symbol}
                              </option>
                            ))}
                          </select>
                        </TableCell>
                      )
                      
                    }
                    if(cellIndex == 2){
                      return(
                        <TableCell>
                          <TextField
                            size="small"
                            value={cell}
                            onChange={(e) => {
                              updateCellValue(index, cellIndex, e.target.value);
                            }}
                          ></TextField>
                          <select
                            styles={{ width: "100%" }}
                            onChange={(e) => {
                              updateRangeCellValue(
                                index,
                                cellIndex,
                                e.target.value
                              );
                            }}
                          >
                            {unitArray.map((option) => (
                              <option value={option.symbol}>
                                {option.symbol}
                              </option>
                            ))}
                          </select>
                        </TableCell>
                      )                      
                    }
                    if(cellIndex == 9){
                      return(
                        <TableCell>
                          <TextField
                            size="small"
                            value={cell}
                            onChange={(e) => {
                              updateCellValue(index, cellIndex, e.target.value);
                            }}
                          ></TextField>
                          <select
                            styles={{ width: "100%" }}
                            onChange={(e) => {
                              updateRangeCellValue(
                                index,
                                cellIndex,
                                e.target.value
                              );
                            }}
                          >
                            {unitArray.map((option) => (
                              <option value={option.symbol}>
                                {option.symbol}
                              </option>
                            ))}
                          </select>
                        </TableCell>
                      )                      
                    }
                    if (cellIndex == 8) {
                      return (
                        <TableCell>
                          <Grid item xs={3}>
                            <Autocomplete
                              size="small"
                              id="combo-box-demo"
                              options={[
                                { id: 1, label: "3 Months" },
                                { id: 2, label: "6 Months" },
                                { id: 3, label: "12 Months" },
                                { id: 3, label: "18 Months" },
                                { id: 4, label: "24 Months" },
                              ]}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              onInputChange={(event, newInputValue) => {
                                if (newInputValue === "3 Months") {
                                  updateCellValue(index, cellIndex, "3 Months");
                                  // changeCertificateStatus(3 + ":" + result.toString());
                                } else if (newInputValue === "6 Months") {
                                  updateCellValue(index, cellIndex, "6 Months");
                                  // changeCertificateStatus(4 + ":" + result.toString());
                                } else if (newInputValue === "12 Months") {
                                  updateCellValue(
                                    index,
                                    cellIndex,
                                    "12 Months"
                                  );
                                }  else if (newInputValue === "18 Months") {
                                  updateCellValue(
                                    index,
                                    cellIndex,
                                    "18 Months"
                                  );
                                } else {
                                  updateCellValue(
                                    index,
                                    cellIndex,
                                    "24 Months"
                                  );
                                }
                              }}
                            />
                          </Grid>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 10) {
                      return (
                        <TableCell>
                          <Grid item xs={3}>
                            <Autocomplete
                              defaultValue={cell == 1 ? "NABL" : "NON NABL"}
                              size="small"
                              id="combo-box-demo"
                              options={[
                                {
                                  id: 1,
                                  label: "NABL",
                                },
                                {
                                  id: 2,
                                  label: "NON NABL",
                                },
                                {
                                  id: 3,
                                  label: "OFF RECORD",
                                },
                              ]}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              onInputChange={(event, newInputValue) => {
                                if (newInputValue === "NABL") {
                                  updateCellValue(index, cellIndex, 0);
                                } else if (newInputValue === "NON NABL") {
                                  updateCellValue(index, cellIndex, 1);
                                } else {
                                  updateCellValue(index, cellIndex, 2);
                                }
                              }}
                            />
                          </Grid>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 11) {
                      return (
                        <TableCell>
                          <Grid item xs={3}>
                            <Autocomplete
                              defaultValue={cell == 1 ? "NABL" : "NON NABL"}
                              size="small"
                              id="combo-box-demo"
                              options={[
                                {
                                  id: 1,
                                  label: "Onsite",
                                },
                                {
                                  id: 2,
                                  label: "In house",
                                },
                              ]}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              onInputChange={(event, newInputValue) => {
                                if (newInputValue === "Onsite") {
                                  updateCellValue(index, cellIndex, "Onsite");
                                } else {
                                  updateCellValue(index, cellIndex, "In house");
                                }
                              }}
                            />
                          </Grid>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 13) {
                      return (
                        <TableCell>
                          <Grid item xs={3}>
                            <Autocomplete
                              size="small"
                              id="combo-box-demo"
                              options={[
                                { id: 1, label: "As per proc" },
                                { id: 2, label: "As per customer" },
                              ]}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              onInputChange={(event, newInputValue) => {
                                if (newInputValue === "As per proc") {
                                  updateCellValue(
                                    index,
                                    cellIndex,
                                    "As per proc"
                                  );
                                  // changeCertificateStatus(3 + ":" + result.toString());
                                } else if (
                                  newInputValue === "As per customer"
                                ) {
                                  updateCellValue(
                                    index,
                                    cellIndex,
                                    "As per customer"
                                  );
                                  // changeCertificateStatus(4 + ":" + result.toString());
                                }
                              }}
                            />
                          </Grid>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 14) {
                      return (
                        <TableCell>
                          <Grid item xs={3}>
                            <Autocomplete
                              size="small"
                              id="combo-box-demo"
                              options={[
                                { id: 1, label: "Direct" },
                                { id: 2, label: "Comparison" },
                                { id: 3, label: "Direct/Comparison" },
                              ]}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              onInputChange={(event, newInputValue) => {
                                if (newInputValue === "Direct") {
                                  updateCellValue(index, cellIndex, "Direct");
                                } else if (newInputValue === "Comparison") {
                                  updateCellValue(
                                    index,
                                    cellIndex,
                                    "Comparison"
                                  );
                                } else {
                                  updateCellValue(
                                    index,
                                    cellIndex,
                                    "Direct/Comparison"
                                  );
                                }
                              }}
                            />
                          </Grid>
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ClassicTable>
      </div>
      <br />
      {/* <br />
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <input type="file" onChange={handleChange} />
          <Toolbar style={{ padding: "0px", overflow: "auto" }}>
            <Button
              variant="contained"
              size="small"
              sx={{ m: 0 }}
              disabled={executing}
              onClick={handleApi}
            >
              Excel Upload
            </Button>
          </Toolbar>
        </Grid>
      </Grid> */}
      <div style={{ marginTop: "40px" }}>
        <Input type="file" onChange={onChange} />
      </div>
      <Stack style={{ "margin-top": "20px" }} justifyContent="start">
        <Typography align="left">Description</Typography>
        <TextareaAutosize
          minRows={3}
          onChange={(e) => {
            updateSrfObject("description", e.target.value);
          }}
          placeholder="Enter your description here"
          style={{ width: 900 }}
        />
      </Stack>
      <div style={{ "margin-top": "20px" }}>
        <Typography align="left">
          Conformity of Statement Requirement:
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </Typography>
      </div>
      <br />
      <Toolbar style={{ padding: "0px", overflow: "auto" }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            // props.setLoader(true);
            handleSubmit();
          }}
        >
          Save
        </Button>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0, ml: 2 }}
          onClick={() => {
            handleSubmit();
          }}
        >
          Print
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}