import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Chip,
  Divider,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Modal,
  Box,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import { useParams } from "react-router-dom";
import CButton from "./../../../utils/components/Button";
const axios = require("axios");

export function UpdateTable(props) {
  const [datasheetColumnName, setDatasheetColumnName] = React.useState([]);
  const [certificateColumnName, setCertificateColumnName] = React.useState([]);
  const [selectedTypeBRelCol, setSelectedTypeBRelCol] = React.useState(null);

  const [datasheetConfiguration, setDatasheetConfiguration] = React.useState({
    values: {},
    formulas: {},
    totalColumns: 0,
    hasCommonHeader: false,
    commonHeaderConfig: null,
  });
  var [uncertaintyFactors, setUncertaintyFactors] = React.useState([]);

  const [certificateConfiguration, setCertificateConfiguration] =
    React.useState({
      relations: {},
      formulas: {},
      totalColumns: 0,
      hasCommonHeader: false,
      commonHeaderConfig: null,
    });
    const [conditionsCol, setConditionsCol] = React.useState(null);
    const [typeBConfiguration, setTypeBConfiguration] = React.useState({
      relations: {},
    });
    const [selectedFormulaCol1, setSelectedFormulaCol1] = React.useState(null);
    const [sketchPickerColor, setSketchPickerColor] = React.useState("#D12929");
    const [dsCol, setDsCol] = React.useState(null);

    const [condConfiguratio, setCondConfiguration] = React.useState({
      relations: {},
    });
  const [selectedValueCol, setSelectedValueCol] = React.useState(null);
  const [selectedFormulaCol, setSelectedFormulaCol] = React.useState(null);
  const [selectedCertFormulaCol, setSelectedCertFormulaCol] =
    React.useState(null);
  const [selectedDatasheetRelCol, setSelectedDatasheetRelCol] =
    React.useState(null);
  const [selectedCertRelCol, setSelectedCertRelCol] = React.useState(null);

  var [instrumentArray, setInstrumentArray] = React.useState([]);
  const [unitType, setUnitType] = React.useState("");
  const [instrument, setInstruments] = React.useState(null);
  const [newTableName, setNewTableName] = React.useState("");
  const [isUncertainty, setUncertainty] = React.useState(false);
  const [instrumentId, setInstrumentId] = React.useState(null);
  const [tableName, setTableName] = React.useState("");
  const [parameterName, setParameterName] = React.useState("");
  const [defaultConfiguration, setDefaultConfiguration] = React.useState("");
  const [columnArray, setColumnArray] = React.useState([]);

  const params = useParams();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };


  const updateCertificateKeys = (value, keyName) => {
    // const newColumnName = [...certificateMergeKeys];
    // newColumnName[keyName] = value.toString();
    // console.log(newColumnName);
    // setCertificateMergeKeys(newColumnName);
  };

  
  const getDatasheetList = () => {
    let url = BASE_URL;
    axios
      .get(url + `datasheetStaticTables/${params.id}`)
      .then((res) => {
        setUnitType(res.data[0].unitType);
        setTableName(res.data[0].tableName);
        setParameterName(res.data[0].parameterName);
        setUncertainty(res.data[0].isUncertainty);
        setDatasheetConfiguration(res.data[0].defaultConfiguration!=null ?JSON.parse(res.data[0].defaultConfiguration):{});
        setTypeBConfiguration(res.data[0].defaultConfiguration!=null ?JSON.parse(res.data[0].defaultConfiguration):{});
        setCondConfiguration(res.data[0].conditionFormatting!=null ?JSON.parse(res.data[0].conditionFormatting):{});
        const columnArr = [];
        columnArr.push(
          res.data[0].c1,
          res.data[0].c2,
          res.data[0].c3,
          res.data[0].c4,
          res.data[0].c5,
          res.data[0].c6,
          res.data[0].c7,
          res.data[0].c8,
          res.data[0].c9,
          res.data[0].c10,
          res.data[0].c11,
          res.data[0].c12,
          res.data[0].c13,
          res.data[0].c14,
          res.data[0].c15
        );
        setDatasheetColumnName(
          columnArr.filter(function (el) {
            return el != null;
          })
        );
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };


  const getCertificateList = () => {
    let url = BASE_URL;
    axios
      .get(url + `xjoin?_join=cert.certificateStaticTables,_j,inst.instruments&_on1=(cert.instrumentId,eq,inst.id)&_fields=cert.instrumentId,cert.isUncertainty,cert.defaultConfiguration,cert.c1,cert.c2,cert.c3,cert.c4,cert.c5,cert.c6,cert.c7,cert.c8,cert.c9,cert.c10,cert.c11,cert.c12,cert.c13,cert.c14,cert.c15,inst.instrumentName&_where=(cert.id,eq,${params.id})`)
      // /api/
      .then((res) => {
        setInstrumentId(res.data[0].cert_instrumentId);
        setUncertainty(res.data[0].cert_isUncertainty);
        setCertificateConfiguration(res.data[0].cert_defaultConfiguration!=null ?JSON.parse(res.data[0].cert_defaultConfiguration):{});
        setDefaultConfiguration(res.data[0].cert_defaultConfiguration);
        const columnArr = [];
        columnArr.push(
          res.data[0].cert_c1,
          res.data[0].cert_c2,
          res.data[0].cert_c3,
          res.data[0].cert_c4,
          res.data[0].cert_c5,
          res.data[0].cert_c6,
          res.data[0].cert_c7,
          res.data[0].cert_c8,
          res.data[0].cert_c9,
          res.data[0].cert_c10,
          res.data[0].cert_c11,
          res.data[0].cert_c12,
          res.data[0].cert_c13,
          res.data[0].cert_c14,
          res.data[0].cert_c15
        );
        setInstruments(res.data[0].inst_instrumentName)
        setCertificateColumnName(
          columnArr.filter(function (el) {
            return el != null;
          })
        );
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const onSubmit = () => {

    var jsonData = {
      tableName: tableName,
      unitType: unitType,
      instrumentId: instrumentId,
      parameterName: parameterName,
      defaultConfiguration: JSON.stringify({
        ...datasheetConfiguration,
        typeb: typeBConfiguration,
      }),
      conditionFormatting: JSON.stringify({
        ...condConfiguratio,
      }),
      isUncertainty: isUncertainty,

    };
    // datasheetColumnName.forEach((column, index) => {
    //   if(column && column!=null){
    //     jsonData["c" + (index + 1)] = column;
    //   }else{
    //     jsonData["c" + (index + 1)] = null
    //   }
    // });

    for (let i = 0; i < 29; i++) {
      if (datasheetColumnName[i]) {
        jsonData["c" + (i + 1)] = datasheetColumnName[i];
      }else{
        jsonData["c" + (i + 1)] = null;
      }
    }
    let url = BASE_URL;
    axios
    .patch(url + `datasheetStaticTables/${params.id}`, jsonData)
      .then((res) => {
        submitCertificate();
        // setTimeout(refresh, 1000);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something Went Wrong3!");
      });
  };

  const submitCertificate = () => {
    var jsonData = {
      tableName: tableName,
      unitType: unitType,
      instrumentId: instrumentId,
      parameterName: parameterName,
      defaultConfiguration: JSON.stringify({
        ...certificateConfiguration,
        typeb: typeBConfiguration,
      }),
      // conditionFormatting: JSON.stringify({
      //   ...condConfiguratio,
      // }),
      isUncertainty: isUncertainty,
    };
    // certificateColumnName.forEach((column, index) => {
    //   jsonData["c" + (index + 1)] = column;
    // });
    for (let i = 0; i < 29; i++) {
      if (certificateColumnName[i]) {
        jsonData["c" + (i + 1)] = certificateColumnName[i];
      }else{
        jsonData["c" + (i + 1)] = null;
      }
    }
    let url = BASE_URL;
    axios
    .patch(url + `certificateStaticTables/${params.id}`, jsonData)

      .then((res) => {
        props.setLoader(false);
        toast("static table updated!");
        // setTimeout(refresh, 1000);
      })
      .catch((error) => {
        props.setLoader(false);
        toast.error("Something Went Wrong in certificate!");
      });
  };

  const updateConditionConfiguration = (type, column = null, value = null) => {
    let _conditionsConfiguration = { ...condConfiguratio };
    if (column != null) {
      _conditionsConfiguration[type][column] = value;
    } else if (type === "relations" && conditionsCol && selectedFormulaCol1) {
      // Greater than", "Less than","Between
      _conditionsConfiguration[type][selectedFormulaCol1] ={
        condition:conditionsCol === 1
        ? "Gt"
        : conditionsCol === 2
        ? "Lt"
        : "Between",
        dsCol:dsCol,
        color:sketchPickerColor
      }
        
    } else {
      _conditionsConfiguration[type] = value;
    }
    setCondConfiguration(_conditionsConfiguration);
  };

  const fetchUncertaintyFactors = (instrumentId) => {
    let uncertaintyFactors = instrumentArray.filter(
      (instrument) => instrument.id == instrumentId
    )[0]?.uncertaintyFactors;

    axios
      .get(BASE_URL + `uncertainty?_where=(id,in,${uncertaintyFactors})`)
      .then((res) => {
        setUncertaintyFactors([...res.data]);
      })
      .catch((error) => {
        toast.error("Failed to uncertainty factors!");
      });
  };

  const getCertificateTableKeysFromCertificateTables = (event) => {
    var splitArr = [];
    let url = BASE_URL;
    axios
      .get(url + "certificateStaticTables")
      .then((res) => {
        if (res.data.length) {
          var sortedData = res.data.reverse()[0];
          var keys = [];
          for (var k in sortedData) keys.push(k);
          keys.splice(keys.length - 12, 12);
          keys.slice(5).forEach(function (value) {
            splitArr.push(value);
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // setCertificateKeys(splitArr);
  };
  // console.log(datasheetColumnName);
  const renderCertificateTableKeysData = () => {
    // return certificateKeys.map((certKeyData, index) => {
    //   return (
    //     <Grid item xs={4}>
    //       <FormControlLabel
    //         sx={{
    //           display: "flex",
    //           gap: 2,
    //         }}
    //         control={
    //           <Checkbox
    //             onChange={(e) => {
    //               updateCertificateKeys(e.target.checked, certKeyData);
    //               // setMatrixId({isChecked:e.target.checked, id:matrixData.id});
    //             }}
    //           />
    //         }
    //         label={certKeyData}
    //       />
    //     </Grid>
    //   );
    // });
  };

  const addDatasheetColumn = () => {
    setDatasheetColumnName([...datasheetColumnName, ""]);
    updateDatasheetConfiguration(
      "totalColumns",
      null,
      Number(datasheetConfiguration["totalColumns"]) + 1
    );
  };

  const deleteDatasheetColumn = (index) => {
    let newDatasheetColumnName = [...datasheetColumnName];
    newDatasheetColumnName.splice(index, 1);
    setDatasheetColumnName([...newDatasheetColumnName]);
  };
  const updateDatasheetColumnName = (value, index) => {
    const newColumnName = [...datasheetColumnName];
    newColumnName[index] = value;
    setDatasheetColumnName(newColumnName);
  };

  const updateDatasheetConfiguration = (type, column, value) => {
    let _datasheetConfiguration = { ...datasheetConfiguration };
    if (column != null) {
      _datasheetConfiguration[type][column] = value;
    } else {
      _datasheetConfiguration[type] = value;
    }
    setDatasheetConfiguration(_datasheetConfiguration);
  };

  const updateTypeBConfiguration = (type, column = null, value = null) => {
    let _typeBConfiguration = { ...typeBConfiguration };
    if (
      type === "relations" &&
      selectedTypeBRelCol &&
      selectedDatasheetRelCol
    ) {
      _typeBConfiguration[type][selectedDatasheetRelCol] = selectedTypeBRelCol;
    }
    setTypeBConfiguration(_typeBConfiguration);
  };

  const updateCertificateConfiguration = (
    type,
    column = null,
    value = null
  ) => {
    let _certificateConfiguration = { ...certificateConfiguration };
    if (column != null) {
      _certificateConfiguration[type][column] = value;
    } else if (
      type == "relations" &&
      selectedCertRelCol &&
      selectedDatasheetRelCol
    ) {
      _certificateConfiguration[type][selectedCertRelCol] =
        selectedDatasheetRelCol;
    } else {
      _certificateConfiguration[type] = value;
    }
    setCertificateConfiguration(_certificateConfiguration);
  };

  const addCertificateColumn = () => {
    setCertificateColumnName([...certificateColumnName, ""]);
    updateCertificateConfiguration(
      "totalColumns",
      null,
      Number(certificateConfiguration["totalColumns"]) + 1
    );
  };
  const deleteCertificateColumn = (index) => {
    let newCertificateColumnName = [...certificateColumnName];
    newCertificateColumnName.splice(index, 1);
    setCertificateColumnName([...newCertificateColumnName]);
  };
  const updateCertificateColumnName = (value, index) => {
    const newColumnName = [...certificateColumnName];
    newColumnName[index] = value;
    setCertificateColumnName(newColumnName);
  };

  const getInstrumentsList = () => {
    let url = BASE_URL;
    axios
      .get(url + "instruments?_where=(status,eq,1)")
      .then((res) => {
             setInstrumentArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong2!");
      });
  };

  useEffect(() => {
    getCertificateTableKeysFromCertificateTables();
    getInstrumentsList();
    getDatasheetList();
    getCertificateList();
  }, []);

  var pushIArray = [];
  for (let i = 0; i < instrumentArray.length; i++) {
    if (instrumentArray[i].instrumentName) {
      pushIArray.push({
        label: instrumentArray[i].id + ", " + instrumentArray[i].instrumentName,
      });
    }
  }

  
  const renderHelpModal = () => {
    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Formula Help
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            same as mathematical expression with following variations
            <hr />* : multiplication
          </Typography>
        </Box>
      </Modal>
    );
  };


  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" sx={{ mb: 2 }}>
        create new static table
      </Typography>
      <Grid container spacing={2} sx={{ mb: 3 }}>
      <Grid item xs={4}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            value={instrument}
            options={pushIArray}
            onChange={(event, value) => {
              setInstruments(parseInt(value.label.split(",")[0]));
              fetchUncertaintyFactors(value.label.split(",")[0]);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Instrument" />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            label="New table name"
            size="small"
            value={tableName}
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            onChange={(e) => setTableName(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            value={unitType}
            label="Unit"
            InputLabelProps={{ shrink: true }}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => setUnitType(e.target.value)}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            InputLabelProps={{ shrink: true }}
            value={parameterName}
            label="parameter name"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => setParameterName(e.target.value)}
          />
        </Grid>
      </Grid>

      <Divider sx={{ mt: 5 }}>
        <Chip
          label="Datasheet table configuration"
          variant="outlined"
          sx={{ fontSize: "16px" }}
        />
      </Divider>

      <div sx={{ mb: 3 }}>
        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          1. add columns
          <CButton
            variant="contained"
            size="small"
            label={<AddIcon />}
            sx={{ ml: 3 }}
            onClick={() => {
              addDatasheetColumn();
            }}
          ></CButton>
        </Typography>
        <Grid container spacing={1}>
          {datasheetColumnName.map((column, index) => (
            <Grid item xs={4}>
              <OutlinedInput
                id="outlined-adornment-password"
                label={"column " + (index + 1) + "(c" + (index + 1) + ")"}
                value={column}
                placeholder={
                  "column " + (index + 1) + " (c" + +(index + 1) + ")"
                }
                onChange={(e) => {
                  updateDatasheetColumnName(e.target.value, index);
                }}
                size="small"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        deleteDatasheetColumn(index);
                      }}
                      edge="end"
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>
          ))}
        </Grid>
      </div>
      <br />
      <div>
        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          2. Define common header
        </Typography>
        <Grid container spacing={1} sx={{ mb: 3 }}>
          <Grid item xs={3}>
            <FormControlLabel
              control={<Checkbox
                checked={datasheetConfiguration.hasCommonHeader == 'on'? true:false}
                />}
              label="Has common header"
              onChange={(e) => {
                updateDatasheetConfiguration(
                  "hasCommonHeader",
                  null,
                  e.target.value
                );
              }}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              id="outlined-basic"
              label="Define common header"
              placeholder="Define common header (, for column separation, $: for empty column)"
              size="small"
              fullWidth
              variant="outlined"
              value={datasheetConfiguration?.commonHeaderConfig || ""}
              onChange={(e) =>
                updateDatasheetConfiguration(
                  "commonHeaderConfig",
                  null,
                  e.target.value
                )
              }
            />
          </Grid>
          <Grid item xs={1}>
            <CButton
              variant="text"
              size="small"
              label={<HelpCenterIcon />}
              onClick={handleOpen}
            ></CButton>
          </Grid>
        </Grid>
      </div>
      <br />
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        3. Set default values (optional)
      </Typography>
      <Grid container spacing={1} sx={{ mb: 3 }}>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select Column</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedValueCol}
              label="Select column"
              onChange={(e) => {
                setSelectedValueCol(e.target.value);
              }}
              size="small"
            >
              {datasheetColumnName.map((columnName, index) => {
                return (
                  <MenuItem value={"c" + (index + 1)}>{columnName}</MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={9}>
          <TextField
            id="outlined-basic"
            label="Add values ( comma separated )"
            size="small"
            fullWidth
            variant="outlined"
            value={datasheetConfiguration?.values[selectedValueCol] || ""}
            onChange={(e) =>
              updateDatasheetConfiguration(
                "values",
                selectedValueCol,
                e.target.value
              )
            }
          />
        </Grid>
      </Grid>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        4. Set formula (optional)
      </Typography>
      <Grid container spacing={1} sx={{ mb: 3 }}>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select column</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedFormulaCol}
              label="Select column"
              onChange={(e) => {
                setSelectedFormulaCol(e.target.value);
              }}
              size="small"
            >
              {datasheetColumnName.map((columnName, index) => {
                return (
                  <MenuItem value={"c" + (index + 1)}>{columnName}</MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={8}>
          <TextField
            id="outlined-basic"
            label="Add formula"
            size="small"
            fullWidth
            variant="outlined"
            value={datasheetConfiguration?.formulas[selectedFormulaCol] || ""}
            onChange={(e) =>
              updateDatasheetConfiguration(
                "formulas",
                selectedFormulaCol,
                e.target.value
              )
            }
          />
        </Grid>
        <Grid item xs={1}>
          <CButton
            variant="text"
            size="small"
            label={<HelpCenterIcon />}
            onClick={handleOpen}
          ></CButton>
        </Grid>
        </Grid>
        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          5. Conditional Formatting
        </Typography>
        <Grid container spacing={1} sx={{ mb: 3 }}>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Datasheet Column
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedFormulaCol1}
                label="Select column"
                onChange={(e) => {
                  setSelectedFormulaCol1(e.target.value);
                }}
                size="small"
              >
                {datasheetColumnName.map((columnName, index) => {
                  return (
                    <MenuItem value={"c" + (index + 1)}>{columnName}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Condition</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={conditionsCol}
                label="Select condition"
                onChange={(e) => {
                  // setSelectedFormulaCol(e.target.value);
                  setConditionsCol(e.target.value);
                }}
                size="small"
              >
                {["Greater than", "Less than", "Between"].map(
                  (columnName, index) => {
                    return <MenuItem value={index + 1}>{columnName}</MenuItem>;
                  }
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Columns
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={dsCol}
                label="Select column"
                onChange={(e) => {
                  setDsCol(e.target.value);
                  // setSelectedFormulaCol(e.tarPlget.value);
                }}
                size="small"
              >
                {["range higher", "range lower", "LC", "accuracy"].map(
                  (columnName, index) => {
                    return <MenuItem value={index + 1}>{columnName}</MenuItem>;
                  }
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <input
              type="color"
              value={sketchPickerColor}
              style={{
                backgroundColor: `${sketchPickerColor}`,
                width: 70,
                height: 42,
                borderRadius: "7px",
              }}
              onChange={(e) => {
                setSketchPickerColor(e.target.value);
              }}
            />          
          </Grid>
          <Grid item xs={1}>
            <CButton
              variant="outlined"
              label={"add"}
              onClick={(e) => updateConditionConfiguration("relations")}
            ></CButton>
          </Grid>
          <Grid item xs={4}>
            <h6>relations</h6>
            <pre>{JSON.stringify(condConfiguratio["relations"])}</pre>
          </Grid>
      </Grid>

      <Divider sx={{ mt: 5 }}>
        <Chip
          label="Certificate table configuration"
          variant="outlined"
          sx={{ fontSize: "16px" }}
        />
      </Divider>
      <br />

      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        add columns
        <CButton
          variant="contained"
          size="small"
          label={<AddIcon />}
          sx={{ ml: 3 }}
          onClick={() => {
            addCertificateColumn();
          }}
        ></CButton>
      </Typography>

      <Grid container spacing={1} sx={{ mb: 3 }}>
        {certificateColumnName.map((column, index) => (
          <Grid item xs={4}>
            <OutlinedInput
              id="outlined-adornment-password"
              label={"column " + (index + 1)}
              value={column}
              placeholder={"column " + (index + 1)}
              onChange={(e) => {
                updateCertificateColumnName(e.target.value, index);
              }}
              size="small"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      deleteCertificateColumn(index);
                    }}
                    edge="end"
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
        ))}
      </Grid>
      <br />
      <div>
        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          2. Define common header
        </Typography>
        <Grid container spacing={1} sx={{ mb: 3 }}>
          <Grid item xs={3}>
            <FormControlLabel
              control={<Checkbox 
                checked={certificateConfiguration.hasCommonHeader == 'on'? true:false}
              />}
              label="Has common header"
              onChange={(e) => {
                updateCertificateConfiguration(
                  "hasCommonHeader",
                  null,
                  e.target.value
                );
              }}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              id="outlined-basic"
              label="Define common header"
              placeholder="Define common header (, for column separation, $: for empty column)"
              size="small"
              fullWidth
              variant="outlined"
              value={certificateConfiguration?.commonHeaderConfig || ""}
              onChange={(e) =>
                updateCertificateConfiguration(
                  "commonHeaderConfig",
                  null,
                  e.target.value
                )
              }
            />
          </Grid>
          <Grid item xs={1}>
            <CButton
              variant="text"
              size="small"
              label={<HelpCenterIcon />}
              onClick={handleOpen}
            ></CButton>
          </Grid>
        </Grid>
      </div>
      <br />
      <div>
        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          Set Relationship (optional)
        </Typography>
        <Grid container spacing={1} sx={{ mb: 3 }}>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select datasheet column
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedDatasheetRelCol}
                label="Select column"
                onChange={(e) => {
                  setSelectedDatasheetRelCol(e.target.value);
                }}
                size="small"
              >
                {datasheetColumnName.map((columnName, index) => {
                  return (
                    <MenuItem value={"c" + (index + 1)}>{columnName}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select certificate column
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedCertRelCol}
                label="Select column"
                onChange={(e) => {
                  setSelectedCertRelCol(e.target.value);
                }}
                size="small"
              >
                {certificateColumnName.map((columnName, index) => {
                  return (
                    <MenuItem value={"c" + (index + 1)}>{columnName}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1}>
            <CButton
              variant="outlined"
              label={"add"}
              onClick={(e) => updateCertificateConfiguration("relations")}
            ></CButton>
          </Grid>
          <Grid item xs={4}>
            <h6>relations</h6>
            <pre>{JSON.stringify(certificateConfiguration["relations"])}</pre>
          </Grid>
        </Grid>
      </div>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Set formula (optional)
      </Typography>
      <Grid container spacing={1} sx={{ mb: 3 }}>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select column</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedCertFormulaCol}
              label="Select column"
              onChange={(e) => {
                setSelectedCertFormulaCol(e.target.value);
              }}
              size="small"
            >
              {certificateColumnName.map((columnName, index) => {
                return (
                  <MenuItem value={"c" + (index + 1)}>{columnName}</MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={8}>
          <TextField
            id="outlined-basic"
            label="Add formula"
            size="small"
            fullWidth
            variant="outlined"
            value={
              certificateConfiguration?.formulas[selectedCertFormulaCol] || ""
            }
            onChange={(e) =>
              updateCertificateConfiguration(
                "formulas",
                selectedCertFormulaCol,
                e.target.value
              )
            }
          />
        </Grid>
        <Grid item xs={1}>
          <CButton
            variant="text"
            size="small"
            label={<HelpCenterIcon />}
            onClick={handleOpen}
          ></CButton>
        </Grid>
        <br />
        <Grid item xs={1}>
          <FormControlLabel
            classes={{
              alignItems: "left",
            }}
            control={
              <Checkbox
                onChange={(e) => {
                  setUncertainty(e.target.checked);
                }}
              />
            }
            label="uncertainty"
          />
        </Grid>
      </Grid>
      <br />
      <div>
        <Divider sx={{ mt: 5 }}>
          <Chip
            label="Type-B configuration"
            variant="outlined"
            sx={{ fontSize: "16px" }}
          />
        </Divider>
        <div>
          <Typography variant="h6" component="h6" style={{ float: "left" }}>
            Set Relationship(optional)
          </Typography>
          <Grid container spacing={1} sx={{ mb: 3 }}>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Datasheet column
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedDatasheetRelCol}
                  label="Select column"
                  onChange={(e) => {
                    setSelectedDatasheetRelCol(e.target.value);
                  }}
                  size="small"
                >
                  {datasheetColumnName.map((columnName, index) => {
                    return (
                      <MenuItem value={"c" + (index + 1)}>
                        {columnName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Type-B column
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedTypeBRelCol}
                  label="Select column"
                  onChange={(e) => {
                    setSelectedTypeBRelCol(e.target.value);
                  }}
                  size="small"
                >
                  {uncertaintyFactors.map((uf, index) => {
                    return (
                      <MenuItem value={uf.id} key={uf.id}>
                        {uf.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1}>
              <CButton
                variant="outlined"
                label={"add"}
                onClick={(e) => updateTypeBConfiguration("relations")}
              ></CButton>
            </Grid>
            <Grid item xs={4}>
              <h6>relations</h6>
              <pre>{JSON.stringify(typeBConfiguration?.typeb?.relations)}</pre>
            </Grid>
          </Grid>
        </div>
        <br />
      </div>
      <hr />
      <Toolbar style={{ padding: "0px" }}>
        <CButton
          variant="contained"
          label="SAVE"
          onClick={() => {
            props.setLoader(true);
            onSubmit();
          }}
        >
          SAVE
        </CButton>
      </Toolbar>
      <ToastContainer />
      {renderHelpModal()}
    </Paper>
  );
}
