import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  InputLabel,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { ClassicTable } from "../../../utils/components/Styles";
const axios = require("axios");

export default function UpdatreClient(props) {
  const [companyName, setCompanyNanme] = React.useState("");
  const [contact, setContact] = React.useState("");
  const [gstNumber, setGstNumber] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [state, setState] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [shippingAddress, setShippingAddress] = React.useState("");
  const [readingRows, setReadingRows] = React.useState([]);
  const [calibratedByChecked, setCalibratedByChecked] = React.useState("false");
  const [checkedByChecked, setCheckedByChecked] = React.useState("false");
  const [approvedByChecked, setApprovedByChecked] = React.useState("false");
  const [reviewedByChecked, setReviewedByChecked] = React.useState("false");
  const [signedByChecked, setSignedByChecked] = React.useState("false");
  const [signature, setSignature] = React.useState(null);

  const params = useParams();

  const readingColumns = [
    [
      {
        field: "id",
        headerName: "Sr. No.",
        editable: false,
      },
      {
        field: "contactPersonName",
        headerName: "Contact Person Name",
        editable: true,
      },
      {
        field: "contactNo",
        headerName: "Contact No",
        editable: true,
      },
      {
        field: "email",
        headerName: "email Id",
        editable: true,
      },
    ],
  ];

  const updateCellValue = (rowIndex, colIndex, value) => {
    const newRows = [...readingRows];
    newRows[rowIndex][colIndex] = value;
    setReadingRows([...newRows]);
  };

  const addRangeRow = () => {
    let newReadingRows = [...readingRows];
    newReadingRows.push(["", "",""]);
    setReadingRows([...newReadingRows]);
  };

  const deleteReadingRow = (rowIndex) => {
    let newReadingRows = [...readingRows];
    if (newReadingRows[rowIndex] != undefined) {
      newReadingRows.splice(rowIndex, 1);
      setReadingRows([...newReadingRows]);
    }
  };

  const initiateChData = () => {
    try {
      let rows = [];
      var contactDetailsArray = [];
      if (contact != "") {
        contactDetailsArray = JSON.parse(contact);
      }
      for (let i = 0; i < contactDetailsArray.length; i++) {
        rows.push([
          contactDetailsArray[i].contact,
          contactDetailsArray[i].contactPersonName,
          contactDetailsArray[i].emailId,
        ]);
      }
      setReadingRows(rows);
    } catch (error) {
      console.log(error);
    }
  };

  const getClientsList = () => {
    let url = BASE_URL;
    axios
      .get(url + `clients/${params.id}`)
      .then((res) => {
        setCompanyNanme(res.data[0].companyName);
        setContact(res.data[0].contact);
        setAddress(res.data[0].address);
        setShippingAddress(res.data[0].shippingAddress);
        setGstNumber(res.data[0].gstNumber);
        setState(res.data[0].state);
        setEmail(res.data[0].email);
        setSignature(res.data[0].signature);
        let rows = [];
        var contactDetailsArray = [];
        if (res.data[0].contact != "" && res.data[0].contact != null) {
          contactDetailsArray = JSON.parse(res.data[0].contact);
        }
        for (let i = 0; i < contactDetailsArray.length; i++) {
          rows.push([
            contactDetailsArray[i].contact,
            contactDetailsArray[i].contactPersonName,
            contactDetailsArray[i].emailId,
          ]);
        }
        setReadingRows(rows);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };
  const onSubmit = () => {
    let rows = readingRows.map((row) => ({
      contact: row[0],
      contactPersonName: row[1],
      emailId: row[2],
    }));
    var checkedObj = {
      forOrganization: {
        calibratedBy: calibratedByChecked.toString(),
        checkedBy: checkedByChecked.toString(),
        approvedBy: approvedByChecked.toString(),
      },
      forClient: {
        reviewedBy: reviewedByChecked.toString(),
        signedBy: signedByChecked.toString(),
      },
    };
    var jsonData = {
      companyName: companyName,
      contact: rows.length ? JSON.stringify(rows) : null,
      address: address,
      shippingAddress: shippingAddress,
      gstNumber: gstNumber,
      state: state,
      email: email,
      signature: JSON.stringify(checkedObj),
    };
    let url = BASE_URL;

    axios
      .patch(url + `clients/${params.id}`, jsonData)
      .then((res) => {
        props.setLoader(false);
        toast("clients updated!");
      })
      .catch((error) => {
        props.setLoader(false);
        toast.error("Something Went Wrong!");
      });
  };

  useEffect(() => {
    getClientsList();
    initiateChData();
  }, []);

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Edit Client
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Company Name *"
            size="small"
            fullWidth
            variant="outlined"
            value={companyName}
            onChange={(e) => setCompanyNanme(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Billing Address"
            size="small"
            fullWidth
            variant="outlined"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </Grid>{" "}
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Shipping Address"
            size="small"
            fullWidth
            value={shippingAddress}
            variant="outlined"
            onChange={(e) => setShippingAddress(e.target.value)}
          />
        </Grid>{" "}
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="GST Number *"
            size="small"
            fullWidth
            variant="outlined"
            value={gstNumber}
            onChange={(e) => setGstNumber(e.target.value)}
          />
        </Grid>{" "}
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="State *"
            size="small"
            fullWidth
            variant="outlined"
            value={state}
            onChange={(e) => setState(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Email *"
            size="small"
            fullWidth
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>
      </Grid>
      <br />
      <InputLabel id="demo-simple-select-label" style={{ float: "left" }}>
        For Organization
      </InputLabel>

      <Grid container spacing={2}>
        <Grid item xs={2}>
          <FormControlLabel
            classes={{
              justifyContent: "space-between",
              alignItems: "left",
            }}
            control={
              <Checkbox
                onChange={(e) => {
                  setCalibratedByChecked(e.target.checked);
                }}
              />
            }
            label="Calibrated by"
          />
        </Grid>
        <Grid item xs={2}>
          <FormControlLabel
            classes={{
              justifyContent: "space-between",
              alignItems: "left",
            }}
            control={
              <Checkbox
                onChange={(e) => {
                  setCheckedByChecked(e.target.checked);
                }}
              />
            }
            label="Checked By"
          />
        </Grid>
        <Grid item xs={2}>
          <FormControlLabel
            classes={{
              justifyContent: "space-between",
              alignItems: "left",
            }}
            control={
              <Checkbox
                onChange={(e) => {
                  setApprovedByChecked(e.target.checked);
                }}
              />
            }
            label="Approved by"
          />
        </Grid>
      </Grid>
      <br />
      <InputLabel id="demo-simple-select-label" style={{ float: "left" }}>
        For Client
      </InputLabel>

      <Grid container spacing={2}>
        <Grid item xs={2}>
          <FormControlLabel
            classes={{
              justifyContent: "space-between",
              alignItems: "left",
            }}
            control={
              <Checkbox
                onChange={(e) => {
                  setReviewedByChecked(e.target.checked);
                }}
              />
            }
            label="Reviewed By"
          />
        </Grid>

        <Grid item xs={2}>
          <FormControlLabel
            classes={{
              justifyContent: "space-between",
              alignItems: "left",
            }}
            control={
              <Checkbox
                onChange={(e) => {
                  setSignedByChecked(e.target.checked);
                }}
              />
            }
            label="Signed By"
          />
        </Grid>
      </Grid>
      <br />

      <Button
        style={{ float: "right" }}
        onClick={(e) => {
          addRangeRow(0);
        }}
      >
        <AddIcon />
      </Button>
      <div style={{ width: "100%", overflow: "auto" }}>
        <ClassicTable>
          <Table sx={{ minWidth: 660 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {readingColumns[0].map((column) => (
                  <TableCell key={column.field}>
                    {" "}
                    <Typography noWrap>{column.headerName}</Typography>
                  </TableCell>
                ))}
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {readingRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  {row.map((cell, cellIndex) => {
                    return (
                      <TableCell>
                        <TextField
                          size="small"
                          value={cell}
                          onChange={(e) => {
                            updateCellValue(index, cellIndex, e.target.value);
                          }}
                        ></TextField>
                      </TableCell>
                    );
                  })}
                  <TableCell>
                    <DeleteIcon
                      style={{ color: "#dc3545" }}
                      onClick={(e) => {
                        deleteReadingRow(0, index);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ClassicTable>
        <br />
      </div>

      <br />
      <Toolbar style={{ padding: "0px" }} sx={{ mt: 5 }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            props.setLoader(true);
            onSubmit();
          }}
        >
          Save
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
