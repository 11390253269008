import React, { useState, useEffect, Fragment } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import moment from "moment";
import QRCode from "react-qr-code";
import { Autocomplete } from "@mui/material";
import { BASE_URL, DOMAIN } from "./../../global";
import { Grid, TextField, Toolbar } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { ToastContainer, toast } from "react-toastify";
import Global from "../image/global.png";
import { useReactToPrint } from "react-to-print";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
const userType = localStorage.getItem("type");
// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const axios = require("axios");
if (userType !== "3") {
  var columns = [
    {
      id: "id",
      label: "Sr. No.",
      align: "left",
      minWidth: 40,
    },
    {
      id: "srnNo",
      label: "Srn No.",
      align: "left",
      minWidth: 40,
    },
    {
      id: "customerName",
      label: "Customer Name",
      align: "left",
      minWidth: 40,
    },
    {
      id: "instrumentName",
      label: "Instrument Name",
      align: "left",
      minWidth: 40,
    },
    {
      id: "range",
      label: "Range",
      minWidth: 40,
      align: "left",
    },
    {
      id: "idNo",
      label: "Id No.",
      minWidth: 40,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "dateOfCalibration",
      label: "Date of Calibration",
      minWidth: 40,
      align: "left",
      format: (value) => value.toFixed(2),
    },
    {
      id: "dueDate",
      label: "Due Date",
      minWidth: 40,
      align: "left",
      format: (value) => value.toFixed(2),
    },
    {
      id: "createdBy",
      label: "Created By",
      minWidth: 40,
      align: "left",
      format: (value) => value.toFixed(2),
    },
  ];
} else {
  var columns = [
    {
      id: "id",
      label: "Sr. No.",
      align: "left",
      minWidth: 40,
    },
    {
      id: "srnNo",
      label: "Srn No.",
      align: "left",
      minWidth: 40,
    },
    {
      id: "customerName",
      label: "Customer Name",
      align: "left",
      minWidth: 40,
    },
    {
      id: "instrumentName",
      label: "Instrument Name",
      align: "left",
      minWidth: 40,
    },
    {
      id: "range",
      label: "Range",
      minWidth: 40,
      align: "left",
    },
    {
      id: "idNo",
      label: "Id No.",
      minWidth: 40,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "dateOfCalibration",
      label: "Date of Calibration",
      minWidth: 40,
      align: "left",
      format: (value) => value.toFixed(2),
    },
    {
      id: "dueDate",
      label: "Due Date",
      minWidth: 40,
      align: "left",
      format: (value) => value.toFixed(2),
    },
  ];
}

if (userType !== "3") {
  console.log("call");
  var createData = (
    id,
    srnNo,
    customerName,
    workOrderNo,
    instrument,
    identificationNo,
    dateOfCalibration,
    calProcRefNo
  ) => {
    return {
      id,
      srnNo,
      customerName,
      workOrderNo,
      instrument,
      identificationNo,
      dateOfCalibration,
      calProcRefNo,
    };
  };
} else {
  console.log("else");
  var createData = (
    id,
    srnNo,
    customerName,
    workOrderNo,
    instrument,
    identificationNo,
    dateOfCalibration,
    calProcRefNo
  ) => {
    return {
      id,
      srnNo,
      customerName,
      workOrderNo,
      instrument,
      identificationNo,
      dateOfCalibration,
      calProcRefNo,
    };
  };
}

const rows = [
  createData(
    2,
    5,
    1,
    "Universal lab",
    "BFI/2112/BFI/22/1/129",
    "Digital Caliper",
    55,
    "1/20/2022 12:00:00 AM",
    "Thermometer rer"
  ),
];

const qrModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

export default function RenewCertificate() {
  const printComponentRef = React.useRef();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [certificate, setCertificate] = useState([]);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [companyName, setCompanyName] = useState("");

  const [qrCodeUrl, setQrCodeUrl] = React.useState(null);
  const [qrModalOpen, setQrModalOpen] = React.useState(false);
  const [enableDisable, setEnableData] = React.useState("");
  const [instrumentName, setInstrumentName] = React.useState("");
  const [idNumber, setIdNumber] = React.useState("");
  const [calDate, setCalDate] = React.useState("");
  const [dueDate, setDueDate] = React.useState("");
  const [certReadings, setCertReadings] = React.useState([]);
  const [finalData, setFinalData] = React.useState([]);
  const [searchBy, setSearchBy] = React.useState("");
  const [searchKey, setSearchKey] = React.useState("");
  const [totalRows, setTotalRows] = React.useState(0);
  const _clientId = localStorage.getItem("clientId");

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
  });

  // console.log('dd',enableDisable)
  const handleOpen = () => setQrModalOpen(true);
  const handleClose = () => setQrModalOpen(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

    // api calls
    function getTotalRows() {
      axios
        .get(BASE_URL + `certificates/count`)
        .then(async (res) => {
          setTotalRows(res.data[0]?.no_of_rows);
        })
        .catch((err) => {
          console.log("datasheet data fetching error: ", err);
        });
    }
  
    function fetchCertificates() {
      let whereConditions = "";
      let pagination_settings = "";
  
      if (searchBy !== "" && searchKey != "") {
        let colName = {
          "Customer Name": "cust.companyName",
          "Srn No.": "cert.srnNo",
          "Instrument Name": "ins.instrumentName",
        }[searchBy];
        whereConditions = `where ${colName} like '%${searchKey}%'`;
      }
  
      if (to != null && from != null) {
        if (whereConditions === "") {
          whereConditions = " where ";
        } else {
          whereConditions += " and ";
        }
  
        whereConditions += ` cert.nextDueDate between '${moment(from).format(
          "YYYY-MM-DD"
        )}' and '${moment(to).format("YYYY-MM-DD")}' `;
      }
  
      if (rowsPerPage != -1) {
        pagination_settings = `limit ${rowsPerPage} offset ${
          rowsPerPage * Number(page)
        }`;
      }
  
      var data = {
        query: `select cert.clientId,cust.companyName,ins.instrumentName,cert.id,cert.status,cert.dcNumber,cert.calibrationDate,cert.nextDueDate,cert.identificationNo,cert.identificationNo,cert.ranges,cert.DUCID,cert.dateOfIssue,cert.srnNo from certificates as cert left join clients as cust on cert.clientId = cust.id left join instruments as ins on cert.instrumentId = ins.id  ${whereConditions} order by cert.id DESC ${pagination_settings}`,
      };
      if (_clientId > 0){
        var data = {
          query: `select cert.clientId,cust.companyName,ins.instrumentName,cert.id,cert.status,cert.dcNumber,cert.calibrationDate,cert.nextDueDate,cert.identificationNo,cert.identificationNo,cert.ranges,cert.DUCID,cert.dateOfIssue,cert.srnNo from certificates as cert left join clients as cust on cert.clientId = cust.id left join instruments as ins on cert.instrumentId = ins.id and cert.status = 1 and cert.clientId = ${_clientId}  ${whereConditions} order by cert.id DESC ${pagination_settings}`,
        };
      }
     
       
      axios
        .post(BASE_URL + `dynamic`, data)
        .then((res) => {
          setCertificate([...res.data]);
          console.log(res.data)
        })
        .catch((err) => {
          console.log("srfs data fetching error: ", err);
        });
    }
  
    const TablePaginationActions = (props) => {
      const theme = useTheme();
      const { count, page, rowsPerPage, onPageChange } = props;
  
      const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
      };
  
      const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
      };
  
      const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
      };
  
      const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
      };
  
      return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
          <IconButton
            onClick={handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label="first page"
          >
            {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
          </IconButton>
          <IconButton
            onClick={handleBackButtonClick}
            disabled={page === 0}
            aria-label="previous page"
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
          </IconButton>
          <IconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="next page"
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </IconButton>
          <IconButton
            onClick={handleLastPageButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="last page"
          >
            {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
          </IconButton>
        </Box>
      );
    };

    

  // api calls
  function fetchCertificatess() {
    let url =
      BASE_URL +
      `xjoin?_join=cert.certificates,_j,ins.instruments,_j,cust.clients&_on1=(cert.instrumentId,eq,ins.id)&_on2=(cust.id,eq,cert.clientId)&_fields=cert.clientId,cust.companyName,ins.instrumentName,cert.id,cert.status,cert.dcNumber,cert.calibrationDate,cert.nextDueDate,cert.identificationNo,cert.identificationNo,cert.ranges,cert.DUCID,cert.dateOfIssue,cert.srnNo&_where=(cert.status,eq,1)~or(cert.status,eq,3)~or(cert.status,eq,4)~and(cert.clientId,eq,${_clientId})`;
    if (_clientId > 0)
      url =
        BASE_URL +
        `xjoin?_join=cert.certificates,_j,ins.instruments,_j,cust.clients&_on1=(cert.instrumentId,eq,ins.id)&_on2=(cust.id,eq,cert.clientId)&_fields=cert.clientId,cust.companyName,ins.instrumentName,cert.id,cert.status,cert.dcNumber,cert.calibrationDate,cert.nextDueDate,cert.identificationNo,cert.identificationNo,cert.ranges,cert.DUCID,cert.dateOfIssue,cert.srnNo&_where=(cert.status,eq,1)${
          _clientId != null ? "~and(cert.clientId,eq," + _clientId + ")" : ""
        }`;

    axios
      .get(url)
      .then((res) => {
        console.log(res.data)
        setCertificate(res.data);
      })
      .catch((err) => {
        console.log("certificate data fetching error: ", err);
      });
  }

  const onSave = (event) => {
    let row = {
      from: new Date(from).toISOString(),
      to: new Date(to).toISOString(),
      companyName: companyName,
    };

    let url = BASE_URL;
    // axios
    //   .post(url + "standards", row)
    //   .then((res) => {
    //     // toast("standards created successfully !");
    //     var insertedID = res.data.insertId;
    //     submitDescriptionRows(readingRows, insertedID);
    //     // setTimeout(refresh, 1000);
    //   })
    //   .catch((error) => {
    //     toast.error("Something Went Wrong!");
    //   });
  };
  // api calls
  function fetchCertificateReadings() {
    let url = BASE_URL;
    axios
      .get(url + `certificateStaticReadings`)
      .then(async (res) => {
        setCertReadings(res.data);
      })
      .catch((err) => {
        console.log("cert data fetching error: ", err);
      });
  }

  const initializeCertTable = () => {
    for (let i = 0; i < certificate.length; i++) {
      if (certificate[i].cert_dateOfIssue != null) {
        certificate[i]["isProgress"] = 90;
      } else if (
        certReadings.some(
          (data) => data.certificateId == certificate[i].id
        )
      ) {
        certificate[i]["isProgress"] = 70;
      } else {
        certificate[i]["isProgress"] = 30;
      }
    }
    setFinalData(certificate);
  };

  const search = () => {
    fetchCertificates();
  };

  useEffect(() => {
    getTotalRows();
  }, []);

  useEffect(() => {
    fetchCertificateReadings();
  }, []);
  
  useEffect(() => {
    fetchCertificates();
  }, [rowsPerPage, page]);

  useEffect(() => {
    initializeCertTable();
  }, [certificate]);

  if (enableDisable.length > 0) {
    var result = enableDisable
      .map(function (item) {
        if (item) {
          let [id, closed] = item.split(",");
          return closed === "true" ? id : null;
        }
      })
      .filter(function (item) {
        return item != undefined && item != null;
      });
  }

  const renderQrModal = () => {
    return (
      <Modal
        open={qrModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        size="md"
      >
        <Box sx={qrModalStyle} ref={printComponentRef}>
          <Typography
            id="transition-modal-title"
            variant="h6"
            component="h2"
            align="center"
          >
            <img src={Global} width="50" alt="IMG" />
            <b>GLOBAL CALIBRATION</b>
          </Typography>
          <hr />

          <div style={{ fontWeight: "bold" }}>
            <span>Inst Name : {instrumentName}</span>
            <br />
            <span>ID No : {idNumber}</span>
            <br />
            <span>Cal. Date : {moment(calDate).format("DD-MM-YYYY")}</span>
            <br />
            <span>
              Due Date : {moment(dueDate).format("DD-MM-YYYY")}
              <div align="right">
                <QRCode value={qrCodeUrl} size={80} align="center" />
              </div>
            </span>
          </div>

          <div align="center" className="textfield">
            {/* <QRCode value={qrCodeUrl} size={80} align="center" /> */}
            <hr />
            <Button size="small" align="center" onClick={handlePrint}>
              Print
            </Button>
          </div>
        </Box>
      </Modal>
    );
  };

  return (
    <div>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <br />
      <div
        className="mb-2"
        style={{ display: "flex", float: "left", justifyContent: "flex-end" }}
      >
        <div style={{ marginLeft: "10px", marginRight: "10px" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns} classNames="mr-2">
            <DesktopDatePicker
              label="From"
              value={from}
              inputFormat="dd/MM/yyyy"
              onChange={(newValue) => setFrom(newValue)}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
        </div>

        <div style={{ marginRight: "10px" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="To"
              value={to}
              inputFormat="dd/MM/yyyy"
              onChange={(newValue) => setTo(newValue)}
              renderInput={(params) => <TextField {...params} size="small" />}
              style={{ marginRight: "20px" }}
            />
          </LocalizationProvider>
        </div>

        <Autocomplete
          sx={{
            m: 0,
            minWidth: 180,
            marginLeft: "10px",
          }}
          size="small"
          id="combo-box-demo"
          options={[
            { key: "customerName", label: "Customer Name" },
            { key: "srnNo", label: "Srn No." },
            { key: "instrumentName", label: "Instrument Name" },
          ]}
          renderInput={(params) => <TextField {...params} label="Search By" />}
          onInputChange={(event, newInputValue) => {
            setSearchBy(newInputValue);
          }}
        />
        <TextField
          id="outlined-basic"
          label={"enter " + searchBy}
          size="small"
          variant="outlined"
          onChange={(e) => setSearchKey(e.target.value)}
        />
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          style={{ marginLeft: "10px" }}
          onClick={() => {
            search();
          }}
        >
          <SearchIcon />
        </Button>
      </div>      
       
        <hr />
        <br />
        <Table
          stickyHeader
          aria-label="sticky table"
          size="small"
          id="datatable-keytable"
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <b>{column.label}</b>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {finalData &&
              finalData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{row.srnNo}</TableCell>
                    
                      <TableCell>{row.companyName}</TableCell>
                      <TableCell>{row.instrumentName}</TableCell>
                      <TableCell>{row.ranges}</TableCell>
                      <TableCell>{row.DUCID}</TableCell>
                      <TableCell>
                        {moment(row.calibrationDate).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell>
                        {moment(row.nextDueDate).format("DD-MM-YYYY")}
                      </TableCell>
                      {userType !== "3" ? (
                        <TableCell>{row.createdBy}</TableCell>
                      ) : (
                        console.log("not a admin")
                      )}
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
        <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100, { label: "All", value: -1 }]}
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        component="div"
        colSpan={3}
        SelectProps={{
          inputProps: {
            "aria-label": "rows per page",
          },
          native: true,
        }}
        ActionsComponent={TablePaginationActions}
      />
        <ToastContainer />
      </TableContainer>
      {renderQrModal()}
    </div>
  );
}
