import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import { useParams } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextareaAutosize,
  Stack,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useSnackbar } from "notistack";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";

import { DataGrid, jaJP } from "@mui/x-data-grid";
import {
  randomCreatedDate,
  randomTraderName,
  randomUpdatedDate,
} from "@mui/x-data-grid-generator";
import { ClassicTable } from "../../utils/components/Styles";
import { API_URL } from "../../../src/global";
import { insert, view } from "../../components/common/api";
import { integerPropType } from "@mui/utils";
import { useReactToPrint } from "react-to-print";

const axios = require("axios");

export default function EditQuotation() {
  const printComponentRef = React.useRef();

  const [srfObject, createSrfObject] = React.useState({});
  var [ProductsList, setProductList] = React.useState([]);
  const [customer, setCustomer] = React.useState("");
  const [clientId, setClientId] = React.useState("");
  const [rate, setRate] = React.useState("");
  const [contact, setContact] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [productArray, setProducts] = React.useState([]);
  var [amount, setAmount] = React.useState(0);
  var [totalAmount, setTotal] = React.useState("");
  const [executing, setExecuting] = React.useState(false);
  const [clientList, setClientList] = React.useState([]);
  const [clientArray, setCLientListArray] = React.useState([]);
  const [quotations, setQuotations] = React.useState([]);
  const [quotationsReadings, setQuotationsReadings] = React.useState([]);
  const [deletedIds, setDeletedIds] = React.useState([]);
  const [contactNumber, setContactNumber] = React.useState("");
  const [quotationNumber, setQuotationNumber] = React.useState("");
  const [referenceNumber, setReferenceNumber] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [date, setDate] = React.useState("");
  const [thankYouForEnquiry, setThankYouForEnquiry] = React.useState("");
  const [conveyanceCharges, setConveyanceCharges] = React.useState("");
  const [termsConditions, setTermsConditions] = React.useState("");
  const userType = localStorage.getItem("type");
  const [gstType, setGstType] = React.useState(null);
  const [cGst, setCGST] = React.useState(0);
  const [sGst, setSGST] = React.useState(0);
  const [clientName, setClientName] = React.useState("");
  const [totalAmt, setTotalAmt] = React.useState(0);
  const [totalCounter, setTotalCounter] = React.useState(0);
  const [instrumentName, setInstrumentName] = React.useState(null);
  const params = useParams();


  
  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
  });


  const readingColumns = [
    [
      {
        field: "id",
        headerName: "Sr. No.",
        editable: false,
        width: 10,
      },
      {
        field: "productName",
        headerName: "Product Name",
        editable: true,
      },
      {
        field: "discription",
        headerName: "Discription",
        editable: true,
      },
      {
        field: "rate",
        headerName: "Rate",
        editable: true,
      },
      {
        field: "quantity",
        headerName: "Quantity",
        editable: true,
      },
      {
        field: "totalAmount",
        headerName: "Amount",
        editable: true,
      },
      {
        field: "action",
        headerName: "Action",
        editable: true,
      },
    ],
  ];

  const [readingRows, setReadingRows] = React.useState([["", "", "", "", ""]]);
  // console.log('readingRows',readingRows)

  // setTotal
  var refresh = () => {
    window.location.reload(false);
  };

  const handleSubmit = (event) => {
    setExecuting(true);
    var totalAmounts = 0;

    if (readingRows.length) {
      readingRows.map((row) => {
        totalAmounts = totalAmounts + row[4];
      });

      var rows = readingRows.map((row) => ({
        srfInstrumentId: parseInt(row[0]),
        discription: row[1],
        rate: row[2],
        quantity: row[3],
        totalAmount: totalAmounts - parseInt(conveyanceCharges),
        clientId: parseInt(clientId),
        quotationNumber: srfObject.quotationNumber,
        referenceNumber: srfObject.referenceNumber,
        subject: srfObject.subject,
        date: moment(srfObject.date).format("YYYY-MM-DD"),
        thankYouForEnquiry: srfObject.thankYouForEnquiry,
        termsConditions: srfObject.termsConditions,
        conveyanceCharges: conveyanceCharges,
      }));
    }
    console.log(rows)
    return
    rows.map((rows) => {
      let url = BASE_URL;
      axios
        .patch(
          url + `quotation/${params.clientId}/${params.quotationNumber}`,
          rows
        )
        .then((res) => {
          toast("Quotation updated successfully !");
          setTimeout(refresh, 500);
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    });
  };

  // const getStandard = () => {
  //       let url = BASE_URL;
  //       axios
  //           .get(
  //               url +
  //                   `xjoin?_join=qt.quotation,_j,client.clients,&_on1=(qt.clientId,eq,client.id)&_fields=qt.quotationNumber,qt.referenceNumber,qt.subject,qt.date,qt.conveyanceCharges,qt.thankYouForEnquiry,qt.termsConditions,qt.srfInstrumentId,qt.rate,qt.quantity,qt.totalAmount,client.companyName,client.address&_where=(qt.id,eq,${params.id})~and(ds.status,eq,1)`
  //           )
  //           .then((res) => {
  //               set(res.data[0].ds_standardName);
  //               setMake(res.data[0].ds_make);
  //               setTraceability(res.data[0].ds_traceability);
  //               setValidUpto(res.data[0].ds_validUpto);
  //               setCertificateNumber(res.data[0].ds_certificateNumber);
  //               setDeciplines(res.data[0].disciplines_name);
  //               setStId(res.data[0].ds_stId);
  //               setCalDate(res.data[0].ds_calDate);
  //           })
  //           .catch((error) => {
  //               toast.error("Something Went Wrong with standards!");
  //           });
  //   };

  // data operations
  const updateSrfObject = (key, value) => {
    let newSrfObject = {
      ...srfObject,
    };
    newSrfObject[key] = value;
    createSrfObject(newSrfObject);
  };

  const updateCellValue = (rowIndex, colIndex, value) => {
    const newRows = [...readingRows];
    newRows[rowIndex][colIndex] = value;
    setReadingRows([...newRows]);
  };

  const addDescriptionRow = () => {
    let newReadingRows = [...readingRows];
    newReadingRows.push([-1, "", "", "", "", ""]);
    setReadingRows([...newReadingRows]);
  };

  const deleteReadingRow = (rowIndex) => {
    let newReadingRows = [...readingRows];
    if (newReadingRows[rowIndex] != undefined) {
      setDeletedIds([...deletedIds, newReadingRows[rowIndex][0]]);
      newReadingRows.splice(rowIndex, 1);
      setReadingRows([...newReadingRows]);
    }
  };

  const updateAmount = (value) => {
    var newRowsData = [...amount];
    newRowsData += parseInt(value);
    setAmount(...newRowsData);
  };

  const getCustomer = (event) => {
    let url = BASE_URL;
    axios
      .get(url + "clients?_where=(status,eq,1)")
      .then((res) => {
        setCustomer(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getQuotations = (event) => {
    let url = BASE_URL;
    axios
      .get(
        url +
        `xjoin?_join=qt.quotation,_j,client.clients,_j,pr.customProducts&_on1=(qt.clientId,eq,client.id)&_on2=(qt.srfInstrumentId,eq,pr.id)&_fields=qt.id,qt.productId,qt.quotationNumber,qt.referenceNumber,qt.date,qt.totalAmount,client.companyName,client.contact,client.address,client.id,qt.srfInstrumentId,qt.subject,qt.termsConditions,qt.thankYouForEnquiry,qt.conveyanceCharges,qt.gstType,qt.srfInstrumentId,pr.productName&_where=(qt.clientId,eq, ${params.clientId})~and(qt.quotationNumber,eq,${params.quotationNumber})`      )
      .then((res) => {
        console.log("sss",res.data)
        setConveyanceCharges(res.data[0].qt_conveyanceCharges);
        setQuotationNumber(res.data[0].qt_quotationNumber);
        setReferenceNumber(res.data[0].qt_referenceNumber);
        setSubject(res.data[0].qt_subject);
        setDate(res.data[0].qt_date);
        setThankYouForEnquiry(res.data[0].qt_thankYouForEnquiry);
        setTermsConditions(res.data[0].qt_termsConditions);
        setClientName(
          res.data[0].client_id + ", " + res.data[0].client_companyName
        );
        setContact(res.data[0].client_contact);
        setAddress(res.data[0].client_address);
        setTotalAmt(res.data[0].qt_totalAmount);
        setGstType(res.data[0].qt_gstType);
        setInstrumentName(res.data[0].pr_productName);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const getQuotationsReadings = (event) => {
    let url = BASE_URL;
    axios
      .get(
        url +
          `xjoin?_join=qt.quotation,_j,client.clients,_j,pr.customProducts&_on1=(qt.clientId,eq,client.id)&_on2=(qt.srfInstrumentId,eq,pr.id)&_fields=qt.id,qt.productId,qt.quotationNumber,qt.referenceNumber,qt.date,qt.totalAmount,client.companyName,client.contact,qt.srfInstrumentId,qt.rate,qt.quantity,qt.discription,pr.productName&_where=(qt.clientId,eq, ${params.clientId})~and(qt.quotationNumber,eq,${params.quotationNumber})`
      )
      .then((res) => {
        setQuotationsReadings(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const initiateRanges = () => {
    let rows = [];
    for (let i = 0; i < quotationsReadings.length; i++) {
      console.log("quotationsReadings", quotationsReadings[i]);
      rows.push([
        quotationsReadings[i].pr_productName,
        quotationsReadings[i].qt_discription,
        quotationsReadings[i].qt_rate,
        quotationsReadings[i].qt_quantity,
        quotationsReadings[i].qt_rate * quotationsReadings[i].qt_quantity,
      ]);
    }
    console.log(rows)
    setReadingRows(rows);
  };

  const initiateClients = () => {
    var pushArray = [];
    var pushCArray = [];

    for (let i = 0; i < customer.length; i++) {
      pushArray.push({
        id: customer[i].id,
        label: customer[i].companyName,
        contact: customer[i].contact,
        address: customer[i].address,
      });
      pushCArray.push({
        label: customer[i].id + ", " + customer[i].companyName,
      });
    }
    setClientList(pushArray);
    setCLientListArray(pushCArray);
  };

  const getProductList = () => {
    let url = BASE_URL;
    axios
      .get(
        url +
          "xjoin?_join=pr.products,_j,ins.instruments&_on1=(pr.srfInstrumentId,eq,ins.id)&_fields=pr.id,pr.rate,ins.instrumentName,ins.id,pr.id&_where=(pr.status,eq,1)"
      )
      .then((res) => {
        setProductList(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const initiateProducts = () => {
    let productsArray = [];

    for (let i = 0; i < ProductsList.length; i++) {
      productsArray.push({
        label: ProductsList[i].pr_id + "," + ProductsList[i].ins_instrumentName,
        rate: ProductsList[i].pr_rate,
      });
    }
    setProducts(productsArray);
  };

  const calculateTotalAmt = () => {
    let counter = 0;
    quotationsReadings.forEach(element => {
        counter += (element.qt_quantity * element.qt_rate)
    });
    setTotalCounter(counter)
  }

  var refresh = () => {
    window.location.reload(false);
    // <Link to="/master/standard">Home</Link>
  };

  useEffect(() => {
    initiateRanges();
  }, [quotationsReadings]);

  useEffect(() => {
    calculateTotalAmt();
  }, [quotationsReadings]);

  useEffect(() => {
    initiateProducts();
  }, [ProductsList]);

  useEffect(() => {
    initiateClients();
  }, [customer]);

  useEffect(() => {
    getQuotationsReadings();
    getQuotations();
    getCustomer();
    getProductList();
  }, []);

  return (
    <>
      <Paper sx={{ mx: 5, mt: 2, p: 2 }} style={{ border: '1px solid' }}>
      <div className="invoice" ref={printComponentRef}>
        <div style={{ padding: '10px', margin: '10px' }}>
          <h1>Quotation</h1>
          <hr />

          <div className="row" style={{ border: '1px solid', marginLeft: '1px', marginRight: '1px' }}>
            <div className="col-6 text-start">
              <span>To, <br /> <span>M/S.<b>{clientName}</b></span></span>
            </div>
            <div className="col-6 text-start">
              <span>Page No : <br />QUOTATION NO : {quotationNumber}<br /> DATE : <b>{moment(date).format("DD-MM-YYYY")}</b></span>
            </div>
          </div>
          <div className="row text-start" style={{ border: '1px solid', marginLeft: '1px', marginRight: '1px' }}>
            <span>Dear Sir / Madam, <br />Thank you for your valuable enquiry and we are pleased to submit our competitive Quotation.</span>
          </div>
          <table className="mt-2 mb-2" width="100%">
            <thead>
              <tr>
                <th>Sr. No. </th>
                <th>Product Name </th>
                <th>Description</th>
                <th>Rate</th>
                <th>Quantity</th>
                <th>Amount</th>
              </tr>
            </thead>
            {quotationsReadings.map((row, index) => (
              <tbody>
                <tr>
                  <td>{index + 1}</td>
                  <td>{row.pr_productName}</td>
                  <td>{row.qt_discription}</td>
                  <td>{row.qt_rate}</td>
                  <td>{row.qt_quantity}</td>
                  <td>{row.qt_quantity * row.qt_rate}</td>
                </tr>
              </tbody>
            ))}
            <tbody>
              <tr>
                <td colspan="5">Conveyance Charges</td>
                <td>{conveyanceCharges}</td>
              </tr>
              {gstType == 2 ?
                    <tr>
                      <td colspan="5">IGST@18%</td>
                      <td colspan="1">{18 * totalCounter / 100}</td>
                    </tr>
                    : ''}
                  {gstType == 1 ?
                    <tr>
                      <td colspan="5">CGST@9%</td>
                      <td colspan="1">{9 * totalCounter / 100}</td>
                    </tr>
                    : ''}
                  {gstType == 1 ?
                    <tr>
                      <td colspan="5">SGST@9%</td>
                      <td colspan="1">{9 * totalCounter / 100}</td>
                    </tr>
                    : ''}
              <tr>
                <td colspan="5">Total</td>
                <td>{totalCounter}</td>
              </tr>
              
            </tbody>
          </table>
          <div className="row" style={{ border: '1px solid', marginLeft: '1px', marginRight: '1px', height: '100px' }}>

          </div>
          <div className="row text-start" style={{ border: '1px solid', marginLeft: '1px', marginRight: '1px' }}>
            Terms/Declaration
          </div>
          <div className="row text-start" style={{ border: '1px solid', marginLeft: '1px', marginRight: '1px' }}>
            <span>1. Validity : 30 days from the date of our offer.</span><br />
            <span>2. Payment : 100% against Performance Invocie.</span><br />
            <span>3. Visting charges : Depends on distance.</span><br />
            <span>4 CERTIFICATE : Calibration or testing certificate will be dispatch within 15 days after completion of work.</span><br />
          </div>
          <div className="row text-start" style={{ border: '1px solid', marginLeft: '1px', marginRight: '1px' }}>
            <div className="col-6">
              {/* <span>Bank Details</span><br />
              <span style={{ float: 'left', paddingLeft: '5px' }}>Account Name : S V Precision Instruments.</span><br />
              <span style={{ float: 'left', paddingLeft: '5px' }}>Account No : 50200069882396.</span><br />
              <span style={{ float: 'left', paddingLeft: '5px' }}>BRANCH/IFSC Code: HDFC Bank / HDFC0006712 </span> */}
            </div>
            <div className="col-6">
              <span><br /><br /><br />For Global Calibration</span><br />
            </div>
          </div>



        </div>
      </div>
      </Paper>

      <Toolbar style={{ padding: "0px", width: "100%" }}>
        <Button
          variant="contained"
          size="small"
          sx={{ ml: 3 }}
          onClick={handlePrint}
        >
          Print
        </Button>
      </Toolbar>
    </>
  );
}
