import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { ClassicTable } from "../../../utils/components/Styles";
import moment from "moment";
const axios = require("axios");

export default function CreateStandard(props) {
  const [srfObject, createSrfObject] = React.useState({});
  var [standardArray, setStandardArray] = React.useState([]);
  const [image, setImage] = React.useState("");
  const [unitArray, setUnitArray] = React.useState([]);
  const [units, setUnits] = React.useState([]);
  const [unitFilter, setUnitFilter] = React.useState([]);
  var [readingRows, setReadingRows] = React.useState([
    ["", "", "", "", "", "", "", "", "", "", ""],
  ]);

  const [percent, setPercent] = React.useState(false);
  const [plus, setPlus] = React.useState(false);
  const [acc, setAccuracy] = React.useState(false);
  const [readingRangeValue, setReadingRangeValue] = React.useState([
    ["", "", "", "", "", "", "", "", "", "", ""],
  ]);

  const handleChange = async (e) => {
    setImage(e.target.files[0]);
  };

  const handleApi = () => {
    //call the api
    const url = "https://login.globalcalibration.com/fileUpload.php";

    const data = new FormData();
    data.append("fileToUpload", image);
    axios.post(url, data).then((res) => {
      if (res.status == 200) {
        toast("File Uploaded successfully !");
        setTimeout(refresh, 500);
      }
    });
  };
  const getUnitMasterArray = () => {
    let url = BASE_URL;
    axios
      .get(url + "unit?_where=(status,eq,1)")
      .then((res) => {
        setUnitArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const updateRangeCellValue = (rowIndex, colIndex, value) => {
    const newRowss = [...readingRangeValue];
    newRowss[rowIndex][colIndex] = value;
    setReadingRangeValue([...newRowss]);
  };

  const getStandardIdList = () => {
    let url = BASE_URL;
    axios
      .get(url + "discipline?_where=(status,eq,1)")
      .then((res) => {
        setStandardArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  useEffect(() => {
    getStandardIdList();
    getUnitMasterArray();
  }, []);

  var readingColumns = [
    [
      {
        field: "id",
        headerName: "Sr. No.",
        editable: false,
      },
      {
        field: "range",
        headerName: "Range",
        editable: true,
      },
      {
        field: "uncertainty",
        headerName: "Uncertainty",
        editable: true,
      },
      {
        field: "accuracy",
        headerName: "Accuracy",
        editable: true,
      },
      {
        field: "percentOfOutput",
        headerName: "% Of Output",
        editable: true,
      },
      {
        field: "plusValue",
        headerName: "+ Value",
        editable: true,
      },
      {
        field: "stability",
        headerName: "Stability",
        editable: true,
      },
      {
        field: "uniformity",
        headerName: "Uniformity",
        editable: true,
      },
      {
        field: "reaction",
        headerName: "reaction",
        editable: true,
      },
      {
        field: "gravity",
        headerName: "Gravity",
        editable: true,
      },
      {
        field: "drift",
        headerName: "Drift",
        editable: true,
      },

      {
        field: "Action",
      },
    ],
  ];
  // submit form
  const submitDescriptionRows = (readingRows, id) => {
    if (percent == false) {
      var rows = readingRows.map((row, i) => ({
        standardId: id,
        rangeName:
          readingRangeValue[i][1] != null && row[1]!=''
            ? row[1] + "," + readingRangeValue[i][1]
            : row[1],
        uncertainty:
          readingRangeValue[i][2] != null &&row[2]!=''
            ? row[2] + "," + readingRangeValue[i][2]
            : row[2],
        accuracy:
          readingRangeValue[i][3] != null&&row[3]!=''
            ? row[3] + "," + readingRangeValue[i][3]
            : row[3],
        percentOfOutput:
          readingRangeValue[i][4] != null&&row[4]!=''
            ? row[4] + "," + readingRangeValue[i][4]
            : row[4],
        plusValue:
          readingRangeValue[i][5] != null&&row[5]!=''
            ? row[5] + "," + readingRangeValue[i][5]
            : row[5],
        stability:
          readingRangeValue[i][6] != null&&row[6]!=''
            ? row[6] + "," + readingRangeValue[i][6]
            : row[6],
        uniformity:
          readingRangeValue[i][7] != null&&row[7]!=''
            ? row[7] + "," + readingRangeValue[i][7]
            : row[7],
        reactionTime:
          readingRangeValue[i][8] != null&&row[8]!=''
            ? row[8] + "," + readingRangeValue[i][8]
            : row[8],
        gravity:
          readingRangeValue[i][9] != null&&row[9]!=''
            ? row[9] + "," + readingRangeValue[i][9]
            : row[9],
        drift:
          readingRangeValue[i][10] != null&&row[10]!=''
            ? row[10] + "," + readingRangeValue[i][10]
            : row[10],
      }));
    } else {
      var rows = readingRows.map((row, i) => ({
        standardId: id,
        rangeName:
          readingRangeValue[i][1] != null&&row[1]!=''
            ? row[1] + "," + readingRangeValue[i][1]
            : row[1],
        uncertainty:
          readingRangeValue[i][2] != null&&row[2]!=''
            ? row[2] + "," + readingRangeValue[i][2]
            : row[2],
        accuracy:
          readingRangeValue[i][3] != null&&row[3]!=''
            ? row[3] + "," + readingRangeValue[i][3]
            : row[3],
        stability:
          readingRangeValue[i][6] != null&&row[6]!=''
            ? row[6] + "," + readingRangeValue[i][6]
            : row[6],
        uniformity:
          readingRangeValue[i][7] != null&&row[7]!=''
            ? row[7] + "," + readingRangeValue[i][7]
            : row[7],
        reactionTime:
          readingRangeValue[i][8] != null&&row[8]!=''
            ? row[8] + "," + readingRangeValue[i][8]
            : row[8],
        gravity:
          readingRangeValue[i][9] != null&&row[9]!=''
            ? row[9] + "," + readingRangeValue[i][9]
            : row[9],
        drift:
          readingRangeValue[i][10] != null&&row[10]!=''
            ? row[10] + "," + readingRangeValue[i][10]
            : row[10],
      }));
    }
    let url = BASE_URL;
    axios
      .post(url + "standardRanges/bulk", rows)
      .then((res) => {
        toast.success("Standard created successfully!");
        props.setLoader(false);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
        props.setLoader(false);
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const handleSubmit = (event) => {
    let row = {
      disciplineId: srfObject.disciplineId,
      standardName: srfObject.standardName,
      make: srfObject.make,
      certificateNumber: srfObject.certificateNumber,
      traceability: srfObject.traceability,
      calDate: moment(srfObject.calDate).format("YYYY-MM-DD"),
      validUpto: moment(srfObject.validUpto).format("YYYY-MM-DD"),
      stId: srfObject.ids,
      type:1,
    };

    let url = BASE_URL;
    axios
      .post(url + "standards", row)
      .then((res) => {
        var insertedID = res.data.insertId;
        submitDescriptionRows(readingRows, insertedID);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
        props.setLoader(false)
      });
  };

  // data operations
  const updateSrfObject = (key, value) => {
    let newSrfObject = {
      ...srfObject,
    };
    newSrfObject[key] = value;
    createSrfObject(newSrfObject);
  };

  const updateCellValue = (rowIndex, colIndex, value) => {
    const newRows = [...readingRows];
    newRows[rowIndex][colIndex] = value;
    setReadingRows([...newRows]);
  };

  const addDescriptionRow = (tableIndex) => {
    let newReadingRows = [...readingRows];
    newReadingRows.push(["", "", "", "", "", "", "", "", "", "", ""]);
    setReadingRows([...newReadingRows]);
    let newReadingRowss = [...readingRangeValue];
    newReadingRowss.push([
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ]);
    setReadingRangeValue([...newReadingRowss]);
  };

  const deleteReadingRow = (tableIndex, rowIndex) => {
    let newReadingRows = [...readingRows];
    newReadingRows.splice(rowIndex, 1);
    setReadingRows([...newReadingRows]);
  };

  const UnitList = () => {
    var uArr = [];
    for (let i = 0; i < units.length; i++) {
      uArr.push({
        label: units[i].id + ", " + units[i].symbol,
      });
    }
    setUnitFilter(uArr);
  };

  useEffect(() => {
    UnitList();
  }, [units]);
  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Create standard
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={standardArray.map(({ id, name }) => ({
              label: id + ", " + name,
            }))}
            renderInput={(params) => (
              <TextField {...params} label="Discipline *" />
            )}
            onInputChange={(event, newInputValue) => {
              var disciplineKeys = false;
              standardArray.forEach((el) => {
                if (el.id == newInputValue.split(",")[0]) {
                  if (el.disciplineKey == "0") {
                    disciplineKeys = true;
                  }
                }
              });

              const unitArr = [];
              for (let i = 0; i < unitArray.length; i++) {
                if (
                  parseInt(newInputValue.split(",")[0]) ==
                  unitArray[i].disciplineId
                ) {
                  unitArr.push(unitArray[i]);
                }
              }
              setUnits(unitArr);

              updateSrfObject(
                "disciplineId",
                parseInt(newInputValue.split(",")[0])
              );

              if (disciplineKeys) {
                setPercent(false);
                setPlus(false);
                setAccuracy(false);
              } else {
                setPercent(true);
                setPlus(true);
                setAccuracy(false);
              }
            }}
          />
        </Grid>

        <Grid item xs={2}>
          <TextField
            id="outlined-basic"
            label="Standard name *"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("standardName", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="outlined-basic"
            label="id/Sr No. *"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("ids", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="outlined-basic"
            label="Make/Model *"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("make", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="outlined-basic"
            label="Certificate Number *"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("certificateNumber", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="outlined-basic"
            label="Cal. Agency. *"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("traceability", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Cal Date *"
              inputFormat="dd/MM/yyyy"
              value={srfObject.calDate ? srfObject.calDate : new Date()}
              onChange={(newValue) => {
                updateSrfObject("calDate", new Date(newValue));
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Due Dt. *"
              inputFormat="dd/MM/yyyy"
              value={srfObject.validUpto ? srfObject.validUpto : new Date()}
              onChange={(newValue) => {
                updateSrfObject("validUpto", new Date(newValue));
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2}>
          <input type="file" onChange={handleChange} />
          <Toolbar style={{ padding: "0px", overflow: "auto" }}>
            <Button
              variant="contained"
              size="small"
              sx={{ m: 0 }}
              onClick={handleApi}
            >
              SUBMIT
            </Button>
          </Toolbar>
        </Grid>
      </Grid>
      <hr />
      <h4 style={{ "margin-bottom": "0px" }}>Standard Ranges</h4>
      <Button
        style={{ float: "right" }}
        onClick={(e) => {
          addDescriptionRow(0);
        }}
      >
        <AddIcon />
      </Button>
      <div style={{ width: "100%", overflow: "auto" }}>
        <ClassicTable>
          <Table sx={{ minWidth: 660 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {readingColumns[0].map((column) => (
                  <TableCell key={column.field}>
                    {" "}
                    <Typography noWrap>{column.headerName}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {readingRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  {row.map((cell, cellIndex) => {
                    if (
                      cellIndex != 3 &&
                      cellIndex != 4 &&
                      cellIndex != 5 &&
                      cellIndex != 0
                    ) {
                      return (
                        <TableCell>
                          <TextField
                            size="small"
                            onChange={(e) => {
                              updateCellValue(index, cellIndex, e.target.value);
                            }}
                          ></TextField>

                          <select
                            disabled={acc}
                            styles={{ width: "100%" }}
                            onChange={(e) => {
                              updateRangeCellValue(
                                index,
                                cellIndex,
                                e.target.value
                              );
                            }}
                          >
                            {units.map((option) => (
                              <option value={option.symbol}>
                                {option.symbol}
                              </option>
                            ))}
                          </select>
                        </TableCell>
                      );
                    }

                    if (cellIndex == 3) {
                      return (
                        <TableCell>
                          <TextField
                            size="small"
                            disabled={acc}
                            onChange={(e) => {
                              updateCellValue(index, cellIndex, e.target.value);
                            }}
                          ></TextField>

                          <select
                            disabled={acc}
                            styles={{ width: "100%" }}
                            onChange={(e) => {
                              updateRangeCellValue(
                                index,
                                cellIndex,
                                e.target.value
                              );
                            }}
                          >
                            {units.map((option) => (
                              <option value={option.symbol}>
                                {option.symbol}
                              </option>
                            ))}
                          </select>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 4) {
                      return (
                        <TableCell>
                          <TextField
                            size="small"
                            disabled={percent}
                            onChange={(e) => {
                              updateCellValue(index, cellIndex, e.target.value);
                            }}
                          ></TextField>

                          <select
                            disabled={percent}
                            styles={{ width: "100%" }}
                            onChange={(e) => {
                              updateRangeCellValue(
                                index,
                                cellIndex,
                                e.target.value
                              );
                            }}
                          >
                            {units.map((option) => (
                              <option value={option.symbol}>
                                {option.symbol}
                              </option>
                            ))}
                          </select>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 5) {
                      return (
                        <TableCell>
                          <TextField
                            size="small"
                            disabled={plus}
                            onChange={(e) => {
                              updateCellValue(index, cellIndex, e.target.value);
                            }}
                          ></TextField>

                          <select
                            disabled={plus}
                            styles={{ width: "100%" }}
                            onChange={(e) => {
                              updateRangeCellValue(
                                index,
                                cellIndex,
                                e.target.value
                              );
                            }}
                          >
                            {units.map((option) => (
                              <option value={option.symbol}>
                                {option.symbol}
                              </option>
                            ))}
                          </select>
                        </TableCell>
                      );
                    }
                  })}
                  <TableCell>
                    <DeleteIcon
                      style={{ color: "#dc3545" }}
                      onClick={(e) => {
                        deleteReadingRow(0, index);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ClassicTable>
      </div>

      <br />
      <Toolbar style={{ padding: "0px", overflow: "auto" }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            props.setLoader(true)
            handleSubmit();
          }}
        >
          Save
        </Button>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0, ml: 2 }}
          onClick={() => {
            // handleSubmit();
          }}
        >
          Print
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
